import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../../../../services/api";
import AlertModal from "../../../../../componentes/alertModal";

export default function EditModalCDN(params) {
  const {
    setOpenEditModal,
    openEditModal,
    setSnackbar,
    setLoadingTable,
    styles,
    selectedRow,
    handleEditCdns,
    validarIp,
  } = params;
  const [disableEditButton, setDisableEditButton] = useState(false);
  const [asNum, setAsNum] = useState("");
  const [asName, setAsName] = useState("");
  const [tipo, setTipo] = useState("");
  const [bloco, setBloco] = useState("");
  //alert modal
  const [confirmarEdicao, setConfirmarEdicao] = useState(false);
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && disableEditButton === false) {
      handleApiPutCdn();
    }
  };
  const handleHelperText = (ip) => {
    if (ip) {
      return validarIp(ip) === 1
        ? "O endereço IPv4 é válido!"
        : validarIp(ip) === 2
        ? "O endereço IPv6 é válido!"
        : "Formato de IP inválido";
    }
    return null;
  };
  useEffect(() => {
    if (selectedRow) {
      setAsName(selectedRow.as_name);
      setAsNum(selectedRow.as_num);
      setBloco(selectedRow.bloco);
      setTipo(selectedRow.tipo);
    }
  }, [selectedRow]);
  useEffect(() => {
    if (!asName || !asNum || !tipo || validarIp(bloco) === 0) {
      setDisableEditButton(true);
    } else {
      setDisableEditButton(false);
    }
  }, [asName, asNum, bloco, tipo]);
  async function handleApiPutCdn() {
    try {
      setLoadingTable(true);
      setDisableEditButton(true);
      const response = await api.put("/relatoriosFlow/cdn/update", {
        id: selectedRow.id,
        as_num: asNum,
        as_name: asName,
        tipo,
        bloco,
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados Salvos com sucesso",
          severity: "success",
        });
        if (response.data) {
          handleEditCdns(response.data);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoadingTable(false);
      setDisableEditButton(false);
      setOpenEditModal(false);
      setConfirmarEdicao(false);
      setOpenAlertModal(false);
    }
  }
  return (
    <>
      <Modal
        open={openEditModal}
        onClose={() => {
          setOpenEditModal(false);
        }}
        sx={{ alignItems: "center", justifyContent: "center", display: "flex" }}
      >
        <Paper sx={styles.paperModal} elevation={3}>
          <DialogTitle color="primary">Edite CDN </DialogTitle>{" "}
          <DialogContent sx={styles.dialogContent}>
            {" "}
            <Stack direction="row" spacing={"20px"} sx={{ mt: 1 }}>
              <TextField
                sx={{ width: "100%" }}
                id="textfield-porta"
                label="AS Nome"
                value={asName}
                onChange={(e) => setAsName(e.target.value)}
              />
            </Stack>
            <Stack direction="row" spacing={"20px"}>
              <TextField
                sx={styles.TextField}
                id="textfield-asnnum"
                label="ASN"
                type="number"
                value={asNum}
                onChange={(e) => setAsNum(e.target.value)}
              />
              <TextField
                sx={styles.TextField}
                id="textfield-tipo"
                label="Tipo"
                value={tipo}
                onChange={(e) => setTipo(e.target.value)}
              />
            </Stack>
            <Stack direction="row" spacing={"20px"}>
              <TextField
                sx={{ width: "100%" }}
                id="textfield-bloco"
                label="Bloco"
                onKeyDown={handleKeyDown}
                value={bloco}
                error={bloco ? !validarIp(bloco) : false}
                helperText={handleHelperText(bloco)}
                onChange={(e) => setBloco(e.target.value)}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              onClick={() => {
                setOpenEditModal(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              disabled={disableEditButton}
              onClick={() => {
                setOpenAlertModal(true);
                // handleApiPutCdn();
              }}
            >
              Editar
            </Button>
          </DialogActions>
        </Paper>
      </Modal>
      <AlertModal
        openModal={openAlertModal}
        setOpenModal={setOpenAlertModal}
        confirmar={confirmarEdicao}
        handleButton={handleApiPutCdn}
        severity={"warning"}
        singleButton={false}
      />
    </>
  );
}
