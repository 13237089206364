import {
  Box,
  Card,
  CardActionArea,
  Stack,
  Tooltip,
  Typography,
  createSvgIcon
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { ReactComponent as RouterSVG } from "../../../../assets/icons/router.svg";
import { DateRange, Settings } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import flow from "../../../../assets/flow.png";
import mon from "../../../../assets/mon.png";
export default function RelatorioCard(props) {
  const { relatorio } = props;
  const RouterIcon = createSvgIcon(<RouterSVG />);
  const navigate = useNavigate();
  const theme = useTheme();

  const styles = {
    backgroundTagCard: {
      background:
        theme.palette.mode == "dark" ? "rgba(0,0,0,0.5)" : "#5a2e87ff",
      color: "white"
    },
    container: {
      width: "100%"
      //minWidth: "355px",
    },
    cardAction: { padding: "16px" },
    icon: { color: "primary.main", fontSize: "25px" },
    boxDate: { display: "flex", alignItems: "center", gap: 1 }
  };

  const CardWrapper = styled(Card)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "1.5rem",
    alignItems: "center",
    ml: "20px ",
    overflow: "hidden",
    position: "relative",
    minWidth: "320px",
    width: "100%",
    borderRadius: "5px",
    "&:before": {
      content: '""',
      position: "absolute",
      width: "8px",
      height: "100%",
      background: theme.palette.chip.completed,
      left: 0
    }
  }));
  const agrupaMetricas = (host) => {
    let metricas = "";
    const {
      disponibilidade = "",
      valorMaximo = "",
      valorMinimo = "",
      valorMedio = "",
      tarifacao = "",
      horarioPersonalizado = ""
    } = host;

    if (disponibilidade)
      metricas += "tempo de disponibilidade, taxa de disponibilidade, ";
    if (valorMaximo) metricas += "valor máximo, ";
    if (valorMinimo) metricas += "valor mínimo, ";
    if (valorMedio) metricas += "valor médio, ";
    if (tarifacao) metricas += "tarifação 95th (noventa e cinco percentil), ";
    if (horarioPersonalizado) metricas += "horário personalizado, ";

    metricas = metricas.replace(/,\s*$/, "");

    return metricas || "Nenhuma";
  };

  return (
    <CardWrapper elevation={3} sx={styles.container}>
      <CardActionArea
        sx={styles.cardAction}
        onClick={() => {
          navigate(`/easyReport/agendar/historico/${relatorio.id}`);
        }}
      >
        <Stack
          direction={"column"}
          alignItems={"start"}
          justifyContent={"start"}
          gap={"14px"}
          height={"100%"}
        >
          <Stack
            sx={{
              ml: "10px",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%"
            }}
            direction="row"
          >
            {" "}
            <Typography fontWeight={500}>{relatorio.titulo}</Typography>
            <Box
              sx={[
                styles.backgroundTagCard,
                {
                  padding: "7px",
                  mr: "10px",
                  height: "30px",
                  display: "flex",
                  alignItems: "center"
                }
              ]}
            >
              <img
                width="85px"
                src={
                  relatorio.tipo === 0
                    ? mon
                    : relatorio.tipo === 1
                    ? flow
                    : null
                }
              />
            </Box>
          </Stack>{" "}
          <Tooltip
            title={"Hosts: " + relatorio.hosts.map((host) => host.nome + " ")}
            placement="bottom-start"
          >
            <Stack direction={"row"} width={"100%"} spacing={1}>
              <RouterIcon sx={styles.icon} />
              <Typography noWrap textOverflow={"ellipsis"} overflow={"hidden"}>
                {relatorio.hosts.map((host) => host.nome + " ")}
              </Typography>
            </Stack>
          </Tooltip>
          <Tooltip
            placement="bottom-start"
            title={
              relatorio.tipo === 0 &&
              relatorio?.hosts[0]?.itens[0]?.configuracoes
                ? agrupaMetricas(relatorio?.hosts[0]?.itens[0]?.configuracoes)
                : " Classes: CDN, FNA, IX/PTT, GGC, OCA|NETFLIX"
            }
          >
            <Stack direction={"row"} width={"100%"} spacing={1}>
              <Settings sx={styles.icon} />

              <Typography noWrap textOverflow={"ellipsis"} overflow={"hidden"}>
                {/* {relatorio.hosts.map((host) => host.nome + " ")} */}
                {relatorio.tipo === 0 &&
                relatorio?.hosts[0]?.itens[0]?.configuracoes
                  ? agrupaMetricas(relatorio?.hosts[0]?.itens[0]?.configuracoes)
                  : " Classes: CDN, FNA, IX/PTT, GGC, OCA|NETFLIX"}
              </Typography>
            </Stack>{" "}
          </Tooltip>
          <Stack direction={"row"} width={"100%"} spacing={2}>
            <Box sx={styles.boxDate}>
              <DateRange sx={styles.icon} />
              <Stack direction={"column"} width={"100%"}>
                <Typography fontSize={"14px"} fontWeight={500}>
                  Data inical
                </Typography>
                <Typography>{relatorio.dataInicial}</Typography>
              </Stack>
            </Box>
            <Box sx={styles.boxDate}>
              <DateRange sx={styles.icon} />
              <Stack direction={"column"} width={"100%"}>
                <Typography fontSize={"14px"} fontWeight={500}>
                  Data final
                </Typography>
                <Typography>{relatorio.dataFinal}</Typography>
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </CardActionArea>
    </CardWrapper>
  );
}
