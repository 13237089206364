import { Paper, Typography, Stack, Box, Link, useTheme } from "@mui/material";

import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";

export default function CardMaps(props) {
  const {
    variant = "selected",
    link,
    title,
    alertas,
    tipo,
    onClick = () => {},
  } = props;

  const theme = useTheme();

  const SvgMenu = ({ selected, sx }) => {
    return (
      <svg
        style={sx}
        width="180"
        height="40"
        viewBox="0 0 163 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0.50119H127.163L163 22.5012H157.798L126.007 2.5477H0V0.50119Z"
          fill={theme.palette.mode == "dark" ? "white" : "#313131"}
        />{" "}
        {/* Primeiro path */}
        <path
          d="M146.799 21.8481L120 4.50119H124.937L157.377 24.9094L163 24.8261L160.904 29.5012H154.556L146.799 24.9094V21.8481Z"
          fill={
            selected == true
              ? "#e83750"
              : theme.palette.mode == "dark"
              ? "white"
              : "#313131"
          }
        />{" "}
        {/* Segundo path */}
      </svg>
    );
  };

  const styles = {
    iconLinked: {
      transform: "rotate(90deg)",
      fontSize: "30px",
    },
    icon: {
      pointerEvents: "none",
      height: "32px",
      marginBottom: "-32px",
    },
    title: {
      ml: "2px",
      fontSize: "1em",
      maxWidth: "135px",
      overflow: "hidden",
      textAlign: "left",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    line: {
      backgroundColor: "primary.main",
      height: "60px",
      width: "3px",
    },

    cardlinked: {
      width: "100%",
      backgroundColor: theme.palette.background.paperSelectedMaps,
      boxShadow: "0px 0px 5px  #0000001a",
      position: "relative",
      padding: "0px 5px",
      display: "flex",
      direction: "row",
      height: "100px",
      alignItems: "center",
      gap: 1,
      "&:hover": {
        backgroundColor: theme.palette.background.hover,
      },
    },
    cardType: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      right: 10,
      top: 0,
      backgroundColor: "primary.main",
      height: "16px",
      width: "50px",
    },
  };

  return (
    <Paper elevation={0} sx={[styles.cardlinked]} onClick={onClick}>
      <LinkOutlinedIcon
        sx={[
          styles.iconLinked,
          { color: theme.palette.mode == "dark" ? "#565656" : "#9A9A9A" },
        ]}
      />

      <Stack direction={"column"} gap={0.5}>
        <Stack direction="row" alignItems={"center"}>
          <ReportProblemOutlinedIcon
            sx={{
              fontSize: "14px",
              color: "primary.main",
            }}
          />
          <Typography fontSize="12px">{alertas + " Alertas"}</Typography>
        </Stack>
        <SvgMenu selected={variant == "selected"} sx={styles.icon} />

        <Typography sx={styles.title}>{title}</Typography>
      </Stack>

      <Box sx={styles.cardType}>
        <Typography sx={{ fontSize: "12px", fontWeight: 500, color: "white" }}>
          {tipo == 0 ? "GEO" : "TOP"}
        </Typography>
      </Box>
    </Paper>
  );
}
