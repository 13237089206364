import {
  ContentCopyOutlined,
  DeleteOutlineOutlined,
  EditOutlined,
} from "@mui/icons-material";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import React, { useCallback } from "react";
import { useReactFlow } from "reactflow";

export default function NodeMenu({
  id,
  top,
  left,
  open,
  node = null,
  onClose = () => {},
  handleDelete = () => {},
  handleCreate = () => {},
  editNode = () => {},
}) {
  const { getNode } = useReactFlow();
  const duplicateNode = useCallback(() => {
    if (!node) {
      const catNode = getNode(id);
      handleCreate(catNode);
    } else {
      handleCreate(node);
    }
    onClose();
  }, [id, getNode]);

  const deleteNode = (id) => {
    handleDelete(id);
    onClose();
  };

  return (
    <Menu
      id="basic-menu"
      className="context-menu"
      open={open !== null}
      onClose={onClose}
      onContextMenu={(e) => {
        e.preventDefault();
        onClose();
      }}
      anchorReference="anchorPosition"
      anchorPosition={open !== null ? { top, left } : undefined}
    >
      <MenuItem onClick={duplicateNode}>
        <ListItemIcon>
          <ContentCopyOutlined fontSize="small" />
        </ListItemIcon>
        <ListItemText>Duplicar</ListItemText>
      </MenuItem>
      {/* <MenuItem onClick={editNode}>
        <ListItemIcon>
          <EditOutlined fontSize="small" />
        </ListItemIcon>
        <ListItemText>Editar</ListItemText>
      </MenuItem> */}
      <MenuItem
        onClick={() => {
          deleteNode(id);
        }}
      >
        <ListItemIcon>
          <DeleteOutlineOutlined fontSize="small" />
        </ListItemIcon>
        <ListItemText>Delete</ListItemText>
      </MenuItem>
    </Menu>
  );
}
