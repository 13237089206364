import { Card, CardActionArea, CardMedia, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

export default function MyCard(props) {
  const {
    link = "",
    img,
    onClick = () => {},
    target = "_blank",
    bgcolor = "primary.main",
    circleColorOne = "primary.main",
    circleColorTwo = "primary.main",
    size,
  } = props;

  const circleDiameter = 150; //210

  const styles = {
    imgStack: {
      height: "100%",
      ml: "-20px",
    },
  };

  const CardWrapper = styled(Card)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "1.5rem",
    alignItems: "center",
    ml: "20px ",
    color: "#fff",
    overflow: "hidden",
    position: "relative",
    minWidth: "320px",
    /*  "&:after": {
      content: '""',
      position: "absolute",
      width: circleDiameter,
      height: circleDiameter,
      background: circleColorOne,
      borderRadius: "50%",
      top: -85,
      right: -75,
      [theme.breakpoints.down("sm")]: {
        top: -105,
        right: -140,
      },
    },*/
    "&:before": {
      content: '""',
      position: "absolute",
      width: circleDiameter,
      height: circleDiameter,
      background: circleColorTwo,
      borderRadius: "50%",
      top: -110,
      right: -20,
      opacity: 1,
      [theme.breakpoints.down("sm")]: {
        top: -155,
        right: -70,
      },
    },
    "> .circle-three": {
      content: '""',
      position: "absolute",
      width: circleDiameter,
      height: circleDiameter,
      background: circleColorOne,
      borderRadius: "50%",
      top: -85,
      right: -75,
      [theme.breakpoints.down("sm")]: {
        top: -105,
        right: -140,
      },
    },
  }));

  return (
    <CardWrapper
      elevation={3}
      sx={{
        borderRadius: 4,
        bgcolor: bgcolor,

        //minWidth: "355px",
      }}
    >
      <div className="circle-three" />
      <CardActionArea
        sx={{ padding: "10px", height: "100px" }}
        href={link}
        target={target}
        onClick={() => {
          onClick();
        }}
      >
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          sx={styles.imgStack}
        >
          <CardMedia
            component={"img"}
            alt="icon"
            image={img}
            sx={{
              width:
                size === "small"
                  ? "100px"
                  : size === "medium"
                  ? "130px"
                  : "170px",
            }}
          />
        </Stack>
      </CardActionArea>
    </CardWrapper>
  );
}
