import React, { useEffect, useState } from "react";

import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Container,
  CssBaseline,
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Tooltip,
  createSvgIcon,
  useTheme,
  ThemeProvider,
} from "@mui/material";
import checkToken from "../../services/CheckToken";

import { ReactComponent as LogoEasyMapsLigth } from "../../assets/easyMaps/logoEasyMaps.svg";

import AppsOutlinedIcon from "@mui/icons-material/AppsOutlined";
import { ReactComponent as IconDots } from "../../assets/easyMaps/iconsSvg/dots-menu.svg";
import { easyMapsTheme } from "../../themes/themeEasyMaps";
import { myTheme } from "../../themes";
import ModalPerfil from "../perfil";
import { Outlet, useNavigate } from "react-router-dom";
import {
  DarkModeOutlined,
  LightModeOutlined,
  Logout,
  NotificationsNoneOutlined,
  PersonOutline,
  SupervisorAccountOutlined,
  SwapHorizOutlined,
} from "@mui/icons-material";

import LoadingPage from "../../componentes/loadingPage";
import Notificacoes from "../layout/notificacoes";
import apiSistema from "../../services/apiSistema";
import Ferramentas from "../layout/ferramentas";

function LayoutEasyMaps(params) {
  const { colorMode, setColorMode, socket, setCheckLogin, socketCliente } =
    params;
  const themeEasyMaps = React.useMemo(
    () => easyMapsTheme(colorMode),
    [colorMode]
  );
  const styles = {
    logo: {
      width: "180px",
      height: "auto",
      marginTop: " 7px",
      fill: themeEasyMaps.palette.color.text,
    },
  };
  const [themeLayout, setThemeLayout] = useState({
    color: themeEasyMaps.palette.color.appBarEasyMaps.backgroundColor,
    icon: <LogoEasyMapsLigth style={styles.logo} alt={"mw-logo"} />,
  });
  const [pageValue, setPageValeu] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loadingPage, setLoadingPage] = useState(false);
  const [baseImage, setBaseImage] = useState("");
  const [administrador, setAdministrador] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [anchorNotification, setAnchorNotification] = useState(null);
  const [anchorFerramentas, setAnchorFerramentas] = useState(null);
  const [notificacoes, setNotificacoes] = useState([]);
  const [snackbar, setSnackbar] = useState({});
  const [countNotificacoes, setCountNotificacoes] = useState(0);
  const [loadingNotifications, setLoadingNotifications] = useState(false);
  const [isEasyMaps, setIsEasyMaps] = useState(false);
  //const [usuarioClienteId, setUsuarioClienteId] = useState("");

  useEffect(() => {
    setThemeLayout({
      color: themeEasyMaps.palette.color.appBarEasyMaps.backgroundColor,
      icon: <LogoEasyMapsLigth style={styles.logo} alt={"mw-logo"} />,
    });
  }, [themeEasyMaps.palette.mode]);

  const DotsIcon = createSvgIcon(<IconDots />);

  useEffect(() => {
    setTimeout(() => {
      setLoadingPage(true);
      tokenValidate();
      checkPerfil();
    }, 200);

    function checkPerfil() {
      let perfilAlreadyExists =
        JSON.parse(localStorage.getItem("1"))?.perfilAlreadyExists || false;
      let satusPerfil =
        JSON.parse(localStorage.getItem("perfil"))?.status || false;
      if (satusPerfil === false || perfilAlreadyExists === false) {
        navigate("/cadastro");
      }
    }

    async function tokenValidate() {
      const data = await checkToken();

      if (!data) {
        navigate("/login");
      }
      setCheckLogin(true);
    }
  }, [pageValue]);

  useEffect(() => {
    if (!notificacoes.length > 0) {
      handleApiGetNotificacoes();
    }
  }, []);

  useEffect(() => {
    const handleUsuarioId = () => {
      let result = {};
      result = localStorage.getItem("1");
      //
      if (result) {
        result = JSON.parse(result);
        if (
          result.usuarioCliente &&
          (result.usuarioCliente.codigoPermissao === "adm001" ||
            result.usuarioCliente.codigoPermissao === "adm002")
        ) {
          setAdministrador(true);
        }
      }

      let resultPerfil = localStorage.getItem("perfil");
      if (resultPerfil) {
        resultPerfil = JSON.parse(resultPerfil);
        setBaseImage(resultPerfil.foto);
      }

      let resultAcessos = localStorage.getItem("acesso");
      if (resultAcessos !== "undefined" && resultAcessos) {
        resultAcessos = JSON.parse(resultAcessos);
        if (resultAcessos.length > 1) {
          navigate("/empresas");
        }
      }
    };
    handleUsuarioId();
  });

  const handleLogout = () => {
    socket.disconnect();
    socketCliente.disconnect();
    localStorage.clear();
    navigate("/login");
  };

  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePerfil = () => {
    setOpenModal(true);
    //navigate("/perfil");
    handleClose();
  };

  const navigate = useNavigate();

  const handleAdm = () => {
    navigate("/administracao");
  };
  function handleNovaNotificacao(novaDemanda) {
    let result = {};
    let usuarioClienteId = "";
    result = localStorage.getItem("1");
    if (result) {
      result = JSON.parse(result);
      usuarioClienteId = result.refreshToken.usuarioClienteId;
    }
    if (novaDemanda.usuarioClienteId !== usuarioClienteId) {
      setCountNotificacoes(countNotificacoes + 1);
      let novoArray = notificacoes;
      let objDemanda = handleConteudoNotificacao(novaDemanda.conteudo);
      objDemanda.tipo = novaDemanda.tipo;
      objDemanda.acao = novaDemanda.acao;
      objDemanda.visto = false;
      objDemanda.notificacaoId = novaDemanda.id;
      objDemanda.usuario = novaDemanda.usuario
        ? novaDemanda.usuario.Perfil
        : "";
      objDemanda.usuarioCliente = novaDemanda.usuarioCliente
        ? novaDemanda.usuarioCliente.PerfilCliente
        : "";
      novoArray = [objDemanda, ...novoArray];
      setNotificacoes(novoArray);
    }
  }
  function handleConteudoNotificacao(demanda) {
    let newObj = {};
    newObj.id = demanda.id;
    /**newObj.usuario = demanda.usuario ? demanda.usuario.Perfil : "";
      newObj.usuarioCliente = demanda.usuarioCliente
        ? demanda.usuarioCliente.PerfilCliente
        : "";*/
    newObj.descricao =
      demanda.descricao ||
      demanda.relatoriosCliente?.descricao ||
      demanda.topicoRelatorio?.relatoriosCliente?.descricao;

    newObj.relatoriosClienteId =
      demanda.topicoRelatorio?.relatoriosCliente?.id ||
      demanda.relatoriosCliente?.id ||
      demanda.id;

    newObj.fixar = demanda?.fixar ? demanda.fixar : false;
    newObj.status = demanda.status;

    if (demanda.updateAt) {
      newObj.tempo = handleTime(demanda.updateAt);
    } else {
      newObj.tempo = handleTime(demanda.createAt);
    }
    newObj.atualizada = demanda.updateAt ? true : false;
    return newObj;
  }
  async function handleApiGetNotificacoes() {
    try {
      setLoadingNotifications(true);
      const response = await apiSistema.post("/notificacoes/findMany", {
        usuarioClienteId: JSON.parse(localStorage.getItem("1")).refreshToken
          .usuarioClienteId,
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar notificacoes",
          severity: "error",
        });
        setLoadingNotifications(false);
      } else {
        setLoadingNotifications(false);
        if (response.data) {
          handleNotificacoes(response.data);
        }
      }
    } catch (error) {
      console.error(error);
      setLoadingNotifications(false);
    }
  }
  function handleNotificacoes(res) {
    let result = {};
    let usuarioClienteId = "";
    result = localStorage.getItem("1");
    if (result) {
      result = JSON.parse(result);
      usuarioClienteId = result.refreshToken.usuarioClienteId;
    }
    let auxCount = 0;
    //setCountNotificacoes(response.data.length);
    let novoArray = [];
    res.map((statusNotificacao) => {
      if (statusNotificacao.notificacao.usuarioClienteId !== usuarioClienteId) {
        let objDemanda = handleConteudoNotificacao(
          statusNotificacao.notificacao.conteudo
        );
        objDemanda.tipo = statusNotificacao.notificacao.tipo;
        objDemanda.acao = statusNotificacao.notificacao.acao;
        objDemanda.visto = statusNotificacao.visto;
        objDemanda.notificacaoId = statusNotificacao.notificacao.id;
        objDemanda.usuario = statusNotificacao.notificacao.usuario
          ? statusNotificacao.notificacao.usuario.Perfil
          : "";
        objDemanda.usuarioCliente = statusNotificacao.notificacao.usuarioCliente
          ? statusNotificacao.notificacao.usuarioCliente.PerfilCliente
          : "";
        novoArray.push(objDemanda);
        if (!statusNotificacao.visto) {
          auxCount++;
        }
      }
    });
    setCountNotificacoes(auxCount);
    setNotificacoes(novoArray);
  }
  function updateVistoNotificacao(visto, notificacaoId) {
    const novoArray = notificacoes.map((notificacao) => {
      if (notificacao.notificacaoId === notificacaoId) {
        let auxNotificao = notificacao;
        auxNotificao.visto = visto;
        return { ...auxNotificao };
      } else {
        return notificacao;
      }
    });
    if (countNotificacoes > 0) {
      setCountNotificacoes(countNotificacoes - 1);
    } else {
      setCountNotificacoes(0);
    }
    setNotificacoes(novoArray);
  }
  function updateManyVistoNotificacao(visto) {
    const novoArray = notificacoes.map((notificacao) => {
      let auxNotificao = notificacao;
      auxNotificao.visto = visto;
      return { ...auxNotificao };
    });
    setCountNotificacoes(0);
    setNotificacoes(novoArray);
  }
  function handleTime(params) {
    const agora = new Date();
    const segundos = parseInt((Date.parse(agora) - Date.parse(params)) / 1000);
    const horas = Math.floor(segundos / (60 * 60));
    const dias = horas > 24 && Math.floor(horas / 24);
    const minutos = Math.floor(segundos / 60) % 60;
    if (dias > 0) {
      if (dias <= 1) {
        return parseInt(dias) + " dia atrás";
      }
      return parseInt(dias) + " dias atrás";
    } else if (horas > 0) {
      if (horas <= 1) {
        return parseInt(horas) + " h atrás";
      } else {
        return parseInt(horas) + " hrs atrás";
      }
    } else if (minutos > 0) {
      return parseInt(minutos) + " min atrás";
    } else {
      return "segs atrás";
    }
  }

  return (
    <ThemeProvider theme={{ ...myTheme, ...themeEasyMaps }}>
      <Box display={"flex"}>
        <CssBaseline />
        {!loadingPage && <LoadingPage />}
        {loadingPage && (
          <Box>
            <AppBar
              position="fixed"
              sx={{
                top: 0,
                backgroundColor: themeLayout.color,
                borderBottom:
                  "1px solid " + themeEasyMaps.palette.color.appBarBorder,
                zIndex: 1201,
              }}
              elevation={0}
              color="primary"
            >
              <Container maxWidth="100vw">
                <Toolbar sx={{ width: "98vw", height: 65 }}>
                  <Stack direction={"row"} width={"100%"} alignItems={"center"}>
                    <IconButton
                      href={"/easyMaps"}
                      sx={{
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <Icon style={styles.logo}>{themeLayout.icon}</Icon>
                    </IconButton>

                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        height: 62,
                        width: "90%",
                        gap: 1.5,
                      }}
                    >
                      {" "}
                      <Tooltip title="Notificações">
                        <IconButton
                          color="inherit"
                          aria-label="notificacoes"
                          onClick={(event) => {
                            setAnchorNotification(event.currentTarget);
                          }}
                        >
                          <Badge
                            color="primary"
                            badgeContent={countNotificacoes}
                          >
                            <NotificationsNoneOutlined
                              sx={{ color: themeEasyMaps.palette.color.icons }}
                            />
                          </Badge>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Ferramentas">
                        <IconButton
                          color="inherit"
                          aria-label="Ferramentas"
                          onClick={(event) => {
                            setAnchorFerramentas(event.currentTarget);
                          }}
                        >
                          <DotsIcon
                            sx={{
                              fill: themeEasyMaps.palette.color.icons,
                              padding: "3px",
                              fontSize: "28px",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Abrir Opções">
                        <IconButton onClick={handleClick}>
                          <Avatar src={baseImage} />
                        </IconButton>
                      </Tooltip>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                        disableScrollLock={true}
                        sx={{ marginLeft: "-5px" }}
                      >
                        <MenuItem onClick={handlePerfil}>
                          <ListItemIcon>
                            <PersonOutline />
                          </ListItemIcon>
                          <ListItemText>Perfil</ListItemText>
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            if (colorMode) {
                              setColorMode(false);
                              localStorage.setItem(
                                "3",
                                JSON.stringify({ colorMode: false })
                              );
                            } else {
                              setColorMode(true);
                              localStorage.setItem(
                                "3",
                                JSON.stringify({ colorMode: true })
                              );
                            }
                          }}
                          disabled={false}
                        >
                          {!colorMode ? (
                            <>
                              <ListItemIcon>
                                <DarkModeOutlined />
                              </ListItemIcon>
                              <ListItemText>Modo Noturno</ListItemText>
                            </>
                          ) : (
                            <>
                              <ListItemIcon>
                                <LightModeOutlined />
                              </ListItemIcon>
                              <ListItemText>Modo Claro</ListItemText>
                            </>
                          )}
                        </MenuItem>
                        {administrador && (
                          <MenuItem onClick={handleAdm}>
                            <ListItemIcon>
                              <SupervisorAccountOutlined />
                            </ListItemIcon>
                            <ListItemText>Administração</ListItemText>
                          </MenuItem>
                        )}
                        <MenuItem onClick={handleLogout}>
                          <ListItemIcon>
                            <Logout />
                          </ListItemIcon>
                          <ListItemText>Sair</ListItemText>
                        </MenuItem>
                      </Menu>
                    </Box>
                  </Stack>
                </Toolbar>
              </Container>
            </AppBar>

            <Box
              sx={{
                height: "65px",
                width:
                  window.location.pathname === "/easyMaps" ? "99vw" : "100vw",
              }}
            />

            <Outlet />
          </Box>
        )}

        <ModalPerfil setOpenModal={setOpenModal} openModal={openModal} />
        <Notificacoes
          notificacoes={notificacoes}
          handleNovaNotificacao={handleNovaNotificacao}
          anchorNotification={anchorNotification}
          setAnchorNotification={setAnchorNotification}
          loadingNotification={loadingNotifications}
          updateVistoNotificacao={updateVistoNotificacao}
          updateManyVistoNotificacao={updateManyVistoNotificacao}
          socket={socket}
        />
        <Ferramentas
          anchorFerramentas={anchorFerramentas}
          setAnchorFerramentas={setAnchorFerramentas}
        />
      </Box>
    </ThemeProvider>
  );
}

export default LayoutEasyMaps;
