import { Add, Check, Edit, Save } from "@mui/icons-material";
import { Button, Divider, Stack, useMediaQuery, useTheme } from "@mui/material";
import Papa from "papaparse";
import {
  GridCellModes,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  useGridApiContext,
} from "@mui/x-data-grid";

import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
export default function CustomToolbar(props) {
  const {
    hosts,
    setHosts,
    setEditMode,
    editMode,
    setCellModesModel,
    rowModesModel,
    setSnackbar,
    handleValidateData = () => {},
    setValidateData = () => {},
    setHostsEdit = () => {},
    hostsEdit,
    updateHosts,
    setKeyTable = () => {},
    setRowSelectionModel = () => {},
    setCellSelected,
  } = props;
  const id = JSON.stringify(Math.random());
  const theme = useTheme();
  const apiRef = useGridApiContext();
  const mdx = useMediaQuery(theme.breakpoints.down("mdx"));

  const addRow = () => {
    return {
      id,
      nome: "",
      ip: "",
      portaSnmp: "161",
      userSsh: "",
      senhaSsh: "",
      interface: "SNMP",
      portaSsh: "",
      userTelnet: "",
      portaTelnet: "",
      senhaTelnet: "",
      tipoSnmp: "SNMPv2",
      comunidade: "",
      status: 0,
      coletor: "",
      fabricante: "",
      funcao: "",
      modelo: "",
      newHost: true,
    };
  };

  const handleEditClick = () => {
    // const rowModesModel = hosts.reduce((acc, row, index) => {
    //     acc[row.id] = { mode: GridCellModes.Edit }; // Ajusta 'edit' diretamente como string
    //     return acc

    // }, {});
    // setRowModesModel(rowModesModel);
    removeSort();
    setRowSelectionModel([]);
    setHostsEdit(JSON.parse(JSON.stringify(hosts)));
    setEditMode(true);
  };

  function validateHosts(colunas) {
    let col = colunas.map((a) =>
      a
        .toUpperCase()
        .trim()
        .replace(/\s/g, "")
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
    );
    let colunaErro = [];
    const colunasTemplate = [
      "nome",
      "funcao",
      "fabricante",
      "modelo",
      "coletor",
      "userSsh",
      "senhaSsh",
      "portaSsh",
      "userTelnet",
      "portaTelnet",
      "senhaTelnet",
      "senhaTelnet",
      "tipoSnmp",
      "portaSnmp",
      "comunidade",
      "ip",
    ];
    colunaErro = colunasTemplate.filter(
      (item) => !col.includes(item.toUpperCase())
    );
    return colunaErro;
  }
  const removeSort = () => {
    let sortModel = apiRef.current.getSortModel();
    if (sortModel.length == 0) return;
    apiRef.current.sortColumn(sortModel[0].field, null);
  };

  const handleAddClick = () => {
    let newLine = addRow();
    //apiRef.current.updateRows([newLine]);
    setRowSelectionModel([]);
    if (!editMode) {
      handleEditClick();
    }
    setHostsEdit((data) => [newLine, ...data]);
  };

  function setViewModeCell() {
    setRowSelectionModel([]);
    setCellSelected(null);
    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: "view" },
              }),
              {}
            ),
          }),
          {}
        ),
      };
    });
  }

  const handleSaveClick = async () => {
    setViewModeCell();
    updateHosts();

    // if (!handleValidateData(rows)) return;
    // await setRowModesModel((rowModes) => {
    //     Object.keys(rowModes).map((key) => {
    //         rowModes[key].mode = GridCellModes.View;
    //     });
    //     return { ...rowModes };
    // });
    // setTimeout(() => {
    //     let removeLines = Object.keys(rowModesModel).filter((key) => key < 1);
    //     removeLines.map((a) => {
    //         if (apiRef.current.getRow(a))
    //             apiRef.current.updateRows([{ id: a, _action: "delete" }]);
    //     });
    //     setRowModesModel((rowModes) => {
    //         removeLines.map((rowId) => {
    //             delete rowModes[rowId];
    //         });
    //         return { ...rowModes };
    //     });
    // }, [100]);
  };
  const handleCancelClick = () => {
    setViewModeCell();

    // let ids = apiRef.current.getAllRowIds()
    // let removeLines = ids.filter((a) => a < 1)
    // removeLines.map((a) => {
    //     if (apiRef.current.getRow(a))
    //         apiRef.current.updateRows([{ id: a, _action: "delete" }]);
    // });

    // const rowModes = hosts.reduce((acc, row) => {
    //     acc[row.id] = { mode: GridCellModes.View, ignoreModifications: true }; // Ajusta 'edit' diretamente como string
    //     return acc;
    // }, {});
    setKeyTable((data) => (data == 1 ? 0 : 1));
    // setRowModesModel(rowModes);
    setValidateData(true);
    setHostsEdit([]);
    setEditMode(false);
  };

  return (
    <GridToolbarContainer>
      <Stack
        sx={{ margin: 1, width: "100%" }}
        direction={"row"}
        justifyContent={"space-between"}
      >
        <Stack direction={"row"}>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarExport />
        </Stack>
        <Stack direction={"row"} justifyContent={"end"} gap={1}>
          <Button
            variant="text"
            sx={{ fontSize: "0.8125rem" }}
            startIcon={<Add />}
            onClick={handleAddClick}
          >
            Novo Host
          </Button>

          {!editMode ? (
            <Button
              variant="text"
              onClick={handleEditClick}
              startIcon={<Edit />}
              disabled={hosts.length == 0}
              sx={{ fontSize: "0.8125rem" }}
            >
              Editar
            </Button>
          ) : (
            <>
              <Divider orientation="vertical" />{" "}
              <Button
                variant="text"
                sx={{
                  fontSize: "0.8125rem",
                  color: theme.palette.chip.completed,
                }}
                startIcon={<Check />}
                onClick={handleSaveClick}
              >
                Concluir
              </Button>
              <Button
                variant="text"
                sx={{ fontSize: "0.8125rem" }}
                color="secondary"
                onClick={handleCancelClick}
              >
                Cancelar
              </Button>
            </>
          )}
        </Stack>
      </Stack>
    </GridToolbarContainer>
  );
}
