import { pdf } from "@react-pdf/renderer";
import { PageTemplateEasyReport } from "../../agendarRelatorio/historicoRelatorio/generateReport";
import { useEffect, useMemo, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { saveAs } from "file-saver";
import ChartBoolean from "../../agendarRelatorio/historicoRelatorio/chartsTypes/booleanChart";
import TrafficChart from "../../agendarRelatorio/historicoRelatorio/chartsTypes/traficChart";
import ChartPie from "../../agendarRelatorio/historicoRelatorio/chartsTypes/pieChart";
import NumberChart from "../../agendarRelatorio/historicoRelatorio/chartsTypes/numberChart";
import api from "../../../../services/api";

import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

export default function GeneratePdfRelatorio(props) {
  const {
    tipo,
    setSnackbar,
    relatorio,
    handleApiCreateRelatorioFlow,
    handleApiCreateRelatorio,
    loading = false,
    setLoadingButton,
  } = props;
  const navigate = useNavigate();
  const file = useMemo(() => relatorio, [relatorio]);
  const [generateButton, setGenerateButton] = useState(false);

  useEffect(() => {
    if (file.id && generateButton) {
      handleDownloadPDF();
      setGenerateButton(false);
    }
  }, [file, generateButton]);

  async function handleGenerateGraphPNG(dataIn, tipo) {
    let graphOut = [];
    await Promise.all(
      dataIn.map(async (a) => {
        if (tipo === 0) {
          if (a.tag === "boolean") {
            let arraySend = [];
            let staticHtmlBool = renderToStaticMarkup(
              <ChartBoolean data={a.history ? a.history : []} />
            );
            arraySend.push({
              data: btoa(unescape(encodeURIComponent(staticHtmlBool))),
              width: 790,
              height: 200,
            });

            if (a.availability) {
              let staticHtmlPie = renderToStaticMarkup(
                <ChartPie data={a.availability.availability} />
              );
              arraySend.push({
                data: btoa(unescape(encodeURIComponent(staticHtmlPie))),
                width: 600,
                height: 300,
              });
            }
            let graphcs = await handleApiGetBase64(arraySend);
            graphOut.push({
              host: a.hostName,
              item: a.itemName,
              graphcs: [{ bool: graphcs[0], pie: graphcs[1] }],
            });
          } else if (a.tag === "traffic") {
            let staticHtml = renderToStaticMarkup(
              <TrafficChart
                data={a.history ? a.history : []}
                c95th={a.c95th}
                limitTraffic={a.limitTraffic}
              />
            );
            let graphcs = await handleApiGetBase64([
              {
                data: btoa(unescape(encodeURIComponent(staticHtml))),
                width: 790,
                height: 200,
              },
            ]);
            graphOut.push({
              host: a.hostName,
              item: a.itemName,
              graphcs: graphcs[0],
            });
          } else if (a.tag === "number") {
            let staticHtml = renderToStaticMarkup(
              <NumberChart data={a.history ? a.history : []} unit={a.unit} />
            );
            let graphcs = await handleApiGetBase64([
              {
                data: btoa(unescape(encodeURIComponent(staticHtml))),
                width: 790,
                height: 200,
              },
            ]);
            graphOut.push({
              host: a.hostName,
              item: a.itemName,
              graphcs: graphcs[0],
            });
          }
        } else if (tipo === 1) {
          let percentilClients = [];
          await Promise.all(
            a.analysis.map(
              async ({ history, type, th95, trafficFull, limitTraffic }) => {
                let staticHtmlTraffic = renderToStaticMarkup(
                  <TrafficChart
                    data={history ? history : []}
                    c95th={th95}
                    limitTraffic={limitTraffic}
                  />
                );
                percentilClients.push({
                  name: type,
                  value: trafficFull,
                  status: true,
                });
                let graphcs = await handleApiGetBase64([
                  {
                    data: btoa(unescape(encodeURIComponent(staticHtmlTraffic))),
                    width: 790,
                    height: 200,
                  },
                ]);

                graphOut.push({
                  client: a.client,
                  type,
                  graphcs: graphcs[0],
                });
              }
            )
          );
          let staticHtmlPie = renderToStaticMarkup(
            <ChartPie data={percentilClients} tipo={tipo} />
          );

          let graphcs = await handleApiGetBase64([
            {
              data: btoa(unescape(encodeURIComponent(staticHtmlPie))),
              width: 600,
              height: 300,
            },
          ]);

          graphOut.push({
            client: a.client,
            type: "all",
            graphcs: graphcs[0],
          });
        }
      })
    );

    return graphOut;
  }
  function agrupaItensDeConteudo(graphcs) {
    const itens = graphcs.reduce((accumulator, { graphcs, item, host }) => {
      if (accumulator.some((a) => a.item === item)) {
        accumulator.map((a) => {
          if (a.item === item) {
            a.graphcs.push({ host, graphcs });
          }
        });
      } else {
        accumulator.push({ graphcs: [{ host, graphcs }], item });
      }

      return accumulator;
    }, []);
    return itens;
  }

  async function handleDownloadPDF() {
    try {
      let graphcs = null;
      let hideCharts = true;

      hideCharts = relatorio?.HistoricoEasyReport?.conteudo[0]?.hideCharts;
      if (!hideCharts) {
        graphcs = await handleGenerateGraphPNG(
          relatorio.HistoricoEasyReport.conteudo,
          relatorio.tipo
        );
        graphcs = tipo === 0 ? agrupaItensDeConteudo(graphcs) : graphcs;
      }

      const blob = await pdf(
        <PageTemplateEasyReport relatorio={relatorio} graphcs={graphcs} />
      ).toBlob();

      saveAs(
        blob,
        `mwRelatorio - ${relatorio.titulo} - etapa ${relatorio.HistoricoEasyReport.etapa}.pdf`
      );

      navigate("/easyReport/selecionarPagina", {
        state: { successModal: true },
      });
    } catch (error) {
      console.log(error);
      setSnackbar({
        children: "Error: Não foi possível gerar o relatório",
        severity: "error",
      });
    } finally {
      //navigate("/easyReport/selecionarPagina");
      setLoadingButton(false);
    }
  }

  async function handleApiGetBase64(htmlBase64) {
    try {
      const response = await api.post("/utils/htmlToImageBase64", {
        htmlBase64,
      });

      if (response.data) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  }

  return (
    <LoadingButton
      variant="contained"
      sx={{ mt: 1.5, mr: 1 }}
      loading={loading}
      onClick={() => {
        setGenerateButton(true);
        tipo === 1
          ? handleApiCreateRelatorioFlow()
          : handleApiCreateRelatorio();
      }}
    >
      Gerar Relatório
    </LoadingButton>
  );
}
