import { EditOutlined } from "@mui/icons-material";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";

export default function ContextMenu({
  contextMenu,
  setContextMenu,
  editMode,
  setEditMode,
}) {
  const handleClose = () => {
    setContextMenu(null);
  };
  const handleEdit = () => {
    setEditMode(true);
  };
  return (
    <Menu
      id="basic-menu"
      className="context-menu"
      open={contextMenu !== null && !editMode}
      onClose={handleClose}
      onContextMenu={(e) => {
        e.preventDefault();
        handleClose();
      }}
      anchorReference="anchorPosition"
      anchorPosition={
        contextMenu !== null
          ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
          : undefined
      }
    >
      <MenuItem onClick={handleEdit}>
        <ListItemIcon>
          <EditOutlined fontSize="small" />
        </ListItemIcon>
        <ListItemText>Editar</ListItemText>
      </MenuItem>
    </Menu>
  );
}
