import { Box, Paper, Tabs, Tab } from "@mui/material";

import { useState } from "react";
import MapasDelinha from "./linha";
import Construcao from "../construcao";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    gap: "2rem",
  },
  paper: {
    borderRadius: 3,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingX: "10px",
  },
  box: { display: "flex", width: "100%" },
};

export default function EasyMaps(props) {
  const [tabValue, setTabValue] = useState(0);

  return (
    <Box sx={styles.container}>
      <Paper elevation={3} sx={styles.paper}>
        <Tabs
          scrollButtons={true}
          value={tabValue}
          onChange={(event, newValue) => {
            setTabValue(newValue);
          }}
        >
          <Tab label={"Linha"} iconPosition="start" value={0} sx={styles.tab} />
          <Tab label={"Fibra"} iconPosition="start" value={1} sx={styles.tab} />
        </Tabs>
      </Paper>
      <>
        {tabValue === 0 && <MapasDelinha key={1} />}
        {tabValue === 1 && <Construcao key={2} />}
      </>
    </Box>
  );
}
