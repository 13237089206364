import { Box, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import IconTransform from "../iconsElement";
import { Fragment } from "react";
const useIsDarkMode = () => {
  const theme = useTheme();
  return theme.palette.mode === "dark";
};
const interfacesType = {
  1: "Agent",
  2: "SNMP",
  3: "IPMI",
  4: "JMX",
};

export default function ElementHeader(props) {
  const { titulo, interfaces, tags, img, tipo, mapa, size } = props;

  const isDarkMode = useIsDarkMode();
  const styles = {
    retangle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "primary.main",
      paddingX: "5px",
      maxWidth: "30px",
      clipPath: "polygon(25% 0, 100% 0, 100% 75%, 75% 100%, 0 100%, 0 25%)",
    },
    text: { fontSize: "0.8em" },

    retangleText: { fontSize: "0.6em", color: "white" },
    elementName: {
      fontSize: "1.15rem",
      textAlign: "left",
      mb: -0.4,
    },
    icon: {
      mx: size == "small" ? "0px" : "15px",
    },
    stackElement: {
      paddingY: "15px",
      justifyContent: "start",
      alignItems: "center",
      with: "100%",
    },
  };
  const agrupaTags = () => {
    if (!tags) return;
    let fabricante = "",
      funcao = "",
      modelo = "";
    tags?.map(({ tag, value }) => {
      if (tag == "fabricante") fabricante = value;
      if (tag == "funcao") funcao = value?.split("-")[1];
      if (tag == "modelo") modelo = value?.split("-")[2];
    });
    return `${fabricante} - ${funcao} - ${modelo}`;
  };

  return (
    <Stack direction={"row"} gap={1} sx={styles.stackElement}>
      <Stack sx={styles.icon}>
        <IconTransform img={img} size="35px" />
      </Stack>
      <Stack>
        <Tooltip title={titulo}>
          <Typography sx={styles.elementName}>
            {titulo?.length > 30
              ? titulo.substring(0, 30)?.toLocaleUpperCase() + "..."
              : titulo?.toLocaleUpperCase()}
          </Typography>
        </Tooltip>

        {tipo != 0 && (
          <>
            <Stack direction={"row"}>
              <Typography sx={styles.text}>
                {mapa?.Element?.length} Elemento(s)
              </Typography>
            </Stack>
            <Box sx={styles.retangle}>
              <Typography sx={styles.retangleText}>
                {mapa?.tipo == 0 ? "Geo" : "Top"}
              </Typography>
            </Box>
          </>
        )}
        {tipo == 0 && (
          <>
            <Typography variant="small">{agrupaTags()}</Typography>
            <Stack direction={"row"} gap={1}>
              {interfaces?.map((inter, index) => (
                <Fragment key={"inter" + index}>
                  <Typography variant="small">{inter.ip}</Typography>
                  <Box sx={styles.retangle}>
                    <Typography sx={styles.retangleText}>
                      {interfacesType[inter.type]}
                    </Typography>
                  </Box>
                </Fragment>
              ))}
            </Stack>
          </>
        )}
      </Stack>
    </Stack>
  );
}
