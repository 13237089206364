import { Alert, Snackbar } from "@mui/material";
export default function SnackBar({ snackbar, setSnackbar }) {
  return (
    <>
      {" "}
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </>
  );
}
