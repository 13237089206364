export const zabbixSeverty = (colorMode) => {
  return {
    warning: "#F49506",
    warningTranslucid: "#F4950633",
    average: "#FF7917",
    averageTranslucid: "#FF791733",
    high: "#EE1127",
    highTranslucid: "#EE112733",
    //highTranslucid: !colorMode ? "#EE1127" : "#EE112733",
    disaster: "#EE1127",
    selectedTranslucid: "#794EA4",
    selected: !colorMode ? "#D4B8EE" : "#A77AD2",
    default: !colorMode ? "#FFFFFF" : "#242424ce",
    //default: !colorMode ? "#794EA4" : "#242424ce",
  };
};

export const appBarEasyMaps = (colorMode) => {
  return {
    backgroundColor: !colorMode ? "#ffffff" : "#121212",
  };
};
