import { useState, useEffect } from "react";
import {
  Modal,
  Paper,
  Stack,
  Button,
  MenuItem,
  Select,
  TextField,
  FormControl,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Alert,
} from "@mui/material";

import api from "../../../services/api";
import apiSistema from "../../../services/apiSistema";
import "dayjs/locale/pt-br";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import AlertModal from "../../../componentes/alertModal";
import dayjs from "dayjs";
const stylesModal = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  paper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: 3,
    boxShadow: 24,
    padding: "10px",
  },
  campoFiltro: {
    width: "200px",
  },
  campoFiltroSecondary: {
    width: "420px",
  },
  button: { height: "40px", minWidth: "120px" },
};

export default function ModalEditaDemanda(props) {
  const { openEditModal, setOpenEditModal, selectedRow, setUpdateRow } = props;

  const [responsavel, setResponsavel] = useState("");
  const [solicitante, setSolicitante] = useState("");
  const [status, setStatus] = useState("");
  const [codigo, setCodigo] = useState("");
  const [id, setId] = useState("");
  const [categoria, setCategoria] = useState("");
  const [subCategoria, setSubCategoria] = useState("");
  const [data, setData] = useState(dayjs(new Date()));
  const [previsao, setPrevisao] = useState(dayjs(new Date()));
  const [descricao, setDescricao] = useState("");
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [confirmarEdicao, setConfirmarEdicao] = useState(false);
  //dados de ususario do localstorage
  const [logsLoginClienteId, setLogsLoginClienteId] = useState("");
  //snackbar
  const [snackbar, setSnackbar] = useState(null);
  useEffect(() => {
    if (selectedRow) {
      setId(selectedRow.id);
      setResponsavel(selectedRow.responsavel);
      setSolicitante(selectedRow.solicitante);
      setCodigo(selectedRow.codigo);
      setStatus(selectedRow.status);
      setCategoria(selectedRow.categoria);
      setSubCategoria(selectedRow.subCategoria);
      setData(dayjs(selectedRow.data));
      setDescricao(selectedRow.descricao);
      setStatus(selectedRow.status);
    }
  }, [selectedRow]);
  useEffect(() => {
    let logs = localStorage.getItem("logs");
    if (logs !== "undefined" && logs) {
      logs = JSON.parse(logs);
      setLogsLoginClienteId(logs.id);
    }
  }, []);

  function handleEditRow(params) {
    let objectRow = {};
    //objectRow.responsavel = params.perfil ? params.perfil : "";
    objectRow.responsavel = params.usuario ? params.usuario.Perfil : "";
    objectRow.solicitante = params.usuarioCliente
      ? params.usuarioCliente.PerfilCliente
      : "";
    objectRow.codigo = params.codigo;
    objectRow.descricao = params.descricao;
    objectRow.categoria = params.categoria;
    objectRow.subCategoria = params.subCategoria;
    objectRow.status = params.status;
    objectRow.previsao = new Date(params.previsao);
    objectRow.data = new Date(new Date(params.createAt) - 3 * 60 * 60 * 1000); //correção de fuso horario, com diferença de 3 horas
    objectRow.id = params.id;
    objectRow.topicos = params._count.TopicoRelatorio;
    setUpdateRow(objectRow);
  }

  async function handleApiPutRelatorios() {
    try {
      //setLoadingPage(true);
      setConfirmarEdicao(true);
      const response = await apiSistema.put("/suporte/update", {
        id,
        descricao,
        solicitante: JSON.parse(localStorage.getItem("1")).refreshToken
          .usuarioClienteId,
        //logsLoginClienteId,
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar dados",
          severity: "error",
        });
        // setLoadingPage(false);
      } else {
        //  setLoadingPage(false);
        if (response.data) {
          handleEditRow(response.data);
        }
        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
      //setLoadingPage(false);
    } finally {
      setOpenEditModal(false);
      setOpenEditDialog(false);
      setConfirmarEdicao(false);
    }
  }
  return (
    <div>
      <Modal
        open={openEditModal}
        onClose={() => {
          setOpenEditModal(false);
        }}
      >
        <Paper sx={stylesModal.paper} elevation={3}>
          <DialogTitle color="primary">{"Editar Demanda"}</DialogTitle>
          <DialogContent
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <TextField
              id="input-solicitante"
              variant="outlined"
              label="Solicitante"
              disabled
              value={solicitante ? solicitante.nome : ""}
              sx={stylesModal.campoFiltroSecondary}
              type="text"
            />
            <FormControl>
              <InputLabel id="select-status">Responsável</InputLabel>
              <Select
                disabled
                labelId="select-responsavel"
                id="select-responsavel-id"
                value={responsavel}
                sx={stylesModal.campoFiltroSecondary}
                label="Responsável"
                onChange={(e) => {
                  setResponsavel(e.target.value);
                }}
              >
                <MenuItem value={""}>Sem responsável</MenuItem>
                <MenuItem value={"André Mafaldo"}>André Mafaldo</MenuItem>
                <MenuItem value={"Renato Emanuel"}>Renato Emanuel</MenuItem>
              </Select>
            </FormControl>
            <Stack direction={"row"} gap={"20px"}>
              {" "}
              <TextField
                disabled
                id="filtro-codigo"
                variant="outlined"
                label="Código"
                value={codigo}
                sx={stylesModal.campoFiltro}
                onChange={(event) => {
                  setCodigo(event.target.value);
                }}
                type="text"
              />{" "}
              <FormControl>
                <InputLabel id="select-status">Status</InputLabel>
                <Select
                  disabled
                  labelId="select-status"
                  id="filtro-status"
                  value={status}
                  sx={stylesModal.campoFiltro}
                  label="Status"
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                >
                  <MenuItem value={0}>Pendente</MenuItem>
                  <MenuItem value={1}>Em andamento</MenuItem>
                  <MenuItem value={2}>Concluída</MenuItem>
                  <MenuItem value={3}>Aguardando Revisão</MenuItem>
                  <MenuItem value={4}>Aguardando Retorno</MenuItem>
                </Select>
              </FormControl>
            </Stack>
            <TextField
              id="input-descricao"
              variant="outlined"
              label="Descrição"
              value={descricao}
              sx={stylesModal.campoFiltroSecondary}
              onChange={(event) => {
                setDescricao(event.target.value);
              }}
              multiline
              rows={4}
              inputProps={{ maxLength: 150 }}
              type="text"
              helperText="Limite de 255 dígitos"
            />
            <Stack direction={"row"} gap={"20px"}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={"pt-br"}
              >
                <DatePicker
                  id="data"
                  label="Data de Criação"
                  disabled
                  sx={stylesModal.campoFiltro}
                  value={dayjs(data)}
                  onChange={(newValeu) => {
                    setData(dayjs(newValeu));
                  }}
                  // renderInput={(params) => (
                  //   <TextField
                  //     {...params}
                  //     sx={stylesModal.campoFiltro}
                  //     color="primary"
                  //     id="data"
                  //     label="Data de Criação"
                  //   />
                  // )}
                />
              </LocalizationProvider>{" "}
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={"pt-br"}
              >
                <DatePicker
                  id="previsao"
                  label="Previsão"
                  disabled
                  sx={stylesModal.campoFiltro}
                  value={dayjs(previsao)}
                  onChange={(newValeu) => {
                    setPrevisao(dayjs(newValeu));
                  }}
                  // renderInput={(params) => (
                  //   <TextField
                  //     {...params}
                  //     sx={stylesModal.campoFiltro}
                  //     color="primary"
                  //     id="previsao"
                  //     label="Previsão"
                  //   />
                  // )}
                />
              </LocalizationProvider>{" "}
            </Stack>{" "}
            <Stack direction={"column"} gap={"20px"}>
              {" "}
              <TextField
                disabled
                id="input-categoria"
                variant="outlined"
                label="Categoria"
                value={categoria}
                sx={stylesModal.campoFiltroSecondary}
                onChange={(event) => {
                  setCategoria(event.target.value);
                }}
                type="text"
              />{" "}
              <TextField
                disabled
                id="input-categoria"
                variant="outlined"
                label="Subcategoria"
                value={subCategoria}
                sx={stylesModal.campoFiltroSecondary}
                onChange={(event) => {
                  setSubCategoria(event.target.value);
                }}
                type="text"
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              // variant="contained"
              sx={stylesModal.button}
              onClick={() => {
                setOpenEditModal(false);
              }}
            >
              CANCELAR
            </Button>{" "}
            <Button
              // variant="contained"
              sx={stylesModal.button}
              onClick={() => {
                setOpenEditDialog(true);
              }}
            >
              EDITAR
            </Button>
          </DialogActions>
        </Paper>
      </Modal>{" "}
      <AlertModal
        openModal={openEditDialog}
        setOpenModal={setOpenEditDialog}
        confirmar={confirmarEdicao}
        handleButton={handleApiPutRelatorios}
        severity={"warning"}
        singleButton={false}
      />
      {/**
      <Dialog
        open={false}
        onClose={() => {
          setOpenEditDialog(false);
        }}
      >
        <DialogTitle>{"Deseja realmente salvar os dados editados?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Uma vez após editado os dados anteriores não poderem ser
            recuperados. Tem certeza que de deseja fazer essa edição ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color={"secondary"}
            onClick={() => {
              setOpenEditDialog(false);
            }}
          >
            Cancelar
          </Button>

          <Button
            autoFocus
            //disabled={disableButton}
            onClick={() => {
              setOpenEditModal(false);
              setOpenEditDialog(false);
              handleApiPutRelatorios();
            }}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog> */}
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </div>
  );
}
