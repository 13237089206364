import {  IconButton,Box} from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';

export default function EditeCell(props) {
    const apiRef = useGridApiContext();
    const { params, mode} = props
    const editOn = () => {
        apiRef.current.startRowEditMode({id:params.row.id})   
    };
    const editOff = () => {
        apiRef.current.stopRowEditMode({id:params.row.id})  
    };
    
     
    if(mode=='view'){
        return (
            <Box sx={{display:'flex', alignItems:'center', justifyContent:"center", width:'100%'}}>
                <IconButton onClick={()=>editOn()}>
                    <EditIcon color='primary' />
                </IconButton>
            </Box>
        )  
    }else{
        return (
            <Box sx={{display:'flex', alignItems:'center', justifyContent:"center", width:'100%'}}>
                <IconButton onClick={()=>editOff()}>
                    <SaveIcon  color='primary' />
                </IconButton>
            </Box>
            
        ) 
    }
    
}