import {
  Paper,
  useTheme,
  IconButton,
  Divider,
  Tooltip,
  Stack,
  Popover,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Switch,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import EditOffIcon from "@mui/icons-material/EditOff";
import EditIcon from "@mui/icons-material/Edit";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import SubtitlesOffOutlinedIcon from "@mui/icons-material/SubtitlesOffOutlined";
import SubtitlesOutlinedIcon from "@mui/icons-material/SubtitlesOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useReactFlow } from "reactflow";
import { LabelOffOutlined, LabelOutlined } from "@mui/icons-material";
import SettingsIcon from "@mui/icons-material/Settings";
import { useState } from "react";
const ToolBarMaps = ({
  checkChanges,
  proceedUpdateMode = () => {},
  setOpenAlertModal = () => {},
  setFullScreem,
  fullScreem = false,
  setEditMode,
  editMode = false,
  setLockMode,
  lockMode = false,
  setShowLabel,
  showLabel = true,
  setShowLabelEnlace,
  showLabelEnlace = true,
  children,
  tipo = 0,
  setEasyMapsConfig = () => {},
  easyMapsConfig,
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const styles = {
    icon: {
      color: theme.palette.color.icons,
    },
    button: {
      borderRadius: "5px",
      padding: "5px",
    },
    content: {
      padding: "5px",
      position: "absolute",
      zIndex: 1,
      display: "flex",
      flexDirection: "column",
      left: "15px",
      top: "15px",
      alignItems: "center",
      gap: 0.5,
      justifyContent: "center",
    },
    config: {
      opacity: 0.8,
      ":hover": { opacity: 1, transform: "rotate(90deg)" },
      color: theme.palette.color.icons,
    },

    card: {
      padding: "5px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: 0.75,
      backgroundColor: theme.palette.background.default,
      justifyContent: "center",

      zIndex: 10,
    },
    paper: {
      display: "flex",
      flexDirection: "column",
      gap: "2px",
      justifyContent: "center",
      alignItems: "center",
    },
    switch: {
      width: "100%",
      ml: "0px",
      mr: "0px",
    },
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (event, path, name) => {
    setEasyMapsConfig((data) => {
      if (data?.[path]) {
        data[path][name] = event.target.checked;
      } else {
        data = {};
        data[path] = { [name]: event.target.checked };
      }
      return { ...data };
    });
  };

  return (
    <Stack sx={styles.content}>
      <Paper elevation={0} sx={styles.card}>
        {children}
        <Paper
          sx={[
            styles.paper,
            {
              backgroundColor: fullScreem ? theme.palette.primary.main : null,
            },
          ]}
        >
          <Tooltip title="Tela Cheia" placement="right">
            <IconButton
              aria-label="delete"
              sx={styles.button}
              disabled={editMode}
              onClick={() => setFullScreem(!fullScreem)}
            >
              {fullScreem ? (
                <FullscreenExitIcon />
              ) : (
                <FullscreenIcon sx={styles.icon} />
              )}
            </IconButton>
          </Tooltip>
        </Paper>
        <Paper
          sx={[
            styles.paper,
            { backgroundColor: editMode ? theme.palette.primary.main : null },
          ]}
        >
          <Tooltip
            title={editMode ? "Modo de visualização" : "Modo de edição"}
            placement="right"
          >
            <IconButton
              aria-label="delete"
              sx={styles.button}
              onClick={() => {
                if (fullScreem) return;
                if (checkChanges)
                  setOpenAlertModal({
                    open: true,
                    severity: "info",
                    buttonText: "Salvar",
                    buttonCancelText: "Voltar",
                    text: "As alterações feitas não foram salvas. Deseja Salvá-las?",
                    callback: proceedUpdateMode,
                    callbackCancel: () => {},
                  });
                else setEditMode(!editMode);
              }}
            >
              {editMode ? (
                <EditIcon sx={[styles.icon, { color: "white" }]} />
              ) : (
                <EditOffIcon sx={styles.icon} />
              )}
            </IconButton>
          </Tooltip>
        </Paper>
        <Paper
          sx={[
            styles.paper,
            { backgroundColor: !showLabel ? theme.palette.primary.main : null },
          ]}
        >
          <Tooltip
            title={showLabel ? "Ocultar Detalhes" : "Mostrar detalhes"}
            placement="right"
          >
            <IconButton
              aria-label="delete"
              sx={styles.button}
              onClick={() => setShowLabel(!showLabel)}
            >
              {showLabel ? (
                <SubtitlesOutlinedIcon sx={styles.icon} />
              ) : (
                <SubtitlesOffOutlinedIcon
                  sx={[styles.icon, { color: "white" }]}
                />
              )}
            </IconButton>
          </Tooltip>
        </Paper>
        {/* <Paper
        sx={[
          styles.paper,
          { backgroundColor: lockMode ? theme.palette.primary.main : null },
        ]}
      >
        <IconButton
          aria-label="delete"
          sx={styles.button}
          onClick={() => setLockMode(!lockMode)}
        >
          {lockMode ? (
            <LockOutlinedIcon sx={[styles.icon, { color: "white" }]} />
          ) : (
            <LockOpenOutlinedIcon sx={styles.icon} />
          )}
        </IconButton>
      </Paper> */}
        {tipo === 1 && (
          <Paper
            sx={[
              styles.paper,
              {
                backgroundColor: !showLabelEnlace
                  ? theme.palette.primary.main
                  : null,
              },
            ]}
          >
            <IconButton
              aria-label="delete"
              sx={styles.button}
              onClick={() => setShowLabelEnlace(!showLabelEnlace)}
            >
              {showLabelEnlace ? (
                <LabelOutlined sx={styles.icon} />
              ) : (
                <LabelOffOutlined sx={[styles.icon, { color: "white" }]} />
              )}
            </IconButton>
          </Paper>
        )}

        <Paper sx={[styles.paper]}>
          <Tooltip title={"configurações"} placement="right">
            <IconButton
              aria-label="delete"
              sx={styles.button}
              onClick={handleClick}
            >
              <SettingsIcon sx={styles.config} onClick={handleClick} />
            </IconButton>
          </Tooltip>
        </Paper>
      </Paper>

      <Popover
        id={"popoverConfig"}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 280, left: 380 }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        // }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <Stack justifyContent={"center"} gap={1} padding={"15px"}>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={
                  easyMapsConfig?.animacao?.alerta == null ||
                  easyMapsConfig?.animacao?.alerta == true
                    ? true
                    : false
                }
              />
            }
            sx={styles.switch}
            labelPlacement="start"
            label={
              <Typography sx={{ width: "180px" }}>
                Animação de alertas
              </Typography>
            }
            onChange={(event) => handleChange(event, "animacao", "alerta")}
          />
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={
                  easyMapsConfig?.animacao?.linha == null ||
                  easyMapsConfig?.animacao?.linha == true
                    ? true
                    : false
                }
              />
            }
            sx={styles.switch}
            labelPlacement="start"
            label={
              <Typography sx={{ width: "180px" }}>
                Animação de linhas
              </Typography>
            }
            onChange={(event) => handleChange(event, "animacao", "linha")}
          />
        </Stack>
      </Popover>
    </Stack>
  );
};
const InputZoomTop = () => {
  const { zoomIn, zoomOut } = useReactFlow();
  const styles = {
    button: {
      borderRadius: "5px",
      padding: "5px",
    },
  };
  return (
    <Paper sx={styles.paper}>
      <IconButton
        aria-label="delete"
        onClick={() => zoomIn()}
        sx={styles.button}
      >
        <AddIcon sx={styles.icon} />
      </IconButton>
      <Divider sx={{ width: "80%" }} />
      <IconButton
        aria-label="delete"
        onClick={() => zoomOut()}
        sx={styles.button}
      >
        <RemoveIcon sx={styles.icon} />
      </IconButton>
    </Paper>
  );
};
const InputZoomGeo = ({ zoom }) => {
  const styles = {
    button: {
      borderRadius: "5px",
      padding: "5px",
    },
  };
  return (
    <>
      <Paper sx={styles.paper}>
        <IconButton
          aria-label="delete"
          onClick={() => zoom(1)}
          sx={styles.button}
        >
          <AddIcon sx={styles.icon} />
        </IconButton>
        <Divider sx={{ width: "80%", ml: "10%" }} />
        <IconButton
          aria-label="delete"
          onClick={() => zoom(-1)}
          sx={styles.button}
        >
          <RemoveIcon sx={styles.icon} />
        </IconButton>
      </Paper>
    </>
  );
};
ToolBarMaps.InputZoomGeo = InputZoomGeo;
ToolBarMaps.InputZoomTop = InputZoomTop;
export default ToolBarMaps;
