import { Document, Font, Page } from "@react-pdf/renderer";
import React, { Fragment } from "react";
import ReportBoolean from "./booleanReport";
import { HeaderPdf } from "./headerPdf";
import { styles } from "./styles";
import { Footer } from "./footerPdf";
import { FirstPage } from "./firstPage";
import ReportTrafic from "./traficReport";
import { convertDateStr, convertTimeStr } from "../utils";
import { Teoric } from "./teoricPage";
import FlowReport from "./flowReport";

Font.register({
  family: "Noto Sans",
  src: "http://fonts.gstatic.com/s/notosans/v6/LeFlHvsZjXu2c3ZRgBq9nKCWcynf_cDxXwCLxiixG1c.ttf",
});
function agrupaItensDeConteudo(conteudoHost) {
  const itens = conteudoHost.reduce(
    (
      accumulator,
      { tag, hostName, history, itemId, itemName, unit, ...rest }
    ) => {
      const existingItem = accumulator.find(
        (item) => item.itemName === itemName
      );
      const hosts = [{ hostName, history, itemId, ...rest }];
      if (existingItem) {
        existingItem.hosts = existingItem.hosts.concat(hosts);
      } else {
        const newItem = { tag, unit, itemId, itemName, hosts };
        accumulator.push(newItem);
      }
      return accumulator;
    },
    []
  );
  return itens;
}
export const PageTemplateEasyReport = ({ relatorio, graphcs }) => {
  const conteudoHost = relatorio?.HistoricoEasyReport?.conteudo;
  let ultimaData = new Date(relatorio?.HistoricoEasyReport?.dataFinalColeta);
  ultimaData.setDate(ultimaData.getDate() - 1);
  const itens =
    relatorio.tipo === 0 ? agrupaItensDeConteudo(conteudoHost) : null;
  const reportsProps = {
    horaInicial: convertTimeStr(
      relatorio?.HistoricoEasyReport?.dataInicialColeta
    ),
    horaFinal: convertTimeStr(relatorio?.HistoricoEasyReport?.dataFinalColeta),
    dataInicial: convertDateStr(
      relatorio?.HistoricoEasyReport?.dataInicialColeta
    ),
    dataFinal: convertDateStr(relatorio?.HistoricoEasyReport?.dataFinalColeta),
    etapa: relatorio.HistoricoEasyReport.etapa,
    etapas: relatorio.etapas,
    tipo: relatorio.tipo,
    ultimaData: convertDateStr(ultimaData),
  };
  let cont = 1;
  const contTable = () => {
    let soma = 0;
    itens.map(({ hosts, tag }) => {
      if (
        tag === "traffic"
          ? !!(hosts[0]?.c95th && hosts[0]?.trafficFull)
          : tag === "boolean"
          ? !!hosts[0]?.availability
          : false
      ) {
        soma += 1;
      }
    });
    return soma;
  };
  return (
    <Document>
      <Page
        size="A4"
        style={styles.body}
        pageTitle="Nome do relatorio"
        key={relatorio?.id}
      >
        <HeaderPdf relatorio={relatorio} />
        <FirstPage
          itens={itens}
          conteudoHost={conteudoHost}
          {...reportsProps}
        />
        {reportsProps.tipo === 0
          ? itens?.map(({ itemName, tag, hosts, itemId, unit }, index) => (
              <Fragment key={index + "itens"}>
                {tag === "boolean" ? (
                  <>
                    <ReportBoolean
                      key={index + "comp"}
                      index={index}
                      itemName={itemName}
                      hosts={hosts}
                      graphcs={
                        graphcs?.find((a) => a.item === itemName).graphcs
                      }
                      {...reportsProps}
                      indiceGraphcs={[cont, cont + 1]}
                      indiceTable={contTable()}
                    />
                    {(cont += hosts.length * 2)}
                  </>
                ) : (
                  <>
                    <ReportTrafic
                      key={index + "comp2"}
                      index={index}
                      itemName={itemName}
                      itemId={itemId}
                      hosts={hosts}
                      unit={unit}
                      graphcs={
                        graphcs?.find((a) => a.item === itemName).graphcs
                      }
                      {...reportsProps}
                      indiceGraphcs={cont}
                    />
                    {(cont += hosts.length)}
                  </>
                )}
              </Fragment>
            ))
          : conteudoHost?.map(
              ({ client, interfaces, analysis, tarifacaoExecedente }, index) =>
                analysis.length > 0 && (
                  <Fragment key={index + "itens"}>
                    <FlowReport
                      client={client}
                      interfaces={interfaces}
                      tarifacaoExcedente={tarifacaoExecedente}
                      analysis={analysis}
                      graphcs={graphcs?.filter((a) => a.client === client)}
                      index={index}
                      indiceGraphcs={[cont, cont + 1]}
                      {...reportsProps}
                    />
                    {!tarifacaoExecedente?.status
                      ? (cont += analysis.length + 1)
                      : (cont = cont + 1)}
                  </Fragment>
                )
            )}
        <Teoric hideCharts={!graphcs} />
        <Footer />
      </Page>
    </Document>
  );
};
