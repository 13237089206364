import { useState, useEffect } from "react";
import {
  Box,
  Stack,
  Button,
  IconButton,
  Typography,
  Divider,
  Avatar,
  Chip,
  Menu,
  MenuItem,
  createSvgIcon,
} from "@mui/material";
import { Edit, MoreVert, Delete, PushPin } from "@mui/icons-material";
import Carousel from "react-material-ui-carousel";
import Editor from "../../../../componentes/editorChat";
import DeleteDialog from "../../../../componentes/deleteDialog";

import api from "../../../../services/api";
import apiSistema from "../../../../services/apiSistema";
import AlertModal from "../../../../componentes/alertModal";

const stylesCardResposta = {
  containerChat: {
    width: "696px",
    padding: "20px",
    gap: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  paperChat: {
    gap: "15px",
    display: "flex",
    flexDirection: "row",
    borderRadius: 3,
    elevation: 0,
    bgcolor: "background.paperChat",
    alignItems: "start",
    padding: "10px",
  },

  boxResponse: {
    gap: "20px",
    display: "flex",
    flexDirection: "row",
    borderRadius: 3,
    elevation: 0,
    alignItems: "start",
  },
  fieldComment: {
    width: "470px",
    ml: "100px",
  },
  avatarResponse: { width: "30px", height: "30px" },
  buttonShowMore: {
    fontSize: "0.8rem",
    height: "1.6rem",
    width: "9rem",
    margin: "auto",
  },
};
const FileNotFound = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="css-1ticslg"
  >
    <path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path>
  </svg>
);
export default function CardResposta(props) {
  const {
    resposta,
    usuarioClienteId,
    setSnackbar,
    formataRespostasComentario,
    updateRespostas,
    deleteResposta,
    fixarResposta,
    socket,
  } = props;
  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = useState(null);
  const [showMoreDesc, setShowMoreDesc] = useState(true);
  const [showButtonMoreDesc, setShowButtonMoreDesc] = useState(false);
  const [editar, setEditar] = useState(false);
  const [editaResposta, setEditaResposta] = useState("");
  const [disabledButtonEdit, setDisabledButtonEdit] = useState(true);
  const open = Boolean(anchorEl);
  //delete dialog
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [confirmarDelete, setConfirmarDelete] = useState(false);
  //socket
  const [getEditResponse, setGetEditResponse] = useState(false);
  const [getDeleteResponse, setGetDeleteResponse] = useState(false);
  const [getFixResponse, setGetFixResponse] = useState(false);

  useEffect(() => {
    function updateChatEvent(data) {
      //console.log("edita resposta", data.dados);
      setGetEditResponse(data.dados);
    }
    function updateChatEvent(data) {
      // console.log("edita Chat", data.dados);
      setGetEditResponse(data.dados);
    }
    function deleteChatEvent(data) {
      // console.log("deletar Chat", data.dados);
      setGetDeleteResponse(data.dados);
    }
    function fixChatEvent(data) {
      // console.log("fixar chat", data.dados);
      setGetFixResponse(data.dados);
    }
    socket.on(`updateChatTopico`, updateChatEvent);
    socket.on(`deleteChatTopico`, deleteChatEvent);
    socket.on(`fixChatTopico`, fixChatEvent);
    return () => {
      socket.off(`updateChatTopico`, updateChatEvent);
      socket.off(`deleteChatTopico`, deleteChatEvent);
      socket.off(`fixChatTopico`, fixChatEvent);
    };
  }, [socket]);
  useEffect(() => {
    if (getEditResponse) {
      updateRespostas(formataRespostasComentario(getEditResponse));
    }
  }, [getEditResponse]);
  useEffect(() => {
    if (getDeleteResponse) {
      deleteResposta(formataRespostasComentario(getDeleteResponse));
    }
  }, [getDeleteResponse]);
  useEffect(() => {
    if (getFixResponse) {
      fixarResposta(getFixResponse.id, getFixResponse.fixar);
    }
  }, [getFixResponse]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const paragraph = document.getElementById("myParagraph");
    if (paragraph.scrollHeight > paragraph.clientHeight) {
      //  setShowButtonMoreDesc(true);
    } else {
      //  setShowButtonMoreDesc(false);
    }
  }, [resposta]);
  function handleTime(params) {
    const agora = new Date();
    const segundos = parseInt((Date.parse(agora) - Date.parse(params)) / 1000);
    const horas = Math.floor(segundos / (60 * 60));
    const dias = horas > 24 && Math.floor(horas / 24);
    const minutos = Math.floor(segundos / 60) % 60;
    if (dias > 0) {
      if (dias <= 1) {
        return parseInt(dias) + " dia atrás";
      }
      return parseInt(dias) + " dias atrás";
    } else if (horas > 0) {
      if (horas <= 1) {
        return parseInt(horas) + " h atrás";
      } else {
        return parseInt(horas) + " hrs atrás";
      }
    } else if (minutos > 0) {
      return parseInt(minutos) + " min atrás";
    } else {
      return "segs atrás";
    }
  }
  async function handleApiPutChat() {
    try {
      //setLoadingChat(true);
      const response = await apiSistema.put("/suporte/topico/chat/update", {
        texto: editaResposta,
        id: resposta.id,
        usuarioClienteId: JSON.parse(localStorage.getItem("1")).refreshToken
          .usuarioClienteId,
        //logsLoginClienteId: JSON.parse(localStorage.getItem("logs")).id,
      });
      //if (response.data) {
      //  setGetEditResponse(response.data);
      //}
      if (response.data.status === "Error") {
        //setLoadingChat(false);
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });
        //setLoadingChat(false);
      }
    } catch (error) {
      // setLoadingChat(false);
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    }
  }
  async function handleApiDelete() {
    try {
      //setLoadingChat(true);
      setConfirmarDelete(true);
      const response = await apiSistema.put("/suporte/topico/chat/delete", {
        id: resposta.id,
        usuarioClienteId: JSON.parse(localStorage.getItem("1")).refreshToken
          .usuarioClienteId,
        //logsLoginClienteId: JSON.parse(localStorage.getItem("logs")).id,
      });
      //if (response.data) {
      //  setGetDeleteResponse(response.data);
      //}
      if (response.data.status === "Error") {
        //setLoadingChat(false);
        setOpenDeleteModal(false);
        setConfirmarDelete(false);
        setSnackbar({
          children: "Error: Não foi possível apagar dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados excluídos com sucesso",
          severity: "success",
        });
        //setLoadingChat(false);
        setOpenDeleteModal(false);
        setConfirmarDelete(false);
      }
    } catch (error) {
      // setLoadingChat(false);
      setOpenDeleteModal(false);
      setConfirmarDelete(false);
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    }
  }

  async function handleApiFixarChat(fixar) {
    try {
      //setLoadingChat(true);
      const response = await apiSistema.put("/suporte/topico/chat/fix", {
        id: resposta.id,
        fixar,
        usuarioClienteId: JSON.parse(localStorage.getItem("1")).refreshToken
          .usuarioClienteId,
        // logsLoginClienteId: JSON.parse(localStorage.getItem("logs")).id,
      });
      //if (response.data) {
      //  setGetFixResponse(response.data);
      //}
      if (response.data.status === "Error") {
        //setLoadingChat(false);
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });
        //setLoadingChat(false);
      }
    } catch (error) {
      // setLoadingChat(false);
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    }
  }
  return (
    <Box sx={stylesCardResposta.boxResponse}>
      <Avatar
        sx={stylesCardResposta.avatarResponse}
        src={
          resposta.usuarioCliente?.PerfilCliente?.foto ||
          resposta.usuario?.Perfil?.foto
        }
      >
        {resposta.usuario?.Perfil?.nome.trim().toUpperCase()[0] ||
          resposta.usuarioCliente?.PerfilCliente?.nomeFantasia
            ?.trim()
            .toUpperCase()[0] ||
          resposta.usuarioCliente?.PerfilCliente?.nome
            ?.trim()
            .toUpperCase()[0] ||
          "U"}
      </Avatar>
      {editar && (
        <Stack
          sx={{
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Editor
                value={editaResposta}
                onChange={(value) => {
                  setEditaResposta(value);
                  if (!value || value === "<p><br></p>") {
                    setDisabledButtonEdit(true);
                  } else {
                    setDisabledButtonEdit(false);
                  }
                }}
              />
            </Box>
            <Box
              sx={{
                width: "95%",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Button
                onClick={() => {
                  // clearCardComentario();
                  setEditar(false);
                }}
                color="secondary"
              >
                Cancelar
              </Button>
              <Button
                disabled={disabledButtonEdit}
                onClick={() => {
                  setDisabledButtonEdit(true);
                  handleApiPutChat(resposta.id);
                  setEditar(false);
                }}
              >
                Enviar
              </Button>
            </Box>
          </Box>
        </Stack>
      )}
      {!editar && (
        <Stack direction={"column"} gap={"10px"} width={"80%"}>
          <Stack direction={"row"} sx={{ justifyContent: "space-between" }}>
            <Stack direction={"row"} gap={"5px"}>
              <Typography
                variant="h4"
                component={"div"}
                gutterBottom
                sx={{
                  fontSize: "1.0rem",
                  fontWeight: "500",
                  textTransform: "capitalize",
                }}
                color="primary"
              >
                {resposta.usuario?.Perfil?.nome
                  ? resposta.usuario?.Perfil?.nome
                  : resposta.usuarioCliente?.PerfilCliente.pessoaFisica
                  ? resposta.usuarioCliente?.PerfilCliente?.nome
                  : resposta.usuarioCliente?.PerfilCliente?.nomeFantasia ||
                    "Usuário sem nome"}
              </Typography>
              <Typography
                sx={{
                  fontSize: "0.8rem",
                  mt: 0.1,
                }}
              >
                há {handleTime(resposta.data)}
                {resposta.dataUpdate ? " (editado)" : ""}
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              gap={"5px"}
              sx={{
                alignItems: "center",
                mr: "-20px",
                mt: "-10px",
              }}
            >
              {resposta.fixar && (
                <Chip
                  label="Fixado"
                  size="small"
                  icon={<PushPin fontSize="small" />}
                  color="primary"
                  //sx={{ paddingLeft: "5px" }}
                />
              )}

              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVert />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseMenu}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: "15ch",
                  },
                }}
              >
                <MenuItem
                  key="op1"
                  onClick={() => {
                    handleApiFixarChat(!resposta.fixar);
                    handleCloseMenu();
                  }}
                >
                  <PushPin sx={{ mr: 1, color: "primary.main" }} />
                  {resposta.fixar ? "Desafixar" : "Fixar"}
                </MenuItem>
                {resposta?.usuarioClienteId === usuarioClienteId && <Divider />}
                {resposta?.usuarioClienteId === usuarioClienteId && (
                  <MenuItem
                    key="op2"
                    onClick={() => {
                      handleCloseMenu();
                      setEditaResposta(resposta.texto);
                      setEditar(true);
                    }}
                  >
                    <Edit sx={{ mr: 1, color: "primary.main" }} />
                    Editar
                  </MenuItem>
                )}

                {resposta?.usuarioClienteId === usuarioClienteId && <Divider />}
                {resposta?.usuarioClienteId === usuarioClienteId && (
                  <MenuItem
                    key="op3"
                    onClick={() => {
                      handleCloseMenu();
                      setOpenDeleteModal(true);
                    }}
                  >
                    <Delete sx={{ mr: 1, color: "secondary.main" }} />
                    Deletar
                  </MenuItem>
                )}
              </Menu>
            </Stack>
          </Stack>
          <Typography
            dangerouslySetInnerHTML={{ __html: resposta.texto }}
            id="myParagraph"
            sx={[
              {
                mt: "-30px",
                fontSize: "0.95rem",
                fontWeight: "400",
                textAlign: "justify",
                textOverflow: "ellipsis",
                overflow: "hidden",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
              },
              showMoreDesc ? {} : { WebkitLineClamp: 5 },
            ]}
          ></Typography>
          {showButtonMoreDesc && (
            <>
              <Button
                sx={stylesCardResposta.buttonShowMore}
                onClick={() => setShowMoreDesc(!showMoreDesc)}
              >
                {showMoreDesc ? <p>Mostrar menos</p> : <p>Mostrar mais</p>}
              </Button>
            </>
          )}
          <Carousel
            visibleItems={3}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            animation="slide"
            autoPlay={false}
            cycleNavigation={false}
            navButtonsAlwaysVisible={true}
            indicators={false}
          ></Carousel>
        </Stack>
      )}
      {/*<DeleteDialog
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        confirmarDelete={confirmarDelete}
        handleApiDelete={handleApiDelete}
      />*/}
      <AlertModal
        openModal={openDeleteModal}
        setOpenModal={setOpenDeleteModal}
        confirmar={confirmarDelete}
        handleButton={handleApiDelete}
        severity={"error"}
        singleButton={false}
      />
    </Box>
  );
}
