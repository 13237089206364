import { Box, Grid } from "@mui/material";
import flowSvg from "../../assets/logosSvg/LOGOEASYFLOW.svg";
import momSvg from "../../assets/logosSvg/LOGOEASYMON.svg";
import biSvg from "../../assets/logosSvg/LOGOEASYBI.svg";
import reportSvg from "../../assets/logosSvg/LOGOEASYREPORT.svg";
import mapsSvg from "../../assets/logosSvg/LOGOEASYMAPS.svg";
import watchSvg from "../../assets/logosSvg/LOGOEASYWATCH.svg";
import shopSvg from "../../assets/logosSvg/LOGOMWShop.svg";

import zabbixLogo from "../../assets/zabbixLogo.png";
import MyCard from "./MyCard";

import Inbox from "./inbox/inbox";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",

    cardGroup: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      flexDirection: "row",
      flexWrap: "wrap",
      gap: "20px",
      width: "100%",
    },
  },
  body: {
    gap: "1.5rem",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
};

export default function NocLinks() {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.body}>
        <Grid
          container
          rowSpacing={1}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          columns={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        >
          <Grid item xs={6} md={6} lg={4} xl={3}>
            <MyCard
              link={window?.config?.REACT_APP_GRAFANA_LINK || ""}
              img={momSvg}
              bgcolor={"#5B2E87"}
              circleColorOne={"#E65835"}
              circleColorTwo={"#AA3F76"}
            />
          </Grid>
          <Grid item xs={6} md={6} lg={4} xl={3}>
            <MyCard
              img={flowSvg}
              bgcolor={"#5B2E87"}
              link={window?.config?.REACT_APP_GRAFANA_LINK || ""}
              circleColorOne={"#2DC989"}
              circleColorTwo={"#AA3F76"}
            />
          </Grid>
          <Grid item xs={6} md={6} lg={4} xl={3}>
            <MyCard
              img={zabbixLogo}
              size={"small"}
              bgcolor={"#5B2E87"}
              link={window?.config?.REACT_APP_ZABBIX_LINK || ""}
              circleColorOne={"#F43F59"}
              circleColorTwo={"#822e87"}
            />
          </Grid>
          <Grid item xs={6} md={6} lg={4} xl={3}>
            <MyCard
              img={biSvg}
              size={"medium"}
              bgcolor={"#5B2E87"}
              link={window?.config?.REACT_APP_GRAFANA_LINK || ""}
              circleColorOne={"#2DC989"}
              circleColorTwo={"#AA3F76"}
            />
          </Grid>
          <Grid item xs={6} md={6} lg={4} xl={3}>
            <MyCard
              img={reportSvg}
              bgcolor={"#5B2E87"}
              target=""
              link={"/easyReport"}
              circleColorOne={"#F48710"}
              circleColorTwo={"#AA3F76"}
            />
          </Grid>
          <Grid item xs={6} md={6} lg={4} xl={3}>
            <MyCard
              img={mapsSvg}
              bgcolor={"#5B2E87"}
              target=""
              link={"/easyMaps"}
              circleColorOne={"#EA4860"}
              circleColorTwo={"#2dc988"}
            />
          </Grid>
          <Grid item xs={6} md={6} lg={4} xl={3}>
            <MyCard
              img={watchSvg}
              bgcolor={"#5B2E87"}
              target=""
              link={"/easyWatch"}
              circleColorOne={"#E9603B"}
              circleColorTwo={"#AA3F76"}
            />
          </Grid>
          <Grid item xs={6} md={6} lg={4} xl={3}>
            <MyCard
              img={shopSvg}
              bgcolor={"#5B2E87"}
              link={"iot/shopping"}
              circleColorOne={"#3EACE2"}
              circleColorTwo={"#AA3F76"}
            />
          </Grid>
        </Grid>
        <Inbox />
      </Box>
    </Box>
  );
}
