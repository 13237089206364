import {
  Search,
  SearchOutlinedIcon,
  SearchIcon,
  AddBox,
} from "@mui/icons-material";
import {
  Box,
  Paper,
  Stack,
  Typography,
  Avatar,
  SvgIcon,
  TextField,
  Autocomplete,
  InputAdornment,
  Grid,
} from "@mui/material";

import mwIcon from "../../assets/logo4.png";
//import { makeStyles } from "@material-ui/core/styles";

import { useEffect, useState } from "react";

import api from "../../services/api";

const styles = {
  ContainerBox: {
    // width: "1600px",
    height: "900px",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default function Empresas(props) {
  return <Box sx={styles.ContainerBox}></Box>;
}

const empresas = [
  { nome: "Nasa", year: 1994 },
  { nome: "Meta", year: 1972 },
  { nome: "Google", year: 1973 },
  { nome: "HUAWEI", year: 1975 },
  { nome: "Amazon", year: 1976 },
  { nome: "Microsoft", year: 1945 },
  { nome: "Samsung", year: 1992 },
];
