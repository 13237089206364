import {
  Paper,
  Stack,
  TextField,
  Divider,
  Typography,
  useTheme,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Autocomplete,
  Tooltip,
  Chip,
  Button,
  Popover,
  Tabs,
  Tab,
  InputAdornment,
  InputBase,
  IconButton,
  alpha,
  Grow,
  Fade,
} from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import IconTransform from "../../iconsElement";
import { Add, Delete, Edit, WhereToVote } from "@mui/icons-material";
import ModalIcon from "../modalAddIcons";
export default function PainelIcons({
  iconsElement,
  setIconsElement = () => {},
  api,
  setSnackbar = () => {},
  setIconElement = () => {},
  handleOnDelete = () => {},
  setCheckChanges = () => {},
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState("nativos");
  const [search, setSearch] = useState("");
  const [iconSelected, setIconSelected] = useState(null);
  const [openModalImg, setOpenModalImg] = useState(false);
  const theme = useTheme();

  const filterIcon = useCallback(() => {
    return iconsElement.filter((icon) =>
      icon.nome.toLowerCase().includes(search.toLowerCase() || "") &&
      value == "nativos"
        ? icon.nome.startsWith("_")
        : !icon.nome.startsWith("_")
    );
  }, [search, iconsElement, value]);
  useEffect(() => {
    if (
      iconSelected &&
      iconsElement.find((a) => a.id == iconSelected.id) == null
    ) {
      setIconSelected(null);
    } else {
      setIconSelected(iconsElement.find((a) => a.id == iconSelected?.id));
    }
  }, [iconsElement]);

  const styles = {
    input: {
      backgroundColor: theme.palette.background.paperSelectedMaps,
      p: "5px 10px",
      display: "flex",
      alignItems: "center",
      width: "100%",
      boxShadow: "none",
      borderRadius: "7px",
      display: "flex",
      direction: "row",
      alignItems: "center",
      gap: "8px",
    },
    button: {
      width: "100%",
      textTransform: "none",
      color: theme.palette.color.text,
      fontWeight: 400,
      border: `1px solid ${theme.palette.background.paperSelectedMaps}`,
      backgroundColor: theme.palette.background.paperSelectedMaps,
      ":hover": {
        backgroundColor: theme.palette.background.hover,
      },
    },
    buttonIcons: { textTransform: "none" },
    paper: {
      width: "400px",
      height: "300px",
      p: "15px",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid" + theme.palette.background.paperSelectedMaps,
      borderRadius: "7px",
    },
    paperExibition: {
      width: "400px",
      height: "100px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: theme.palette.background.paper,

      borderRadius: "7px",
      mb: "5px",
    },
    nameIcon: {
      fontSize: "20px",
      color: theme.palette.color.textEasyMaps,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      width: "250px",
    },
    tab: {
      "& .MuiTabs-indicator": {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "transparent",
      },
      "& .Mui-selected": {
        //backgroundColor: alpha("#888585", 0.2),
        backgroundColor: theme.palette.background.paperSelectedMaps,
        borderRadius: "7px",
      },
      "& .MuiButtonBase-root": {
        padding: "17px 15px",
        borderRadius: "6px",
        minHeight: "25px",
        height: "25px",
        textTransform: "none",
        fontSize: "14px",
      },
    },
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setIconSelected(null);
  };
  const paperRef = useRef(null);

  const handlePaperClick = (event) => {
    // Converte a NodeList em um array e verifica se o clique foi em algum botão
    const isButtonClick = Array.from(
      paperRef.current.querySelectorAll("button")
    ).some((button) => button.contains(event.target));
    if (!isButtonClick) {
      setIconSelected(null);
    }
  };

  return (
    <>
      <Button onClick={handleClick} variant="outlined" sx={styles.button}>
        Alterar ícone{" "}
      </Button>
      <Popover
        onClose={handleClose}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        sx={{
          "& .MuiPopover-paper": {
            backgroundColor: "transparent", // Cor de fundo personalizada
            boxShadow: "none",
            paddingRight: "15px",
          },
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <Fade in={Boolean(iconSelected)} timeout={0}>
          <Paper sx={styles.paperExibition}>
            <Stack direction={"row"} alignItems="center" gap={2.5}>
              {" "}
              <IconTransform img={iconSelected?.img} size="45px" />
              <Divider
                orientation="vertical"
                variant="middle"
                sx={{ height: "60px" }}
              />
              <Box>
                <Typography sx={styles.nameIcon}>
                  {iconSelected?.nome?.replace("_", "")}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: theme.palette.color.textDisabled,
                    mt: "-5px",
                  }}
                >
                  {iconSelected?.nome?.startsWith("_")
                    ? "Nativo"
                    : "Customizado"}
                </Typography>

                <Stack gap={1} direction={"row"}>
                  <Button
                    startIcon={<WhereToVote />}
                    sx={styles.buttonIcons}
                    color="inherit"
                    variant="text"
                    onClick={() => {
                      setIconElement(iconSelected);
                      setCheckChanges(true);
                      setIconSelected(null);
                      setAnchorEl(null);
                    }}
                  >
                    Usar
                  </Button>
                  <Button
                    startIcon={<Edit />}
                    sx={styles.buttonIcons}
                    variant="text"
                    disabled={iconSelected?.nome?.startsWith("_")}
                    color="inherit"
                    onClick={() => {
                      if (!iconSelected?.nome?.startsWith("_"))
                        setOpenModalImg(true);
                    }}
                  >
                    Editar
                  </Button>
                  <Button
                    sx={styles.buttonIcons}
                    startIcon={<Delete />}
                    disabled={iconSelected?.nome?.startsWith("_")}
                    color="primary"
                    variant="text"
                    onClick={() => {
                      if (!iconSelected?.nome?.startsWith("_"))
                        handleOnDelete(iconSelected.id);
                    }}
                  >
                    Deletar
                  </Button>
                </Stack>
              </Box>
            </Stack>
          </Paper>
        </Fade>

        <Paper sx={styles.paper} ref={paperRef} onClick={handlePaperClick}>
          <Stack gap={2} alignItems={"space-between"}>
            <Stack direction={"row"} gap={1} alignItems={"center"}>
              <Paper sx={styles.input}>
                <SearchIcon
                  size="small"
                  sx={{ color: theme.palette.color.textDisabled }}
                />
                <InputBase
                  placeholder="Pesquisar icons"
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  onChange={(event) => setSearch(event.target.value)}
                />
              </Paper>
              {value != "nativos" && (
                <>
                  <Tooltip title="Adicionar ícone">
                    <IconButton
                      onClick={() => {
                        setIconSelected(null);
                        setOpenModalImg(true);
                      }}
                      size="small"
                      sx={{
                        backgroundColor:
                          theme.palette.background.paperSelectedMaps,
                      }}
                    >
                      <Add />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </Stack>
            <Stack sx={{ overflow: "auto", height: "150px" }}>
              <Stack
                direction={"row"}
                flexWrap={"wrap"}
                justifyContent={"flex-start"}
                gap={1}
              >
                {filterIcon()?.map((icon) => (
                  <Tooltip title={icon.nome.replace("_", "")}>
                    <IconButton
                      size="small"
                      sx={{
                        borderRadius: "7px",
                        height: "40px",
                        width: "40px",
                      }}
                      onClick={() => setIconSelected(icon)}
                    >
                      <IconTransform img={icon?.img} size="25" />
                    </IconButton>
                  </Tooltip>
                ))}
              </Stack>
            </Stack>
            <Stack gap={1}>
              <Divider />

              <Tabs value={value} onChange={handleChange} sx={styles.tab}>
                <Tab label="Nativos" value={"nativos"} />
                <Tab label="Customizados" value={"customizados"} />
              </Tabs>
            </Stack>
          </Stack>
        </Paper>
      </Popover>
      <ModalIcon
        iconsElementNames={iconsElement.map((icon) => icon.nome)}
        openModalImg={openModalImg}
        setIconsElement={setIconsElement}
        setOpenModalImg={setOpenModalImg}
        api={api}
        setSnackbar={setSnackbar}
        setIconElement={setIconElement}
        icon={iconSelected}
      />
    </>
  );
}
