import { Image, View, Text, Line, Svg } from "@react-pdf/renderer";
import { styles } from "./styles";
import mwIcon from "../../../../../assets/Logo5.png";
import cellIcon from "../../../../../assets/easyReport/icon/cell.png";
import siteIcon from "../../../../../assets/easyReport/icon/site.png";
import instaIcon from "../../../../../assets/easyReport/icon/insta.png";

const Contatos = () => {
  return (
    <View
      style={[
        styles.alingRowCenter,
        { marginTop: "-5px", marginBottom: "5px" },
      ]}
    >
      <View style={styles.alingRowCenter}>
        <Image style={styles.iconContato} src={cellIcon} />
        <Text style={[styles.header]}>{"(84) 9 8121 - 8149"}</Text>
      </View>
      <View style={styles.alingRowCenter}>
        <Image
          style={[styles.iconContato, { marginLeft: "15px" }]}
          src={siteIcon}
        />
        <Text style={[styles.header]}>{"contato@mw-solucoes.com"}</Text>
      </View>
      <View style={styles.alingRowCenter}>
        <Image
          style={[styles.iconContato, { marginLeft: "15px" }]}
          src={instaIcon}
        />
        <Text style={[styles.header]}>{"@mwsolucoesltda"}</Text>
      </View>
    </View>
  );
};

export const HeaderPdf = ({ relatorio }) => (
  <>
    <View fixed>
      <Image style={styles.logo} src={mwIcon} />
      <Contatos />
      <Text style={[{ fontSize: 16 }, styles.title]}>
        {relatorio.titulo.toUpperCase()}
      </Text>
      <Svg height="1" width="100%" style={{ marginTop: -5, marginBottom: 10 }}>
        <Line
          x1="0"
          y1="0"
          x2="800"
          y2="0"
          strokeWidth={1.5}
          stroke="rgb(0,0,0)"
        />
      </Svg>
    </View>
  </>
);
