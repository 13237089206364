import {
  Cancel,
  Delete,
  EditNote,
  RestoreFromTrash,
  Send,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { GridPagination } from "@mui/x-data-grid";

export const CustomPagination = (props) => {
  const theme = useTheme();
  const mdx = useMediaQuery(theme.breakpoints.down("mdx"));
  const md = useMediaQuery(theme.breakpoints.down("md"));

  const {
    setCellSelected = () => {},
    editMode,
    rowSelectionModel = [],
    updateStateHost = () => {},
    setOpenModalUpdateMany = () => {},
    lixeira = false,
  } = props;
  return (
    <Stack
      sx={{ width: "100%", justifyContent: "center", direction: "row" }}
      onClick={() => setCellSelected(null)}
    >
      <GridPagination {...props} />
      <Stack
        direction="row"
        alignItems="center"
        sx={{ width: "max-content", position: "absolute" }}
      >
        <Typography
          sx={{ height: "max-content", padding: "10px", fontSize: "13px" }}
        >
          {rowSelectionModel.length + " selecionado"}
        </Typography>
        <Tooltip
          title={rowSelectionModel.length == 0 && "Nenhuma linha selecionada"}
        >
          <Box>
            {editMode && (
              <>
                <Button
                  disabled={rowSelectionModel.length == 0}
                  onClick={() => setOpenModalUpdateMany(true)}
                  variant="text"
                  sx={{ width: "max-content", mr: "10px", fontSize: "13px" }}
                  startIcon={<EditNote />}
                >
                  Atualização em massa
                </Button>
              </>
            )}
            {!editMode && (
              <>
                <Button
                  disabled={editMode || rowSelectionModel.length == 0}
                  onClick={() => updateStateHost(rowSelectionModel)}
                  variant="text"
                  sx={{
                    width: "max-content",
                    mr: "10px",
                    fontSize: "13px",
                    color: !lixeira
                      ? "secondary.main"
                      : theme.palette.chip.completed,
                  }}
                  startIcon={lixeira ? <Delete /> : <RestoreFromTrash />}
                >
                  {mdx
                    ? "lixeira"
                    : !lixeira
                    ? "Mover para lixeira"
                    : "Restaurar"}
                </Button>
              </>
            )}
          </Box>
        </Tooltip>
      </Stack>
    </Stack>
  );
};
