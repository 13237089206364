import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import {
  Box,
  Button,
  Collapse,
  Fade,
  List,
  ListItem,
  useTheme,
} from "@mui/material";
import { useState, useRef, useEffect, Children, cloneElement } from "react";
import { TransitionGroup } from "react-transition-group";

const Carousel = ({ children }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalChildren = Children.count(children);
  const carouselRef = useRef(null);
  const [visibleCount, setVisibleCount] = useState(0);

  const theme = useTheme();

  const updateWidth = () => {
    if (carouselRef?.current) {
      setVisibleCount(Math.floor(carouselRef.current.offsetWidth / 366));
    }
  };

  useEffect(() => {
    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + 1 < totalChildren - visibleCount + 1
        ? prevIndex + 1
        : prevIndex
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  const classes = {
    carouselStyle: {
      position: "relative",
      overflow: "hidden",
      borderRadius: "10px",
      display: "flex",
      minHeight: "180px",
      width: "50vw",
      flexGrow: 1,
      justifyContent: "flex-start",
      "@media (max-width: 600px)": {
        width: "69vw",
      },
    },
    carouselSlidesStyle: {
      display: "flex",
      flexDirection: "row",
      transition: "transform 0.5s ease",
      transform: `translateX(-${currentIndex * 366}px)`,
    },
    carouselImageStyle: {
      minWidth: `${100 / visibleCount}%`,
    },
    buttonStyle: {
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      minWidth: "25px",
      minHeight: "25px",
      width: "30px",
      height: "30px",
      borderRadius: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 99,
    },
    leftGrandiente: {
      background:
        theme.palette.mode === "dark"
          ? "linear-gradient(90deg ,#1e1e1eff 0%,#1e1e1e85 50%,#1e1e1e28 100%)"
          : "linear-gradient(90deg ,#ffffffff 0%,#ffffff85 50%,#ffffff28 100%)",
      display: "flex",
      height: "100%",
      width: "80px",
      pointerEvents: "none",
      zIndex: 99,
    },
    colapse: {
      position: "absolute",
      pointerEvents: "none",
      left: 0,
      width: "80px",
      zIndex: 99,
      height: 200,
    },
    rightGrandiente: {
      background:
        theme.palette.mode === "dark"
          ? "linear-gradient(90deg ,#1e1e1e28 0%,#1e1e1e85 50%, #1e1e1eff 100%)"
          : "linear-gradient(90deg ,#ffffff28 0%,#ffffff85 50%,#ffffffff  100%)",
      display: "flex",
      height: "100%",
      width: "80px",
      right: 0,
      position: "absolute",
      pointerEvents: "none",
      display: currentIndex === totalChildren - visibleCount ? "none" : "flex",
    },
  };

  return (
    <List ref={carouselRef} sx={classes.carouselStyle} id="cirilo">
      <Collapse
        orientation="horizontal"
        in={currentIndex !== 0}
        sx={classes.colapse}
      >
        <Box sx={classes.leftGrandiente} />
      </Collapse>
      <Button
        style={{
          ...classes.buttonStyle,
          left: "10px",
          display: currentIndex === 0 ? "none" : "flex",
        }}
        onClick={prevSlide}
        variant="contained"
        disabled={currentIndex === 0}
      >
        <ChevronLeft />
      </Button>

      <TransitionGroup style={classes.carouselSlidesStyle}>
        {Children.map(children, (child, index) => (
          <Collapse
            key={children[index].key}
            orientation="horizontal"
            sx={{ ml: index === 0 ? 0 : 2 }}
          >
            {cloneElement(child, { style: classes.carouselImageStyle })}
          </Collapse>
        ))}
      </TransitionGroup>

      <Box sx={classes.rightGrandiente} />
      <Button
        style={{
          ...classes.buttonStyle,
          right: "10px",
          display:
            currentIndex === totalChildren - visibleCount ? "none" : "flex",
        }}
        onClick={nextSlide}
        variant="contained"
        disabled={currentIndex === totalChildren - visibleCount}
      >
        <ChevronRight />
      </Button>
    </List>
  );
};

export default Carousel;
