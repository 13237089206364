import { Error } from "@mui/icons-material";
import {
  Tooltip,
  TextField,
  useTheme,
  Stack,
  Modal,
  Box,
  Typography,
  Paper,
  FormControlLabel,
  Checkbox,
  Button,
  Autocomplete,
} from "@mui/material";
import { memo, useCallback, useState } from "react";
import { AutoCompleteMemo } from "./autoCompleteMemo";
import AlertModal from "../../alertModal";

export function ModalEditMass({
  open = false,
  setOpen = () => {},
  predefinicoes = [],
  proxys = [],
  tiposInterfaces = [
    { tipo: "AGENTE", porta: 10050 },
    { tipo: "SNMP", porta: "161" },
  ],
  tiposSNMP = [{ tipoSnmp: "SNMPv1" }, { tipoSnmp: "SNMPv2" }],
  setHostsEdit = () => {},
  rowSelectionModel = [],
}) {
  //variaveis de agrupamento
  const [tags, setTags] = useState(false);
  const [interfaces, setInterfaces] = useState(false);
  const [ssh, setSsh] = useState(false);
  const [telnet, setTelnet] = useState(false);
  const [comunidade, setComunidade] = useState(false);
  const [coletor, setColetor] = useState(false);
  //variaveis de dados
  const [funcao, setFuncao] = useState(null);
  const [modelo, setModelo] = useState(null);
  const [fabricante, setFabricante] = useState(null);
  const [tipoInterface, setTipoInterface] = useState(null);
  const [portaInterface, setPortaInterface] = useState(null);
  const [tipoSnmp, setTipoSnmp] = useState(null);
  const [userSsh, setUserSsh] = useState(null);
  const [senhaSsh, setSenhaSsh] = useState(null);
  const [portaSsh, setPortaSsh] = useState(null);
  const [comunidadeName, setComunidadeName] = useState(null);
  const [coletorName, setColetorName] = useState(null);
  const [userTelnet, setUserTelnet] = useState(null);
  const [senhaTelnet, setSenhaTelnet] = useState(null);
  const [portaTelnet, setPortaTelnet] = useState(null);

  const [modalAlert, setmodalAlert] = useState(false);

  const theme = useTheme();
  const handleClose = (event, reason) => {
    if (reason === "backdropClick" && validation()) {
      setmodalAlert(true);
      return; // Impede o fechamento
    }
    setTipoInterface(null);
    setPortaInterface(null);
    setUserSsh(null);
    setSenhaSsh(null);
    setPortaSsh(null);
    setUserTelnet(null);
    setSenhaTelnet(null);
    setPortaTelnet(null);
    setComunidadeName(null);
    setColetorName(null);
    setFuncao(null);
    setFabricante(null);
    setModelo(null);
    setOpen(false);
    setTags(false);
    setSsh(false);
    setTelnet(false);
    setComunidade(false);
    setColetor(false);
    setInterfaces(false);
    setOpen(false);
  };
  const styles = {
    container: {
      width: "300px",
      backgroundColor: "#303030",
      p: "10px 20px",
      borderRadius: "4px",
    },
    textField: {
      mb: "10px",
    },
  };

  const applyChanges = () => {
    setHostsEdit((data) => {
      return [
        ...data.map((host) => {
          if (!rowSelectionModel.includes(host.id)) return host;

          if (tags) {
            if (fabricante) {
              host.fabricante = fabricante ? fabricante : host.fabricante;
              host.funcao = funcao ? funcao : host.funcao;
              host.modelo = modelo ? modelo : host.modelo;
            }
          }
          if (interfaces) {
            host.tipoSnmp = tipoSnmp ? tipoSnmp : host.tipoSnmp;
            host.interface = tipoInterface ? tipoInterface : host.interface;
            host.portaSnmp = portaInterface
              ? portaInterface.toString()
              : host.portaSnmp;
          }
          if (ssh) {
            host.userSsh = userSsh ? userSsh : host.userSsh;
            host.senhaSsh = senhaSsh ? senhaSsh : host.senhaSsh;
            host.portaSsh = portaSsh ? portaSsh : host.portaSsh;
          }
          if (telnet) {
            host.userTelnet = userTelnet ? userTelnet : host.userTelnet;
            host.senhaTelnet = senhaTelnet ? senhaTelnet : host.senhaTelnet;
            host.portaTelnet = portaTelnet ? portaTelnet : host.portaTelnet;
          }
          host.comunidade = comunidadeName ? comunidadeName : host.comunidade;
          host.coletor = coletorName ? coletorName : host.coletor;

          return host;
        }),
      ];
    });
    handleClose();
  };
  const validation = () => {
    return (
      (tags || ssh || telnet || interfaces || comunidade || coletor) &&
      (funcao ||
        modelo ||
        fabricante ||
        tipoInterface ||
        portaInterface ||
        tipoSnmp ||
        userSsh ||
        senhaSsh ||
        portaSsh ||
        comunidadeName ||
        coletorName ||
        userTelnet ||
        senhaTelnet ||
        portaTelnet)
    );
  };
  const filterTags = () => {
    const funcoes =
      predefinicoes
        ?.find((pre) => pre.fabricante == fabricante)
        ?.Funcoes?.map((a) => a.funcao) || [];

    // Encontra os modelos predefinidos para a função específica e desestrutura para obter os modelos ou um array vazio
    const modelos =
      predefinicoes
        ?.find((pre) => pre.fabricante == fabricante)
        ?.Funcoes?.find((pre) => pre.funcao == funcao)
        ?.Modelos?.map((a) => a.modelo) || [];

    return [funcoes, modelos];
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Paper sx={{ p: 4 }}>
          <Typography color="primary" variant="h6" component="h2">
            Atualização em massa
          </Typography>
          <Typography color="white">
            Selecione as colunas para realizar a atualização
          </Typography>
          <Stack gap={2} direction="row" sx={{ maxHeight: "450px", mt: 3 }}>
            <Stack gap={2} direction="column">
              <Stack sx={styles.container}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={tags}
                        onChange={(event) => setTags(event.target.checked)}
                      />
                    }
                    label={"Tags"}
                  />
                  {!tags && (
                    <Typography
                      fontSize="13px"
                      color={theme.palette.color.textDisable}
                    >
                      Original
                    </Typography>
                  )}
                </Stack>
                {tags && (
                  <>
                    <AutoCompleteMemo
                      styles={styles.textField}
                      label="Fabricante"
                      options={
                        predefinicoes
                          ? predefinicoes?.map((a) => a.fabricante)
                          : []
                      }
                      onChange={(event, newInputValue) => {
                        setFabricante(newInputValue);
                      }}
                      value={fabricante ? fabricante : ""}
                    />
                    <AutoCompleteMemo
                      styles={styles.textField}
                      label="Função"
                      options={filterTags()[0] || []}
                      onChange={(event, newInputValue) =>
                        setFuncao(newInputValue)
                      }
                      value={funcao ? funcao : ""}
                    />
                    <AutoCompleteMemo
                      styles={styles.textField}
                      label="Modelo"
                      options={filterTags()[1] || []}
                      onChange={(event, newInputValue) =>
                        setModelo(newInputValue)
                      }
                      value={modelo ? modelo : ""}
                    />
                  </>
                )}
              </Stack>
              <Stack sx={styles.container}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={interfaces}
                        onChange={(event) =>
                          setInterfaces(event.target.checked)
                        }
                      />
                    }
                    label={"Interface"}
                  />
                  {!interfaces && (
                    <Typography
                      fontSize="13px"
                      color={theme.palette.color.textDisable}
                    >
                      Original
                    </Typography>
                  )}
                </Stack>
                {interfaces && (
                  <>
                    <AutoCompleteMemo
                      clear={true}
                      styles={styles.textField}
                      label="Interface"
                      options={tiposInterfaces.map((a) => a.tipo)}
                      onChange={(event, newInputValue) => {
                        if (newInputValue) {
                        }
                        let porta =
                          tiposInterfaces?.find((a) => a.tipo == newInputValue)
                            ?.porta || null;
                        if (newInputValue == "SNMP")
                          setTipoSnmp(tiposSNMP[1].tipoSnmp);
                        else setTipoSnmp("-");
                        setTipoInterface(newInputValue);
                        if (porta) setPortaInterface(porta);
                      }}
                      value={tipoInterface ? tipoInterface : ""}
                    />
                    <AutoCompleteMemo
                      clear={true}
                      styles={styles.textField}
                      label="Tipo SNMP"
                      options={
                        tipoInterface == "SNMP"
                          ? tiposSNMP.map((a) => a.tipoSnmp)
                          : ["-"]
                      }
                      onChange={(event, newInputValue) => {
                        setTipoSnmp(newInputValue);
                      }}
                      value={tipoSnmp ? tipoSnmp : ""}
                    />

                    <TextField
                      key={"portInterface" + tipoInterface}
                      sx={styles.textField}
                      label="Porta"
                      variant="standard"
                      type="number"
                      value={portaInterface || ""}
                      onChange={(event) => {
                        setPortaInterface(event.target.value);
                      }}
                    />
                  </>
                )}
              </Stack>
            </Stack>
            <Stack gap={2} direction="column">
              <Stack sx={styles.container}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={ssh}
                        onChange={(event) => setSsh(event.target.checked)}
                      />
                    }
                    label={"Ssh"}
                  />
                  {!ssh && (
                    <Typography
                      fontSize="13px"
                      color={theme.palette.color.textDisable}
                    >
                      Original
                    </Typography>
                  )}
                </Stack>
                {ssh && (
                  <>
                    <TextField
                      sx={styles.textField}
                      label="Usuario"
                      disabled={!ssh}
                      variant="standard"
                      onChange={(event) => setUserSsh(event.target.value)}
                    />
                    <TextField
                      sx={styles.textField}
                      label="Senha"
                      disabled={!ssh}
                      variant="standard"
                      onChange={(event) => setSenhaSsh(event.target.value)}
                    />
                    <TextField
                      sx={styles.textField}
                      label="Porta"
                      disabled={!ssh}
                      variant="standard"
                      onChange={(event) => setPortaSsh(event.target.value)}
                    />{" "}
                  </>
                )}
              </Stack>

              <Stack sx={styles.container}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={comunidade}
                        onChange={(event) =>
                          setComunidade(event.target.checked)
                        }
                      />
                    }
                    label={"Comunidade"}
                  />
                  {!comunidade && (
                    <Typography
                      fontSize="13px"
                      color={theme.palette.color.textDisable}
                    >
                      Original
                    </Typography>
                  )}
                </Stack>
                {comunidade && (
                  <>
                    <TextField
                      sx={styles.textField}
                      label="Comunidade"
                      disabled={!comunidade}
                      variant="standard"
                      onChange={(event) =>
                        setComunidadeName(event.target.value)
                      }
                    />
                  </>
                )}
              </Stack>
            </Stack>
            <Stack gap={2} direction="column">
              <Stack sx={styles.container}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={telnet}
                        onChange={(event) => setTelnet(event.target.checked)}
                      />
                    }
                    label={"Telnet"}
                  />
                  {!telnet && (
                    <Typography
                      fontSize="13px"
                      color={theme.palette.color.textDisable}
                    >
                      Original
                    </Typography>
                  )}
                </Stack>
                {telnet && (
                  <>
                    <TextField
                      sx={styles.textField}
                      label="Usuario"
                      disabled={!telnet}
                      variant="standard"
                      onChange={(event) => setUserTelnet(event.target.value)}
                    />
                    <TextField
                      sx={styles.textField}
                      label="Senha"
                      disabled={!telnet}
                      variant="standard"
                      onChange={(event) => setSenhaTelnet(event.target.value)}
                    />
                    <TextField
                      sx={styles.textField}
                      label="Porta"
                      disabled={!telnet}
                      variant="standard"
                      onChange={(event) => setPortaTelnet(event.target.value)}
                    />
                  </>
                )}
              </Stack>

              <Stack sx={styles.container}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={coletor}
                        onChange={(event) => setColetor(event.target.checked)}
                      />
                    }
                    label={"Coletor"}
                  />
                  {!coletor && (
                    <Typography
                      fontSize="13px"
                      color={theme.palette.color.textDisable}
                    >
                      Original
                    </Typography>
                  )}
                </Stack>
                {coletor && (
                  <>
                    <AutoCompleteMemo
                      styles={styles.textField}
                      label="Coletor"
                      options={proxys.map((a) => a.host)}
                      onChange={(event, newInputValue) => {
                        setColetorName(newInputValue);
                      }}
                      value={coletorName}
                    />
                  </>
                )}
              </Stack>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            gap={2}
            justifyContent="end"
            width="100%"
            mt={2}
          >
            <Button onClick={applyChanges} disabled={!validation()}>
              Atualizar
            </Button>
            <Button color={"secondary"} onClick={handleClose}>
              Cancelar
            </Button>
          </Stack>
        </Paper>
      </Modal>
      <AlertModal
        openModal={modalAlert}
        setOpenModal={setmodalAlert}
        //confirmar={disableButton}
        textContent={
          "Se você prosseguir com a opreção pederá as modificações do modal de edição em massa"
        }
        handleButton={() => {
          setOpen(false);
          setmodalAlert(false);
        }}
        severity={"warning"}
        singleButton={false}
      />
    </>
  );
}
