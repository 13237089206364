import {
  Box,
  Paper,
  Stack,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
  Button,
  Tab,
  Tabs,
  Checkbox,
  Link,
  Snackbar,
  Alert,
  Avatar,
  Tooltip,
} from "@mui/material";
import { Visibility, VisibilityOff, PhotoCamera } from "@mui/icons-material";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Resizer from "react-image-file-resizer";

import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import api from "../../services/api";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axios from "axios";

const styles = {
  ContainerBox: {
    display: "flex",
    width: "100%",
    marginTop: "40px",
    height: "1000px",
    justifyContent: "center",
    alignItems: " center",
    flexShrink: 0,
  },
  TextCampo: {
    width: "48%",
    minWidht: "220px",
  },
  TextCampoSecondary: {
    width: "100%",
  },
  Paper: {
    justifyContent: "flex-start",
    alignItems: " center",
    minWidth: "520px",
    maxWidth: "980px",
    width: "40vw",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    gap: "25px",
    //  margin: "200px",
  },
  Title: {
    fontWeight: "400",
    lineHeight: "123.5%",
    letterSpacing: "0.8px",
    marginTop: "25px",
    textShadow: "0px 3px 3px rgba(0, 0, 0, 0.3)",
  },
  StackPage: {
    width: "460.5px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "16px",
  },
  ButtonFoto: {
    width: "150px",
    position: "absolute",
    display: " flex",
    padding: "8px 22px",
    alignItems: "center",
    margin: "160px",
    gap: "8px",
    fontSize: "15px",
  },
  StackFoto: {
    width: "100%",
    alignItems: "center",
    height: "220px",
  },
  Avatar: { width: 180, height: 180 },
  ButtonCadastrar: { width: "460px" },
};
const maskCPF = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");
};
const removeMask = (value) => {
  return value.replace(/\D/g, "");
};
const maskPhone = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "($1) $2")
    .replace(/(\d{5})(\d)/, "$1-$2")
    .replace(/(-\d{4})(\d+?)$/, "$1");
};
const maskCellPhone = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "($1) $2")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(-\d{4})(\d+?)$/, "$1");
};
const maskCnpj = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");
};
const isValidCPF = (cpf) => {
  if (
    typeof cpf !== "string" ||
    cpf.length !== 11 ||
    !Array.from(cpf).filter((e) => e !== cpf[0]).length
  ) {
    return false;
  }
  let soma = 0;
  let resto;
  for (let i = 1; i <= 9; i++)
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(9, 10))) return false;
  soma = 0;
  for (let i = 1; i <= 10; i++)
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(10, 11))) return false;
  return true;
};
const hasNumbersAndChars = (value) => {
  const hasNumber = /\d/.test(value); // Verifica se há pelo menos um número.
  const hasChar = /[a-zA-Z]/.test(value); // Verifica se há pelo menos um caractere (letra).
  return hasNumber && hasChar;
};

export default function Cadastro(props) {
  const [showPass, setShowPass] = useState(false);
  const [showPassConf, setShowPassConf] = useState(false);
  const [checkSenha, setCheckSenha] = useState(true);
  const [checkCaracterSenha, setCheckCaracterSenha] = useState(true);
  const [nome, setNome] = useState("");
  const [sobrenome, setSobrenome] = useState("");
  const [foto, setFoto] = useState("");
  const [cpf, setCpf] = useState("");
  const [inputCpf, setInputCpf] = useState("");
  const [razaoSocial, setRazaoSocial] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [inputCnpj, setInputCnpj] = useState("");
  const [telefone, setTelefone] = useState("");
  const [inputTelefone, setInputTelefone] = useState("");
  const [pessoaFisica, setPessoaFisica] = useState(true);
  const [usuarioClienteId, setUsuarioClienteId] = useState("");
  const [senha, setSenha] = useState("");
  const [nomeFantasia, setNomeFantasia] = useState("");
  const [confirmarSenha, setConfirmarSenha] = useState("");
  const [dataDeNascimento, setDataDeNascimento] = useState(null);
  const [pageValue, setPageValue] = useState(0);
  const [alertSize, setAlertSize] = useState(false);
  const [termosUso, setTermosUso] = useState(false);
  const [termosContrato, setTermosContrato] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showErrorCpf, setShowErrorCpf] = useState(false);
  const [showErrorCnpj, setShowErrorCnpj] = useState(false);
  const [checkData, setCheckData] = useState(false);
  const [checkCadastro, setCheckCadastro] = useState(true);
  const [logsLoginClienteId, setLogsLoginClienteId] = useState("");
  const [acessoClientesId, setAcessoClientesId] = useState("");
  const navigate = useNavigate();
  const [snackbar, setSnackbar] = useState(null);
  const token = () => {
    let result = localStorage.getItem("1");
    result = JSON.parse(result);
    if (result) {
      return result.token;
    } else {
      return "";
    }
  };
  const handleVoltar = () => {
    localStorage.clear();
    navigate("/login");
  };
  useEffect(() => {
    let result = localStorage.getItem("1");
    result = JSON.parse(result);
    setUsuarioClienteId(result.refreshToken.usuarioClienteId);

    let resultPerfil = localStorage.getItem("perfil");
    if (resultPerfil) {
      resultPerfil = JSON.parse(resultPerfil);
      if (resultPerfil.status) {
        navigate("/homein");
      }
    }
    let resultLogLogin = localStorage.getItem("logs");
    if (resultLogLogin !== "undefined") {
      resultLogLogin = JSON.parse(resultLogLogin);
      setLogsLoginClienteId(resultLogLogin.id);
    }
    let resultAcesso = localStorage.getItem("acesso");
    if (resultAcesso !== "undefined" && resultAcesso) {
      resultAcesso = JSON.parse(resultAcesso);
      setAcessoClientesId(resultAcesso.acessoClientesId);
    }
  }, []);
  useEffect(() => {
    if (confirmarSenha === senha) {
      setCheckSenha(false);
    } else {
      setCheckSenha(true);
    }
    if (senha.length >= 6 && hasNumbersAndChars(senha)) {
      setCheckCaracterSenha(false);
    } else {
      setCheckCaracterSenha(true);
    }
  }, [senha, confirmarSenha]);

  useEffect(() => {
    if (pessoaFisica) {
      if (
        confirmarSenha === senha &&
        senha !== "" &&
        confirmarSenha !== "" &&
        nome !== "" &&
        sobrenome !== "" &&
        dataDeNascimento !== null &&
        cpf !== "" &&
        telefone !== "" &&
        termosContrato !== false &&
        termosUso !== false
      ) {
        setCheckCadastro(false);
      } else {
        setCheckCadastro(true);
      }
    }

    if (!pessoaFisica) {
      if (
        confirmarSenha === senha &&
        senha !== "" &&
        confirmarSenha !== "" &&
        nomeFantasia !== "" &&
        cnpj !== "" &&
        telefone !== "" &&
        termosContrato !== false &&
        termosUso !== false
      ) {
        setCheckCadastro(false);
      } else {
        setCheckCadastro(true);
      }
    }
  }, [
    senha,
    nome,
    sobrenome,
    confirmarSenha,
    cpf,
    cnpj,
    razaoSocial,
    nomeFantasia,
    dataDeNascimento,
    telefone,
    pessoaFisica,
    termosContrato,
    termosUso,
  ]);
  function clearCampos() {
    setNome("");
    setSobrenome("");
    setCpf("");
    setDataDeNascimento(null);
    setSenha("");
    setConfirmarSenha("");
    setRazaoSocial("");
    setNomeFantasia("");
    setCnpj("");
    setTelefone("");
    setInputCnpj("");
    setInputCpf("");
    setInputTelefone("");
    setShowErrorCpf(false);
    setShowErrorCnpj(false);
    setShowError(false);
  }
  async function handleApi() {
    try {
      //let response = {};
      const response = await api.post("/perfil/create", {
        usuarioClienteId,
        foto,
        nome,
        sobrenome,
        cpf,
        cnpj,
        telefone,
        dataDeNascimento,
        nomeFantasia,
        razaoSocial,
        pessoaFisica,
        status: true,
        novaSenha: senha,
        acessoClientesId,
        logsLoginClienteId,
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });
        localStorage.setItem(
          "perfil",
          JSON.stringify(response.data.perfilCliente)
        );
        let json1 = JSON.parse(localStorage.getItem("1"));
        json1["perfilAlreadyExists"] = true;
        localStorage.setItem("1", JSON.stringify(json1));
        navigate("/homein");
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    }
  }
  return (
    <>
      <Box sx={styles.ContainerBox}>
        <Paper sx={styles.Paper}>
          <Stack
            sx={{
              position: "absolute",
              minWidth: "520px",
              maxWidth: "980px",
              width: "40vw",
              alignItems: "flex-start",
            }}
          >
            <Tooltip title={"Voltar"}>
              <IconButton
                aria-label="button-back"
                sx={{ ml: "30px", mt: "23px" }}
                onClick={() => {
                  handleVoltar();
                }}
              >
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
          </Stack>

          <Typography variant="h4" sx={styles.Title}>
            Cadastro
          </Typography>
          <Tabs
            value={pageValue}
            onChange={(event, newValue) => {
              setPageValue(newValue);
              setPessoaFisica(Boolean(!newValue));
              clearCampos();
            }}
            aria-label="tabs"
          >
            <Tab label="Pessoa Física" value={0} />
            <Tab label="Pessoa Jurídica" value={1} />
          </Tabs>
          {pageValue === 0 && (
            <Stack direction={"column"} sx={styles.StackPage}>
              <Stack sx={styles.StackFoto}>
                <Avatar src={foto} color="secondary" sx={styles.Avatar} />
                <Button
                  variant="contained"
                  component="label"
                  sx={styles.ButtonFoto}
                >
                  <PhotoCamera />
                  Adicionar
                  <input
                    hidden
                    accept=".png, .jpg, .jpeg"
                    type="file"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file.size < 1024 * 300) {
                        //1024 byte = 1 kbyte
                        // 1 MB
                        Resizer.imageFileResizer(
                          file,
                          500,
                          500,
                          "JPEG",
                          80,
                          0,
                          (uri) => {
                            setFoto(uri);
                          },
                          "base64",
                          500,
                          500
                        );
                        const fileReader = new FileReader();
                        fileReader.readAsDataURL(file);
                        fileReader.onload = () => {};
                        fileReader.onerror = (error) => {
                          console.log("Error:", error);
                        };
                      } else {
                        setAlertSize(true);
                      }
                    }}
                  />{" "}
                </Button>
              </Stack>

              <Stack direction={"row"} spacing={"20px"}>
                <TextField
                  required
                  id="nome"
                  label="Nome"
                  value={nome}
                  sx={styles.TextCampo}
                  onChange={(event) => {
                    setNome(event.target.value);
                  }}
                />
                <TextField
                  required
                  id="sobrenome"
                  label="Sobrenome"
                  value={sobrenome}
                  sx={styles.TextCampo}
                  onChange={(event) => {
                    setSobrenome(event.target.value);
                  }}
                />
              </Stack>
              <TextField
                required
                id="telefone"
                label="Telefone"
                value={inputTelefone}
                sx={styles.TextCampoSecondary}
                onChange={(event) => {
                  const value = event.target.value;
                  setTelefone(removeMask(value));
                  if (value.length <= 14) {
                    setInputTelefone(maskCellPhone(value));
                  } else {
                    setInputTelefone(maskPhone(value));
                  }
                }}
              />
              <Stack direction={"row"} spacing={"20px"}>
                <TextField
                  required
                  error={showErrorCpf}
                  helperText={showErrorCpf && "CPF inválido"}
                  id="cpf"
                  label="CPF"
                  value={inputCpf}
                  sx={styles.TextCampo}
                  onChange={(event) => {
                    const value = event.target.value;
                    setCpf(removeMask(maskCPF(value)));
                    setInputCpf(maskCPF(value));
                  }}
                />

                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={"pt-br"}
                >
                  <DatePicker
                    disableFuture
                    value={dayjs(dataDeNascimento)}
                    onChange={(newValeu) => {
                      const dataAtual = dayjs();
                      setDataDeNascimento(dayjs(newValeu));
                      if (newValeu && newValeu.isAfter(dataAtual, "day")) {
                        // Verifica se a newValeu é maior (no futuro) do que a dataAtual.
                        setCheckData(true); // Define o estado hasError como true se houver erro.
                      } else {
                        setCheckData(false); // Define o estado hasError como false se não houver erro.
                      }
                    }}
                    // renderInput={(params) => (
                    //   <TextField
                    //     required
                    //     error={checkData}
                    //     helperText={checkData && "Data inválida."}
                    //     {...params}
                    //     sx={styles.TextCampo}
                    //     color="primary"
                    //     id="dataDeNascimento"
                    //     label="Data de Nascimento"
                    //   />
                    // )}
                  />
                </LocalizationProvider>
              </Stack>
              <TextField
                required
                error={checkCaracterSenha && showError}
                sx={styles.TextCampoSecondary}
                name="senha"
                label="Senha"
                color="primary"
                helperText={
                  checkCaracterSenha &&
                  showError &&
                  "As senhas devem ter no mínimo 6 caracteres e conter pelo menos uma letra ou um número."
                }
                type={showPass ? "text" : "password"}
                value={senha}
                onChange={(event) => {
                  setSenha(event.target.value);
                }}
                slotProps={{
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={() => setShowPass((event) => !event)}
                        >
                          {showPass ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                }}
              />

              <TextField
                required
                error={checkSenha}
                sx={styles.TextCampoSecondary}
                name="confirmarsenha"
                label="Confirmar nova senha"
                color="primary"
                helperText={checkSenha && "Senhas não correspondem"}
                type={showPassConf ? "text" : "password"}
                value={confirmarSenha}
                onChange={(event) => {
                  setConfirmarSenha(event.target.value);
                }}
                slotProps={{
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={() => setShowPassConf((event) => !event)}
                        >
                          {showPassConf ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                }}
              />
            </Stack>
          )}
          {pageValue === 1 && (
            <Stack direction={"column"} sx={styles.StackPage}>
              <Stack sx={styles.StackFoto}>
                <Avatar src={foto} color="secondary" sx={styles.Avatar} />
                <Button
                  variant="contained"
                  component="label"
                  sx={styles.ButtonFoto}
                >
                  <PhotoCamera />
                  Adicionar
                  <input
                    hidden
                    accept=".png, .jpg, .jpeg"
                    type="file"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file.size < 1024 * 300) {
                        Resizer.imageFileResizer(
                          file,
                          500,
                          500,
                          "JPEG",
                          80,
                          0,
                          (uri) => {
                            setFoto(uri);
                          },
                          "base64",
                          500,
                          500
                        );
                        const fileReader = new FileReader();
                        fileReader.readAsDataURL(file);
                        fileReader.onload = () => {};
                        fileReader.onerror = (error) => {
                          console.log("Error:", error);
                        };
                      } else {
                        setAlertSize(true);
                      }
                    }}
                  />{" "}
                </Button>
              </Stack>
              <TextField
                required
                id="nomeFantasia"
                label="Nome Fantasia"
                value={nomeFantasia}
                sx={styles.TextCampoSecondary}
                onChange={(event) => {
                  setNomeFantasia(event.target.value);
                }}
              />{" "}
              <TextField
                required
                id="telefone"
                label="Telefone"
                value={inputTelefone}
                sx={styles.TextCampoSecondary}
                onChange={(event) => {
                  const value = event.target.value;
                  setTelefone(removeMask(value));
                  if (value.length <= 14) {
                    setInputTelefone(maskCellPhone(value));
                  } else {
                    setInputTelefone(maskPhone(value));
                  }
                }}
              />
              <TextField
                required
                error={showErrorCnpj}
                helperText={showErrorCnpj && "CPF inválido"}
                id="cnpj"
                label="CNPJ"
                value={inputCnpj}
                sx={styles.TextCampoSecondary}
                onChange={(event) => {
                  const value = event.target.value;
                  setInputCnpj(maskCnpj(value));
                  setCnpj(removeMask(value));
                }}
              />
              <TextField
                required
                error={checkCaracterSenha && showError}
                sx={styles.TextCampoSecondary}
                name="senha"
                label="Senha"
                color="primary"
                helperText={
                  checkCaracterSenha &&
                  showError &&
                  "As senhas devem ter no mínimo 6 caracteres e conter pelo menos uma letra ou um número."
                }
                type={showPass ? "text" : "password"}
                value={senha}
                onChange={(event) => {
                  setSenha(event.target.value);
                }}
                slotProps={{
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={() => setShowPass((event) => !event)}
                        >
                          {showPass ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                }}
              />
              <TextField
                required
                error={checkSenha}
                sx={styles.TextCampoSecondary}
                name="confirmarsenha"
                label="Confirmar nova senha"
                color="primary"
                helperText={checkSenha && "Essas senhas não correspondem"}
                type={showPassConf ? "text" : "password"}
                value={confirmarSenha}
                onChange={(event) => {
                  setConfirmarSenha(event.target.value);
                }}
                slotProps={{
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={() => setShowPassConf((event) => !event)}
                        >
                          {showPassConf ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                }}
              />
            </Stack>
          )}
          <Stack>
            <Stack
              direction="row"
              sx={{
                alignItems: "center",
                justifyContent: "flex-start",
                width: "460px",
              }}
            >
              <Checkbox
                checked={termosUso}
                onChange={(event) => {
                  setTermosUso(event.target.checked);
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
              {"Eu aceito os "}&nbsp;
              <Link href="#" underline="always">
                {"termos de uso"}
              </Link>
            </Stack>
            <Stack
              direction="row"
              alignItems={"center"}
              justifyContent={"flex-start"}
              width="460px"
            >
              <Checkbox
                checked={termosContrato}
                onChange={(event) => {
                  setTermosContrato(event.target.checked);
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
              {"Eu aceito os "}&nbsp;
              <Link href="#" underline="always">
                {"termos de contrato"}
              </Link>
            </Stack>
          </Stack>
          <Stack sx={{ height: "60px" }}>
            <Button
              variant="contained"
              sx={styles.ButtonCadastrar}
              disabled={checkCadastro}
              onClick={() => {
                if (checkCaracterSenha) {
                  setShowError(true);
                } else if (!isValidCPF(cpf)) {
                  setShowErrorCpf(true);
                  if (!pessoaFisica) {
                    handleApi();
                  }
                  //  } else if (!isCNPJ.validate(cnpj)) {
                  //    setShowErrorCnpj(true);
                } else {
                  handleApi();
                }
              }}
            >
              Cadastrar
            </Button>
          </Stack>
        </Paper>
      </Box>{" "}
      <Snackbar
        autoHideDuration={3000}
        open={alertSize}
        onClose={() => {
          setAlertSize(false);
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="error">Tamanho máximo permitido: 300KB</Alert>
      </Snackbar>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </>
  );
}
