import {
  Autocomplete,
  Box,
  TextField,
  Typography,
  useTheme,
  Stack,
  Fade,
  LinearProgress,
} from "@mui/material";
import api from "../../../../../services/api";
import { useEffect, useState } from "react";

const useStyles = (theme) => ({
  subTitle: { width: "200px" },
  interfaceText: {
    width: "200px",
  },
  coordinates: {
    width: "98px",
  },

  legendCoordinates: {
    fontSize: "0.75rem",
    lineHeight: "1.66",
    letterSpacing: "0.03333em",
    color: "primary.main",
    marginLeft: -1.3,
    fontWeight: 600,
  },
  coordinatesBox: { display: "flex", flexDirection: "row", gap: "1rem" },
  interfaceBox: { display: "flex", flexDirection: "column", gap: "1rem" },
  hostBox: { display: "flex", flexDirection: "row", gap: "1rem" },
});
export default function Enlace(props) {
  const { index, enlaces, handleEnlaceChange, hosts, setSnackbar } = props;
  const theme = useTheme();
  const styles = useStyles(theme);

  const [interfaces, setInterfaces] = useState([]);
  const [host, setHost] = useState(null);
  const [disableInterface, setDisableInterface] = useState(false);

  async function handleApiGetKeyItems(hostId) {
    try {
      setDisableInterface(true);
      const response = await api.post("/easyReport/KeyItem/find", {
        hostId,
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar Interfaces",
          severity: "error",
        });
      } else {
        setInterfaces(response.data);
      }
    } catch (error) {
      console.log(error);

      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setDisableInterface(false);
    }
  }

  useEffect(() => {
    if (host) {
      handleApiGetKeyItems(host.id);
    }
  }, [host]);

  const getHostsLabel = (hosts) => `${hosts.nome}`;
  const getInterfacesLabel = (interfaces) => `${interfaces.nome}`;

  return (
    <Stack key={index} direction={"column"} gap={0.5}>
      <Typography variant="subtitle2" sx={{ color: "primary.main" }}>
        Enlace {index + 1}
      </Typography>
      <Box sx={styles.hostBox}>
        <Autocomplete
          name={"hostEntrada"}
          sx={styles.interfaceText}
          onChange={(event, newValue) => {
            let eventAutocomplete = {
              target: { name: "hostEntrada", value: newValue },
            };
            handleEnlaceChange(index, eventAutocomplete);
            eventAutocomplete = {
              target: { name: "interface", value: "" },
            };
            handleEnlaceChange(index, eventAutocomplete);
            setHost(newValue);
          }}
          options={hosts}
          getOptionLabel={getHostsLabel}
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                size="small"
                name="hostEntrada"
                /* value={enlaces[index].hostSaida}
                  onChange={(event) => {
                    handleEnlaceChange(index, event);
                  }}*/
                label={"Host de entrada"}
              />
            </>
          )}
        />
        <Box sx={styles.coordinatesBox}>
          <TextField
            size="small"
            name="coordenadaEntradaY"
            value={enlaces[index]?.coordenadaEntradaY || null}
            onChange={(event) => {
              handleEnlaceChange(index, event);
            }}
            placeholder={"0.00"}
            type="number"
            sx={styles.coordinates}
            helperText={
              <>
                <Typography sx={styles.legendCoordinates}>latitude</Typography>
              </>
            }
          />
          <TextField
            size="small"
            name="coordenadaEntradaX"
            value={enlaces[index]?.coordenadaEntradaX}
            onChange={(event) => {
              handleEnlaceChange(index, event);
            }}
            placeholder={"0.00"}
            type="number"
            sx={styles.coordinates}
            helperText={
              <>
                <Typography sx={styles.legendCoordinates}>longitude</Typography>
              </>
            }
          />

          <Autocomplete
            name={"interface"}
            disabled={disableInterface}
            value={enlaces[index]?.interface || null}
            sx={styles.interfaceText}
            onChange={(event, newValue) => {
              const eventAutocomplete = {
                target: { name: "interface", value: newValue },
              };
              handleEnlaceChange(index, eventAutocomplete);
            }}
            options={interfaces}
            getOptionLabel={getInterfacesLabel}
            renderInput={(params) => (
              <>
                <TextField {...params} label="Interface" size="small" />
                <Fade in={disableInterface}>
                  <LinearProgress sx={{ mt: -0.5 }} />
                </Fade>
              </>
            )}
          />
        </Box>
      </Box>
      <Box sx={styles.hostBox}>
        <Autocomplete
          name={"hostSaida"}
          sx={styles.interfaceText}
          onChange={(event, newValue) => {
            const eventAutocomplete = {
              target: { name: "hostSaida", value: newValue },
            };
            handleEnlaceChange(index, eventAutocomplete);
          }}
          options={hosts}
          getOptionLabel={getHostsLabel}
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                size="small"
                name="hostEntrada"
                /* value={enlaces[index].hostSaida}
                  onChange={(event) => {
                    handleEnlaceChange(index, event);
                  }}*/
                label={"Host de saida"}
              />
            </>
          )}
        />

        <Box sx={styles.coordinatesBox}>
          <TextField
            size="small"
            name="coordenadaSaidaY"
            value={enlaces[index]?.coordenadaSaidaY || null}
            onChange={(event) => {
              handleEnlaceChange(index, event);
            }}
            placeholder={"0.00"}
            type="number"
            sx={styles.coordinates}
            helperText={
              <>
                <Typography sx={styles.legendCoordinates}>latitude</Typography>
              </>
            }
          />
          <TextField
            size="small"
            name="coordenadaSaidaX"
            value={enlaces[index]?.coordenadaSaidaX || null}
            onChange={(event) => {
              handleEnlaceChange(index, event);
            }}
            type="number"
            placeholder={"0.00"}
            sx={styles.coordinates}
            helperText={
              <>
                <Typography sx={styles.legendCoordinates}>longitude</Typography>
              </>
            }
          />
          <TextField
            size="small"
            name="capacidade"
            value={enlaces[index]?.capacidade || null}
            onChange={(event) => {
              handleEnlaceChange(index, event);
            }}
            sx={styles.interfaceText}
            label={"Capicidade do enlaces"}
            type="number"
            helperText={
              <>
                <Typography sx={styles.legendCoordinates}>
                  Capicidade em Gb/s
                </Typography>
              </>
            }
          />
        </Box>
      </Box>
    </Stack>
  );
}
