import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { ExpandMore } from "@mui/icons-material";
import { ptBR } from "@mui/x-data-grid/locales";
const stylesTable = {
  paperContainer: {
    with: "100%",
    display: "flex",
    flexDirection: "column",
    borderRadius: 3,
  },
  dataGrid: {
    fontSize: 15,
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
      outline: "none !important",
    },
    ".MuiDataGrid-cell": { display: "flex", alignItems: "center" },
  },
  typography: {
    fontSize: "1.25rem",
    fontWeight: "500",
  },
};
export default function TabelaRelatorio(params) {
  const {
    rows,
    columns,
    title,
    loading,
    defaultExpanded = true,
    children,
    columnVisibilityModel = {
      id: false,
      conteudo: false,
      hostId: false,
    },
  } = params;
  const initialState = {
    ...rows?.initialState,
    pagination: {
      pageSize: 10,
    },
  };
  return (
    <Accordion
      square
      disableGutters
      sx={stylesTable.paperContainer}
      defaultExpanded={defaultExpanded}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="demandas-hoje-content"
        id="demandas-hoje-header"
      >
        <Typography
          variant="h6"
          component={"div"}
          gutterBottom
          sx={stylesTable.typography}
          color="primary"
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <>
          {children}
          <DataGrid
            sx={stylesTable.dataGrid}
            // getRowHeight={() => "auto"}
            autoHeight={true}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            rows={rows}
            columns={columns}
            pageSizeOptions={[10, 50, 100]}
            initialState={initialState}
            loading={loading}
            disableColumnMenu
            columnVisibilityModel={columnVisibilityModel}
            disableRowSelectionOnClick={true}
          />
        </>
      </AccordionDetails>
    </Accordion>
  );
}
