import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
} from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid";
import { useRef, useState, useEffect } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import DeselectIcon from "@mui/icons-material/Deselect";
import { Palette } from "@mui/icons-material";
const styles = {
  buttoTabelType: {
    borderRadius: "0px",
    fontSize: "15px",
    opacity: "0.3",
  },
  buttonPermissoa: {
    minWidth: "0px",
    background: "rgba(0,0,0,0.0)",
    height: "30px",

    hover: {
      background: "red",
    },
  },
  ContainerBox: {
    padding: "1vw",
    width: "60%",
    marginRight: "auto",
    marginLeft: "auto",
    minWidth: "1200px",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  boxPermissao: {
    borderRadius: "20px",
    background: "rgb(63, 32, 94)",
    textAlign: "center",
    color: "white",
    height: "27px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    color: "white",
    margin: "5px",
  },
  paper: {
    alignItems: "center",
    width: "60%",
    padding: "20px",
    minHeight: "600px",
    minWidth: "800px",
    justifyContent: "center",
  },
  CabecalhoBox: {
    width: "100%",
    alignItems: "center",
    padding: " 30px 0px",
    height: "10px",
    minWidth: "800px",
    justifyContent: "center",
  },
  StackContainer: {
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  LoadingLogo: {
    // m: 1,
    position: "relative",
    marginLeft: "45%",
    marginTop: "20%",
  },
  CircularProgress: {
    color: "primary",
    position: "absolute",
    top: -9,
    left: -6,
    zIndex: 1,
  },
  TextCampo: {
    mt: 1,
    mb: 2,
    width: "200px",
  },
  select: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
    },
    borderStyle: "none",
    borderBottom: "none",
    marginTop: "-5px",
    marginBottom: "-5px",
  },
};
const permissoes = [
  { name: "superAdmin", value: "adm001" },
  { name: "admin", value: "adm002" },
  { name: "usuario", value: "user001" },
];

export default function SelectPermissoes(props) {
  const apiRef = useGridApiContext();
  const { params, role, handlePutPermissoes } = props;

  const [selectedPermissoes, setSelectedPermissoes] = useState(params.value);
  const dataUser = JSON.parse(localStorage.getItem("perfil"));
  /*const handleSelectAll = () => {
        let select = permissoes;
        if (!admin) {
            if (!selectedPermissoes.includes('superAdmin')) select = select.filter((value) => value !== 'superAdmin')
            if (!selectedPermissoes.includes('admin')) select = select.filter((value) => value !== 'admin')
        }
        setSelectedPermissoes(select);
    };
    const handleClearAll = () => {
        let select = [];
        if (!admin) {
            if (selectedPermissoes.includes('superAdmin')) select.push('superAdmin')
            if (selectedPermissoes.includes('admin')) select.push('admin')
        }
        setSelectedPermissoes(select);
    };*/
  const handlePermissoesChange = (e) => {
    setSelectedPermissoes([e.target.value]);
    apiRef.current.stopCellEditMode({
      id: params.row.id,
      field: "codigoPermissao",
    });
  };
  useEffect(() => {
    apiRef.current.setEditCellValue({
      id: params.row.id,
      field: "codigoPermissao",
      value: selectedPermissoes,
    });
  }, [selectedPermissoes]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
      }}
    >
      <FormControl variant="filled" sx={styles.select}>
        <Select
          value={selectedPermissoes}
          onChange={handlePermissoesChange}
          disabled={params.id == dataUser.usuarioClienteId}
          input={<OutlinedInput />}
          sx={{ background: "rgba(0,0,0,0.1)" }}
          renderValue={(value) => {
            const name = permissoes.find(
              (perm) => perm.value === value[0]
            ).name;
            return (
              <Stack
                gap={1}
                direction="row"
                flexWrap="wrap"
                sx={{ padding: "0px", WebkitTextFillColor: "white" }}
              >
                <Chip
                  sx={{
                    padding: "0px",
                    backgroundColor: "chip.waitingReturn",
                    color: "white!important",
                  }}
                  key={value}
                  label={name}
                />
              </Stack>
            );
          }}
        >
          {permissoes.map((perm) => (
            <MenuItem
              sx={{ fontFamily: "Rubik" }}
              disabled={
                ((perm.name === "superAdmin" || perm.name === "admin") &&
                  role == "user001") ||
                (perm.name === "superAdmin" && role == "adm002")
              }
              key={perm.name}
              value={perm.value}
            >
              <Checkbox
                color="secondary"
                checked={selectedPermissoes.includes(perm.value)}
                sx={{ marginLeft: "-10px" }}
              />
              {perm.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
