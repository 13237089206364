
import { Typography } from '@mui/material';
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';

const Interval = forwardRef(({ onTimeout }, ref) => {
    const [seconds, setSeconds] = useState(60);
    const [intervalId, setIntervalId] = useState(null);
    useEffect(() => {
        if (seconds === 0) onTimeout()
    }, [seconds]);

    useEffect(() => {
        start()
    }, []);

    function start() {
        setSeconds(60)
        if (intervalId) {
            clearInterval(intervalId);
        }
        const interval = setInterval(() => {
            setSeconds(prevSeconds => {
                if (prevSeconds > 0) {
                    return prevSeconds - 1;
                } else {
                    clearInterval(interval);
                    return 0;
                }
            });
        }, 1000);
        setIntervalId(interval);
    }
    useImperativeHandle(ref, () => ({
        startCounter: () => {
            start();
        }
    }));
    if (seconds !== 0) {
        return (
            <Typography> Para enviar novamente aguarde {seconds} segundo(s)</Typography>
        )
    }
    return ("")
});

export default Interval;