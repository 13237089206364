import { Autocomplete, TextField } from "@mui/material";
import { memo, useEffect } from "react";

export const AutoCompleteMemo = memo(
  ({
    value = null,
    options = [],
    onChange = () => {},
    disabled = false,
    label = "",
    styles,
    clear = false,
  }) => {
    return (
      <Autocomplete
        value={value}
        key={label}
        freeSolo
        autoSelect
        //onClose={onClose}
        onChange={onChange}
        onInputChange={onChange}
        disabled={disabled}
        sx={{
          width: "100%",
          fontSize: 13,
          height: "100%",
        }}
        options={options}
        getOptionLabel={(option) => option || ""}
        renderInput={(params) => (
          <TextField
            key={label + "autoT"}
            sx={styles}
            label={label}
            autoComplete="off"
            {...params}
            variant="standard"
          />
        )}
      />
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.value?.toLowerCase() === nextProps.value?.toLowerCase() &&
      prevProps.options === nextProps.options
    );
  }
);
