import React, { useEffect, useState } from "react";
import {
  Box,
  styled,
  Paper,
  Switch,
  FormControlLabel,
  Stack,
  Typography,
  Button,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  Modal,
  Avatar,
  Checkbox,
  Divider,
  OutlinedInput,
  MenuItem,
  Chip,
  Icon,
  FormHelperText,
  FormControl,
  InputLabel,
  CircularProgress,
  IconButton,
  Select,
  InputAdornment,
} from "@mui/material";

import "dayjs/locale/pt-br";
import api from "../../services/api";
import mwIcon from "../../assets/logo4.png";

import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import SelectPermissoes from "../../componentes/SelectPermissoes";
import SearchIcon from "@mui/icons-material/Search";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import BusinessIcon from "@mui/icons-material/Business";
import PersonIcon from "@mui/icons-material/Person";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import EditeCell from "../../componentes/editCell";
import AddIcon from "@mui/icons-material/Add";

const styles = {
  buttoTabelType: {
    borderRadius: "0px",
    fontSize: "15px",
    opacity: "0.3",
  },
  buttonPermissoa: {
    minWidth: "0px",
    background: "rgba(0,0,0,0.2)",
    height: "30px",
    marginTop: "5px",

    hover: {
      background: "red",
    },
  },

  ContainerBox: {
    padding: "1vw",
    width: "90%",
    marginRight: "auto",
    marginLeft: "auto",
    minWidth: "1200px",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  boxPermissao: {
    borderRadius: "20px",
    background: "rgb(63, 32, 94)",
    textAlign: "center",
    color: "white",
    height: "27px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    color: "white",
    margin: "5px",
  },
  paper: {
    alignItems: "center",
    width: "60%",
    padding: "20px",
    minHeight: "600px",
    minWidth: "800px",
    justifyContent: "center",
  },
  CabecalhoBox: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "10px",
  },
  StackContainer: {
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  LoadingLogo: {
    // m: 1,
    position: "relative",
    marginLeft: "45%",
    marginTop: "20%",
  },
  CircularProgress: {
    color: "primary",
    position: "absolute",
    top: -9,
    left: -6,
    zIndex: 1,
  },
  TextCampo: {
    mt: 1,
    mb: 2,
    width: "200px",
  },
};

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-switchBase": {
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(20px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.chip.completed,
        opacity: 1,
        border: 0,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },

  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.chip.completed)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));
const permissoes = [
  { name: "superAdmin", value: "adm001" },
  { name: "admin", value: "adm002" },
  { name: "usuario", value: "user001" },
];
export default function Permissoes() {
  const [snackbar, setSnackbar] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  //const [admin, setAdmin] = useState(false);
  const [role, setRole] = useState("");
  const [selectedPermissoes, setSelectedPermissoes] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectTableShow, setSelectTableShow] = useState("Pessoa Física");
  const [rows, setRows] = useState([]);
  const [openNew, setOpenNew] = React.useState(false);
  const [Null, setNull] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [senhaValid, setSenhaValid] = useState(false);
  const [cadastroLogin, setCadastroLogin] = React.useState("");
  const [cadastroSenha, setCadastroSenha] = React.useState("");
  const [cadastroEmail, setCadastroEmail] = React.useState("");
  const [promiseArguments, setPromiseArguments] = React.useState(null);
  const apiRef = React.useRef(null);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showDialogStatus, setshowDialogStatus] = React.useState({
    open: false,
    status: "",
    id: "",
  });
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleNewOpen = () => {
    setOpenNew(true);
    setSelectedPermissoes(["user001"]);
  };
  const handleNewClose = () => {
    setOpenNew(false);
    setCadastroEmail("");
    setCadastroLogin("");
    setCadastroSenha("");
    setSelectedPermissoes([]);
    setNull(false);
    setShowPassword(false);
  };

  const handleEditMode = (id) => {
    apiRef.current.startRowEditMode({ id: id });
  };

  const handlePermissoesChange = (e) => {
    setSelectedPermissoes([e.target.value]);
  };
  const handleChange = (event) => {
    setSelectTableShow(event.target.value);
  };
  const handleSearchInputChange = (event) => {
    setSearchText(event.target.value);
  };
  const filteredRowsF = rows.filter(
    (row) =>
      (row?.PerfilCliente?.nome
        ?.toLowerCase()
        .startsWith(searchText.toLowerCase()) ||
        row?.PerfilCliente?.sobrenome
          ?.toLowerCase()
          .startsWith(searchText.toLowerCase()) ||
        row?.email.toLowerCase().startsWith(searchText.toLowerCase()) ||
        row?.PerfilCliente?.cpf
          ?.toLowerCase()
          .startsWith(searchText.toLowerCase())) &&
      row?.PerfilCliente?.pessoaFisica === true
  );
  const filteredRowsJ = rows.filter(
    (row) =>
      (row?.PerfilCliente?.nomeFantasia
        ?.toLowerCase()
        .startsWith(searchText.toLowerCase()) ||
        row?.email?.toLowerCase().startsWith(searchText.toLowerCase()) ||
        row?.PerfilCliente?.cnpj
          ?.toLowerCase()
          .startsWith(searchText.toLowerCase())) &&
      row?.PerfilCliente?.pessoaFisica === false
  );

  const filteredRowsSemPerfil = rows.filter((row) =>
    row?.email?.toLowerCase().startsWith(searchText.toLowerCase()) &&
    row?.PerfilCliente
      ? false
      : true
  );

  const selectPermissoesInputCell = (params) => {
    return (
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100%", width: "100%" }}
      >
        <SelectPermissoes
          role={role}
          params={params}
          handlePutPermissoes={handleApiPutPermissoes}
        />
      </Stack>
    );
  };
  const editCellView = (params) => {
    return <EditeCell mode="view" params={params} />;
  };
  const editCellEdit = (params) => {
    return <EditeCell mode="edit" params={params} />;
  };

  const openDialogStatus = () => {
    const status = showDialogStatus.status;
    function handleAccept() {
      setshowDialogStatus({ open: false, status: "", id: "" });
      handleApiPutstatus(showDialogStatus.id, showDialogStatus.status);
    }
    function handleNoAccept() {
      setshowDialogStatus({ open: false, status: "", id: "" });
    }

    return (
      <Dialog
        maxWidth="xs"
        open={showDialogStatus.open}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <DialogTitle variant="h6">Atenção</DialogTitle>
        <DialogContent>
          {`Ao clicar em sim o status da conta mudará para ${
            showDialogStatus.status ? "Ativado" : "Desativado"
          }, deseja realizar esta alteração?`}
        </DialogContent>

        <DialogActions>
          <Button
            color="secondary"
            variant="text"
            ref={noButtonRef}
            onClick={handleNoAccept}
          >
            Não
          </Button>
          <Button background="primary" variant="text" onClick={handleAccept}>
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const navigate = useNavigate();

  useEffect(() => {
    async function permissionValidate() {
      try {
        const response = await api.get("/authPermissionClient");

        if (response.data.message !== "Permission is valid") {
          navigate("/homeIn");
        }
      } catch (error) {
        console.error(error);
      }
    }
    permissionValidate();
  }, []);

  useEffect(() => {
    if (cadastroEmail.includes("@") && cadastroEmail.includes("."))
      setEmailValid(true);
    else setEmailValid(false);

    if (cadastroSenha.length >= 6 && /[a-z]/.test(cadastroSenha))
      setSenhaValid(true);
    else setSenhaValid(false);
  }, [cadastroLogin, cadastroSenha, cadastroEmail]);

  useEffect(() => {
    setRole(
      JSON.parse(localStorage.getItem("1")).usuarioCliente.codigoPermissao
    );

    async function apiget(params) {
      try {
        const responsePerfis = await api.get("/perfil/findMany");
        let perfilCliente = [];
        responsePerfis.data.map((perfil) => {
          perfil["codigoPermissao"] = [perfil.codigoPermissao];
          perfilCliente.push(perfil);
        });
        setRows(perfilCliente);
        setLoadingPage(true);
      } catch (error) {
        console.log(error);
        setSnackbar({
          children: "Error: Não foi possível se conectar ao servidor",
          severity: "error",
        });
      } finally {
        setLoadingPage(true);
      }
    }
    apiget();
  }, []);

  const colunasSemPerfil = [
    {
      sortable: false,
      field: "foto",
      headerName: "",
      width: 60,
      renderCell: (params) => {
        const foto = params?.row?.PerfilCliente
          ? params?.row?.PerfilCliente.foto
          : "";
        return (
          <Avatar
            alt={params?.row?.email}
            src={foto ? foto : "/"}
            sx={{ width: "30px", height: "30px" }}
          />
        );
      },
    },
    {
      width: 150,
      field: "login",
      headerName: "Login",
      valueGetter: (value, row) => row?.login || "",
    },
    { field: "email", headerName: "Email", flex: 1 },
    {
      headerAlign: "center",
      field: "status",
      headerName: "Status",
      width: 80,
      renderCell: (params) => (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ width: "100%", height: "100%" }}
        >
          <FormControlLabel
            sx={{ margin: "auto" }}
            control={
              <Android12Switch
                disabled={
                  params?.id ==
                  JSON.parse(localStorage.getItem("perfil")).usuarioClienteId
                }
                checked={params?.value}
                onClick={() => {
                  setshowDialogStatus({
                    open: true,
                    status: !params?.value,
                    id: params?.id,
                  });
                }}
              />
            }
          />
        </Stack>
      ),
    },
    {
      headerAlign: "center",
      field: "verificado",
      headerName: "Verificado",
      width: 100,
      renderCell: (params) => (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ width: "100%", height: "100%" }}
        >
          {params?.value ? (
            <Chip
              label="Sim"
              color="success"
              variant="contained"
              sx={{ width: "100%" }}
            />
          ) : (
            <Chip
              label="Não"
              color="secondary"
              variant="contained"
              sx={{ width: "100%" }}
            />
          )}
        </Stack>
      ),
    },
    {
      headerAlign: "center",
      sortable: false,
      editable: true,
      field: "codigoPermissao",
      headerName: "Permisssão",
      width: 150,
      renderCell: (params) => {
        return (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              width: "100%",
              height: "100%",
            }}
          >
            <Chip
              key={params?.value}
              label={
                permissoes.find((perm) => perm.value === params?.value[0])?.name
              }
              sx={{
                width: "100%",
                color: "white!important",
                backgroundColor: "chip.waitingReturn",
              }}
              variant="contained"
            />
          </Stack>
        );
      },
      renderEditCell: selectPermissoesInputCell,
    },
    {
      headerAlign: "center",
      sortable: false,
      editable: true,
      field: "a",
      headerName: "Ação",
      width: 60,
      renderCell: editCellView,
      renderEditCell: editCellEdit,
    },
  ];
  const colunasPessoaJuridica = [
    {
      sortable: false,
      field: "foto",
      headerName: "",
      width: 60,
      renderCell: (params) => {
        const foto = params?.row?.PerfilCliente
          ? params?.row?.PerfilCliente.foto
          : "";
        return (
          <Avatar
            alt={
              params?.row?.PerfilCliente
                ? params?.row?.PerfilCliente.nomeFantasia
                : ""
            }
            src={foto ? foto : "/"}
          />
        );
      },
    },
    {
      width: 150,
      field: "login",
      headerName: "Login",
      valueGetter: (value, row) => (row?.PerfilCliente ? row?.login : ""),
    },
    {
      flex: 1,
      field: "nomeFantasia",
      headerName: "Nome fantasia",
      valueGetter: (value, row) =>
        row?.PerfilCliente ? row?.PerfilCliente.nomeFantasia : "",
    },
    { flex: 1, field: "email", headerName: "Email" },
    {
      width: 130,
      field: "telefone",
      headerName: "Telefone",
      valueGetter: (value, row) =>
        row?.PerfilCliente ? row?.PerfilCliente.telefone : "",
    },
    {
      width: 170,
      field: "cnpj",
      headerName: "Cnpj",
      valueGetter: (value, row) =>
        row?.PerfilCliente ? row?.PerfilCliente.cnpj : "",
    },
    {
      headerAlign: "center",
      field: "status",
      headerName: "Status",
      width: 80,
      renderCell: (params) => (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ width: "100%", height: "100%" }}
        >
          <FormControlLabel
            sx={{ margin: "auto" }}
            control={
              <Android12Switch
                disabled={
                  params?.id ==
                  JSON.parse(localStorage.getItem("perfil")).usuarioClienteId
                }
                checked={params?.value}
                onClick={() => {
                  setshowDialogStatus({
                    open: true,
                    status: !params?.value,
                    id: params?.id,
                  });
                }}
              />
            }
          />
        </Stack>
      ),
    },
    {
      headerAlign: "center",
      field: "verificado",
      headerName: "Verificado",
      width: 100,
      renderCell: (params) => (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ width: "100%", height: "100%" }}
        >
          {params?.value ? (
            <Chip
              label="Sim"
              color="success"
              variant="contained"
              sx={{ width: "100%" }}
            />
          ) : (
            <Chip
              label="Não"
              color="secondary"
              variant="contained"
              sx={{ width: "100%" }}
            />
          )}
        </Stack>
      ),
    },
    {
      headerAlign: "center",
      sortable: false,
      editable: true,
      field: "codigoPermissao",
      headerName: "Permisssão",
      width: 150,

      renderCell: (params) => {
        return (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              width: "100%",
              height: "100%",
            }}
          >
            <Chip
              key={params?.value}
              label={
                permissoes.find((perm) => perm.value === params?.value[0])?.name
              }
              sx={{
                width: "100%",

                color: "white!important",
                backgroundColor: "chip.waitingReturn",
              }}
              variant="contained"
            />
          </Stack>
        );
      },
      renderEditCell: selectPermissoesInputCell,
    },
    {
      headerAlign: "center",
      sortable: false,
      editable: true,
      field: "a",
      headerName: "Ação",
      width: 60,
      renderCell: editCellView,
      renderEditCell: editCellEdit,
    },
  ];
  const colunasPessoaFisica = [
    {
      sortable: false,
      field: "foto",
      headerName: "",
      width: 60,
      renderCell: (params) => {
        const foto = params?.row?.PerfilCliente
          ? params?.row?.PerfilCliente.foto
          : "";
        return (
          <Avatar
            alt={
              params?.row?.PerfilCliente ? params?.row?.PerfilCliente.nome : ""
            }
            src={foto ? foto : "/"}
            sx={{ width: "30px", height: "30px" }}
          />
        );
      },
    },
    {
      width: 150,
      field: "login",
      headerName: "Login",
      valueGetter: (value, row) => (row?.PerfilCliente ? row?.login : ""),
    },
    {
      flex: 1,
      field: "nome",
      headerName: "Nome",
      valueGetter: (value, row) =>
        row?.PerfilCliente ? row?.PerfilCliente.nome : "",
    },
    {
      flex: 1,
      field: "sobrenome",
      headerName: "Sobrenome",
      valueGetter: (value, row) =>
        row?.PerfilCliente ? row?.PerfilCliente.sobrenome : "",
    },
    { flex: 1, field: "email", headerName: "Email" },
    {
      width: 130,
      field: "telefone",
      headerName: "Telefone",
      valueGetter: (value, row) =>
        row?.PerfilCliente ? row?.PerfilCliente.telefone : "",
    },
    {
      width: 120,
      field: "cpf",
      headerName: "Cpf",
      valueGetter: (value, row) =>
        row?.PerfilCliente ? row?.PerfilCliente.cpf : "",
    },
    {
      headerAlign: "center",
      field: "status",
      headerName: "Status",
      width: 80,
      renderCell: (params) => (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ width: "100%", height: "100%" }}
        >
          <FormControlLabel
            sx={{ margin: "auto" }}
            control={
              <Android12Switch
                disabled={
                  params?.id ==
                  JSON.parse(localStorage.getItem("perfil")).usuarioClienteId
                }
                checked={params?.value}
                onClick={() => {
                  setshowDialogStatus({
                    open: true,
                    status: !params?.value,
                    id: params?.id,
                  });
                }}
              />
            }
          />
        </Stack>
      ),
    },
    {
      headerAlign: "center",
      field: "verificado",
      headerName: "Verificado",
      width: 100,
      renderCell: (params) => (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ width: "100%", height: "100%" }}
        >
          {params?.value ? (
            <Chip
              label="Sim"
              color="success"
              variant="contained"
              sx={{ width: "100%" }}
            />
          ) : (
            <Chip
              label="Não"
              color="secondary"
              variant="contained"
              sx={{ width: "100%" }}
            />
          )}
        </Stack>
      ),
    },
    {
      headerAlign: "center",
      sortable: false,
      editable: true,
      field: "codigoPermissao",
      headerName: "Permisssão",
      width: 150,

      renderCell: (params) => {
        return (
          <Stack
            direction="row"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
              height: "100%",
            }}
            spacing={1}
          >
            <Chip
              key={params?.value}
              label={
                permissoes.find((perm) => perm.value === params?.value[0])?.name
              }
              sx={{
                width: "100%",
                marginBottom: "5px",
                color: "white!important",
                backgroundColor: "chip.waitingReturn",
              }}
              variant="contained"
            />
          </Stack>
        );
      },
      renderEditCell: selectPermissoesInputCell,
    },
    {
      headerAlign: "center",
      sortable: false,
      editable: true,
      field: "a",
      headerName: "Ação",
      width: 60,
      renderCell: editCellView,
      renderEditCell: editCellEdit,
    },
  ];

  async function handleApiNovoUsuario() {
    setNull(true);
    if (
      !senhaValid ||
      !emailValid ||
      !cadastroEmail ||
      !cadastroSenha ||
      !cadastroLogin
    )
      return;
    try {
      const response = await api.post("/usuario/create", {
        login: cadastroLogin,
        senha: cadastroSenha,
        email: cadastroEmail,
        logsLoginClienteId: JSON.parse(localStorage.getItem("logs")).id,
        codigoPermissao: selectedPermissoes.join(","),
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        response.data["codigoPermissao"] = [response.data.codigoPermissao];
        setRows([...rows, response.data]);

        handleNewClose();
        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });
      }
    } catch (error) {
      console.log(error);
      setSnackbar({
        children: error?.request?.response
          ? `${JSON.parse(error.request.response).error}`
          : "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    }
  }

  async function handleApiPutPermissoes(id, permissoes) {
    try {
      const response = await api.put("/usuario/permissoes/update", {
        id: id,
        permissoes: permissoes,
        logsLoginClienteId: JSON.parse(localStorage.getItem("logs")).id,
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        const updateRows = rows;
        updateRows.map((row) => {
          if (row?.id == response.data.id) {
            const CodigoPerm = response.data.codigoPermissao;
            if (CodigoPerm) row.codigoPermissao = CodigoPerm.split(",");
            else row.codigoPermissao = [];
          }
        });
        setRows(updateRows);

        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function handleApiPutstatus(id, status) {
    try {
      const response = await api.put("/usuario/status/update", {
        id: id,
        status: status,
        logsLoginClienteId: JSON.parse(localStorage.getItem("logs")).id,
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        const updateRows = rows;
        updateRows.map((row) => {
          if (row?.id === id) {
            row.status = status;
          }
        });
        setRows(updateRows);

        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  {
    /*------------------------- Modal de confirmação-----------------------------------*/
  }

  function computeMutation(newRow, oldRow) {
    if (newRow?.codigoPermissao !== oldRow?.codigoPermissao) {
      return true;
    }
    return false;
  }
  const processRowUpdate = React.useCallback(
    (newRow, oldRow) =>
      new Promise((resolve, reject) => {
        if (computeMutation(newRow, oldRow)) {
          // Save the arguments to resolve or reject the promise later
          setPromiseArguments({ resolve, reject, newRow, oldRow });
        } else {
          resolve(oldRow); // Nothing was changed
        }
      }),
    []
  );

  const useFakeMutation = () => {
    return React.useCallback(
      (user) =>
        new Promise((resolve, reject) =>
          setTimeout(() => {
            if (user.name?.trim() === "") {
              reject();
            } else {
              resolve(user);
            }
          }, 200)
        ),
      []
    );
  };

  const mutateRow = useFakeMutation();
  const noButtonRef = React.useRef(null);

  const handleNo = () => {
    const { oldRow, resolve } = promiseArguments;
    resolve(oldRow); // Resolve with the old row to not update the internal state
    setPromiseArguments(null);
  };
  const handleYes = async () => {
    const { newRow, oldRow, reject, resolve } = promiseArguments;
    //console.log(newRow);
    try {
      handleApiPutPermissoes(newRow?.id, newRow?.codigoPermissao);
      const response = await mutateRow(newRow);
      resolve(response);
      setPromiseArguments(null);
    } catch (error) {
      reject(oldRow);
      setPromiseArguments(null);
    }
  };

  const handleEntered = () => {};

  const renderConfirmDialog = () => {
    if (!promiseArguments) {
      return null;
    }

    return (
      <Dialog
        maxWidth="xs"
        TransitionProps={{ onEntered: handleEntered }}
        open={!!promiseArguments}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: "1.25em",
            width: "100%",
            textAlign: "center",
            padding: "5px",
          }}
        >
          Atenção
        </Typography>
        <Divider></Divider>
        <Typography sx={{ fontFamily: "Rubik", padding: "20px" }}>
          Ao clicar em <i color="secondary">sim</i> a modificação será salva no
          sistema, tem ceteza que quer realizá-la?
        </Typography>

        <DialogActions>
          <Button
            color="secondary"
            variant="text"
            ref={noButtonRef}
            onClick={handleNo}
          >
            Não
          </Button>
          <Button background="primary" variant="text" onClick={handleYes}>
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      {!loadingPage && (
        <Box sx={styles.LoadingLogo}>
          <Icon style={{ width: 80, height: 80 }}>
            <img src={mwIcon} style={{ width: 80, height: 80 }} alt="mw icon" />
          </Icon>

          <CircularProgress
            size={100}
            sx={styles.CircularProgress}
            thickness={2.5}
          />
        </Box>
      )}
      {loadingPage && (
        <Box sx={styles.ContainerBox}>
          <Box sx={styles.CabecalhoBox}>
            <Typography width="100%" variant="h5">
              Painel de Administração
            </Typography>
          </Box>

          <Paper
            elevation={3}
            sx={{
              padding: "10px",
              width: "100%",
              flexDirection: "column",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "100%",
                marginBottom: "10px",
                justifyContent: "space-between",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl size="small">
                <InputLabel htmlFor="outlined-adornment-amount">
                  Busca
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-amount"
                  value={searchText}
                  onChange={handleSearchInputChange}
                  endAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                  label="Busca"
                />
              </FormControl>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FormControl
                  sx={{
                    m: 1,
                    minWidth: 120,
                    marginRight: "25px",
                    display: "flex",
                  }}
                  size="small"
                >
                  <InputLabel id="demo-select-small-label">
                    Tipo de usuário
                  </InputLabel>
                  <Select
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        display: "flex!important",
                      },
                    }}
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={selectTableShow}
                    label="Tipo de usuário"
                    onChange={handleChange}
                  >
                    <MenuItem
                      sx={{ display: "flex", alignItems: "center" }}
                      value="Pessoa Física"
                    >
                      <PersonIcon sx={{ marginRight: "5px" }} />
                      Pessoa Física
                    </MenuItem>
                    <MenuItem
                      sx={{ display: "flex", alignItems: "center" }}
                      value="Pessoa Jurídica"
                    >
                      <BusinessIcon sx={{ marginRight: "5px" }} />
                      Pessoa Jurídica
                    </MenuItem>
                    <MenuItem
                      sx={{ display: "flex", alignItems: "center" }}
                      value="Sem Perfil"
                    >
                      <LiveHelpIcon sx={{ marginRight: "5px" }} />
                      Sem Perfil
                    </MenuItem>
                  </Select>
                </FormControl>
                <Button
                  endIcon={<AddIcon sx={{ marginTop: "-3px" }} />}
                  onClick={handleNewOpen}
                  sx={{ fontSize: "13px", marginRight: "10px" }}
                  variant="contained"
                >
                  Novo Usuário
                </Button>
              </Box>
            </Box>

            <Box sx={{ width: "100%" }}>
              {renderConfirmDialog()}
              <DataGrid
                editMode="row"
                pageSizeOptions={[25, 50, 100]}
                apiRef={apiRef}
                rows={
                  selectTableShow === "Pessoa Física"
                    ? filteredRowsF
                    : selectTableShow === "Pessoa Jurídica"
                    ? filteredRowsJ
                    : filteredRowsSemPerfil
                }
                columns={
                  selectTableShow === "Pessoa Física"
                    ? colunasPessoaFisica
                    : selectTableShow === "Pessoa Jurídica"
                    ? colunasPessoaJuridica
                    : colunasSemPerfil
                }
                initialState={{
                  ...rows.initialState,
                  pagination: {
                    pageSize: 25,
                  },
                }}
                loading={!loadingPage}
                disableColumnMenu
                disableColumnFilter
                disableSelectionOnClick
                processRowUpdate={processRowUpdate}
                sx={{
                  "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                    py: "5px",
                  },
                  "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                    py: "5px",
                  },
                  "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                    py: "22px",
                  },
                  fontSize: 15,
                  "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                    outline: "none !important",
                  },
                  ".MuiDataGrid-cell": {
                    display: "flex",
                    alignItems: "center",
                  },
                }}
              />
            </Box>
          </Paper>
        </Box>
      )}

      <Modal
        open={openNew}
        onClose={handleNewClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper
          sx={{
            width: "450px",
            margin: "auto",
            marginTop: "15vh",
            display: "flex",
            flexDirection: "column",
            borderRadius: "8px",
            padding: "10px",
          }}
        >
          <DialogTitle color="primary.main">Cadastro de Usuários</DialogTitle>
          <DialogContent>
            <FormControl sx={{ width: "100%", mt: 0.5 }} variant="outlined">
              <InputLabel>Login</InputLabel>
              <OutlinedInput
                error={!cadastroLogin && Null}
                id="outlined-adornment-login"
                type="text"
                value={cadastroLogin}
                onChange={(e) => setCadastroLogin(e.target.value)}
                label="Login"
              />
              {!cadastroLogin && Null && (
                <FormHelperText error id="accountId-error">
                  Login Inválido.
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              sx={{ width: "100%", marginTop: "12px" }}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-senha">Senha</InputLabel>
              <OutlinedInput
                error={(!cadastroSenha || !senhaValid) && Null}
                id="outlined-adornment-senha"
                type={showPassword ? "text" : "password"}
                value={cadastroSenha}
                onChange={(e) => setCadastroSenha(e.target.value)}
                label="Senha"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {(!cadastroSenha || !senhaValid) && Null && (
                <FormHelperText error id="accountId-error">
                  A senha deve ter no mínimo 6 dígitos e uma letra maiúscula.
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              sx={{ width: "100%", marginTop: "12px" }}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-email">Email</InputLabel>
              <OutlinedInput
                error={(!cadastroSenha || !emailValid) && Null}
                id="outlined-adornment-email"
                type="email"
                value={cadastroEmail}
                onChange={(e) => setCadastroEmail(e.target.value)}
                label="Email"
              />
              {(!cadastroSenha || !emailValid) && Null && (
                <FormHelperText error id="accountId-error">
                  Formato de email Inválido.
                </FormHelperText>
              )}
            </FormControl>

            <FormControl sx={{ width: "100%", marginTop: "15px" }}>
              <InputLabel id="demo-multiple-chip-label2">
                Nível de acesso
              </InputLabel>
              <Select
                labelId="demo-multiple-chip-label2"
                id="demo-multiple-chip2"
                value={selectedPermissoes}
                onChange={handlePermissoesChange}
                input={
                  <OutlinedInput
                    id="select-multiple-chip2"
                    label="Nível de acesso"
                  />
                }
                renderValue={(value) => {
                  const name = permissoes.find(
                    (perm) => perm.value === value[0]
                  ).name;

                  return (
                    <Stack
                      gap={1}
                      direction="row"
                      flexWrap="wrap"
                      sx={{ padding: "0px", width: "100%", height: "100%" }}
                    >
                      <Chip
                        sx={{
                          padding: "0px",
                          backgroundColor: "chip.waitingReturn",
                          color: "white!important",
                        }}
                        key={value}
                        label={name}
                      />
                    </Stack>
                  );
                }}
              >
                {permissoes.map((perm) => (
                  <MenuItem
                    sx={{ fontFamily: "Rubik" }}
                    disabled={
                      ((perm.name === "superAdmin" || perm.name === "admin") &&
                        role == "user001") ||
                      (perm.name === "superAdmin" && role == "adm002")
                    }
                    key={perm.name}
                    value={perm.value}
                  >
                    <Checkbox
                      color="secondary"
                      checked={selectedPermissoes.includes(perm.name)}
                      sx={{ marginLeft: "-10px" }}
                    />
                    {perm.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleNewClose}
              sx={{ color: "secondary.main" }}
              variant="text"
            >
              {" "}
              Cancelar
            </Button>
            <Button onClick={handleApiNovoUsuario} variant="text">
              {" "}
              Cadastrar
            </Button>
          </DialogActions>
        </Paper>
      </Modal>

      {openDialogStatus()}
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2500}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </>
  );
}
