import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  Grid,
  Stack,
  Typography,
} from "@mui/material";

import imgGerar from "../../../assets/easyReport/imgGerar.png";
import imgAgendar from "../../../assets/easyReport/imgAgendar.png";
import { useLocation, useNavigate } from "react-router-dom";
import AlertModal from "../../../componentes/alertModal";
import { useEffect, useState } from "react";

export default function SelectionPage(params) {
  const [alertmodal, setAlertModal] = useState(null);
  const [openNovoAgendamentoModal, setOpenNovoAgendamentoModal] =
    useState(false);

  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    if (location.state?.successModal === true) {
      navigate(".", { state: { successModal: false } });
      setOpenNovoAgendamentoModal(true);
      setAlertModal({
        severity: "success",
        textContent: "Relatório gerado com sucesso.",
        singleButton: true,
      });
    }
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{ fontSize: "24px", fontWeight: "700", paddingY: "47px" }}
      >
        Selecione uma das opções
      </Typography>
      <Grid container spacing={10} justifyContent={"center"}>
        <Grid item>
          <Card sx={{ height: "442px", borderRadius: "50px" }} elevation={3}>
            <CardActionArea
              onClick={() => {
                navigate("/easyReport/gerar");
              }}
            >
              <Stack alignItems={"center"} paddingY={"20px"} gap={"7px"}>
                <Typography
                  color={"primary"}
                  sx={{ fontSize: "24px", fontWeight: 700 }}
                >
                  GERAR RELATÓRIOS
                </Typography>
                <CardMedia
                  component={"img"}
                  alt="icon"
                  image={imgGerar}
                  sx={{ height: "382px" }}
                />
              </Stack>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item>
          <Card
            sx={{
              height: "442px",
              borderRadius: "50px",
            }}
            elevation={3}
          >
            <CardActionArea
              onClick={() => {
                navigate("/easyReport/agendar");
              }}
            >
              <Stack alignItems={"center"} paddingY={"20px"}>
                <Typography
                  color={"primary"}
                  sx={{
                    fontSize: "24px",
                    fontWeight: 700,
                  }}
                >
                  AGENDAR RELATÓRIOS
                </Typography>
                <CardMedia
                  component={"img"}
                  alt="icon"
                  image={imgAgendar}
                  sx={{ height: "413px", mt: "-26px" }}
                />
              </Stack>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
      <AlertModal
        openModal={openNovoAgendamentoModal}
        setOpenModal={setOpenNovoAgendamentoModal}
        handleButton={() => {
          setOpenNovoAgendamentoModal(false);
        }}
        {...alertmodal}
      />
    </Box>
  );
}
