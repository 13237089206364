import {
  Box,
  Paper,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Stack,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  FormGroup,
  Checkbox,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import api from "../../../../services/api";
import ConfigureRelatorio from "./configureRelatorio";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import ConfigureOsItens from "./configureOsItens";
import Agendamento from "./agendamento";
import { useNavigate } from "react-router-dom";
import ConfigureRelatorioFlow from "./configureRelatorioFlow";
import SnackBar from "../../../../componentes/snackbar";
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank,
} from "@mui/icons-material";

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function NovoRelatorio() {
  const [activeStep, setActiveStep] = useState(0);
  const [hosts, setHosts] = useState([]);
  const [clientes, setClientes] = useState([]);
  const initialHost = {
    id: null,
    nome: "",
    itens: [],
  };
  const initialClientes = {
    id: 0,
    client: "",
    types: [],
  };
  const [titulo, setTitulo] = useState("");
  const [produto, setProduto] = useState("");
  const [tipoProduto, setTipoProduto] = useState("");
  const [produtos, setProdutos] = useState([]);
  const [selectedHosts, setSelectedHosts] = useState([initialHost]);
  const [selectedClientes, setSelectedClientes] = useState([initialClientes]);
  const [loadingHosts, setLoadingHosts] = useState(false);
  const [snackbar, setSnackbar] = useState(null);
  const [listTags, setListTags] = useState([]);
  const [listItens, setListItens] = useState([]);
  const [loadingClientes, setLoadingClientes] = useState(false);
  const [disableClientes, setDisableClientes] = useState(false);
  const [fna, setFna] = useState("");
  const [ggc, setGgc] = useState("");
  const [ixPpt, setIxPpt] = useState("");
  const [transIp, setTransIp] = useState("");
  const [ocaNetflix, setOcaNetflix] = useState("");
  const [tarifacaoExcedente, setTarifacaoExcedente] = useState({
    status: false,
    valor: "",
    valorExcedente: "",
    limite: "",
  });
  const [ocultarGraficos, setOcultarGraficos] = useState(false);
  const [agruparPorNome, setAgruparPorNome] = useState({
    status: false,
    cliente: "",
  });
  const [filterStatusFlow, setFilterStatusFlow] = useState(false);
  const [interfacesFlow, setInterfacesFlow] = useState([]);
  const [filterInterfaceFlow, setFilterInterfaceFlow] = useState([]);
  const [dataInicial, setDataInicial] = useState(
    dayjs(new Date()).subtract(1, "day").startOf("day").toDate()
  );
  const [dataFinal, setDataFinal] = useState(
    dayjs(new Date()).add(0, "day").startOf("day").toDate()
  );
  const [etapas, setEtapas] = useState(null);
  const [periodoColeta, setPeriodoColeta] = useState({
    repetir: "",
    periodo: null,
  });
  const [disableButtom, setDissableButton] = useState([true, true, true, true]);

  const [terminaEm, setTerminaEm] = useState(0);

  const maskCurrency = (value) => {
    const onlyDigits = value
      .split("")
      .filter((s) => /\d/.test(s))
      .join("")
      .padStart(3, "0");
    const digitsFloat = parseFloat(
      onlyDigits.slice(0, -2) + "." + onlyDigits.slice(-2)
    );

    return new Intl.NumberFormat("pt-BR", { minimumFractionDigits: 2 }).format(
      digitsFloat
    );
  };

  const navigate = useNavigate();

  useEffect(() => {
    setFilterInterfaceFlow([]);
  }, [filterStatusFlow]);

  //controla botao de continuar no primeiro passo
  useEffect(() => {
    let newArray = [...disableButtom];
    if (titulo && produto) {
      newArray[0] = false;
      setDissableButton(newArray);
    } else {
      newArray[0] = true;
      setDissableButton(newArray);
    }
  }, [titulo, produto]);
  //controla o botao de continuar segundo passo
  useEffect(() => {
    let newArray = [...disableButtom];

    if (tipoProduto === "easyflow") {
      if (agruparPorNome.status) {
        agruparPorNome.cliente !== ""
          ? (newArray[1] = false)
          : (newArray[1] = true);

        setDissableButton(newArray);
      } else {
        //verifica7u se 'id' e 'client' estão vazios
        const todosCamposPreenchidos = selectedClientes.every((client) => {
          // Verifica se 'id' e 'nome' não estão vazios
          if (!client.id || !client.client) {
            return false;
          }
          // Verifica se 'itens' não está vazio e todos os 'id' dentro de 'itens' estão preenchidos
          return (
            client.typesValue.length > 0 &&
            client.typesValue.some((typesValue) => typesValue === true)
          );
        });

        if (todosCamposPreenchidos) {
          newArray[1] = false;
          setDissableButton(newArray);
        } else {
          newArray[1] = true;
          setDissableButton(newArray);
        }
      }
    } else {
      const todosCamposPreenchidos = selectedHosts.every((host) => {
        // Verifica se 'id' e 'nome' não estão vazios
        if (!host.id || !host.nome) {
          return false;
        }
        // Verifica se 'itens' não está vazio e todos os 'id' dentro de 'itens' estão preenchidos
        return (
          host.itens.length > 0 && host.itens.every((item) => !!item.itemid)
        );
      });

      if (todosCamposPreenchidos) {
        newArray[1] = false;
        newArray[2] = false;
        setDissableButton(newArray);
      } else {
        newArray[1] = true;
        newArray[2] = true;
        setDissableButton(newArray);
      }
    }
  }, [selectedHosts, selectedClientes, agruparPorNome]);
  //controla o botao de continuar quarto passo

  useEffect(() => {
    let newArray = [...disableButtom];

    const checkPeriodoPersonalizado = !(
      periodoColeta.repetir === "personalizado" && !periodoColeta.periodo
    );

    const checkTerminaApos = !!(
      terminaEm == 0 &&
      etapas &&
      (periodoColeta.repetir || periodoColeta.periodo)
    );

    const checkTerminaEm = !!(
      terminaEm == 1 && dayjs(dataInicial).isBefore(dataFinal)
    );

    if (
      (checkPeriodoPersonalizado && checkTerminaApos) ||
      (checkPeriodoPersonalizado && checkTerminaEm)
    ) {
      newArray[tipoProduto === "easyflow" ? 2 : 3] = false;
      setDissableButton(newArray);
    } else {
      newArray[tipoProduto === "easyflow" ? 2 : 3] = true;
      setDissableButton(newArray);
    }
  }, [dataFinal, dataInicial, periodoColeta, etapas]);

  // criar lista de itens para configurações

  function pesoDaTag(tag) {
    switch (tag) {
      case "traffic":
        return 1;
      case "number":
        return 2;
      case "boolean":
        return 3;
      default:
        return 4; // Qualquer outro valor
    }
  }
  useEffect(() => {
    const configuracoes = {
      variacaoAbrupta: { status: false },
      tarifacao: { status: true, valor: 0 },
      tarifacaoExcedente: {
        status: false,
        valorExcedente: 0,
        limite: 0,
        valor: 0,
      },
      //zeroDown: false,
      zeroDown: { status: false, valor: 0 },
      horarioPersonalizado: { status: false },
      valorMinimo: true,
      valorMaximo: true,
      valorMedio: true,
      disponibilidade: false,
    };

    //remove array mais externo
    const flatResponse = selectedHosts.flatMap((obj) => obj?.itens);
    // cria o array de intens
    const newTagArray = Array.from(
      new Map(
        flatResponse.map((item) => [
          item.tag,
          item,
          (item.configuracoes = configuracoes),
        ])
      ).values()
    );
    const newItemArray = Array.from(
      new Map(
        flatResponse.map((item) => [
          item.nome,
          item,
          (item.configuracoes = configuracoes),
        ])
      ).values()
    );

    setListItens(newItemArray);
    //criar um valor unico para todos todos os itens de tráfico

    setListTags(
      newTagArray.sort((a, b) => pesoDaTag(a.tag) - pesoDaTag(b.tag))
    );
  }, [selectedHosts]);

  useEffect(() => {
    handleApiClienteProduto();
    handleApiGetHosts();
    handleApiFindClientsFlowDB();
    handleApiFindInterfacesFlow();
  }, []);

  const handleSelectHostsChange = (index, event) => {
    const { name, value } = event.target;
    let newSelectedHost = [...selectedHosts];
    newSelectedHost[index][name] = value;
    setSelectedHosts(newSelectedHost);
  };

  const handleSelectClientesChange = (index, event) => {
    const { name, value } = event.target;

    let newSelectedCliente = [...selectedClientes];
    newSelectedCliente[index][name] = value;
    setSelectedClientes(newSelectedCliente);
  };

  //adiciona configurações
  const handleListItensChange = (index, event) => {
    const { name, checked, value } = event.target;
    let newListItens = JSON.parse(JSON.stringify(listTags));

    switch (name) {
      //tarifacao
      case "tarifacao":
        newListItens[index].configuracoes[name].status = checked;
        if (checked)
          newListItens[index].configuracoes["tarifacaoExcedente"].status =
            !checked;
        break;
      case "tarifacao-valor":
        newListItens[index].configuracoes["tarifacao"].valor = value
          ? value
          : 0;
        break;
      //tarifacao excedente
      case "tarifacaoExcedente":
        newListItens[index].configuracoes[name].status = checked;
        if (checked)
          newListItens[index].configuracoes["tarifacao"].status = !checked;
        break;
      case "tarifacaoExcedente-valor":
        newListItens[index].configuracoes["tarifacaoExcedente"].valor = value
          ? value
          : 0;
        break;
      case "tarifacaoExcedente-valorExcedente":
        newListItens[index].configuracoes["tarifacaoExcedente"].valorExcedente =
          value ? value : 0;
        break;
      case "tarifacaoExcedente-limite":
        newListItens[index].configuracoes["tarifacaoExcedente"].limite = value
          ? value
          : 0;
        break;
      //horario
      case "horarioPersonalizado":
        newListItens[index].configuracoes[name].status = checked;
        break;

      case "horaInicial":
        newListItens[index].configuracoes["horarioPersonalizado"].horaInicial =
          value;
        break;

      case "horaFinal":
        newListItens[index].configuracoes["horarioPersonalizado"].horaFinal =
          value;
        break;
      // variacaoAbrupta
      case "variacaoAbrupta":
        newListItens[index].configuracoes[name].status = checked;
        break;
      case "variacao":
        newListItens[index].configuracoes["variacaoAbrupta"].variacao = value;
        break;
      // valor minimo para disponibilidade
      case "zeroDown":
        newListItens[index].configuracoes[name].status = checked;
        if (checked)
          newListItens[index].configuracoes["disponibilidade"] = true;
        break;
      case "zeroDown-valor":
        newListItens[index].configuracoes["zeroDown"].valor = value;
        break;
      default:
        newListItens[index].configuracoes[name] = checked;
        break;
    }

    setListTags(newListItens);
  };

  const addSelectedHosts = () => {
    if (selectedHosts.length) {
      setSelectedHosts([
        ...selectedHosts,
        {
          id: "",
          nome: "",
          itens: [],
          randomKey: Math.random(),
        },
      ]);
    }
  };

  const addSelectedClientes = () => {
    if (selectedHosts.length) {
      setSelectedClientes([
        ...selectedClientes,
        {
          client: "",
          types: [],
          randomKey: Math.random(),
        },
      ]);
    }
  };

  const removeSelectedHosts = (index) => {
    const newSelectedHost = [...selectedHosts];
    newSelectedHost.splice(index, 1);

    setSelectedHosts(newSelectedHost);
  };

  const removeSelectedClientes = (index) => {
    const newSelectedClientes = [...selectedClientes];
    newSelectedClientes.splice(index, 1);

    setSelectedClientes(newSelectedClientes);
  };

  const handleTarifacaoExedente = (event) => {
    const { name, value, checked } = event.target;
    const newTarifacao = { ...tarifacaoExcedente };
    newTarifacao[name] =
      name === "status"
        ? checked
        : name === "limite"
        ? value
        : maskCurrency(value);
    setTarifacaoExcedente(newTarifacao);
  };

  const handleOcultarGraficos = (event) => {
    const { name, value, checked } = event.target;
    const newFeature = checked;
    setOcultarGraficos(newFeature);
  };

  const handleAgruparPorNome = (event) => {
    const { name, value, checked } = event.target;
    const newAgruparPorNome = { ...agruparPorNome };
    newAgruparPorNome[name] =
      name === "status" ? checked : name === "cliente" ? value : null;

    setAgruparPorNome(newAgruparPorNome);
  };

  async function handleApiGetHosts() {
    {
      try {
        setLoadingHosts(true);

        const response = await api.get("/easyReport/HostName/find");

        if (response.data.status === "Error") {
          console.error(response.data);
          setSnackbar({
            children: "Error: Não foi possível buscar hosts",
            severity: "error",
          });
        } else {
          setHosts(response.data);
        }
      } catch (error) {
        setSnackbar({
          children: "Error: Falha ao buscar hosts no conectar com o servidor",
          severity: "error",
        });

        console.error(error);
      } finally {
        setLoadingHosts(false);
      }
    }
  }
  async function handleApiFindClientsFlowDB() {
    try {
      setLoadingClientes(true);

      const response = await api.get("/easyReport/findClientsFlowDB");

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar Clientes",
          severity: "error",
        });
      } else {
        setClientes(response.data);
      }
    } catch (error) {
      console.error(error);

      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoadingClientes(false);
      setDisableClientes(false);
    }
  }

  async function handleApiFindInterfacesFlow(params) {
    try {
      const response = await api.get("/easyReport/findInterfacesFlowDB");

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar Interfaces do Flow",
          severity: "error",
        });
      } else {
        setInterfacesFlow(response.data);
      }
    } catch (error) {
      console.error(error);

      setSnackbar({
        children: "Error: Não foi possível Buscar Interfaces do Flow",
        severity: "error",
      });
    }
  }

  async function handleApiClienteProduto() {
    try {
      const response = await api.post("/cliente/produto/findMany");
      if (response.data.status === "Error") {
        console.error(response.data);
        setSnackbar({
          children: "Error: Não foi possível buscar produtos",
          severity: "error",
        });
      } else {
        setProdutos(response.data);
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Falha ao buscar produtos no conectar com o servidor",
        severity: "error",
      });

      console.error(error);
    }
  }

  async function handleApiCreateRelatorioFlow() {
    try {
      const data = {
        titulo,
        status: 0,
        tipo: 1,
        acessoProdutoId: produto,
        periodoColeta,
        dataInicial,
        dataFinal:
          terminaEm == 1
            ? dataFinal
            : terminaEm == 0 && periodoColeta.repetir === "mensalmente"
            ? dayjs(dataInicial).add(etapas, "month").toDate()
            : terminaEm == 0 && periodoColeta.repetir === "semanalmente"
            ? dayjs(dataInicial).add(etapas, "week").toDate()
            : dayjs(dataInicial).add(periodoColeta.periodo, "day").toDate(),
        hosts: agruparPorNome.status
          ? [
              {
                nome: agruparPorNome.cliente.trim(),
                hostId: null,
                itens: {
                  filterInterfaceFlow: filterInterfaceFlow.map((filter) => {
                    return filter.interface;
                  }),
                  agruparPorNome: agruparPorNome.status,
                  inet: ["IPv4", "IPv6"],
                  tarifacao: {
                    FNA: {
                      valor: fna ? parseFloat(fna.replace(/[.,]/g, ".")) : 0,
                    },
                    GGC: {
                      valor: ggc ? parseFloat(ggc.replace(/[.,]/g, ".")) : 0,
                    },
                    "IX/PTT": {
                      valor: ixPpt
                        ? parseFloat(ixPpt.replace(/[.,]/g, "."))
                        : 0,
                    },
                    "TRANS/IP": {
                      valor: transIp
                        ? parseFloat(transIp.replace(/[.,]/g, "."))
                        : 0,
                    },
                    "OCA|NETFLIX": {
                      valor: ocaNetflix
                        ? parseFloat(ocaNetflix.replace(/[.,]/g, "."))
                        : 0,
                    },
                  },
                  tarifacaoExcedente: {
                    status: tarifacaoExcedente.status,
                    valor: parseFloat(
                      tarifacaoExcedente.valor.replace(/[.,]/g, ".")
                    ),
                    valorExcedente: parseFloat(
                      tarifacaoExcedente.valorExcedente.replace(/[.,]/g, ".")
                    ),
                    limite: parseFloat(
                      tarifacaoExcedente.limite.replace(/[.,]/g, ".")
                    ),
                  },
                  horarioPersonalizado: {
                    status: false,
                  },
                  hideCharts: ocultarGraficos,
                },
              },
            ]
          : selectedClientes.map((host) => {
              const hostData = {
                nome: host.client,
                hostId: null,
                itens: {
                  filterInterfaceFlow: filterInterfaceFlow.map((filter) => {
                    return filter.interface;
                  }),
                  inet: host.types.filter((type, id) => {
                    if (host?.typesValue[id] === true) {
                      return type;
                    }
                  }),
                  tarifacao: {
                    FNA: {
                      valor: fna ? parseFloat(fna.replace(/[.,]/g, ".")) : 0,
                    },
                    GGC: {
                      valor: ggc ? parseFloat(ggc.replace(/[.,]/g, ".")) : 0,
                    },
                    "IX/PTT": {
                      valor: ixPpt
                        ? parseFloat(ixPpt.replace(/[.,]/g, "."))
                        : 0,
                    },
                    "TRANS/IP": {
                      valor: transIp
                        ? parseFloat(transIp.replace(/[.,]/g, "."))
                        : 0,
                    },
                    "OCA|NETFLIX": {
                      valor: ocaNetflix
                        ? parseFloat(ocaNetflix.replace(/[.,]/g, "."))
                        : 0,
                    },
                  },
                  tarifacaoExcedente: {
                    status: tarifacaoExcedente.status,
                    valor: parseFloat(
                      tarifacaoExcedente.valor.replace(/[.,]/g, ".")
                    ),
                    valorExcedente: parseFloat(
                      tarifacaoExcedente.valorExcedente.replace(/[.,]/g, ".")
                    ),
                    limite: parseFloat(
                      tarifacaoExcedente.limite.replace(/[.,]/g, ".")
                    ),
                  },
                  horarioPersonalizado: {
                    status: false,
                  },
                  hideCharts: ocultarGraficos,
                },
              };
              return hostData;
            }),
      };

      const response = await api.post("/easyReport/relatorio/create", {
        ...data,
        logsLoginClienteId: JSON.parse(localStorage.getItem("logs")).id,
      });
      if (response.data.status === "Error") {
        console.error(response.data);
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        navigate("/easyReport/agendar", { state: { successModal: true } });
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });

      console.error(error);
    } finally {
    }
  }
  async function handleApiCreateRelatorio() {
    try {
      const data = {
        titulo,
        status: 0,
        tipo: 0,
        acessoProdutoId: produto,
        periodoColeta,
        dataInicial,
        dataFinal:
          terminaEm == 1
            ? dataFinal
            : terminaEm == 0 && periodoColeta.repetir === "mensalmente"
            ? dayjs(dataInicial).add(etapas, "month").toDate()
            : terminaEm == 0 && periodoColeta.repetir === "semanalmente"
            ? dayjs(dataInicial).add(etapas, "week").toDate()
            : dayjs(dataInicial).add(periodoColeta.periodo, "day").toDate(),
        hosts: selectedHosts.map((host) => {
          let newItensArray = [];
          host.itens.map((item) => {
            let newItem = [];
            listTags.map((itemConfig) => {
              if (item.tag === itemConfig.tag) {
                const novoItem = {
                  ...item,
                  configuracoes: {
                    ...itemConfig.configuracoes,
                    hideCharts: ocultarGraficos,
                  },
                };
                novoItem.itemId = item.itemid;
                novoItem.item = item.nome;
                newItem.push(novoItem);
              }
            });

            delete newItem[0]?.itemid;
            delete newItem[0]?.value_type;
            delete newItem[0]?.units;
            //delete newItem[0]?.nome;
            delete newItem[0]?.name;
            //delete newItem[0]?.tag;

            newItensArray.push(newItem);
          });

          let returnData = {
            ...host,
            itens: newItensArray.flat(),
            hostId: host.id,
          };
          delete returnData.randomKey;
          delete returnData.id;
          return returnData;
        }),
      };
      const response = await api.post("/easyReport/relatorio/create", {
        ...data,
        logsLoginClienteId: JSON.parse(localStorage.getItem("logs")).id,
      });
      if (response.data.status === "Error") {
        console.error(response.data);
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        navigate("/easyReport/agendar", { state: { successModal: true } });
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });

      console.error(error);
    } finally {
    }
  }

  //sim isso é um array de componentes!
  const steps = [
    {
      label: "Adicione um título e selecione o produto",
      description: (
        <Stack direction={"row"} gap={"20px"}>
          <TextField
            label="Título"
            sx={{ width: "340px" }}
            size="small"
            value={titulo}
            onChange={(event) => {
              setTitulo(event.target.value);
            }}
            autoComplete="off"
          />
          <FormControl sx={{ width: "210px" }} size="small">
            <InputLabel id="produto">Selecione o produto</InputLabel>
            <Select
              labelId="produto"
              label="Selecione o produto"
              value={produto}
              onChange={(event) => {
                setProduto(event.target.value);
              }}
            >
              {produtos?.map((p, index) => (
                <MenuItem
                  onClick={(event) => {
                    setTipoProduto(p.tipo);
                  }}
                  key={index}
                  id={index}
                  value={p.id}
                >
                  {p.nome}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      ),
    },
    ...(tipoProduto === "easyflow"
      ? [
          {
            label: "Configure o Relatório",
            description: (
              <Stack direction={"column"} gap={2}>
                <Stack>
                  <Typography color={"primary"}>Ocultar gráficos</Typography>
                  <FormGroup sx={{ width: "fit-content" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={ocultarGraficos}
                          size="small"
                          name="chart"
                          onChange={(event) => {
                            handleOcultarGraficos(event);
                          }}
                        />
                      }
                      label="Ocultar gráficos"
                    />
                  </FormGroup>
                </Stack>
                <Stack>
                  <Typography color={"primary"}>
                    Adiciona valor por Mbps
                  </Typography>
                  <FormGroup sx={{ width: "fit-content" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={tarifacaoExcedente.status}
                          size="small"
                          name="status"
                          onChange={(event) => {
                            handleTarifacaoExedente(event);
                          }}
                        />
                      }
                      label="Cobrança por excedente"
                    />
                  </FormGroup>
                </Stack>
                {!tarifacaoExcedente.status && (
                  <Stack direction={"row"} gap={2} flexWrap={"wrap"}>
                    <TextField
                      label="FNA"
                      value={fna}
                      onChange={(event) => {
                        setFna(maskCurrency(event.target.value));
                      }}
                      autoComplete="off"
                      size="small"
                      sx={{ width: "200px" }}
                    />
                    <TextField
                      label="GGC"
                      value={ggc}
                      onChange={(event) => {
                        setGgc(maskCurrency(event.target.value));
                      }}
                      autoComplete="off"
                      size="small"
                      sx={{ width: "200px" }}
                    />
                    <TextField
                      value={ixPpt}
                      label="IX/PTT"
                      size="small"
                      onChange={(event) => {
                        setIxPpt(maskCurrency(event.target.value));
                      }}
                      autoComplete="off"
                      sx={{ width: "200px" }}
                    />
                    <TextField
                      value={transIp}
                      label="TRANS/IP"
                      size="small"
                      onChange={(event) => {
                        setTransIp(maskCurrency(event.target.value));
                      }}
                      autoComplete="off"
                      sx={{ width: "200px" }}
                    />
                    <TextField
                      value={ocaNetflix}
                      label="OCA|NETFLIX"
                      size="small"
                      onChange={(event) => {
                        setOcaNetflix(maskCurrency(event.target.value));
                      }}
                      autoComplete="off"
                      sx={{ width: "200px" }}
                    />
                  </Stack>
                )}
                {tarifacaoExcedente.status && (
                  <Stack direction={"row"} gap={2} flexWrap={"wrap"}>
                    <TextField
                      label="valor por Mbps"
                      value={tarifacaoExcedente.valor}
                      name="valor"
                      onChange={(event) => {
                        handleTarifacaoExedente(event);
                      }}
                      autoComplete="off"
                      size="small"
                      sx={{ width: "200px" }}
                      slotProps={{
                        input: {
                          startAdornment: (
                            <InputAdornment position="start">R$</InputAdornment>
                          ),
                        },
                      }}
                    />
                    <TextField
                      label="limite de tráfego"
                      value={tarifacaoExcedente.limite}
                      helperText="Em Mbps"
                      name="limite"
                      type="number"
                      onChange={(event) => {
                        handleTarifacaoExedente(event);
                      }}
                      autoComplete="off"
                      size="small"
                      sx={{ width: "200px" }}
                      //InputLabelProps={{ shrink: true }}
                      slotProps={{
                        inputLabel: { shrink: true },
                      }}
                    />
                    <TextField
                      label="valor do excedente"
                      value={tarifacaoExcedente.valorExcedente}
                      name="valorExcedente"
                      onChange={(event) => {
                        handleTarifacaoExedente(event);
                      }}
                      autoComplete="off"
                      size="small"
                      sx={{ width: "200px" }}
                      slotProps={{
                        input: {
                          startAdornment: (
                            <InputAdornment position="start">R$</InputAdornment>
                          ),
                        },
                      }}
                    />
                  </Stack>
                )}
                <Typography color={"primary"}>
                  Selecione o(s) cliente(s)
                </Typography>
                <FormGroup sx={{ width: "fit-content" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={agruparPorNome.status}
                        size="small"
                        name="status"
                        onChange={(event) => {
                          handleAgruparPorNome(event);
                        }}
                      />
                    }
                    label="Agrupar por nome"
                  />
                </FormGroup>

                {agruparPorNome.status && (
                  <TextField
                    sx={{ width: "380px" }}
                    name="cliente"
                    size="small"
                    autoComplete="off"
                    label={"Digite o nome desejado"}
                    onChange={(event) => {
                      handleAgruparPorNome(event);
                    }}
                    helperText={
                      <Typography
                        sx={{
                          fontSize: "0.75rem",
                          opacity: 0.7,
                          marginLeft: -1.3,
                          fontWeight: 600,
                        }}
                      >
                        O relatório irá agrupar a informação de todos os
                        clientes que contiverem o texto acima.
                      </Typography>
                    }
                  />
                )}
                {!agruparPorNome.status &&
                  selectedClientes.map((value, index) => (
                    <Fragment key={`${value.randomKey}-${index}`}>
                      <ConfigureRelatorioFlow
                        index={index}
                        clientes={clientes}
                        setClientes={setClientes}
                        setSnackbar={setSnackbar}
                        selectedClientes={selectedClientes}
                        setSelectedClientes={setSelectedClientes}
                        addSelectedClientes={addSelectedClientes}
                        handleSelectClientesChange={handleSelectClientesChange}
                        removeSelectedClientes={removeSelectedClientes}
                        loadingClientes={loadingClientes}
                        setLoadingClientes={loadingClientes}
                        disableClientes={disableClientes}
                        setDisableClientes={setDisableClientes}
                        handleOcultarGraficos={handleOcultarGraficos}
                        ocultarGraficos={ocultarGraficos}
                      />
                    </Fragment>
                  ))}
                <Typography color={"primary"}>
                  Selecione o(s) filtros
                </Typography>
                <FormGroup sx={{ width: "fit-content" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterStatusFlow}
                        size="small"
                        name="status"
                        onChange={(event) => {
                          setFilterStatusFlow(event.target.checked);
                        }}
                      />
                    }
                    label="Adicionar filtro de interface"
                  />
                </FormGroup>
                {filterStatusFlow && (
                  <Autocomplete
                    multiple
                    id="interfaceFlow"
                    options={interfacesFlow}
                    value={filterInterfaceFlow}
                    onChange={(event, newValue) => {
                      setFilterInterfaceFlow(newValue);
                    }}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.interface}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.interface}
                      </li>
                    )}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        sx={{ width: "380px" }}
                        {...params}
                        label="Interfaces"
                        helperText={
                          <Typography
                            sx={{
                              fontSize: "0.75rem",
                              opacity: 0.7,
                              marginLeft: -1.3,
                              fontWeight: 600,
                            }}
                          >
                            Este filtro irá remover as interfaces selecionadas.
                          </Typography>
                        }
                      />
                    )}
                  />
                )}
              </Stack>
            ),
          },
        ]
      : [
          {
            label: "Configure o Relatório",
            description: selectedHosts.map((value, index) => (
              <Fragment key={`${value.randomKey}-${index}`}>
                <ConfigureRelatorio
                  index={index}
                  hosts={hosts}
                  loadingHosts={loadingHosts}
                  setLoadingHosts={setLoadingHosts}
                  setSnackbar={setSnackbar}
                  selectedHosts={selectedHosts}
                  setSelectedHosts={setSelectedHosts}
                  handleSelectHostsChange={handleSelectHostsChange}
                  addSelectedHosts={addSelectedHosts}
                  removeSelectedHosts={removeSelectedHosts}
                  handleOcultarGraficos={handleOcultarGraficos}
                  ocultarGraficos={ocultarGraficos}
                />
              </Fragment>
            )),
          },
          {
            label: "Configure os Itens",
            description: (
              <ConfigureOsItens
                agendamento
                listTags={listTags}
                listItens={listItens}
                handleListItensChange={handleListItensChange}
              />
            ),
          },
        ]),
    {
      label: "Agendamento",
      description: (
        <Agendamento
          dataInicial={dataInicial}
          setDataInicial={setDataInicial}
          periodoColeta={periodoColeta}
          setPeriodoColeta={setPeriodoColeta}
          terminaEm={terminaEm}
          setTerminaEm={setTerminaEm}
          etapas={etapas}
          setEtapas={setEtapas}
          dataFinal={dataFinal}
          setDataFinal={setDataFinal}
        />
      ),
    },
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Paper
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          padding: "18px",
          mt: "20px",
          minWidth: "840px",
        }}
      >
        <Typography fontSize={"24px"} fontWeight={700} color={"primary.main"}>
          Agendar Relatórios
        </Typography>
        <Typography fontWeight={400}>
          Siga os passos para agendar a geração de relatórios automática
        </Typography>
        <Box>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel
                  optional={
                    index === steps.length - 1 ? (
                      <Typography variant="caption">Último passo</Typography>
                    ) : null
                  }
                >
                  {step.label}
                </StepLabel>
                <StepContent>
                  <Typography>{step.description}</Typography>
                  <Box sx={{ mb: 2 }}>
                    <div>
                      <Button
                        variant="contained"
                        onClick={() => {
                          index === steps.length - 1
                            ? tipoProduto === "easyflow"
                              ? handleApiCreateRelatorioFlow()
                              : handleApiCreateRelatorio()
                            : handleNext();
                        }}
                        sx={{ mt: 1.5, mr: 1 }}
                        disabled={disableButtom[index]}
                      >
                        {index === steps.length - 1 ? "Finalizar" : "Continue"}
                      </Button>
                      <Button
                        disabled={index === 0}
                        onClick={handleBack}
                        sx={{ mt: 1.5, mr: 1 }}
                      >
                        voltar
                      </Button>
                    </div>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length && (
            <Paper square elevation={0} sx={{ p: 3 }}>
              <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                Reset
              </Button>
            </Paper>
          )}
        </Box>
      </Paper>
      <SnackBar snackbar={snackbar} setSnackbar={setSnackbar} />
    </Box>
  );
}
