import { createTheme } from "@mui/material/styles";

const mainTheme = (colorMode) =>
  createTheme({
    palette: {
      mode: !colorMode ? "light" : "dark",
      primary: {
        main: !colorMode ? "#5B2E87" : "#A77AD2",
      },
      secondary: {
        main: "#e83750",
      },
      drawer: {
        main: !colorMode ? "#5B2E87" : "#5B2E87",
        text: !colorMode ? "#E8E6E3" : "#ffffff",
        chat: !colorMode ? "#fff " : "#000000",
      },
      background: {
        default: !colorMode ? "#F0F2F5" : "#141617",
        paper: !colorMode ? "#fff " : "#121212",
        paperOutlined: !colorMode ? "#fff " : "#252525",
        paperChat: !colorMode ? "#F9FAFB" : "#353535ad",
        paperNotification: !colorMode ? "#e4e3e3" : "#1d2021",
      },
      layoutBackGround: { main: !colorMode ? "#5B2E87" : "#121212" },
      chip: {
        pending: !colorMode ? "#E83750" : "#A61328",
        warning: !colorMode ? "#e89b37" : "#c28a37",
        inProgress: !colorMode ? "#ED6C02" : "#BE5602",
        completed: !colorMode ? "#2E7D32" : "#387F4D",
        waitingReview: !colorMode ? "#0288D1" : "#077CB1",
        waitingReturn: !colorMode ? "#5B2E87" : "#502876",
        arquive: !colorMode ? "#777777" : "#424242",
      },
      color: {
        icons: colorMode ? "#fff " : "#313131",
        textDisabled: colorMode ? "#969696" : "#676767",
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
        fh: 1920,
        qhd: 2560,
      },
    },

    components: {
      MuiButton: {
        styleOverrides: {
          containedPrimary: colorMode && { backgroundColor: "#5B2E87" },
        },
      },
      MuiChip: {
        styleOverrides: {
          containedPrimary: true && { backgroundColor: "#5B2E87" },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: !colorMode ? "#5B2E87" : "#121212",
            color: "#fff",
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          colorPrimary: {
            backgroundColor: !colorMode ? "#5B2E87" : "#5B2E87",
          },
        },
      },
    },
    mixins: {
      MuiDataGrid: {
        containerBackground: !colorMode ? "#fff" : "#262626",
      },
    },
    global: `
      .leaflet-layer,
      .leaflet-control-zoom-in,
      .leaflet-control-zoom-out,
      .leaflet-control-attribution {
        filter: invert(100%) hue-rotate(180deg) brightness(95%) contrast(90%);
      }
    `,
  });

export default mainTheme;
