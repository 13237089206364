import {
  CheckCircleRounded,
  ErrorOutlineRounded,
  InfoRounded,
  WarningRounded,
} from "@mui/icons-material";
import { useTheme } from "@mui/material";

const {
  DialogContentText,
  DialogActions,
  Stack,
  Paper,
  Modal,
  Typography,
  DialogTitle,
  DialogContent,
  Button,
  Box,
} = require("@mui/material");

export default function AlertModal(props) {
  const {
    openModal,
    setOpenModal,
    confirmar = false,
    handleButton = () => {},
    handleCancel = () => {},
    severity = "success",
    textContent,
    singleButton = true,
    customButton,
    buttonText,
    buttonCancelText,
  } = props;

  const theme = useTheme();
  const colorMode = theme.palette.mode;
  const alertText = () => {
    if (textContent) {
      return textContent;
    }
    return severity === "success"
      ? "Sucesso na operação!"
      : severity === "warning"
      ? "Se você prosseguir com a operação o item será editado permanentemente e os dados anteriores são perdidos!"
      : severity === "info"
      ? "Está ação pode ser permanentemente!"
      : "Se você prosseguir com a operação o item será deletado e os dados apagados serão perdidos permanentemente!";
  };
  const alertTitle = () => {
    return severity === "success"
      ? "Sucesso!"
      : severity === "warning"
      ? "Alerta!"
      : severity === "info"
      ? "Informação"
      : "Atenção";
  };
  const buttonTextDefault = () => {
    if (buttonText) {
      return buttonText;
    }
    return severity === "success"
      ? "Aceitar"
      : severity === "warning"
      ? "Prosseguir"
      : severity === "info"
      ? "Confirmar"
      : "Excluir";
  };

  const color = () => {
    return severity === "success"
      ? "chip.completed"
      : severity === "warning"
      ? "chip.inProgress"
      : severity === "info"
      ? "chip.waitingReview"
      : "chip.pending";
  };
  const stylesModal = {
    container: { minWidth: "400px" },
    ContainerBox: {
      width: "100%",
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      height: "100%",
    },
    Paper: {
      width: "100%",
      height: "210px",
      justifyContent: "flex-start",
      alignItems: " center",
      display: "flex",
      flexDirection: "column",
      borderRadius: 3,
      borderTopLeftRadius: "0px",
      borderTopRightRadius: "0px",
      bgcolor: "background.paperOutlined",
    },
    Stack: {
      borderRadius: 3,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
      border: "none",
      bgcolor: `${
        severity === "success"
          ? "chip.completed"
          : severity === "warning"
          ? "chip.inProgress"
          : severity === "info"
          ? "chip.waitingReview"
          : "chip.pending"
      }`,
      color: "#ffffff",
      width: "454px",
      ml: "-2px",
      mb: "-2px",
      zIndex: 0,
      minHeight: "182px",

      alignItems: "center",
      justifyContent: "space-around",
      padding: "14px",
      gap: "44x",
    },
    DialogTitle: {
      "&.MuiTypography-root": { color: "#ffffff" },
      fontSize: "32px",
      fontStyle: "normal",
      fontWeight: 700,
      padding: "5px 20px",
    },
    DialogContent: {
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
    },
    DialogContentText: {
      display: "flex",
      justifyContent: "center",
      marginBottom: "20px",
      width: "399px",
      fontSize: "16px",
      textAlign: "center",
      fontWeight: 700,
      color: colorMode === "light" ? "#000000" : "#ffffff",
    },
    DialogActions: {
      width: "100%",
      justifyContent: "flex-end",
      padding: "15px",
    },
    ButtonConfirm: {
      fontWeight: "800",
      color: "#ffffff",
      backgroundColor: `${color()}`,
      "&:hover": {
        backgroundColor: `${color()}`,
        filter: `${
          colorMode === "light" ? "brightness(85%)" : "brightness(120%)"
        }`,
      },
    },
    ButtonCancel: {
      fontWeight: "800",
      //color: `${colorMode === "light" ? "chip.arquive" : "white"}`,
    },
    Icon: { width: "67px", height: "67px" },
  };

  return (
    <Modal
      open={openModal}
      closeAfterTransition
      onClose={() => {
        setOpenModal(false);
      }}
    >
      <Box sx={stylesModal.ContainerBox}>
        <Stack direction="column" width="450px">
          <Stack sx={stylesModal.Stack}>
            {severity === "success" ? (
              <CheckCircleRounded sx={stylesModal.Icon} />
            ) : severity === "warning" ? (
              <ErrorOutlineRounded sx={stylesModal.Icon} />
            ) : severity === "info" ? (
              <InfoRounded sx={stylesModal.Icon} />
            ) : (
              <WarningRounded sx={stylesModal.Icon} />
            )}

            <DialogTitle sx={stylesModal.DialogTitle}>
              {alertTitle()}
            </DialogTitle>
          </Stack>

          <Paper variant="outlined" sx={stylesModal.Paper}>
            <DialogContent sx={stylesModal.DialogContent}>
              <DialogContentText sx={stylesModal.DialogContentText}>
                <Typography variant="h4" fontSize={20}>
                  {alertText()}
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={stylesModal.DialogActions}>
              {singleButton === true ? (
                <Button
                  autoFocus
                  variant="contained"
                  color={severity}
                  sx={stylesModal.ButtonConfirm}
                  onClick={handleButton}
                  disabled={confirmar}
                >
                  {buttonTextDefault()}
                </Button>
              ) : (
                <>
                  <Button
                    variant="outlined"
                    color="inherit"
                    onClick={() => {
                      setOpenModal(false);
                      handleCancel();
                    }}
                    sx={stylesModal.ButtonCancel}
                  >
                    {buttonCancelText ?? "Cancelar"}
                  </Button>

                  <Button
                    autoFocus
                    variant="contained"
                    color={severity}
                    sx={stylesModal.ButtonConfirm}
                    onClick={handleButton}
                    disabled={confirmar}
                  >
                    {buttonTextDefault()}
                  </Button>
                </>
              )}
              {customButton}
            </DialogActions>
          </Paper>
        </Stack>
      </Box>
    </Modal>
  );
}
