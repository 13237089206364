import { Dangerous } from "@mui/icons-material";

const { DialogContentText, DialogActions } = require("@mui/material");
const { Box } = require("@mui/material");
const { Button } = require("@mui/material");
const { DialogTitle, DialogContent } = require("@mui/material");
const { Dialog } = require("@mui/material");

export default function DeleteDialog(props) {
  const {
    openDeleteModal,
    setOpenDeleteModal,
    confirmarDelete,
    handleApiDelete,
  } = props;

  return (
    <Dialog
      open={openDeleteModal}
      onClose={() => {
        setOpenDeleteModal(false);
      }}
    >
      <DialogTitle sx={{ color: "#D32F2F" }}>
        Deseja realmente <span style={{ fontWeight: "900" }}>excluir</span>{" "}
        esses dados?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <Dangerous color={"error"} sx={{ fontSize: 60 }} />
          </Box>
          <span style={{ color: "#D32F2F" }}>
            Uma vez após excluidos os dados não podem ser recuperados. Tem
            certeza que de deseja fazer essa exclusão ?
          </span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpenDeleteModal(false);
          }}
        >
          Cancelar
        </Button>

        <Button
          autoFocus
          color={"error"}
          sx={{ width: "100px", fontWeight: "800" }}
          onClick={handleApiDelete}
          disabled={confirmarDelete}
        >
          Excluir
        </Button>
      </DialogActions>
    </Dialog>
  );
}
