import { Error } from "@mui/icons-material";
import { Tooltip, TextField, useTheme, Stack } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid";

export default function EditcellTextField({
  id,
  field,
  value,
  error,
  setIsEdit = () => {},
  selected,
}) {
  const apiRef = useGridApiContext();
  const theme = useTheme();
  const handleChange = (event) => {
    setIsEdit((data) => (data.includes(id) ? data : [...data, id]));
    apiRef.current.setEditCellValue({
      id,
      field,
      value: event.target.value,
      debounceMs: 1,
    });
  };

  return (
    <Tooltip
      title={error}
      placement="bottom-start"
      slotProps={{
        tooltip: {
          sx: {
            p: 0.5,
            px: 1,
            bgcolor: theme.palette.mode === "dark" ? "#F44336" : "#D32F2F",
            color: theme.palette.mode === "dark" ? "#fff" : "#fff",
            fontSize: 14,
            borderRadius: "3px",
          },
        },
        popper: {
          sx: { pointerEvents: "none" },
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -5],
              },
            },
          ],
        },
      }}
    >
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
          width: "100%",
          border: selected ? "1px solid" + theme.palette.primary.main : null,
        }}
      >
        <TextField
          autoFocus
          autoComplete="off"
          onChange={handleChange}
          variant="standard"
          sx={{ p: "0px 10px", width: "100%" }}
          value={value}
          slotProps={{
            input: {
              disableUnderline: true,
              style: { fontSize: "13px" },
              endAdornment: error === "Campo obrigatório" && (
                <Error color="error" />
              ),
            },
          }}
          error={!!error}
        />
      </Stack>
    </Tooltip>
  );
}
