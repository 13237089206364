import {
  ArrowDropDown,
  Close,
  Done,
  FilterAltOutlined,
} from "@mui/icons-material";
import {
  Badge,
  Button,
  ListItemIcon,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import IconTransform from "../iconsElement";

export default function FilterElements({
  iconsElement,
  elementsFilter,
  setElementsFilter,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  //const [elementsFilter, setElementsFilter] = useState([]);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const styles = {
    icon: {
      color: theme.palette.color.icons,
    },
    stack: {
      position: "absolute",
      display: "flex",
      flexDirection: "column",
      left: "70px",
      top: "20px",
      alignItems: "center",
      gap: 1,
      justifyContent: "center",
      zIndex: 10,
      flexDirection: "row",
      fontWeight: "700",
    },
    paperFilter: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "theme.palette.background.paper",
      zIndex: 10,
      flexDirection: "row",
      fontWeight: "700",
    },
    addFilter: {
      width: "250px",
      alignItems: "center",
      justifyContent: "space-between",
      ml: "20px",
      mb: "10px",
    },
    menuItem: { margin: 0.5 },
    button: {
      color: theme.palette.color.text,
      textTransform: "capitalize",
    },
  };
  function addFilter(tipo, nome) {
    setElementsFilter((ele) => [...ele, { nome, tipo }]);
  }
  function removeFilter(tipo, nome) {
    setElementsFilter((ele) => ele.filter((value) => tipo !== value.tipo));
  }
  const MenuItemFilter = ({ nome, tipo, elementsFilter }) => {
    const icon = iconsElement.find((icon) => icon.nome == nome);
    const selected = elementsFilter?.find((value) => tipo === value.tipo);
    const styles = {
      menuItem: {
        margin: 0.5,
        widht: "100%",
        pr: 0,
        pl: 1,
        borderRadius: 2,
      },
      menuItemSelected: {
        margin: 0.5,
        widht: "100%",
        pr: 0,
        pl: 1,
        borderRadius: 2,
        backgroundColor: theme.palette.color.menuItem,
        "&:hover": {
          backgroundColor: theme.palette.color.menuItem,
        },
      },
    };
    if (selected) {
      return (
        <MenuItem
          onClick={() => removeFilter(tipo, icon.nome)}
          sx={styles.menuItemSelected}
          className="menuitem-selected"
        >
          <Stack
            justifyContent={"space-between"}
            direction={"row"}
            width="100%"
          >
            <Stack direction={"row"}>
              <ListItemIcon>
                <IconTransform
                  img={icon?.img}
                  color={theme.palette.primary.main}
                />
              </ListItemIcon>
              <Typography color={theme.palette.primary.main}>
                {tipo === 0 ? "elementos" : icon.nome.replace("_", "")}
              </Typography>
            </Stack>
            <ListItemIcon>
              <Done color="primary" />
            </ListItemIcon>
          </Stack>
        </MenuItem>
      );
    }
    return (
      <MenuItem onClick={() => addFilter(tipo, icon.nome)} sx={styles.menuItem}>
        <Stack justifyContent={"space-between"} direction={"row"} width="100%">
          <Stack direction={"row"}>
            <ListItemIcon color="primary">
              <IconTransform img={icon?.img} />
            </ListItemIcon>
            <Typography>
              {" "}
              {tipo === 0 ? "elementos" : icon.nome.replace("_", "")}
            </Typography>
          </Stack>
        </Stack>
      </MenuItem>
    );
  };
  return (
    <>
      <Stack sx={styles.stack}>
        <Paper sx={styles.paperFilter}>
          <Button
            startIcon={
              <Badge
                color="primary"
                sx={{
                  "& .MuiBadge-badge": { top: 4 },
                }}
                //badgeContent={elementsFilter?.length}
              >
                <FilterAltOutlined />
              </Badge>
            }
            endIcon={<ArrowDropDown />}
            onClick={handleClick}
            sx={styles.button}
          >
            Filtro
          </Button>
        </Paper>
        {elementsFilter.map(({ nome, tipo }) => (
          <Paper>
            <Button
              startIcon={
                <IconTransform
                  //size="20px"
                  img={iconsElement.find((icon) => icon.nome == nome)?.img}
                />
              }
              endIcon={<Close />}
              onClick={() => removeFilter(tipo, nome)}
              sx={styles.button}
            >
              {nome.replace("_", "")}
            </Button>
          </Paper>
        ))}
      </Stack>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          marginTop: "10px",
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Stack direction={"row"} sx={styles.addFilter}>
          <Typography>Adicionar Filtro</Typography>
          <Button
            sx={{ textTransform: "none" }}
            variant="text"
            size={"small"}
            onClick={() => setElementsFilter([])}
          >
            <u>Limpar</u>
          </Button>
        </Stack>
        <MenuItemFilter
          nome={"_roteador"}
          tipo={0}
          elementsFilter={elementsFilter}
        />
        <MenuItemFilter
          nome={"_mapa"}
          tipo={1}
          elementsFilter={elementsFilter}
        />
        <MenuItemFilter
          nome={"_pop"}
          tipo={2}
          elementsFilter={elementsFilter}
        />
      </Menu>
    </>
  );
}
