import {
  Paper,
  Stack,
  Box,
  useTheme,
  createSvgIcon,
  Button,
} from "@mui/material";

import { ReactComponent as IconRouter } from "../../../../assets/easyMaps/Router.svg";
import ElementHeader from "../../../../componentes/easyMaps/elementHeader";

export default function CardHosts(props) {
  const { host, setFocusNodeId, iconsElement } = props;
  const theme = useTheme();

  const styles = {
    line: {
      backgroundColor: "primary.main",
      height: "60px",
      width: "3px",
    },
    text: {
      marginTop: "-3px",
      fontSize: "0.8em",
      maxWidth: "190px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },

    card: {
      boxShadow: "0px 0px 5px  #0000001a",
      overflow: "hidden",
      padding: "0px 10px",
      width: "100%",
      backgroundColor: theme.palette.background.paperSelectedMaps,
      "&:hover": {
        backgroundColor: theme.palette.background.hover,
      },
    },
  };

  return (
    <Paper elevation={0} sx={styles.card}>
      <Stack direction="row" alignItems="center" gap={1}>
        <Box sx={styles.line} />
        <ElementHeader
          size="small"
          tipo={host.tipo}
          titulo={host.titulo}
          interfaces={
            Array.isArray(host?.elementConfig?.config?.hosts) &&
            host?.elementConfig.config?.hosts?.length > 0
              ? host?.elementConfig?.config?.hosts[0]?.interfaces
              : []
          }
          tags={
            Array.isArray(host?.elementConfig?.config?.hosts) &&
            host?.elementConfig?.config?.hosts?.length > 0
              ? host?.elementConfig?.config?.hosts[0]?.tags
              : []
          }
          img={iconsElement?.find((icon) => icon.id == host.img)?.img}
        />
      </Stack>
    </Paper>
  );
}
