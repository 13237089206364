import axios from "axios";

const apiSistema = axios.create({});

apiSistema.interceptors.request.use(async (config) => {
  const url = `${window?.config?.REACT_APP_LINK_MW || ""}`;
  const token = window?.config?.REACT_APP_MW_SISTEMA_TOKEN || "";
  const acesso = JSON.parse(localStorage.getItem("acessoClienteId")) || {};
  if (token) {
    try {
      config.baseURL = url;
      config.headers.Authorization = token ? `Bearer ${token}` : "";
      config.headers["x-acess-id"] = acesso ? `${acesso}` : "";
      // atualiza o token antes de continuar com a requisição
      return config;
    } catch (error) {
      console.error(error);
    }
  } /* else {
    localStorage.clear();
    window.location.replace("/login");
  } */
});

export default apiSistema;
