import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Modal,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../../../../services/api";
import { Add, Delete } from "@mui/icons-material";

export default function CreateModalASN(params) {
  const {
    setOpenCreateModal,
    openCreateModal,
    setSnackbar,
    setLoadingTable,
    styles,
    setErrorName,
    errorName,
    comparaNome,
    handleNewAsn,
  } = params;
  const [disableCreateButton, setDisableCreateButton] = useState(false);
  const [nomeAsn, setNomeAsn] = useState("");
  const [asn, setAsn] = useState([""]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && disableCreateButton === false) {
      handleApiPostAsn();
    }
  };
  useEffect(() => {
    if (comparaNome(nomeAsn) || !asn.every((asnValue) => asnValue)) {
      setDisableCreateButton(true);
    } else {
      setDisableCreateButton(false);
    }
  }, [asn, nomeAsn]);

  const handleChangeAsn = (e, index) => {
    let auxAsn = [...asn];
    auxAsn[index] = e.target.value.replace(/\s/g, "");
    setAsn(auxAsn);
  };
  const addAsn = () => {
    setAsn([...asn, ""]);
  };
  const removeAsn = (index) => {
    const newAsn = [...asn];
    newAsn.splice(index, 1);
    setAsn(newAsn);
  };
  function clearModal() {
    setNomeAsn("");
    setAsn([""]);
  }
  async function handleApiPostAsn() {
    try {
      setLoadingTable(true);
      setDisableCreateButton(true);
      const response = await api.post("/relatoriosFlow/asn/create", {
        nome_asn: nomeAsn,
        asn: asn.join(),
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados Salvos com sucesso",
          severity: "success",
        });
        if (response.data) {
          handleNewAsn(response.data);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoadingTable(false);
      setDisableCreateButton(false);
      setOpenCreateModal(false);
      clearModal();
    }
  }
  return (
    <>
      <Modal
        open={openCreateModal}
        onClose={() => {
          setOpenCreateModal(false);
          clearModal();
        }}
        sx={{ alignItems: "center", justifyContent: "center", display: "flex" }}
      >
        <Paper sx={styles.paperModal} elevation={3}>
          <DialogTitle color="primary">Novo ASN </DialogTitle>{" "}
          <DialogContent sx={styles.dialogContent}>
            <Stack direction="column" spacing={"20px"} sx={{ mt: 1 }}>
              <TextField
                sx={styles.TextField}
                id="textfield-nome"
                label="Nome"
                value={nomeAsn}
                error={errorName}
                helperText={errorName ? "Nome é uma chave única" : null}
                onChange={(e) => {
                  if (comparaNome(e.target.value)) {
                    setErrorName(true);
                  } else {
                    setErrorName(false);
                  }
                  setNomeAsn(e.target.value);
                }}
              />
              {asn?.map((asnValue, index) => (
                <Stack direction="row" key={index}>
                  <TextField
                    sx={{ width: "100%" }}
                    id="textfield-asn"
                    label={"ASN " + (index + 1)}
                    type="number"
                    onKeyDown={handleKeyDown}
                    value={asnValue}
                    onChange={(event) => handleChangeAsn(event, index)}
                  />{" "}
                  {asn.length > 1 && (
                    <IconButton
                      onClick={() => removeAsn(index)}
                      sx={{ mr: -1 }}
                    >
                      <Delete color="secondary" />
                    </IconButton>
                  )}
                  <IconButton
                    size="small"
                    onClick={addAsn}
                    sx={{ ml: 1, mr: -1 }}
                  >
                    <Add color="primary" />
                  </IconButton>
                </Stack>
              ))}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              onClick={() => {
                setOpenCreateModal(false);
                clearModal();
              }}
            >
              Cancelar
            </Button>
            <Button
              disabled={disableCreateButton}
              onClick={() => {
                handleApiPostAsn();
              }}
            >
              Criar
            </Button>
          </DialogActions>
        </Paper>
      </Modal>
    </>
  );
}
