import {
  Stack,
  TextField,
  DialogTitle,
  Paper,
  Modal,
  Button,
  InputAdornment,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { useEffect, useState } from "react";
import "dayjs/locale/pt-br";
import api from "../../../../../services/api";
import SnackBar from "../../../../../componentes/snackbar";
import AlertModal from "../../../../../componentes/alertModal";
import { DialogActions } from "@mui/material";
import { DialogContent } from "@mui/material";
const styles = {
  paper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: 3,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
  },
  dialogTitle: { padding: 0 },
  dialogContent: {
    padding: 0,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    gap: 1,
  },
};
export default function ModalConfigFlow(props) {
  const { openModalConfig, setOpenModalConfig, hosts } = props;
  const [clientes, setClientes] = useState([]);
  const initialClientes = {
    id: 0,
    client: "",
    types: [],
  };
  const [selectedClientes, setSelectedClientes] = useState([initialClientes]);
  const [loadingClientes, setLoadingClientes] = useState(false);
  const [fna, setFna] = useState("");
  const [ggc, setGgc] = useState("");
  const [ixPpt, setIxPpt] = useState("");
  const [transIp, setTransIp] = useState("");
  const [ocaNetflix, setOcaNetflix] = useState("");
  const [snackbar, setSnackbar] = useState(null);
  const [disableButton, setDisableButton] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [ocultarGraficos, setOcultarGraficos] = useState(false);
  const [tarifacaoExcedente, setTarifacaoExcedente] = useState({
    status: false,
    valor: "",
    valorExcedente: "",
    limite: "",
  });
  const maskCurrency = (value) => {
    const onlyDigits = value
      .split("")
      .filter((s) => /\d/.test(s))
      .join("")
      .padStart(3, "0");
    const digitsFloat = parseFloat(
      onlyDigits.slice(0, -2) + "." + onlyDigits.slice(-2)
    );

    return new Intl.NumberFormat("pt-BR", { minimumFractionDigits: 2 }).format(
      digitsFloat
    );
  };
  const handleFormatString = (value) => {
    const onlyDigits = value
      .split("")
      .filter((s) => /\d/.test(s))
      .join("")
      .padStart(3, "0");
    const digitsFloat = parseFloat(
      onlyDigits.slice(0, -2) + "." + onlyDigits.slice(-2)
    );

    return new Intl.NumberFormat("pt-BR", { minimumFractionDigits: 2 }).format(
      digitsFloat
    );
  };
  const onCloseModal = () => {
    setOpenModalConfig(false);
  };

  const onButtonSave = () => {
    setOpenConfirmDialog(true);
  };
  useEffect(() => {
    const tarifacao = hosts[0]?.itens?.tarifacao;
    const excedente = hosts[0]?.itens?.tarifacaoExcedente;
    setOcultarGraficos(hosts[0]?.itens?.hideCharts);
    if (hosts && tarifacao) {
      setSelectedClientes(hosts);
      setFna(maskCurrency(tarifacao["FNA"]?.valor?.toString()));
      setGgc(maskCurrency(tarifacao["GGC"]?.valor?.toString()));
      setIxPpt(maskCurrency(tarifacao["IX/PTT"]?.valor?.toString()));
      setOcaNetflix(maskCurrency(tarifacao["OCA|NETFLIX"]?.valor?.toString()));
      setTransIp(maskCurrency(tarifacao["TRANS/IP"]?.valor?.toString()));
      setTarifacaoExcedente({
        limite: excedente?.limite?.toString(),
        valor: excedente?.valor?.toString(),
        valorExcedente: excedente?.valorExcedente?.toString(),
        status: excedente?.status?.toString(),
      });
    }
  }, [hosts]);

  const handleTarifacaoExedente = (event) => {
    const { name, value, checked } = event.target;
    const newTarifacao = { ...tarifacaoExcedente };
    newTarifacao[name] =
      name === "status"
        ? checked
        : name === "limite"
        ? value
        : maskCurrency(value);
    setTarifacaoExcedente(newTarifacao);
  };
  const handleOcultarGraficos = (event) => {
    const { name, value, checked } = event.target;
    const newFeature = checked;
    setOcultarGraficos(newFeature);
  };
  async function handleApiPutHosts() {
    try {
      setDisableButton(true);
      const response = await api.put("/easyReport/hosts/update", {
        logsLoginClienteId: JSON.parse(localStorage.getItem("logs")).id,
        hosts: hosts.map((host) => {
          const hostData = {
            id: host.id,
            nome: host.client,
            hostId: null,
            itens: {
              inet: host.itens.inet,
              tarifacao: {
                FNA: { valor: fna ? parseFloat(fna.replace(/[.,]/g, ".")) : 0 },
                GGC: { valor: ggc ? parseFloat(ggc.replace(/[.,]/g, ".")) : 0 },
                "IX/PTT": {
                  valor: ixPpt ? parseFloat(ixPpt.replace(/[.,]/g, ".")) : 0,
                },
                "TRANS/IP": {
                  valor: transIp
                    ? parseFloat(transIp.replace(/[.,]/g, "."))
                    : 0,
                },
                "OCA|NETFLIX": {
                  valor: ocaNetflix
                    ? parseFloat(ocaNetflix.replace(/[.,]/g, "."))
                    : 0,
                },
              },
              tarifacaoExcedente: {
                status: tarifacaoExcedente.status,
                valor: parseFloat(
                  tarifacaoExcedente.valor.replace(/[.,]/g, ".")
                ),
                valorExcedente: parseFloat(
                  tarifacaoExcedente.valorExcedente.replace(/[.,]/g, ".")
                ),
                limite: parseFloat(
                  tarifacaoExcedente.limite.replace(/[.,]/g, ".")
                ),
              },
              horarioPersonalizado: {
                status: false,
              },
              hideCharts: ocultarGraficos,
            },
          };
          return hostData;
        }),
      });

      if (response.data.status === "Error") {
        console.error(response.data);
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });

      console.error(error);
    } finally {
      setDisableButton(false);
      setOpenConfirmDialog(false);
      setOpenModalConfig(false);
    }
  }
  return (
    <>
      <Modal open={openModalConfig} onClose={onCloseModal}>
        <Paper sx={styles.paper} elevation={3}>
          <DialogContent>
            <DialogContent sx={styles.dialogContent}>
              {" "}
              <DialogTitle sx={styles.dialogTitle} color="primary">
                Adiciona valor por Mbps
              </DialogTitle>
              <FormGroup sx={{ width: "fit-content" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={tarifacaoExcedente.status}
                      size="small"
                      name="status"
                      onChange={(event) => {
                        handleTarifacaoExedente(event);
                      }}
                    />
                  }
                  label="Cobrança por excedente"
                />
              </FormGroup>
              <Stack
                direction={"row"}
                gap={3}
                width={"650px"}
                sx={{ flexWrap: "wrap" }}
              >
                {!tarifacaoExcedente.status && (
                  <>
                    <TextField
                      label="FNA"
                      value={fna}
                      onChange={(event) => {
                        setFna(maskCurrency(event.target.value));
                      }}
                      autoComplete="off"
                      size="small"
                      sx={{ width: "200px" }}
                    />
                    <TextField
                      label="GGC"
                      value={ggc}
                      onChange={(event) => {
                        setGgc(maskCurrency(event.target.value));
                      }}
                      autoComplete="off"
                      size="small"
                      sx={{ width: "200px" }}
                    />
                    <TextField
                      value={ixPpt}
                      label="IX/PTT"
                      size="small"
                      onChange={(event) => {
                        setIxPpt(maskCurrency(event.target.value));
                      }}
                      autoComplete="off"
                      sx={{ width: "200px" }}
                    />
                    <TextField
                      value={transIp}
                      label="TRANS/IP"
                      size="small"
                      onChange={(event) => {
                        setTransIp(maskCurrency(event.target.value));
                      }}
                      autoComplete="off"
                      sx={{ width: "200px" }}
                    />
                    <TextField
                      value={ocaNetflix}
                      label="OCA|NETFLIX"
                      size="small"
                      onChange={(event) => {
                        setOcaNetflix(maskCurrency(event.target.value));
                      }}
                      autoComplete="off"
                      sx={{ width: "200px" }}
                    />
                  </>
                )}
                {tarifacaoExcedente.status && (
                  <>
                    <TextField
                      label="valor por Mbps"
                      value={tarifacaoExcedente.valor}
                      name="valor"
                      onChange={(event) => {
                        handleTarifacaoExedente(event);
                      }}
                      autoComplete="off"
                      size="small"
                      sx={{ width: "200px" }}
                      slotProps={{
                        input: {
                          startAdornment: (
                            <InputAdornment position="start">R$</InputAdornment>
                          ),
                        },
                      }}
                    />
                    <TextField
                      label="limite de tráfego"
                      value={tarifacaoExcedente.limite}
                      helperText="Em Mbps"
                      name="limite"
                      type="number"
                      onChange={(event) => {
                        handleTarifacaoExedente(event);
                      }}
                      autoComplete="off"
                      size="small"
                      sx={{ width: "200px" }}
                      //InputLabelProps={{ shrink: true }}
                      slotProps={{
                        inputLabel: { shrink: true },
                      }}
                    />
                    <TextField
                      label="valor do excedente"
                      value={tarifacaoExcedente.valorExcedente}
                      name="valorExcedente"
                      onChange={(event) => {
                        handleTarifacaoExedente(event);
                      }}
                      autoComplete="off"
                      size="small"
                      sx={{ width: "200px" }}
                      slotProps={{
                        input: {
                          startAdornment: (
                            <InputAdornment position="start">R$</InputAdornment>
                          ),
                        },
                      }}
                    />
                  </>
                )}{" "}
              </Stack>
            </DialogContent>
            <DialogContent sx={styles.dialogContent}>
              {/* <DialogTitle sx={styles.dialogTitle} color="primary">
                Ocultar gráficos
              </DialogTitle> */}
              <FormGroup sx={{ width: "fit-content" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={ocultarGraficos}
                      size="small"
                      name="chart"
                      onChange={(event) => {
                        handleOcultarGraficos(event);
                      }}
                    />
                  }
                  label="Ocultar gráficos"
                />
              </FormGroup>
            </DialogContent>
          </DialogContent>
          <DialogActions sx={{ display: "flex", width: "100%" }}>
            <Button color="secondary" onClick={onCloseModal}>
              Cancelar
            </Button>
            <Button onClick={onButtonSave}>Salvar</Button>
          </DialogActions>
        </Paper>
      </Modal>
      <AlertModal
        openModal={openConfirmDialog}
        setOpenModal={setOpenConfirmDialog}
        confirmar={disableButton}
        handleButton={handleApiPutHosts}
        severity={"warning"}
        singleButton={false}
      />
      <SnackBar snackbar={snackbar} setSnackbar={setSnackbar} />
    </>
  );
}
