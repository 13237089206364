import axios from "axios";
import tokenUpdate from "./tokenUpdate";

const token = () => {
  let result = localStorage.getItem("1");
  result = JSON.parse(result);
  if (result) {
    return result.token;
  } else {
    return "";
  }
};
const usuarioId = () => {
  let result = localStorage.getItem("1");
  result = JSON.parse(result);
  if (result) {
    //const codigos = ;
    return result.refreshToken.usuarioClienteId;
  } else {
    return "";
  }
};
const acessoClientId = () => {
  let result = localStorage.getItem("acesso");
  result = JSON.parse(result);
  if (result) {
    //const codigos = ;
    return result[0].acessoClientesId;
  } else {
    return "";
  }
};
const api = axios.create({});

api.interceptors.request.use(async (config) => {
  const url = `${
    window?.config?.REACT_APP_BASE_URL ? window?.config?.REACT_APP_BASE_URL : ""
  }`;
  if (token()) {
    try {
      const tokenAtual = token();
      const user = usuarioId();
      const acesso = acessoClientId();
      config.baseURL = url;
      config.headers.Authorization = tokenAtual ? `Bearer ${tokenAtual}` : "";
      config.headers["x-user-id"] = user ? `${user}` : "";
      config.headers["x-acess-id"] = acesso ? `${acesso}` : "";
      // atualiza o token antes de continuar com a requisição
      await tokenUpdate(token());
      return config;
    } catch (error) {
      console.error(error);
    }
  } else {
    if (window?.config) {
      localStorage.clear();
      window.location.replace("/login");
    }
  }
});
export default api;
