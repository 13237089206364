import { AccessTime, DateRange } from "@mui/icons-material";
import { Stack, Typography, createSvgIcon } from "@mui/material";
import { ReactComponent as IconFlow } from "../../../../assets/icons/IconEasyFlow.svg";
import { ReactComponent as IconMon } from "../../../../assets/icons/IconEasyMon.svg";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
const EasyFlowIcon = createSvgIcon(<IconFlow />);
const EasyMonIcon = createSvgIcon(<IconMon />);
export const HeaderBar = ({
  dataInicial,
  dataFinal,
  periodoColeta = { repetir: "personalizado", periodo: 7 },
  tipo
}) => {
  return (
    <Stack
      direction="row"
      justifyContent={"flex-start"}
      alignItems={"flex-end"}
      gap={"30px"}
    >
      <Stack direction="row" alignItems={"flex-end"} gap={"10px"}>
        <DateRange color="primary" />
        <Stack direction="column">
          <Typography variant="subtitle2">Data Inicial</Typography>
          <Typography>
            {dayjs(new Date(dataInicial))
              .add(3, "hours")
              .toDate()
              .toLocaleDateString()}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" alignItems={"flex-end"} gap={"10px"}>
        <DateRange color="primary" />
        <Stack direction="column">
          <Typography variant="subtitle2">Data Final</Typography>
          <Typography>
            {dayjs(new Date(dataFinal))
              .add(3, "hours")
              .toDate()
              .toLocaleDateString()}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" alignItems={"flex-end"} gap={"10px"}>
        <AccessTime color="primary" />
        <Stack direction="column">
          <Typography variant="subtitle2">Intervalo de Coleta</Typography>
          <Typography
            sx={{
              textTransform: "capitalize"
            }}
          >
            {!periodoColeta.repetir ||
            periodoColeta.repetir?.toLowerCase() === "personalizado"
              ? periodoColeta.periodo + " dias"
              : periodoColeta.repetir?.toLowerCase()}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" alignItems={"flex-end"} gap={"10px"}>
        {tipo === 0 ? (
          <EasyMonIcon color="primary" />
        ) : (
          <EasyFlowIcon color="primary" />
        )}
        <Stack direction="column">
          <Typography variant="subtitle2">Tipo</Typography>
          <Typography>{tipo === 0 ? "EasyMon" : "EasyFlow"}</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
