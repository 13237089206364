import {
  Divider,
  Box,
  Paper,
  Stack,
  Typography,
  useTheme,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
  alpha,
} from "@mui/material";
import StatusCard from "../statusCard";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Fragment, useEffect, useState } from "react";
import { convertBitsToBytesUniversal } from "../../utils";

export default function Detalhes(
  { host, expanded, setExpanded, index, unique },
  key
) {
  const theme = useTheme();
  const [alert, setAlert] = useState(false);

  useEffect(() => {
    if (!host) return;
    // aqui pode adicionar outros paramentros para informar aviso no host, perda d eping alta e etc
    if (host.ping != 1 || host.snmp != 1) {
      setAlert(true);
    } else {
      setAlert(false);
    }
  }, [host]);

  const styles = {
    line: {
      position: "absolute",
      height: "80%", //typeElement == "Host" ? "150px" : "230px",
      minWidth: "3px",
      ml: "-5px",
    },
    textAlerta: {
      fontSize: "0.85rem",
      color: theme.palette.color.textDisabled,
    },
    textMedium: { color: theme.palette.color.textDisabled },
    textLarge: { fontSize: "2em" },
    contentTab: { gap: "10px" },
    detailsCard: {
      backgroundColor: theme.palette.background.paperLinkedMaps,
      paddingX: "10px",
      paddingY: "15px",
      display: "flex",
      flexDirection: "column",
      gap: 2,
    },
    rowsDetails: { alignItems: "center", justifyContent: "space-between" },
    textHeaderAco: {
      fontSize: "0.85rem",

      color: theme.palette.color.text,
    },
  };
  function handleChange(selected) {
    setExpanded(selected == expanded ? null : index);
  }

  return (
    <Accordion
      key={key}
      disableGutters
      expanded={expanded == index || unique}
      onChange={() => handleChange(index)}
      sx={{
        padding: "5px",
        borderRadius: "5px",
        backgroundColor: theme.palette.background.paperSelectedMaps,

        boxShadow: "0px 0px 5px  #0000001a",
        border: "none",
        "&::before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={!unique ? <ExpandMoreIcon /> : null}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{
          padding: "0px",
        }}
      >
        <Stack
          direction="row"
          justifyContent="start"
          alignItems="center"
          width="100%"
          ml={1}
        >
          {!unique && expanded != index && (
            <Box
              sx={[styles.line, { backgroundColor: alert ? "red" : "#387F4D" }]}
            ></Box>
          )}
          <Tooltip title={host.name}>
            <Typography
              sx={{
                fontSize: "0.9em",
                maxWidth: "250px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                ml: "5px",
              }}
            >
              {host.name}
            </Typography>
          </Tooltip>
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: "5px" }}>
        <Stack sx={styles.contentTab}>
          <StatusCard tipo="ping" status={host.ping == 1} />
          <StatusCard tipo="snmp" status={host.snmp == 1} />
          <Paper sx={styles.detailsCard} elevation={0}>
            <Stack direction={"row"} justifyContent={"space-around"}>
              <Stack alignItems={"center"}>
                <Stack direction={"row"} sx={{ alignItems: "flex-end" }}>
                  <Typography sx={styles.textLarge}>
                    {parseFloat(host.latencia * 1000 || 0).toFixed(2)}
                  </Typography>
                  <Typography color="primary" mb={"6px"}>
                    ms
                  </Typography>
                </Stack>
                <Typography sx={styles.textMedium}>Latência</Typography>
              </Stack>
              <Stack alignItems={"center"}>
                <Stack direction={"row"} sx={{ alignItems: "flex-end" }}>
                  <Typography sx={styles.textLarge}>
                    {parseFloat(host?.perdaPing || 0).toFixed(2)}
                  </Typography>
                  <Typography color="primary" mb={"6px"}>
                    %
                  </Typography>
                </Stack>{" "}
                <Typography sx={styles.textMedium}>Perda de ping</Typography>
              </Stack>
            </Stack>
          </Paper>
          {host.itens?.length != 0 && (
            <Accordion
              key={key + "outrosItenshost"}
              disableGutters
              sx={{
                backgroundColor: theme.palette.background.paperLinkedMaps,
                boxShadow: "none",
                "&::before": {
                  display: "none",
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={styles.textHeaderAco}>Itens do host</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {host?.itens?.map((item, index) => (
                  <Fragment key={index + item.name}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent={"space-between"}
                      width="100%"
                    >
                      <Typography
                        sx={styles.textAlerta}
                        fontSize={"0.7rem"}
                        width="170px"
                      >
                        {item.name}
                      </Typography>

                      <Stack direction={"row"} gap={"2px"}>
                        <Typography
                          textAlign={"end"}
                          fontSize={isNaN(item?.lastvalue) ? "0.7rem" : null}
                        >
                          {!isNaN(item.lastvalue)
                            ? convertBitsToBytesUniversal(
                                parseFloat(item?.lastvalue).toFixed(2),
                                item.units
                              ).valor
                            : item?.lastvalue}
                        </Typography>
                        {!isNaN(item?.lastvalue) && (
                          <Typography
                            color="primary"
                            variant="small"
                            mt={"4px"}
                          >
                            {
                              convertBitsToBytesUniversal(
                                parseFloat(item?.lastvalue).toFixed(2),
                                item.units
                              ).unidade
                            }
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                    <Divider sx={{ mt: 1, mb: 1 }} />
                  </Fragment>
                ))}
              </AccordionDetails>
            </Accordion>
          )}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}
