import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";

export default function ModalMap({
  setOpen,
  open,
  type = "",
  api,
  selectMapRef,
  addMapInMaps,
  setSnackbar,
}) {
  const theme = useTheme();
  const [titulo, setTitulo] = useState("");
  const [buttonConfirm, setButtonConfirm] = useState(false);
  const [tipoMapa, setTipoMapa] = useState(0);
  const handleClose = () => {
    setOpen(false);
    setTitulo("");
  };
  const clearDialog = () => {
    setTipoMapa(0);
    setTitulo("");
  };
  async function handleApiCreateMap() {
    try {
      setButtonConfirm(true);
      const tipo = type == "POP" ? 2 : tipoMapa;
      const response = await api.post("/easyMaps/map/create", {
        titulo,
        tipo,
        logsLoginClienteId: JSON.parse(localStorage.getItem("logs"))?.id || "",
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível criar mapa",
          severity: "error",
        });
      } else {
        if (response.data) {
          const map = response.data;
          addMapInMaps(map);
          selectMapRef(map);
        }
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.status === 400) {
        setSnackbar({
          children: "Error: Mapa já existe",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: `Error:${
            error?.message || "Não foi possível se conectar com o servidor"
          } `,
          severity: "error",
        });
      }
    } finally {
      setButtonConfirm(false);
      setOpen(false);
      clearDialog();
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleApiCreateMap();
    }
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        {type === "POP" ? (
          <DialogTitle>Adição de POP</DialogTitle>
        ) : (
          <DialogTitle>Adição de mapa</DialogTitle>
        )}
        <DialogContent>
          <Stack
            gap={1}
            sx={{ padding: "5px 0px" }}
            alignItems="flex-start"
            justifyContent="center"
            width="300px"
          >
            <Typography>Título</Typography>
            <TextField
              size="small"
              sx={{ width: "100%" }}
              onKeyDown={handleKeyDown}
              label={
                type === "POP" ? "Título do novo POP" : "Título do novo mapa"
              }
              value={titulo}
              autoComplete="off"
              onChange={(e) => {
                setTitulo(e.target.value);
              }}
            />
            {type != "POP" && (
              <FormControl>
                <Typography> Tipo de mapa</Typography>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={0}
                  value={tipoMapa}
                  name="radio-buttons-group"
                  onChange={(event) => setTipoMapa(event.target.value)}
                >
                  <FormControlLabel
                    value={0}
                    control={<Radio />}
                    label="Mapa de rede geolocalizado"
                  />
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label="Mapa de topologia rede"
                  />
                </RadioGroup>
              </FormControl>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            onClick={handleApiCreateMap}
            disabled={buttonConfirm}
            sx={{ color: theme.palette.color.text }}
          >
            Adicionar
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
