import { Box, Button, Grid, Paper, TextField } from "@mui/material";
import { Add } from "@mui/icons-material";
import RelatorioCard from "./relatoriosCard";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../../../services/api";
import AlertModal from "../../../componentes/alertModal";
import SnackBar from "../../../componentes/snackbar";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    gap: "20px",
    mt: "20px"
  },
  paper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px",
    width: "100%"
  }
};

export default function AgendarRelatorio(props) {
  const [pesquisar, setPesquisar] = useState("");
  const [relatorios, setRelatorios] = useState("");
  const [snackbar, setSnackbar] = useState(null);
  const [alertmodal, setAlertModal] = useState(null);
  const [openNovoAgendamentoModal, setOpenNovoAgendamentoModal] =
    useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const relatoriosFiltred = relatorios
    ? relatorios.filter((relatorio) => relatorio.titulo.includes(pesquisar))
    : null;

  useEffect(() => {
    handleApiGetRelatorios();
  }, []);

  useEffect(() => {
    if (location.state?.successModal === true) {
      navigate(".", { state: { successModal: false } });
      setOpenNovoAgendamentoModal(true);
      setAlertModal({
        severity: "success",
        textContent: "Relatório agendado com sucesso.",
        singleButton: true
      });
    }
    if (location.state?.infoModal === true) {
      navigate(".", { state: { infoModal: false } });
      setOpenNovoAgendamentoModal(true);
      setAlertModal({
        severity: "info",
        textContent: "Relatório excluído com sucesso.",
        singleButton: true
      });
    }
  }, []);

  function manipulaArrayRelatorios(arrayRelatorios) {
    let arrayResult = {};
    arrayResult = arrayRelatorios.map(
      ({ dataInicial, dataFinal, HostEasyReport, ...rest }) => {
        return {
          dataInicial: new Date(dataInicial).toLocaleDateString(),
          dataFinal: new Date(dataFinal).toLocaleDateString(),
          hosts: HostEasyReport,
          ...rest
        };
      }
    );
    setRelatorios(arrayResult);
    //result.host = [{ id, nome, hostId, itens: [{ nome, itemId }] }];
  }
  async function handleApiGetRelatorios() {
    try {
      // setLoadingTable(true);

      const response = await api.get("/easyReport/relatorio/findMany", {});

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar dados",
          severity: "error"
        });
      } else {
        if (response.data) {
          //setItens(response.data);
          manipulaArrayRelatorios(response.data);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error"
      });
    } finally {
    }
  }
  return (
    <Box sx={styles.container}>
      <Paper sx={styles.paper}>
        <TextField
          label="pesquisar"
          onChange={(event) => {
            setPesquisar(event.target.value);
          }}
        />
        <Button
          variant="contained"
          onClick={() => {
            navigate("/easyReport/agendar/novoRelatorio");
          }}
          startIcon={<Add />}
        >
          NOVO RELATÓRIO
        </Button>
      </Paper>
      <Grid
        container
        rowSpacing={1}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        columns={{ xs: 12, md: 12, lg: 12, xl: 12, fh: 12, qhd: 12 }}
      >
        {relatoriosFiltred?.map((relatorio, index) => (
          <Grid
            item
            key={index}
            xs={12}
            sm={8}
            md={6}
            lg={4}
            xl={3}
            fh={3}
            qhd={2}
          >
            <RelatorioCard relatorio={relatorio} />
          </Grid>
        ))}
      </Grid>
      <AlertModal
        openModal={openNovoAgendamentoModal}
        setOpenModal={setOpenNovoAgendamentoModal}
        handleButton={() => {
          setOpenNovoAgendamentoModal(false);
        }}
        {...alertmodal}
      />
      <SnackBar snackbar={snackbar} setSnackbar={setSnackbar} />
    </Box>
  );
}
