import React, { useEffect, useState } from "react";

import {
  Avatar,
  Box,
  CssBaseline,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  useTheme,
  CircularProgress,
  Stack,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Circle, MarkEmailReadOutlined, History } from "@mui/icons-material";
import api from "../../../services/api";

function Notificacoes(params) {
  const {
    notificacoes,
    handleNovaNotificacao,
    anchorNotification,
    setAnchorNotification,
    loadingNotifications,
    updateVistoNotificacao,
    updateManyVistoNotificacao,
    socket,
  } = params;
  const navigate = useNavigate();
  const maxCharLength = 80;
  const theme = useTheme();
  const colorMode = theme.palette.mode;
  const [novaNotificacao, setNovaNotificacao] = useState("");
  const openNotifications = Boolean(anchorNotification);

  const styles = {
    logo: { width: "260px", height: "auto" },
    history: {
      opacity: "50%",
      ml: 1,
    },
    demandaText: {
      fontSize: "0.9rem",
      mt: "8px",
    },
    textoNotificacao: {
      marginLeft: "5px",
      maxWidth: "400px",
      wordWrap: "break-word",
      whiteSpace: "normal",
      wordBreak: "break-all",
    },
    menuItem: {
      margin: "0px 10px",
      borderRadius: 3,
      display: "flex",
      justifyContent: "start",
      bgcolor: "background.paperNotification",
      padding: "15px 20px",
    },
    title: {
      fontSize: "1.25rem",
      fontWeight: "400",
      paddingLeft: "27px",
      marginTop: "1px",
    },
    menu: {
      marginLeft: "-100px",
      ".MuiMenu-list": {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      },
      ".MuiMenu-paper": {
        borderRadius: 3,
        //bgcolor: colorMode === "dark" && "#141617",
      },
      ".MuiMenu-paper::-webkit-scrollbar": {
        width: "0.7em",
        display: "block",
        overflow: "auto",
        borderRadius: "30px",
        height: "800px",
      },
      ".MuiMenu-paper::-webkit-scrollbar-track": {
        paddingTop: "20px",
      },
      ".MuiMenu-paper::-webkit-scrollbar-thumb": {
        background: colorMode === "dark" ? "#464B4F" : "#dbdbdb",
        maxHeight: "10px",
        overflow: "hidden",
        borderRadius: 3,
      },
      ".MuiMenu-paper::-webkit-scrollbar-thumb:hover": {
        background: colorMode === "dark" ? "#373737" : "#cccccc",
      },
    },
  };

  useEffect(() => {
    function notificationEvent(data) {
      setNovaNotificacao(data.dados);
      socket.emit("mensagemRecebidaConfirmacao");
    }
    socket.on(`notificacao`, notificationEvent);

    return () => {
      socket.off(`notificacao`, notificationEvent);
    };
  }, [socket]);

  useEffect(() => {
    if (novaNotificacao) {
      handleNovaNotificacao(novaNotificacao);
    }
  }, [novaNotificacao]);

  const RenderHTML = (props) => (
    <Typography
      dangerouslySetInnerHTML={{ __html: props.html }}
      id="myParagraph"
      sx={[
        {
          mt: "-16px",
          ml: "5px",
          fontSize: "1rem",
          fontWeight: "400",
          textAlign: "justify",
          textOverflow: "ellipsis",
          overflow: "hidden",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
        },
        {},
      ]}
    ></Typography>
  );

  async function handleApiUpdateNotificacao(notificacaoId) {
    try {
      // setLoadingNotifications(true);
      const response = await api.put("/notificacoes/update", {
        notificacaoId,
        visto: true,
      });
      if (response.data.status === "Error") {
        // setLoadingNotifications(false);
      } else {
        // setLoadingNotifications(false);
        if (response.data) {
          updateVistoNotificacao(
            response.data.visto,
            response.data.notificacaoId
          );
        }
      }
    } catch (error) {
      console.error(error);
      // setLoadingNotifications(false);
    }
  }
  async function handleApiUpdateManyNotificacao() {
    try {
      // setLoadingNotifications(true);
      const response = await api.put("/notificacoes/updateMany", {
        visto: true,
      });
      if (response.data.status === "Error") {
        // setLoadingNotifications(false);
      } else {
        // setLoadingNotifications(false);
        if (response.data) {
          updateManyVistoNotificacao(true);
        }
      }
    } catch (error) {
      console.error(error);
      // setLoadingNotifications(false);
    }
  }
  return (
    <Box display={"flex"}>
      <CssBaseline />
      {loadingNotifications && (
        <CircularProgress
          size={100}
          sx={styles.CircularProgress}
          thickness={2.5}
        />
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorNotification}
        open={openNotifications}
        onClose={() => {
          setAnchorNotification(null);
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        disableScrollLock={true}
        elevation={colorMode === "dark" ? 0 : 3}
        sx={styles.menu}
      >
        {" "}
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          marginBottom={"-8px"}
          paddingRight={"10px"}
        >
          {" "}
          <Typography variant="h6" sx={styles.title}>
            Notificações
          </Typography>{" "}
          <Stack sx={{ paddingBottom: 1 }}>
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                handleApiUpdateManyNotificacao();
              }}
              startIcon={<MarkEmailReadOutlined />}
            >
              Visualizar todos
            </Button>
          </Stack>
          {/*<Tooltip
            title="Marcar tudo como lido"
            onClick={() => {
              handleApiUpdateManyNotificacao();
            }}
          >
            <IconButton sx={{ marginX: "10px" }}>
              {" "}
              <MarkEmailReadOutlined />
            </IconButton>
          </Tooltip>*/}
        </Stack>
        {notificacoes?.length === 0 && (
          <MenuItem
            sx={styles.menuItem}
            onClick={() => {
              setAnchorNotification(null);
            }}
          >
            <ListItemText>
              <Typography
                color={colorMode === "dark" ? "#dad7d7" : "#9C9C9C"}
                fontWeight="500"
                sx={{
                  wordWrap: "break-word",
                  display: "flex",
                  width: "400px",
                }}
              >
                Sem notificacões no momento
              </Typography>
            </ListItemText>
          </MenuItem>
        )}
        {notificacoes.length > 0 &&
          notificacoes?.map((notificacao, index) => {
            return (
              <MenuItem
                sx={styles.menuItem}
                key={index}
                onClick={() => {
                  setAnchorNotification(null);
                  handleApiUpdateNotificacao(notificacao.notificacaoId);
                  if (notificacao.tipo === 0) {
                    navigate(`/suporte`);
                  } else {
                    navigate(`/suporte/${notificacao.relatoriosClienteId}`);
                  }
                }}
              >
                <ListItemIcon sx={{ width: "10px", mr: "-15px" }}>
                  {!notificacao?.visto ? (
                    <>
                      <Circle
                        sx={{
                          width: "10px",
                          color: "#0e8edd",
                        }}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </ListItemIcon>
                <ListItemIcon>
                  <Avatar
                    sx={{ mr: 2 }}
                    src={
                      notificacao.usuarioCliente?.foto ||
                      notificacao.usuario?.foto
                    }
                  >
                    {notificacao.usuario?.nome?.trim().toUpperCase()[0] ||
                      notificacao.usuarioCliente?.nomeFantasia
                        ?.trim()
                        .toUpperCase()[0] ||
                      notificacao.usuarioCliente?.nome
                        ?.trim()
                        .toUpperCase()[0] ||
                      "U"}
                  </Avatar>
                </ListItemIcon>
                <ListItemText>
                  <Box
                    sx={{
                      margin: "8px",
                      width: "420px",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Typography component={"h4"} sx={styles.textoNotificacao}>
                      {" "}
                      <strong>
                        {(notificacao.usuario?.nome?.trim() ||
                          notificacao.usuarioCliente?.nomeFantasia?.trim() ||
                          notificacao.usuarioCliente?.nome?.trim() ||
                          "Usuário sem nome") + " "}
                      </strong>
                      {(notificacao.acao === 0
                        ? "criou "
                        : notificacao.acao === 1
                        ? "editou "
                        : notificacao.acao === 2
                        ? "excluiu"
                        : notificacao.acao === 3 && notificacao.status === 5
                        ? "arquivou"
                        : notificacao.acao === 3 && notificacao.status === 0
                        ? "desarquivou"
                        : notificacao.acao === 4 && notificacao.fixar === true
                        ? "fixou"
                        : "desafixou") +
                        (notificacao.tipo === 0
                          ? " a demanda "
                          : notificacao.tipo === 1
                          ? " um novo tópico na demanda "
                          : notificacao.tipo === 2
                          ? " um comentário no tópico da demanda "
                          : "") +
                        (notificacao.descricao?.length > maxCharLength
                          ? `${notificacao.descricao.substring(
                              0,
                              maxCharLength
                            )}...`
                          : notificacao.descricao)}
                    </Typography>
                    {/*notificacao.tipo === 1 && (
                      <RenderHTML html={notificacao.descricao} />
                    )*/}
                  </Box>
                  <Stack direction={"row"} gap={"5px"}>
                    <History
                      fontSize="small"
                      sx={styles.history}
                      color={colorMode === "dark" ? "#dad7d7" : "#9C9C9C"}
                    />
                    <Typography
                      variant="caption"
                      fontWeight="500"
                      fontSize="14px"
                      color={colorMode === "dark" ? "#dad7d7" : "#9C9C9C"}
                    >
                      {notificacao.tempo}
                    </Typography>
                  </Stack>
                </ListItemText>{" "}
              </MenuItem>
            );
          })}
      </Menu>
    </Box>
  );
}

export default Notificacoes;
