import {
  DialogContent,
  DialogTitle,
  Modal,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";

const styles = {
  filter: { width: "300px" },
  textFiled: { width: "180px" },
  comunidade: { width: "375px", mt: 0.3 },
  porta: { width: "82px" },
};

export default function CopyModal(props) {
  const {
    openCopyModal,
    setOpenCopyModal,
    Cliente,
    acessoClientesId,
    acessoProdutoId,
  } = props;

  const [fabricante, setFabricante] = useState("fabricante teste");
  const [funcao, setFuncao] = useState("funcao teste");
  const [modelo, setModelo] = useState("modelo teste");
  const [userSsh, setUserSsh] = useState("");
  const [portaSsh, setPortaSsh] = useState(22);
  const [senhaSsh, setSenhaSsh] = useState("");
  const [userTelnet, setUserTelnet] = useState("");
  const [portaTelnet, setPortaTelnet] = useState(23);
  const [senhaTelnet, setSenhaTelnet] = useState("");

  return (
    <Modal
      open={openCopyModal}
      onClose={() => {
        setOpenCopyModal(false);
      }}
      sx={{ alignItems: "center", justifyContent: "center", display: "flex" }}
    >
      <Paper>
        <DialogTitle color="primary">Preencha os dados</DialogTitle>
        <DialogContent>
          <Stack gap={"12px"} direction={"column"}>
            <Stack gap={"16px"} direction={"row"}>
              <TextField
                id="fabricante-host"
                variant="standard"
                label="Fabricante"
                value={fabricante}
                disabled
                sx={styles.textFiled}
              />
              <TextField
                id="funcoes-host"
                variant="standard"
                label="Funções"
                value={funcao}
                disabled
                sx={styles.textFiled}
              />
              <TextField
                id="Modelo-host"
                variant="standard"
                label="Modelo"
                value={modelo}
                disabled
                sx={styles.textFiled}
              />
            </Stack>
            <Stack gap={"16px"} direction={"row"}>
              <TextField
                id="fabricante-host"
                variant="standard"
                label="Fabricante"
                value={fabricante}
                disabled
                sx={styles.textFiled}
              />
              <TextField
                id="funcoes-host"
                variant="standard"
                label="Funções"
                value={funcao}
                disabled
                sx={styles.textFiled}
              />
              <TextField
                id="Modelo-host"
                variant="standard"
                label="Modelo"
                value={modelo}
                disabled
                sx={styles.textFiled}
              />
            </Stack>
            <Typography color={"primary"} fontWeight={500} mt={2}>
              Acesso SSH
            </Typography>
            <Stack gap={"16px"} direction={"row"}>
              <TextField
                id="host-user"
                variant="standard"
                label="User SSH"
                value={userSsh}
                onChange={(event) => {
                  setUserSsh(event.target.value);
                }}
              />
              <TextField
                id="host-portaAcesso"
                variant="standard"
                label="Porta SSH"
                type="number"
                value={portaSsh}
                onChange={(event) => {
                  setPortaSsh(event.target.value);
                }}
              />
              <TextField
                id="host-senha"
                variant="standard"
                label="Senha SSH"
                value={senhaSsh}
                onChange={(event) => {
                  setSenhaSsh(event.target.value);
                }}
              />
            </Stack>
            <Typography color={"primary"} fontWeight={500} mt={2}>
              Acesso TELNET
            </Typography>
            <Stack gap={"16px"} direction={"row"}>
              <TextField
                id="host-user"
                variant="standard"
                label="User TELNET"
                value={userTelnet}
                onChange={(event) => {
                  setUserTelnet(event.target.value);
                }}
              />
              <TextField
                id="host-portaAcesso"
                variant="standard"
                label="Porta TELNET"
                type="number"
                value={portaTelnet}
                onChange={(event) => {
                  setPortaTelnet(event.target.value);
                }}
              />
              <TextField
                id="host-senha"
                variant="standard"
                label="Senha TELNET"
                value={senhaTelnet}
                onChange={(event) => {
                  setSenhaTelnet(event.target.value);
                }}
              />
            </Stack>
          </Stack>
        </DialogContent>
      </Paper>
    </Modal>
  );
}
