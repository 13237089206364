import { Text, View } from "@react-pdf/renderer";
import { styles } from "../styles";
import { convertDateStr, convertTimeStr } from "../../utils";

export function TableAvailability({ downtimeHistory, headerTitles }) {
  return (
    <View>
      {Array.isArray(downtimeHistory) && downtimeHistory.length > 0 ? (
        <View style={styles.table}>
          <View style={styles.tableRow}>
            {headerTitles.map((value, index) => (
              <View key={"table-cell" + index} style={styles.tableCell}>
                <Text> {value}</Text>
              </View>
            ))}
          </View>
          {downtimeHistory.map(
            ({ start, end, percent, interval }, indexRow) => (
              <View key={"table-row" + indexRow} style={styles.tableRow}>
                <View style={styles.tableCell}>
                  <Text>
                    {convertDateStr(start) + " " + convertTimeStr(start)}
                  </Text>
                </View>
                <View style={styles.tableCell}>
                  <Text>{convertDateStr(end) + " " + convertTimeStr(end)}</Text>
                </View>
                <View style={styles.tableCell}>
                  <Text>{percent + "%"}</Text>
                </View>
                <View style={styles.tableCell}>
                  <Text>{interval}</Text>
                </View>
              </View>
            )
          )}
        </View>
      ) : null}
    </View>
  );
}
