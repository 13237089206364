import { StyleSheet } from "@react-pdf/renderer";
export const styles = StyleSheet.create({
  body: {
    paddingTop: 30,
    paddingBottom: 65,
    paddingHorizontal: 45,
  },
  title: {
    fontSize: 14,
    textAlign: "left",
    fontFamily: "Times-Bold",
    color: "#5B2E87",
    marginVertical: 10,
  },
  subtitle: {
    fontSize: 12,
    textAlign: "left",
    color: "#5B2E87",
    fontFamily: "Times-Bold",
    marginVertical: 10,
  },
  text: {
    fontSize: 12,
    textAlign: "justify",
    fontFamily: "Times-Roman",
    lineHeight: 1.5,
  },
  textBold: {
    fontSize: 12,
    textAlign: "justify",
    fontFamily: "Times-Bold",
    lineHeight: 1.5,
  },
  legend: {
    fontSize: 10,
    fontFamily: "Times-Roman",
    maxWidth: "90%",
  },
  legendBold: {
    fontSize: 10,
    fontFamily: "Times-Bold",
  },
  logo: {
    width: "150px",
    height: "60px",
    objectFit: "cover",
  },
  header: {
    fontSize: 12,
    textAlign: "left",
    fontFamily: "Times-Roman",
  },
  pageNumber: {
    fontSize: 10,
    left: 0,
    textAlign: "right",
  },
  footerText: {
    fontSize: 8,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontFamily: "Times-Roman",
  },
  footer: {
    width: "100%",
    bottom: 40,
    left: 45,
    position: "absolute",
  },
  footerLine: {
    width: "100%",
    height: "1px",
    marginVertical: "5px",
  },
  //excluir line se nao funcionar
  line: {
    lineWidth: 1, // Largura da linha
    color: "#000000", // Cor da linha (no formato hexadecimal)
    end: { x: 500, y: 50 }, // Coordenadas do final da linha (ajuste conforme necessário)
    start: { x: 50, y: 50 },
  },
  table: {
    fontSize: 10,
    borderTop: 3,
    borderColor: "#5B2E87",
    display: "table",
    width: "auto",
    marginVertical: 10,
    fontFamily: "Times-Roman",
    gap: -1,
  },
  tableRow: {
    flexDirection: "row",
    gap: -1,
  },
  textBoldTable: {
    fontFamily: "Times-Bold",
  },
  textTable: {
    fontFamily: "Times-Roman",
  },
  tableCell: {
    width: "25%",
    borderWidth: 0.5,
    paddingVertical: 10,
    paddingHorizontal: 5,
    borderColor: "#d4d4dc",
    backgroundColor: "#F7F7F8",
  },
  alingRowCenter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  iconContato: {
    filter:
      "invert(21%) sepia(12%) saturate(7089%) hue-rotate(246deg) brightness(98%) contrast(95%)",
    height: "23px",
    width: "23px",
    marginRight: "5px",
  },
});
