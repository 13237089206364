import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt-br";

const styles = {
  widthInput: {
    width: "200px",
  },
  terminaEm: { width: "120px", zIndex: 1 },
  helperText: {
    fontSize: "0.75rem",
    opacity: 0.7,
    marginLeft: -1.3,
    fontWeight: 600,
  },
  inputAdornment: { position: "absolute", right: 52, zIndex: -1 },
  formControl: { gap: 1, mt: 0 },
  formLabel: { fontWeight: 800, color: "primary.main", mb: 1 },
  radioGroup: { gap: 1 },
  formControlLabel: { width: "80px" },
};

export default function Agendamento(props) {
  const {
    dataInicial,
    setDataInicial,
    periodoColeta,
    setPeriodoColeta,
    terminaEm,
    setTerminaEm,
    etapas,
    setEtapas,
    dataFinal,
    setDataFinal,
    agendarRelatorio = false,
  } = props;

  return (
    <Stack direction={"column"} mt={1}>
      {agendarRelatorio === true ? (
        <>
          <Stack direction={"row"} gap={2} minHeight={"62px"}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={"pt-br"}
            >
              <DateTimePicker
                label="Data inicial"
                value={dayjs(dataInicial)}
                minDate={dayjs(
                  dayjs().subtract(60, "day").format("YYYY-MM-DD")
                )}
                maxDate={dayjs(dayjs().format("YYYY-MM-DD"))}
                onChange={(newValue) => setDataInicial(dayjs(newValue))}
                // renderInput={(params) => (
                //   <TextField size={"small"} {...params} />
                // )}
                sx={styles.widthInput}
              />
            </LocalizationProvider>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={"pt-br"}
            >
              <DateTimePicker
                label="Data Final"
                value={dayjs(dataFinal)}
                minDate={dayjs(
                  dayjs().subtract(60, "day").format("YYYY-MM-DD")
                )}
                maxDate={dayjs(dayjs().format("YYYY-MM-DD"))}
                onChange={(newValue) => setDataFinal(dayjs(newValue))}
                // renderInput={(params) => (
                //   <TextField
                //     size={"small"}
                //     {...params}
                //     sx={styles.widthInput}
                //   />
                // )}
              />
            </LocalizationProvider>
          </Stack>
        </>
      ) : (
        <>
          <Stack direction={"row"} gap={2} minHeight={"62px"}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={"pt-br"}
            >
              <DateTimePicker
                label="Data inicial"
                slotProps={{ textField: { size: "small" } }}
                value={dayjs(dataInicial)}
                minDate={dayjs(
                  dayjs().subtract(60, "day").format("YYYY-MM-DD")
                )}
                maxDate={dayjs(
                  dayjs().add(1, "year").add(1, "day").format("YYYY-MM-DD")
                )}
                onChange={(newValue) => setDataInicial(dayjs(newValue))}
                // renderInput={(params) => (
                //   <TextField size={"small"} {...params} />
                // )}
                sx={styles.widthInput}
              />
            </LocalizationProvider>
            <FormControl sx={styles.widthInput} size="small">
              <InputLabel id="repetir">Repetir</InputLabel>
              <Select
                value={periodoColeta?.repetir}
                labelId="repetir"
                label="Repetir"
                onChange={(event) => {
                  let newPeriodo = { ...periodoColeta };
                  newPeriodo.repetir = event.target.value;
                  setPeriodoColeta(newPeriodo);
                }}
              >
                <MenuItem value={"mensalmente"}>Mensalmente</MenuItem>
                <MenuItem value={"semanalmente"}>Semanalmente</MenuItem>
                <MenuItem value={"personalizado"}>Personalizado</MenuItem>
              </Select>
            </FormControl>

            {periodoColeta?.repetir === "personalizado" && (
              <TextField
                label={"Período"}
                size="small"
                autoComplete="off"
                type="number"
                value={periodoColeta?.periodo}
                onChange={(event) => {
                  let newPeriodo = { ...periodoColeta };

                  if (event.target.value > 60 && event.target.value !== "") {
                    newPeriodo.periodo = 60;
                  } else if (
                    event.target.value <= 0 &&
                    event.target.value !== ""
                  ) {
                    newPeriodo.periodo = "";
                  } else {
                    newPeriodo.periodo = event.target.value;
                  }
                  setPeriodoColeta(newPeriodo);
                }}
                //InputLabelProps={{ shrink: true }}
                sx={styles.terminaEm}
                helperText={
                  <Typography sx={styles.helperText}>máximo 60 dias</Typography>
                }
                slotProps={{
                  inputLabel: { shrink: true },
                  input: {
                    endAdornment: (
                      <InputAdornment position="end" sx={styles.inputAdornment}>
                        {periodoColeta?.periodo ? "dias" : ""}
                      </InputAdornment>
                    ),
                  },
                }}
              />
            )}
          </Stack>

          <FormControl sx={styles.formControl}>
            <FormLabel sx={styles.formLabel}>Termina em</FormLabel>
            <RadioGroup
              sx={styles.radioGroup}
              value={terminaEm}
              onChange={(event) => {
                setTerminaEm(event.target.value);
              }}
            >
              <Stack direction={"row"}>
                <FormControlLabel
                  value={0}
                  control={<Radio />}
                  label="Após"
                  sx={styles.formControlLabel}
                />
                <TextField
                  label={"Ocorrências"}
                  size="small"
                  autoComplete="off"
                  type="number"
                  value={etapas}
                  onChange={(event) => {
                    if (event.target.value > 12 && event.target.value !== "") {
                      setEtapas(12);
                    } else if (
                      event.target.value <= 0 &&
                      event.target.value !== ""
                    ) {
                      setEtapas("");
                    } else {
                      setEtapas(event.target.value);
                    }
                  }}
                  //InputLabelProps={{ shrink: true }}
                  sx={{ ...styles.widthInput, zIndex: 1 }}
                  helperText={
                    <Typography
                      sx={{
                        fontSize: "0.75rem",
                        opacity: 0.7,
                        marginLeft: -1.3,
                        fontWeight: 600,
                      }}
                    >
                      máximo 12 ocorrências
                    </Typography>
                  }
                  slotProps={{
                    inputLabel: { shrink: true },
                    input: {
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{ position: "absolute", right: 52, zIndex: -1 }}
                        >
                          {etapas ? "ocorrências" : ""}
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              </Stack>
              <Stack direction={"row"}>
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label="Em"
                  sx={styles.formControlLabel}
                />
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={"pt-br"}
                >
                  <DatePicker
                    slotProps={{ textField: { size: "small" } }}
                    sx={styles.widthInput}
                    value={dayjs(dataFinal)}
                    minDate={dayjs(
                      dayjs().subtract(30, "day").format("YYYY-MM-DD")
                    )}
                    maxDate={dayjs(
                      dayjs().add(1, "year").add(1, "day").format("YYYY-MM-DD")
                    )}
                    onChange={(newValue) => setDataFinal(dayjs(newValue))}
                    // renderInput={(params) => (
                    //   <TextField
                    //     size={"small"}
                    //     {...params}
                    //     sx={styles.widthInput}
                    //   />
                    // )}
                  />
                </LocalizationProvider>
              </Stack>
            </RadioGroup>
          </FormControl>
        </>
      )}
    </Stack>
  );
}
