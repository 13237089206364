import { useEffect } from "react";
import { useReactFlow, useViewport } from "reactflow";

export default function ZoomFlow({ focusNodeId, setFocusNodeId, id }) {
  const { zoomIn, zoomOut, fitView, getNodes } = useReactFlow();
  const nodes = getNodes();
  useEffect(() => {
    fitView({ nodes: [nodes] });
  }, [id]);
  useEffect(() => {
    const filter = nodes.find((node) => (node.id = focusNodeId));
    //console.log("filter", filter, focusNodeId);
    if (focusNodeId && filter) {
      fitView({ nodes: [{ id: focusNodeId }], duration: 1000, maxZoom: 3 });
      setFocusNodeId(null);
    }
  }, [fitView, focusNodeId, nodes]);

  return <div styles={{ display: "none" }}></div>;
}
