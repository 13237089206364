import { useState, useEffect } from "react";
import {
  Modal,
  Paper,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Autocomplete,
  TextField,
  Checkbox,
  Fade,
  LinearProgress,
} from "@mui/material";

import api from "../../../../../services/api";
import AlertModal from "../../../../../componentes/alertModal";
import { CheckBoxOutlineBlank, CheckBoxOutlined } from "@mui/icons-material";

const stylesModal = {
  paper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: 3,
    padding: "10px",
  },
  campoFiltro: {
    width: "200px",
  },
  campoFiltroSecondary: {
    width: "420px",
  },
  DialogContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "20px",
  },
  button: { height: "40px", minWidth: "120px" },
};

export default function ModalEditaItens(props) {
  const {
    openEditModal,
    setOpenEditModal,
    selectedRow,
    setUpdateRow,
    setSnackbar,
    itensCadastrados,
  } = props;
  const [itens, setItens] = useState([]);
  const [itensOptions, setItensOptions] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [host, setHost] = useState([]);
  const [confirmarEdicao, setConfirmarEdicao] = useState(false);
  const [hostId, setHostId] = useState("");
  const [loadingItens, setLoadingItens] = useState(false);
  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const checkedIcon = <CheckBoxOutlined fontSize="small" />;
  //console.log("itens", !!itens[0], typeof itensCadastrados);

  useEffect(() => {
    if (selectedRow) {
      // setItens(
      //   selectedRow?.itens.map(({ itemId, item, key_, configuracoes }) => {
      //     return { itemId, item, configuracoes, key_ };
      //   })
      // );
      setItens(selectedRow?.itens?.filter((item) => item));
      setHostId(selectedRow.hostId);
      setHost(selectedRow);
      handleApiGetItens(selectedRow.hostId);
    }
  }, [selectedRow]);
  useEffect(() => {
    // setItens(
    //   itensCadastrados.map(({ itemId, item, key_, configuracoes }) => {
    //     return { itemId, item, configuracoes, key_ };
    //   })
    // );
    //setItens(...itensCadastrados);
  }, [itensCadastrados]);
  const onCloseModal = () => {
    setOpenEditModal(false);
  };
  const onChange = (event, newValue) => {
    setItens(
      newValue.map((item) => {
        if (!item.configuracoes) {
          return {
            ...item,
            configuracoes: {
              variacaoAbrupta: { status: false },
              tarifacao: { status: true, valor: 0 },
              tarifacaoExcedente: {
                status: false,
                valorExcedente: 0,
                limite: 0,
                valor: 0,
              },
              horarioPersonalizado: { status: false },
              valorMinimo: false,
              valorMaximo: false,
              valorMedio: false,
              disponibilidade: false,
            },
          };
        }
        return item;
      })
    );
  };
  const getOptionLabel = (option) => {
    return option?.item;
  };

  const renderOption = (props, option, { selected }) => (
    <li {...props}>
      <Checkbox
        icon={icon}
        checkedIcon={checkedIcon}
        style={{ marginRight: 8 }}
        checked={selected}
      />
      {option?.item}
    </li>
  );
  const onClickButtonCancel = () => {
    setOpenEditModal(false);
  };
  const onClickButtonConfirm = () => {
    setOpenEditDialog(true);
  };
  async function handleApiGetItens(hostId) {
    try {
      setLoadingItens(true);

      const response = await api.post("/easyReport/Items/find", { hostId });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar dados",
          severity: "error",
        });
      } else {
        if (response.data && Array.isArray(response.data)) {
          let teste = [
            ...response.data.map((item) => {
              return {
                itemId: item.itemid,
                item: item.nome,
                name: item.nome,
                ...item,
              };
            }),
            ...itensCadastrados,
          ];
          setItensOptions(
            Array.from(new Map(teste.map((item) => [item.key_, item])).values())
          );
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoadingItens(false);
    }
  }
  async function handleApiPutHostsItens() {
    try {
      // setLoadingTable(true);
      setConfirmarEdicao(true);
      const response = await api.put("/easyReport/hosts/update", {
        relatoriosEasyReportId: "a2b2efa2-a5f4-4ce0-b890-60cb702c5a48",
        hosts: [
          {
            id: host.id,
            nome: host.nome,
            hostId: host.hostId,
            itens,
          },
        ],
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar dados",
          severity: "error",
        });
      } else {
        if (response.data) {
          //setItens(response.data);
          setUpdateRow(
            Array.isArray(response.data) ? response.data[0] : response.data
          );
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setConfirmarEdicao(false);
      setOpenEditModal(false);
      setOpenEditDialog(false);
    }
  }
  return (
    <div>
      <Modal open={openEditModal} onClose={onCloseModal}>
        <Paper sx={stylesModal.paper} elevation={3}>
          <DialogTitle color="primary">{selectedRow.nome}</DialogTitle>{" "}
          <DialogTitle variant="subtitle2" marginTop={"-20px"}>
            {"Editar Itens"}
          </DialogTitle>
          <DialogContent sx={stylesModal.DialogContent}>
            <Autocomplete
              disableCloseOnSelect
              multiple
              disabled={loadingItens}
              id="templates-autocomplete"
              isOptionEqualToValue={(option, value) =>
                option.itemId === value.itemId
              }
              value={itens}
              defaultValue={[]}
              onChange={onChange}
              getOptionLabel={getOptionLabel}
              sx={stylesModal.filter}
              options={itensOptions}
              //renderOption={renderOption}
              style={{ width: 500 }}
              renderInput={(params) => (
                <>
                  <TextField {...params} label="Itens" variant="standard" />
                  <Fade in={loadingItens}>
                    <LinearProgress />
                  </Fade>
                </>
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={onClickButtonCancel}>
              CANCELAR
            </Button>{" "}
            <Button onClick={onClickButtonConfirm} disabled={loadingItens}>
              EDITAR
            </Button>
          </DialogActions>
        </Paper>
      </Modal>{" "}
      <AlertModal
        openModal={openEditDialog}
        setOpenModal={setOpenEditDialog}
        confirmar={confirmarEdicao}
        handleButton={handleApiPutHostsItens}
        severity={"warning"}
        singleButton={false}
        textContent={
          "Se prosseguir com a ação os itens serão editados permantemente, e não será possível voltar ao estado anterior!"
        }
      />
    </div>
  );
}
