import { Alert, Box, Paper, Snackbar, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import Router from "../relatoriosFlow/router";
import Interfaces from "./interfaces";
import Blocos from "./blocos";
import ASNs from "./asn";
import CDNs from "./cdn";
import Ports from "./port";

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    gap: "2rem",
    marginTop: "30px",
  },
  paperCliente: {
    borderRadius: 1,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "20px",
    minWidth: "70vw",
    flexDirection: "column",
  },
};

export default function RelatoriosFlow() {
  const [value, setValue] = useState(0);
  const [snackbar, setSnackbar] = useState(null);

  return (
    <>
      <Box sx={styles.container}>
        <Paper
          sx={{
            borderRadius: "15px",
            padding: "0px 10px",
          }}
          elevation={3}
        >
          <Tabs
            variant="scrollable"
            scrollButtons={true}
            value={value}
            sx={styles.tabs}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
          >
            <Tab
              label={"Router"}
              iconPosition="start"
              value={0}
              sx={styles.tab}
            />
            <Tab
              label={"Interfaces"}
              iconPosition="start"
              value={1}
              sx={styles.tab}
            />
            <Tab
              label={"Blocos"}
              iconPosition="start"
              value={2}
              sx={styles.tab}
            />
            <Tab
              label={"ASNs"}
              iconPosition="start"
              value={3}
              sx={styles.tab}
            />
            <Tab
              label={"CDNs"}
              iconPosition="start"
              value={4}
              sx={styles.tab}
            />
            <Tab
              label={"Ports"}
              iconPosition="start"
              value={5}
              sx={styles.tab}
            />
          </Tabs>
        </Paper>

        {value === 0 && <Router key={1} />}
        {value === 1 && <Interfaces key={2} />}
        {value === 2 && <Blocos key={3} />}
        {value === 3 && <ASNs key={4} />}
        {value === 4 && <CDNs key={5} />}
        {value === 5 && <Ports key={6} />}
      </Box>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}{" "}
    </>
  );
}
