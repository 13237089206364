import { icon } from "leaflet";

const useStyles = (theme) => ({
  mainContent: {
    flexDirection: "row",
    gap: 2,
  },
  cardImg: { bgcolor: theme?.palette?.mode === "dark" ? "#262626" : "#f9fafb" },
  cardAction: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "150px",
    width: "200px",
    gap: "10px",
  },
  icon: {
    fontSize: "48px",
  },
  boxAddIcon: {
    position: "relative",
    display: "flex",
  },
  addIcon: {
    position: "absolute",
    color: "white",
    backgroundColor: "primary.main",
    borderRadius: "100%",
    right: 0,
    bottom: 0,
    border: `2px solid ${theme.palette.mode === "dark" ? "#1E1E1E" : "#fff"}`,
    fontSize: "22px",
  },
  typography: {
    fontWeight: 500,
    width: "160px",
    textAlign: "center",
  },
  textfield: {
    width: "360px",
  },
  formControl: { gap: "8px", mt: "8px" },
  confirmButton: {
    color: theme?.palette?.color?.buttonConfirm || "primary.main",
  },
});

export default useStyles;
