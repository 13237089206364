import { useState } from "react";
import {
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  IconButton,
  Stack,
  Chip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { ExpandMore, UnarchiveOutlined } from "@mui/icons-material";
import api from "../../../services/api";
import { ptBR } from "@mui/x-data-grid/locales";
import { CustomPagination } from "../../../componentes/easyAdd/customPagination";
import { useTheme } from "@emotion/react";

const stylesImportCsv = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "30px",
    width: "100%",
  },
  paper: {
    width: "100%",
    minWidth: "900px",
    padding: "20px",
    gap: "20px",
    display: "flex",
    flexDirection: "column",
    borderRadius: 3,
    elevation: 3,
  },
  accordion: {
    width: "100%",
    // width: "84vw",
    // minWidth: "900px",
    borderRadius: 3,
  },
  button: { height: "40px", minWidth: "120px", m: 1 },
  filter: { width: "180px" },
  deleButton: { width: "100px", fontWeight: "800" },
  dangerousIcon: { fontSize: 60 },
  boxDialogs: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  },
  dialogText: { color: "#D32F2F" },
  porta: { width: "105px" },
  textField: { width: "230px" },
  titles: { fontWeight: 500, fontSize: "20px" },
};

export default function HostsArquivados(props) {
  const { setSnackbar, loading, hosts, setHosts } = props;
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const theme = useTheme();
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    id: false,
    senhaTelnet: false,
    portaTelnet: false,
    userTelnet: false,
    tipoSnmp: false,
    portaSnmp: false,
    comunidade: false,
    senhaSsh: false,
    userSsh: false,
    portaSsh: false,
  });

  function updateStateHost(ids) {
    let hostsUpdate = hosts
      .filter((host) => ids.includes(host.id))
      .map((host) => ({ ...host, status: 0 }));
    if (hostsUpdate.length == 0) return;
    handleApiUpdateHosts(hostsUpdate);
  }

  async function handleApiUpdateHosts(hosts) {
    try {
      setLoadingUpdate(true);
      const response = await api.put("/easyAdd/hosts/update", {
        hosts,
      });
      if (response.data) {
        const updateHosts = response?.data;

        setHosts((prevHosts) => {
          const hostMap = new Map(prevHosts.map((host) => [host.id, host]));
          updateHosts?.forEach((newHost) => {
            hostMap.set(newHost.id, newHost);
          });
          return Array.from(hostMap.values());
        });

        setSnackbar({
          children: "Hosts salvos com sucesso!",
          severity: "success",
        });
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error:${
          error?.message || "Error: Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    } finally {
      setLoadingUpdate(false);
    }
  }

  const columns = [
    { field: "id", headerName: "ID", width: 180, hide: true },
    {
      field: "updateAt",
      headerName: "TEMPO RESTANTE",
      headerAlign: "center",
      width: 140,
      editable: false,
      renderCell: (params) => {
        const lastUpdated = new Date(params.value).getTime();
        const now = Date.now();
        const interval =
          30 - parseInt((now - lastUpdated) / (60 * 60 * 24 * 1000));
        const bg =
          interval < 5
            ? theme.palette.chip.pending
            : interval < 15
            ? theme.palette.chip.inProgress
            : theme.palette.chip.warning;
        return (
          <Chip
            sx={{
              color: "white",
              width: "100px",
              height: "25px",
              backgroundColor: bg,
            }}
            label={interval + " dias"}
          />
        );
      },
    },
    {
      field: "nome",
      headerName: "NOME",
      flex: 1,
      //width: 175,
    },
    {
      field: "ip",
      headerName: "IP",
      width: 150,
    },
    {
      field: "portaSnmp",
      headerName: "PORTA SNMP",
      width: 80,
    },
    {
      field: "tipoSnmp",
      headerName: "TIPO SNMP",
      width: 85,
    },
    {
      field: "comunidade",
      headerName: "COMUNIDADE",
      width: 150,
    },
    {
      field: "fabricante",
      headerName: "FABRICANTE",
      editable: true,
      width: 150,
    },
    {
      field: "funcao",
      headerName: "FUNÇÃO",
      width: 150,
    },
    {
      field: "modelo",
      headerName: "MODELO",
      width: 150,
      hide: true,
    },
    {
      field: "coletor",
      headerName: "COLETOR",
      width: 150,
    },
    {
      field: "tipoSnmp",
      headerName: "SNMP",
      width: 70,
      type: "",
    },
    {
      field: "userSsh",
      headerName: "USER SSH",
      width: 100,
    },
    {
      field: "portaSsh",
      headerName: "PORTA SSH",
      width: 100,
    },
    {
      field: "senhaSsh",
      headerName: "SENHA SSH",
      width: 100,
    },
    {
      field: "userTelnet",
      headerName: "USER TELNET",
      width: 100,
    },
    {
      field: "portaTelnet",
      headerName: "PORTA TELNET",
      width: 110,
    },
    {
      field: "senhaTelnet",
      headerName: "SENHA TELNET",
      width: 110,
    },
  ];

  return (
    <>
      <Box sx={stylesImportCsv.container}>
        <Accordion
          sx={stylesImportCsv.accordion}
          square
          elevation={3}
          defaultExpanded={false}
        >
          <AccordionSummary
            aria-controls="previous-regsiter-content"
            id="previous-regsiter-header"
            expandIcon={<ExpandMore />}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              paddingRight="10px"
            >
              <Typography
                sx={[stylesImportCsv.titles, { color: "secondary.main" }]}
              >
                Lixeira
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  color: theme.palette.text.disabled,
                }}
              >
                Arquivos da lixeira serão excluidos após 30 dias
              </Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Stack
              sx={{
                height:
                  hosts.length > 19
                    ? "800px"
                    : hosts.length == 0
                    ? "250px"
                    : hosts.length * 36 + 94 + "px",
              }}
            >
              <DataGrid
                density="compact"
                loading={loading}
                editMode="row"
                sx={{
                  "& .MuiDataGrid-selectedRowCount": {
                    display: "none!important",
                  },
                  fontSize: 13,
                  "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                    outline: "none !important",
                  },
                  height: "100%",

                  ".MuiDataGrid-cell": {
                    display: "flex",
                    alignItems: "center",
                  },
                }}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                rows={hosts}
                columns={columns}
                pageSizeOptions={[25, 50, 100]}
                initialState={{
                  pagination: { paginationModel: { pageSize: 25 } },
                }}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setColumnVisibilityModel(newModel)
                }
                slots={{
                  pagination: CustomPagination,
                }}
                slotProps={{
                  pagination: {
                    updateStateHost,
                    rowSelectionModel,
                    lixeira: true,
                  },
                }}
                checkboxSelection
                // isRowSelectable={(params) => !editMode ? true : params.row?.newHost == true ? true : false}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setRowSelectionModel(newRowSelectionModel);
                }}
                rowSelectionModel={rowSelectionModel}
                disableColumnMenu
                disableColumnFilter
                disableSelectionOnClick
                disableRowSelectionOnClick={true}
              />
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
}
