import { Done, ReportProblem } from "@mui/icons-material";
import {
  Box,
  Divider,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Error } from "@mui/icons-material";
import { memo } from "react";

export const CellExibition = memo(
  ({ value, error, selected }) => {
    const theme = useTheme();

    return (
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          position: "absolute",
          left: 0,
          right: 0,
          padding: "0px 10px",
          height: "100%",
          width: "100%",
          border: selected ? "1px solid" + theme.palette.primary.main : null,
        }}
      >
        <Tooltip
          title={error}
          placement="bottom-start"
          slotProps={{
            tooltip: {
              sx: {
                p: 0.5,
                px: 1,
                bgcolor: theme.palette.mode === "dark" ? "#F44336" : "#D32F2F",
                color: theme.palette.mode === "dark" ? "#fff" : "#fff",
                fontSize: 14,
                borderRadius: "3px",
              },
            },
            popper: {
              sx: { pointerEvents: "none!important" },
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -5],
                  },
                },
              ],
            },
          }}
        >
          <Typography
            sx={{
              fontSize: 13,
              width: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            error={true}
            color={error ? "error" : "inherit"}
          >
            {value ? value : "-"}
          </Typography>
        </Tooltip>
        {error === "Campo obrigatório" && (
          <Error color="error" sx={{ fontSize: "18px" }} />
        )}
      </Stack>
    );
  },
  (prevProps, nextProps) => {
    // Renderiza novamente somente se params ou predefinicoes mudarem
    return (
      prevProps.value?.toLowerCase() === nextProps.value?.toLowerCase() &&
      prevProps.selected === nextProps.selected &&
      prevProps.error === nextProps.error
    );
  }
);
