import {
  Add,
  CheckBoxOutlineBlank,
  MapOutlined,
  CheckBox as CheckBoxIcon,
} from "@mui/icons-material";
import {
  CardActionArea,
  Typography,
  Card,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  createSvgIcon,
  useTheme,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  DialogActions,
  Button,
  Fade,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import { ReactComponent as NovoMapaSVG } from "../../../assets/easyMaps/iconsSvg/novoMapa.svg";
import { useEffect, useState } from "react";
import useStyles from "./styles";
import api from "../../../services/api";

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function NewMap({
  groupsId,
  openNovoMapaDialog = false,
  setOpenNovoMapaDialog = () => {},
  setSnackbar = () => {},
  addNewMapInGroup = () => {},
  groupPosition,
  maps = [],
  addMapInMaps = () => {},
  addMapsInGroup = () => {},
  initialtab = 0,
  tipoDefault = 0,
}) {
  const [titulo, setTitulo] = useState("");
  const [tipo, setTipo] = useState(0);
  const [tabValue, setTabValue] = useState(initialtab);
  const [loadinButtom, setLoadingButtom] = useState(false);
  const [mapsId, setMapsId] = useState([]);
  function clearDialog() {
    setTabValue(initialtab);
    setTitulo("");
    setTipo(0);
  }
  useEffect(() => {
    clearDialog();
  }, []);

  const NovoMapaIcon = createSvgIcon(<NovoMapaSVG />);

  const theme = useTheme();
  const styles = useStyles(theme);

  const handleClose = () => {
    setOpenNovoMapaDialog(false);
    setTabValue(initialtab);
  };
  async function handleApiNewMap() {
    try {
      setLoadingButtom(true);
      const response = await api.post("/easyMaps/map/create", {
        titulo,
        tipo: tipoDefault ? tipoDefault : parseInt(tipo),
        groupsId: groupsId ? [groupsId] : [],
        logsLoginClienteId: JSON.parse(localStorage.getItem("logs"))?.id || "",
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível criar mapa",
          severity: "error",
        });
      } else {
        if (response.data) {
          if (initialtab === 0) {
            addNewMapInGroup(response.data, groupPosition);
            addMapInMaps(response.data);
            clearDialog();
            setOpenNovoMapaDialog(false);
          } else {
            addMapInMaps(response.data);
            clearDialog();
            setOpenNovoMapaDialog(false);
          }
        }
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.status === 400) {
        setSnackbar({
          children: "Error: Mapa já existe",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: `Error:${
            error?.message || "Não foi possível se conectar com o servidor"
          } `,
          severity: "error",
        });
      }
    } finally {
      setLoadingButtom(false);
    }
  }

  async function handleAddMapsInGroup() {
    try {
      setLoadingButtom(true);
      const response = await api.post("/easyMaps/groupMaps/addMapasInGroup", {
        id: groupsId,
        mapsId: mapsId,
        logsLoginClienteId: JSON.parse(localStorage.getItem("logs"))?.id || "",
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível adicionar mapas",
          severity: "error",
        });
      } else {
        if (response.data) {
          clearDialog();
          setOpenNovoMapaDialog(false);
          addMapsInGroup(mapsId, groupPosition);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error:${
          error?.message || "Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    } finally {
      setLoadingButtom(false);
    }
  }

  const CardSelection = () => (
    <>
      <DialogContent sx={styles.mainContent}>
        <Card elevation={3} sx={styles.cardImg}>
          <CardActionArea sx={styles.cardAction} onClick={() => setTabValue(1)}>
            <NovoMapaIcon sx={styles.icon} color="primary" />
            <Typography sx={styles.typography}>Criar novo mapa</Typography>
          </CardActionArea>
        </Card>
        <Card sx={styles.cardImg} elevation={3}>
          <CardActionArea sx={styles.cardAction} onClick={() => setTabValue(2)}>
            <Box sx={styles.boxAddIcon}>
              <MapOutlined sx={styles.icon} color="primary" />
              <Add sx={styles.addIcon} onClick={() => setTabValue(2)} />
            </Box>
            <Typography sx={styles.typography}>
              Adicionar mapas existente
            </Typography>
          </CardActionArea>
        </Card>
      </DialogContent>
    </>
  );
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleApiNewMap();
    }
  };
  return (
    <Dialog open={openNovoMapaDialog} onClose={handleClose}>
      <DialogTitle>
        {tipoDefault !== 2 ? "Adicionar novo mapa" : "Adicionar novo POP"}
      </DialogTitle>

      {tabValue === 0 && (
        <Fade in={tabValue === 0}>
          <Box>
            <CardSelection />
          </Box>
        </Fade>
      )}
      {tabValue === 1 && (
        <Fade in={tabValue === 1}>
          <Box>
            <DialogContent>
              <Typography> Título</Typography>
              <TextField
                value={titulo}
                size="small"
                onKeyDown={handleKeyDown}
                sx={styles.textfield}
                autoComplete="off"
                placeholder={
                  tipoDefault !== 2 ? "Título do mapa" : "Título do POP"
                }
                onChange={(event) => setTitulo(event.target.value)}
              />
              {tipoDefault !== 2 && (
                <FormControl sx={styles.formControl}>
                  <Typography> Tipo de mapa</Typography>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={0}
                    name="radio-buttons-group"
                    onChange={(event) => setTipo(event.target.value)}
                  >
                    <FormControlLabel
                      value={0}
                      control={<Radio />}
                      label="Mapa de rede geolocalizado"
                    />
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label="Mapa de topologia de rede"
                    />
                  </RadioGroup>
                </FormControl>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  if (initialtab === 0) {
                    setTabValue(0);
                  } else {
                    setTabValue(initialtab);
                    setOpenNovoMapaDialog(false);
                  }

                  clearDialog();
                }}
              >
                CANCELAR
              </Button>
              <Button
                sx={styles.confirmButton}
                onClick={handleApiNewMap}
                disabled={loadinButtom || !titulo}
              >
                CONFIRMAR
              </Button>
            </DialogActions>
          </Box>
        </Fade>
      )}
      {tabValue === 2 && (
        <Fade in={tabValue === 2}>
          <Box>
            <DialogContent>
              <Typography> Adicionar mapas ja existente</Typography>
            </DialogContent>

            <Autocomplete
              multiple
              id="checkboxes-maps"
              options={maps}
              disableCloseOnSelect
              onChange={(event, newValue) => {
                setMapsId(newValue);
              }}
              getOptionLabel={(option) =>
                option?.titulo ? option?.titulo : ""
              }
              renderOption={(props, option, { selected }) => (
                <Box component="li" {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option?.titulo}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  hiddenLabel={true}
                  variant={"outlined"}
                  placeholder="Selecione os mapas"
                  sx={styles.textfield}
                />
              )}
            />
            <DialogActions>
              <Button
                onClick={() => {
                  setTabValue(0);
                  clearDialog();
                }}
              >
                CANCELAR
              </Button>
              <Button
                sx={styles.confirmButton}
                onClick={handleAddMapsInGroup}
                disabled={loadinButtom}
              >
                CONFIRMAR
              </Button>
            </DialogActions>
          </Box>
        </Fade>
      )}
    </Dialog>
  );
}
