import { View, Text, StyleSheet } from "@react-pdf/renderer";
import { styles } from "./styles";
import { TableReport } from "./tableReport";
import { agrupaMetricas } from "../utils";
const headerTableBoolean = [
  "Nome do Host",
  "Taxa de Disponibilidade",
  "Tempo de Disponibilidade",
  "Taxa de Indisponibilidade",
  "Tempo de Indisponibilidade",
];
const headerTableTraffic = [
  "Nome do Item",
  "Tráfego Total",
  "Tráfego Médio",
  "95th Percentil",
  "Valor",
];
const headerTableFlow = [
  "Tipo",
  "Tráfego Total",
  "Tráfego Médio",
  "95th Percentil",
  "Valor",
];
const headerTableFlowExcedente = [
  "Tráfego Total",
  "Tráfego Médio",
  "95th Percentil",
  "Valor",
];
const stylesFirstPage = StyleSheet.create({
  viewHosts: {
    display: "flex",
    flexDirection: "row",
    gap: 1,
    flexWrap: "wrap",
  },
  textPage: {
    fontSize: 12,
    textAlign: "justify",
    fontFamily: "Times-Italic",
    display: "flex",
    flexWrap: "wrap",
    lineHeight: 1.5,
  },
});
export const FirstPage = ({
  itens,
  tipo,
  conteudoHost,
  dataFinal,
  dataInicial,
  horaInicial,
  horaFinal,
}) => {
  const sufficientData = (hosts, tag) => {
    if (tag === "traffic") {
      return hosts.every(
        (host) =>
          (!!host?.c95th || host?.c95th == 0) &&
          (!!host?.averageValue || host?.averageValue == 0) &&
          (!!host.trafficFull || host?.trafficFull == 0)
      );
    } else if (tag === "boolean") {
      return !hosts.every((host) => !host?.availability);
    } else {
      return false;
    }
  };
  const sufficientDataFlow = (analysis) => {
    const flatAnalysis = [analysis].flat(Infinity);
    return analysis.every(
      (analysi) =>
        (!!analysi?.th95 || analysi?.th95 == 0) &&
        (!!analysi?.avgValue || analysi?.avgValue == 0) &&
        (!!analysi.trafficFull || analysi?.trafficFull == 0)
    );
  };
  const agrupaPorHosts = (conteudoHost) => {
    const filtraItensTrafego = conteudoHost.filter(
      ({ tag }) => tag === "traffic"
    );
    const hosts = filtraItensTrafego.reduce(
      (
        accumulator,
        { tag, hostName, history, itemId, itemName, unit, ...rest }
      ) => {
        const existingHost = accumulator.find(
          (host) => host.hostName === hostName
        );
        const itens = [{ itemName, history, itemId, ...rest }];
        if (existingHost) {
          existingHost.itens = existingHost.itens.concat(itens);
        } else {
          const newHost = { tag, unit, hostName, itens };
          accumulator.push(newHost);
        }
        return accumulator;
      },
      []
    );
    return hosts;
  };

  let indice = 1;
  return (
    <View>
      <View style={{ marginBottom: 5 }}>
        <Text style={styles.title}>{"PERÍODO DO RELATÓRIO"}</Text>
        <Text style={styles.text}>
          {"Data Inicial: " + dataInicial + " " + horaInicial}
        </Text>
        <Text style={styles.text}>
          {"Data Final: " + dataFinal + " " + horaFinal}
        </Text>
      </View>

      {tipo === 0 && (
        <>
          <Text style={styles.title}>
            {tipo === 0 && "ITENS DE MÉTRICAS"}
            {tipo === 1 && "MÉTRICAS"}
          </Text>
          <Text style={styles.text}>
            Estarão contidos nesse relatório os itens de métricas associados aos
            seus respectivos hosts.
          </Text>
        </>
      )}
      {tipo === 0 &&
        itens?.map(({ itemName, hosts }, index) => (
          <View key={"first-page-itens" + index}>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Text style={styles.textBold}>{`Item ${
                index + 1
              }: ${itemName}`}</Text>
            </View>
            <View style={stylesFirstPage.viewHosts}>
              <Text style={styles.text}>{"Hosts analizados: "}</Text>
              <Text style={[stylesFirstPage.textPage, { width: "80%" }]}>
                {hosts.map((host) => host.hostName).join(", ")}
              </Text>
            </View>
            <View style={stylesFirstPage.viewHosts}>
              <Text style={styles.text}>{"Métricas Personalizadas: "}</Text>
              <Text style={[stylesFirstPage.textPage, { width: "70%" }]}>
                {agrupaMetricas(hosts[0])}
              </Text>
            </View>
          </View>
        ))}
      <Text style={styles.title}>
        {((tipo === 0 &&
          !!itens?.find(({ hosts, tag }) => sufficientData(hosts, tag))) ||
          tipo == 1) &&
          "RESUMO"}
      </Text>
      {tipo === 0
        ? itens?.map(({ itemName, hosts, tag }, index) => (
            <View key={"first-page-itens-table" + index}>
              {sufficientData(hosts, tag) && tag === "boolean" ? (
                <>
                  <TableReport
                    title={itemName}
                    index={index}
                    hosts={hosts}
                    tag={tag}
                    conteudoHost={conteudoHost}
                    headerTitles={headerTableBoolean}
                    indiceTable={indice}
                  />
                  {(indice += 1)}
                </>
              ) : null}
            </View>
          ))
        : null}
      {tipo === 0 &&
      Array.isArray(agrupaPorHosts(conteudoHost)) &&
      agrupaPorHosts(conteudoHost).length > 0
        ? agrupaPorHosts(conteudoHost).map(
            ({ hostName, tag, itens }, index) => (
              <View key={"first-page-traffic-table" + index}>
                {sufficientData(itens, tag) ? (
                  <>
                    <TableReport
                      title={hostName}
                      index={index}
                      hosts={itens}
                      tag={tag}
                      headerTitles={headerTableTraffic}
                      indiceTable={indice}
                    />
                    {(indice += 1)}
                  </>
                ) : null}
              </View>
            )
          )
        : null}
      {tipo === 1 &&
        conteudoHost?.map(
          ({ analysis, client, tarifacaoExecedente }, index) => (
            <View key={"first-page-itens-table" + index}>
              {sufficientDataFlow(analysis) ? (
                <>
                  <TableReport
                    title={client}
                    index={index}
                    hosts={analysis}
                    headerTitles={
                      tarifacaoExecedente?.status
                        ? headerTableFlowExcedente
                        : headerTableFlow
                    }
                    tarifacaoExcedente={tarifacaoExecedente}
                    indiceTable={indice}
                    tipo={tipo}
                  />
                  {(indice += 1)}
                </>
              ) : null}
            </View>
          )
        )}
    </View>
  );
};
