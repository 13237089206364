import {
  Autocomplete,
  Button,
  Divider,
  Drawer,
  Fade,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Stack,
  TextField,
  Typography,
  createSvgIcon,
  useTheme,
  Tooltip,
  Chip,
} from "@mui/material";
import IconTransform from "../iconsElement";
import {
  AnimationOutlined,
  CancelOutlined,
  CloseOutlined,
  Delete,
  DeleteOutline,
  SaveOutlined,
  ShapeLineOutlined,
} from "@mui/icons-material";
import { ReactComponent as LinkedIcon } from "../../../assets/easyMaps/iconsSvg/Linked.svg";
import { ReactComponent as CapacitySvg } from "../../../assets/easyMaps/iconsSvg/capacidade.svg";
import CleaningServicesOutlinedIcon from "@mui/icons-material/CleaningServicesOutlined";
import {
  forwardRef,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
} from "react";
import ForkRightIcon from "@mui/icons-material/ForkRight";
import NearMeIcon from "@mui/icons-material/NearMe";
import { LoadingButton } from "@mui/lab";
const lineTypes = ["reta", "ortogonal", "curvada"];
const animationTypes = ["pontilhada", "tracejada"];

const EnlaceSideBarEasyMaps = forwardRef(
  (
    {
      setEdgeSelected = () => {},
      setOpenAlertModal = () => {},
      connection = null,
      setConnection = () => {},
      edgeSelected,
      setSnackbar,
      api,
      produtos,
      elements,
      iconsElement,
      saveConnection,
      handleDelete = () => {},
      changeConnection,
      fullScreem = false,
      autoRouting = () => {},
      cleanRouting = () => {},
      loadRouting = false,
      setLoadRouting = () => {},
      tipoMapa = 0,
      checkChanges,
      setCheckChanges,
      loadingButton,
    },
    ref
  ) => {
    const [line, setLine] = useState("reta");
    const [itens, setItens] = useState([]);
    const [link, setLink] = useState("");
    const [hostId, setHostId] = useState("");
    const [itemConnection, setItemConnection] = useState([]);
    const [otherItensConnection, setOtherItensConnection] = useState([]);
    const [capacidade, setCapacidade] = useState("");
    const [iconsElementPair, setIconsElementPair] = useState([]);
    const [animation, setAnimation] = useState("");
    const [loading, setLoading] = useState(false);
    const [elementsPair, setElementsPair] = useState([]);
    //const previousChange = usePrevious(positionHandlers);
    const theme = useTheme();
    const CapacityIcon = createSvgIcon(
      <CapacitySvg height="100%" width="100%" />
    );
    useImperativeHandle(ref, () => ({
      update() {
        handleSave();
      },
    }));

    const styles = {
      containerData: {
        overflow: "auto",
        height: "calc(100vh-65px)",
        padding: "10px 10px",
        marginBottom: "10px",
      },
      text: {
        fontSize: "0.875rem",
        color: theme.palette.color.text,
      },
      paper: {
        borderRadius: "0px",
        height: "calc(100vh-65px)",
        position: "sticky",
        width: "340px!important",
        backgroundColor: theme.palette.background.slideBar,
      },
      card: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0px 0px 5px  #0000001a",
        display: "flex",
        direction: "row",
        padding: "10px",

        width: "100%",
      },
    };
    const SvgArrow = () => {
      return (
        <svg
          width="71"
          height="12"
          viewBox="0 0 71 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M71 6L61 0.226497V11.7735L71 6ZM0 7H1.97222V5H0V7ZM5.91667 7H9.86111V5H5.91667V7ZM13.8056 7H17.75V5H13.8056V7ZM21.6944 7H25.6389V5H21.6944V7ZM29.5833 7H33.5278V5H29.5833V7ZM37.4722 7H41.4167V5H37.4722V7ZM45.3611 7H49.3056V5H45.3611V7ZM53.25 7H57.1944V5H53.25V7ZM61.1389 7H65.0833V5H61.1389V7Z"
            fill="#F43F59"
          />
        </svg>
      );
    };

    useEffect(() => {
      if (connection != undefined) {
        let imgsPair = [];
        const reference = elements.find(
          ({ id }) => id == connection?.referenceId
        );
        const destination = elements.find(
          ({ id }) => id == connection?.destinationId
        );
        let findPair = [reference, destination];
        imgsPair = [reference?.img, destination?.img];
        if (Array.isArray(findPair) && findPair.length === 2) {
          setElementsPair(findPair);
          setIconsElementPair([
            iconsElement?.find((icon) => imgsPair[0] === icon?.id),
            iconsElement?.find((icon) => imgsPair[1] === icon?.id),
          ]);
        }

        setCapacidade(connection?.config?.capacidade ?? "");
        setAnimation(connection?.config?.animation ?? "desativada");
        setLine(connection?.config?.type || lineTypes[0]);
        // devido a possibilidade de criar conexões sem itens
        let hostConnId = connection?.config?.hostOriginId; // as novas conexões carregaram esse campo (cardConnection)
        if (!hostConnId) {
          //verifica o host pelos itens da conexão
          if (Array.isArray(connection.item) && connection.item.length > 0) {
            setHostId(connection?.item[0]?.hostOriginId);
          } else {
            let elementoReference = elements.find(
              (a) => a.id == connection.referenceId
            );
            if (elementoReference?.tipo == 0) {
              setHostId(
                elementoReference?.elementConfig?.config?.hosts[0]?.hostid
              );
            }
            // Aqui fica sério pq em mapas ou pops temos muitos hosts e não dá para saber qual deles é a origim se nã tiver itens ou o hostOriginId,
            //nesse caso os usuarios precisaram recolocar o host de origem na conexão para que carregem o campo hostOriginId
          }
        } else {
          setHostId(hostConnId);
        }
        const produtoId = elements.find(
          (ele) => ele.id == connection?.referenceId
        )?.elementConfig?.config?.produtoId;
        let link = null;
        const produto = produtos.find((pro) => pro.id == produtoId);
        if (
          Array.isArray(produto?.AcessoLink) &&
          produto?.AcessoLink.length > 0
        )
          setLink(produto.AcessoLink[0]?.link);
      }
    }, [connection?.id]);

    useEffect(() => {
      if (hostId == "" || hostId == null || link == "" || !connection) return;
      getItens();
    }, [link, hostId]);

    function clearMemory() {
      setLoadRouting(false);
      setLine("reta");
      setItens([]);
      setLink("");
      setHostId("");
      setItemConnection([]);
      setOtherItensConnection([]);
      setCheckChanges(false);
    }
    function close() {
      setConnection(null);
      setEdgeSelected(null);
      clearMemory();
    }
    useEffect(() => {
      if (itens.length == 0) return;
      let itensTrafficFilter = itens?.filter((item) =>
        connection?.item?.some(
          (con) =>
            con?.itemId == item?.itemid &&
            (con?.key_?.toLowerCase().includes("inoctets") ||
              con?.key_?.toLowerCase().includes("outoctets"))
        )
      );

      let itensNoTrafficFilter = itens?.filter((item) =>
        connection?.item?.some(
          (con) =>
            con?.itemId == item?.itemid &&
            !con?.key_?.toLowerCase().includes("inoctets") &&
            !con?.key_?.toLowerCase().includes("outoctets")
        )
      );

      setItemConnection(itensTrafficFilter);
      setOtherItensConnection(itensNoTrafficFilter);
    }, [itens, connection?.item]);

    async function getItens() {
      try {
        setItemConnection([]);
        setOtherItensConnection([]);
        setLoading(true);

        const response = await api.post("/easyMaps/zabbix/itens", {
          link: link,
          hostids: hostId,
        });

        if (response.data.status === "Error") {
          setSnackbar({
            children: "Error: Não foi possível buscar os hosts",
            severity: "error",
          });
        } else {
          let responseItens = response.data;

          setItens([
            ...new Map(
              responseItens.map((item) => [item.itemid, item])
            ).values(),
          ]);
        }
      } catch (error) {
        console.error(error);
        setSnackbar({
          children: "Error: Não foi possível se conectar com o servidor",
          severity: "error",
        });
      } finally {
        setLoading(false);
      }
    }
    const handleSave = () => {
      let updateConnection = connection;
      const referenceElem = elements.find(
        ({ id }) => id === updateConnection?.referenceId
      );
      const acessoProdutoId = referenceElem?.elementConfig?.config?.produtoId;
      updateConnection.item =
        itemConnection && otherItensConnection
          ? [
              ...itemConnection?.map((item) => ({
                itemId: item?.itemid || null,
                itemName: item?.name || null,
                hostOriginId: item?.hostid || null,
                acessoProdutoId: acessoProdutoId || null,
                key_: item?.key_ || null,
              })),
              ...otherItensConnection?.map((item) => ({
                itemId: item?.itemid || null,
                itemName: item?.name || null,
                hostOriginId: item?.hostid || null,
                acessoProdutoId: acessoProdutoId || null,
                key_: item?.key_ || null,
              })),
            ]
          : updateConnection?.item;

      if (edgeSelected)
        saveConnection(
          updateConnection,
          edgeSelected,
          line,
          capacidade,
          animation,
          connection.config?.hostOriginId
        );
      else
        saveConnection(
          updateConnection,
          null,
          line,
          capacidade,
          animation,
          connection.config?.hostOriginId
        );
    };
    const filterRemoveTraffic = () => {
      let filteredItems = itens.filter(
        (a) =>
          !a?.key_.toLowerCase().includes("inoctets") &&
          !a?.key_.toLowerCase().includes("outoctets")
      );
      filteredItems.sort((a, b) => a.name.localeCompare(b.name));
      return [
        ...new Map(filteredItems.map((item) => [item.itemid, item])).values(),
      ];
    };
    const filterTraffic = () => {
      let filteredItems = itemConnection.some(
        (a) =>
          a.key_.toLowerCase().includes("inoctets") ||
          a.key_.toLowerCase().includes("outoctets")
      )
        ? itens.filter(
            (a) =>
              !a.key_.toLowerCase().includes("inoctets") &&
              !a.key_.toLowerCase().includes("outoctets")
          )
        : [...itens];
      filteredItems = filteredItems.filter(
        (a) =>
          a.key_.toLowerCase().includes("inoctets") ||
          a.key_.toLowerCase().includes("outoctets")
      );
      let uniqueItems = [];
      let trafficSet = new Set();
      filteredItems.forEach((item) => {
        if (item.name.includes("Tráfego")) {
          let baseName = item.name
            .replace(" de Entrada", "")
            .replace(" de Saída", "");
          if (!trafficSet.has(baseName)) {
            trafficSet.add(baseName);
            uniqueItems.push(item);
          }
        } else {
          uniqueItems.push(item);
        }
      });

      // Ordenar os itens pelo nome
      uniqueItems.sort((a, b) => a.name.localeCompare(b.name));
      return [
        ...new Map(filteredItems.map((item) => [item.itemid, item])).values(),
      ];
    };
    const getDisplayName = (option) => {
      if (typeof option === "string") {
        return option;
      }
      if (option?.name.includes("Tráfego")) {
        return option?.name.replace(" de Entrada", "").replace(" de Saída", "");
      }
      return option?.name;
    };

    useEffect(() => {
      if (connection === null) {
        clearMemory();
      }
    }, [connection]);

    return (
      <Drawer
        key={connection?.id}
        variant="persistent"
        anchor={"right"}
        open={!!connection}
        //onClose={() => close()}
        sx={{
          backgroundColor: theme.palette.background.slideBar,
          "& .MuiDrawer-paper": {
            marginTop: fullScreem ? "" : "65px",
            overflow: "visible",
            backgroundColor: theme.palette.background.slideBar,
            borderLeft: "1px solid " + theme.palette.color.appBarBorder,
          },
        }}
      >
        <Paper elevation={0} sx={styles.paper}>
          <Stack direction="column" gap={1} sx={styles.containerData}>
            {/* <CloseOutlined /> */}
            <Paper sx={styles.card}>
              <Stack width="100%" alignItems="center" justifyContent={"center"}>
                <IconTransform
                  img={
                    iconsElementPair.length > 0 ? iconsElementPair[0]?.img : ""
                  }
                  size="35px"
                />
                <Tooltip title={elementsPair[0]?.titulo}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      width: "100px",
                      textAlign: "center",
                    }}
                  >
                    {elementsPair[0]?.titulo.length > 30
                      ? elementsPair[0]?.titulo.substring(0, 30) + "..."
                      : elementsPair[0]?.titulo}
                  </Typography>
                </Tooltip>
              </Stack>
              <Stack width="100%" alignItems="center" justifyContent={"center"}>
                <SvgArrow />
              </Stack>
              <Stack width="100%" alignItems="center" justifyContent={"center"}>
                <IconTransform
                  img={
                    iconsElementPair.length > 0
                      ? iconsElementPair[1]?.img ?? iconsElementPair[0]?.img
                      : ""
                  }
                  size="35px"
                />
                <Tooltip title={elementsPair[1]?.titulo}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      width: "100px",
                      textAlign: "center",
                    }}
                  >
                    {elementsPair[1]?.titulo.length > 30
                      ? elementsPair[1]?.titulo.substring(0, 30) + "..."
                      : elementsPair[1]?.titulo}
                  </Typography>
                </Tooltip>
              </Stack>
            </Paper>
            {!!loading && (
              <Stack
                gap={2}
                sx={{
                  maxHeight: "80vh",
                  overflow: "auto",
                }}
              >
                {/* <Divider /> */}
                {/* <Stack>
                <Skeleton width={"100%"} height={"40px"} />
                <Skeleton width={"100%"} height={"80px"} />
              </Stack>
              <Stack>
                <Skeleton width={"100%"} height={"40px"} />
                <Skeleton width={"100%"} height={"80px"} />
              </Stack>
              <Stack>
                <Skeleton width={"100%"} height={"40px"} />
                <Skeleton width={"100%"} height={"80px"} />
              </Stack> */}
                <Stack sx={{ width: "100%" }}>
                  <LinearProgress />
                </Stack>
              </Stack>
            )}
            {!loading && (
              <>
                <Stack
                  gap={2}
                  sx={{
                    maxHeight: "80vh",
                    overflow: "auto",
                    //paddingRight: "10px",
                  }}
                >
                  <Divider />
                  <Stack direction="row" gap={2} alignItems="center">
                    <LinkedIcon
                      style={{
                        fill: theme.palette.primary.main,
                        height: "24px",
                        width: "24px",
                      }}
                    />
                    <Typography sx={styles.text}>
                      Selecione os itens da Conexão
                    </Typography>
                  </Stack>
                  <Autocomplete
                    key="autoc-01"
                    multiple
                    disablePortal
                    id="itensConn"
                    size="small"
                    color="primary"
                    options={filterTraffic()}
                    value={[...itemConnection]}
                    onChange={(event, newValue) => {
                      setItemConnection(newValue);
                      setCheckChanges(true);
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Tooltip title={option.name} key={index + "tool"}>
                          <Chip
                            label={option.name}
                            {...getTagProps({ index })}
                          />
                        </Tooltip>
                      ))
                    }
                    getOptionLabel={getDisplayName}
                    isOptionEqualToValue={(option, value) =>
                      option.itemid === value.itemid
                    }
                    renderOption={(props, option) => (
                      <li {...props} key={option.itemid}>
                        {getDisplayName(option)}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        color="primary"
                        label="Itens de Tráfego"
                      />
                    )}
                  />

                  <Autocomplete
                    key="autoc-02"
                    multiple
                    disableCloseOnSelect
                    disablePortal
                    id="itensnoTraffic"
                    size="small"
                    color="primary"
                    options={filterRemoveTraffic()}
                    value={[...otherItensConnection] || []}
                    onChange={(event, newValue) => {
                      setOtherItensConnection(newValue);
                      setCheckChanges(true);
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Tooltip title={option.name} key={index + "tool"}>
                          <Chip
                            label={option.name}
                            {...getTagProps({ index })}
                          />
                        </Tooltip>
                      ))
                    }
                    getOptionLabel={(option) => option?.name}
                    renderOption={(props, option) => (
                      <li {...props} key={option.itemid}>
                        {option?.name}
                      </li>
                    )}
                    isOptionEqualToValue={(option, value) =>
                      option?.itemid == value?.itemid
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        color="primary"
                        label="Outros itens"
                      />
                    )}
                  />
                  <Stack direction="row" gap={2} alignItems="center">
                    <AnimationOutlined color="primary" />
                    <Typography sx={styles.text}>
                      Selecione a animação
                    </Typography>
                  </Stack>
                  <FormControl size="small">
                    <InputLabel id="demo-multiple-checkbox-label">
                      animação da linha
                    </InputLabel>
                    <Select
                      label={"animação da linha"}
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      value={animation}
                      onChange={(event) => {
                        setCheckChanges(true);
                        setAnimation(event.target.value);
                      }}
                    >
                      <MenuItem value="desativada">
                        <em>Desativada</em>
                      </MenuItem>
                      {animationTypes?.map((type, index) => (
                        <MenuItem key={index} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {tipoMapa == 1 && (
                    <>
                      <Stack direction="row" gap={2} alignItems="center">
                        <ShapeLineOutlined color="primary" />
                        <Typography sx={styles.text}>
                          Selecione o tipo de Linha
                        </Typography>
                      </Stack>
                      <FormControl size="small">
                        <InputLabel id="demo-multiple-checkbox-label">
                          tipo de Linha
                        </InputLabel>
                        <Select
                          label={"Colaborador"}
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          value={line}
                          onChange={(event) => {
                            setLine(event.target.value);
                            changeConnection(connection.id, {
                              type: event.target.value,
                            });
                            setCheckChanges(true);
                          }}
                        >
                          {lineTypes?.map((type, index) => (
                            <MenuItem key={index} value={type}>
                              {/* <Camera /> */}
                              {type}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </>
                  )}
                  <Stack direction="row" gap={2} alignItems="center">
                    <CapacityIcon
                      color="primary"
                      sx={{
                        fontSize: "30px",
                      }}
                    />
                    <Typography sx={styles.text} ml="-5px">
                      Informe a capacidade máxima (Gb)
                    </Typography>
                  </Stack>
                  <TextField
                    size="small"
                    value={capacidade}
                    onChange={(e) => {
                      setCapacidade(e.target.value);
                      setCheckChanges(true);
                    }}
                    //helperText="Gb"
                  />
                  {tipoMapa == 0 && (
                    <>
                      <Stack direction="row" gap={2} alignItems="center">
                        <ForkRightIcon
                          color="primary"
                          sx={{ fontSize: "30px" }}
                        />
                        <Typography sx={styles.text}>
                          Roteamento automático
                        </Typography>
                      </Stack>
                      <Button
                        disabled={loadRouting}
                        sx={{ width: "180px", color: theme.palette.color.text }}
                        color="primary"
                        variant="text"
                        startIcon={<NearMeIcon />}
                        onClick={() => {
                          setCheckChanges(true);
                          autoRouting(connection);
                        }}
                      >
                        Encontrar rota
                      </Button>
                      <Fade in={loadRouting}>
                        <LinearProgress
                          sx={{ height: "2px", width: "180px", mt: "-15px" }}
                        />
                      </Fade>
                      <Button
                        disabled={loadRouting}
                        sx={{ width: "180px", color: theme.palette.color.text }}
                        color="primary"
                        variant="text"
                        startIcon={<CleaningServicesOutlinedIcon />}
                        onClick={() => {
                          setCheckChanges(true);
                          cleanRouting(connection);
                        }}
                      >
                        Limpar pontos
                      </Button>

                      <Divider />
                    </>
                  )}
                </Stack>

                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  gap={2}
                  mt="10px"
                >
                  <LoadingButton
                    sx={{
                      width: "50%",
                    }}
                    color="primary"
                    variant="contained"
                    loading={loadingButton}
                    //disabled={!checkChanges}
                    endIcon={<SaveOutlined />}
                    onClick={() => {
                      handleSave();
                      close();
                    }}
                  >
                    {connection?.id ? "Salvar" : "Adicionar"}
                  </LoadingButton>

                  <Button
                    sx={{
                      width: "50%",
                    }}
                    endIcon={<CancelOutlined />}
                    //disabled={!checkChanges}
                    color="primary"
                    variant="outlined"
                    // endIcon={<CloseOutlined />}
                    onClick={() => {
                      checkChanges
                        ? setOpenAlertModal({
                            open: true,
                            severity: "warning",
                            text: "As alterações feitas não foram salvas. Se prosseguir com a operação as mudanças serão perdidas.",
                          })
                        : close();
                    }}
                  >
                    {"Cancelar"}
                  </Button>
                </Stack>
                <Stack gap={2}>
                  {connection?.id && (
                    <Button
                      sx={{
                        width: "100%",
                        //mt: "10px",
                        textDecoration: "none",
                      }}
                      color="primary"
                      variant="text"
                      //endIcon={<DeleteOutline />}
                      startIcon={<DeleteOutline />}
                      onClick={() => {
                        setCheckChanges(false);
                        handleDelete(connection.id);
                      }}
                    >
                      {"Apagar Enlace"}
                    </Button>
                  )}
                </Stack>
              </>
            )}
          </Stack>
        </Paper>
      </Drawer>
    );
  }
);
export default EnlaceSideBarEasyMaps;
