import { Text, View, Image } from "@react-pdf/renderer";
import { styles } from "./../styles";
import { agrupaMetricas } from "../../utils";
import { Fragment } from "react";
import { TableAvailability } from "./tableAvailability";
const RowTrafic = ({ topic, content }) =>
  (content || content == 0) && (
    <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
      <Text style={styles.textBold}>{topic}</Text>
      <Text style={styles.text}>{content}</Text>
    </View>
  );
const RowHorarioPersonalizado = ({ status, horaInicial, horaFinal }) => {
  return (
    <>
      {status ? (
        <RowTrafic
          topic={"Hora personalizada: "}
          content={`${horaInicial} - ${horaFinal}`}
        />
      ) : null}
    </>
  );
};
const headerDowntimeHistory = [
  "Data Inicial",
  "Data Final",
  "Taxa de Indisponibilidade",
  "Tempo de indisponibilidade",
];
export default function ReportBoolean({
  itemName,
  hosts,
  horaInicial,
  horaFinal,
  dataFinal,
  dataInicial,
  graphcs,
  indiceGraphcs,
  indiceTable,
  etapa,
  etapas,
  ultimaData,
  index,
}) {
  const metricas = agrupaMetricas(hosts[0]);
  const texto = !!(metricas.split(",").length > 1)
    ? ` Estas métricas buscam o valor de tempo de atividade no período entre `
    : ` Esta métrica busca o valor de tempo de atividade no período entre `;
  let indice = indiceTable;
  return (
    <>
      <View break>
        <Text style={styles.title}>{`ITEM ${
          index + 1
        }: ${itemName.toUpperCase()}`}</Text>
        <Text
          style={[styles.text, { textIndent: "20px" }]}
        >{`Para cálculos de ${itemName} associados ao equipamento(s) ${hosts
          .map((host) => host.hostName)
          .join(
            ", "
          )}, o SistemaMW usa as métricas: ${metricas}.${texto} ${dataInicial} ${horaInicial} à ${dataFinal} ${horaFinal}. `}</Text>
      </View>

      {hosts?.map(({ hostName, availability, timeCustom }, index) => (
        <Fragment key={"boolean-viewq" + index}>
          <View
            key={"boolean-top" + index}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              marginVertical: 10,
            }}
            wrap={false}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                width: availability ? "50%" : "100%",
                zIndex: 100,
                flexWrap: "wrap",
              }}
            >
              <Text
                style={styles.subtitle}
              >{`Resultados para o host: ${hostName}`}</Text>
              {availability && (
                <>
                  <RowTrafic
                    topic={"Taxa de disponibilidade: "}
                    content={availability?.availability}
                  />
                  <RowTrafic
                    topic={"Taxa de indisponibilidade: "}
                    content={
                      (
                        100.0 -
                        parseFloat(availability?.availability.replace("%", ""))
                      ).toFixed(2) + "%"
                    }
                  />
                  <RowTrafic
                    topic={"Tempo de disponibilidade: "}
                    content={availability?.uptime}
                  />
                  <RowTrafic
                    topic={"Tempo de indisponibilidade: "}
                    content={availability?.downtime}
                  />
                  <RowTrafic
                    topic={"Tempo de análise: "}
                    content={availability?.rangeAnalyzed}
                  />
                </>
              )}
              <RowTrafic
                topic={"Período do relatório: "}
                content={
                  etapa === etapas && timeCustom?.status
                    ? `${dataInicial} ${timeCustom.horaInicial} à ${ultimaData} ${timeCustom.horaFinal}`
                    : timeCustom?.status
                    ? `${dataInicial} ${timeCustom.horaInicial} à ${dataFinal} ${timeCustom.horaFinal}`
                    : `${dataInicial} ${horaInicial} à ${dataFinal} ${horaFinal}`
                }
              />

              <RowHorarioPersonalizado {...timeCustom} />
            </View>
            {availability && graphcs ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "50%",
                  marginVertical: 10,
                }}
              >
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <Text
                    style={styles.legendBold}
                  >{`Gráfico ${indiceGraphcs[0]}: `}</Text>
                  <Text
                    style={styles.legend}
                  >{`Taxa de disponibilidade e indisponibilidade. `}</Text>
                </View>

                <Image
                  key={"iamge1" + index}
                  style={{
                    height: "auto",
                    width: "100%",
                  }}
                  src={`data:application/pdf;base64,${
                    graphcs?.find((graphc) => graphc?.host == hostName)
                      ?.graphcs[0]?.pie
                  }`}
                />
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={styles.legend}
                  >{`Fonte: Dados coletados pelo Zabbix`}</Text>
                </View>
              </View>
            ) : null}
          </View>
          {graphcs && (
            <View
              key={"boolean-top2" + index}
              style={{
                display: "flex",
                flexDirection: "column",
                marginVertical: 10,
              }}
              wrap={false}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={styles.legendBold}
                >{`Gráfico ${indiceGraphcs[1]}: `}</Text>
                <Text
                  style={styles.legend}
                >{`Disponibilidade ${itemName} para o equipamento ${hostName} ao longo do tempo. `}</Text>
              </View>

              <Image
                key={"iamge2" + index}
                style={{ height: "auto", width: "500px" }}
                src={`data:application/pdf;base64,${
                  graphcs?.find((graphc) => graphc?.host == hostName)
                    ?.graphcs[0]?.bool
                }`}
              />
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={styles.legend}
                >{`Fonte: Dados coletados pelo Zabbix`}</Text>
              </View>
            </View>
          )}
          <View>
            {Array.isArray(availability?.downtimeHistory) &&
            availability?.downtimeHistory.length > 0 ? (
              <View wrap={true}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  {(indice += 1)}
                  <Text
                    style={[styles.legendBold]}
                  >{`Tabela ${indice}: `}</Text>

                  <Text
                    style={styles.legend}
                  >{`Intervalos de indisponibilidade ${itemName} para o equipamento ${hostName}`}</Text>
                </View>
                <TableAvailability
                  headerTitles={headerDowntimeHistory}
                  downtimeHistory={availability?.downtimeHistory}
                />
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={styles.legend}
                  >{`Fonte: Dados coletados pelo Zabbix`}</Text>
                </View>
                {(indiceTable += 1)}
              </View>
            ) : null}
          </View>
        </Fragment>
      ))}
    </>
  );
}
