import React, { useEffect } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";

function Legend() {
  const map = useMap();

  // get color depending on population density value
  const getColor = (d) => {
    return d > 100
      ? "#DF1C1C"
      : d > 75
      ? "#DF1C1C"
      : d > 50
      ? "#EC782B"
      : d > 25
      ? "#ECE92B"
      : d > 0
      ? "#05A01F"
      : d == 0
      ? "#808080"
      : "#05A01F";
  };

  useEffect(() => {
    const legend = L.control({ position: "bottomright" });

    legend.onAdd = () => {
      const div = L.DomUtil.create("div", "info legend");
      div.style.padding = "6px 8px";
      div.style.font = "14px/16px Arial, Helvetica, sans-serif";
      div.style.background = "white";
      div.style.background = "rgba(255, 255, 255, 0.8)";
      div.style.boxShadow = "0 0 15px rgba(0, 0, 0, 0.2)";
      div.style.borderRadius = "5px";

      const grades = [0, 0, 25, 50, 75, 100];
      let from;
      let to;

      for (let i = 0; i < grades.length; i++) {
        if (i == 1) {
          from = 0.1;
          to = grades[i + 1];
        } else {
          from = grades[i];
          to = grades[i + 1];
        }

        const legendItem = L.DomUtil.create("div", "legend-item");
        legendItem.style.textAlign = "left";
        legendItem.style.lineHeight = "18px";
        legendItem.style.color = "#555";

        const colorBox = L.DomUtil.create("i", "legend-color");
        colorBox.style.width = "18px";
        colorBox.style.height = "18px";
        colorBox.style.float = "left";
        colorBox.style.marginRight = "8px";
        colorBox.style.opacity = "0.7";
        colorBox.style.backgroundColor =
          i === 0 ? getColor(from) : getColor(from + 1);

        const label = L.DomUtil.create("span", "legend-label");
        label.textContent = from + (to ? "% –" + to + "%" : "");
        legendItem.appendChild(label);
        i != grades.length - 1 && legendItem.appendChild(colorBox);
        i != grades.length - 1 && div.appendChild(legendItem);
      }

      return div;
    };

    legend.addTo(map);

    // Cleanup function to remove the control when component unmounts
    return () => {
      legend.remove();
    };
  }, [map]);

  return null;
}

export default Legend;
