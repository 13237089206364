import { Box, Card, CardActionArea, Paper } from "@mui/material";

import easyReportLogo from "../../assets/logosSvg/LOGOEASYREPORT.svg";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "30px",
    marginTop: "30px",
    flexDirection: "column",
    width: "100%",
  },
  card: {
    backgroundColor: "drawer.main",
    borderRadius: "20px",

    width: "100%",
  },
  cardActionArea: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "97px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  img: { width: "300px", height: "auto" },
};

export default function EasyReport() {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname === "/easyReport") {
      navigate("/easyReport/selecionarPagina");
    }
  }, []);
  return (
    <Box sx={styles.container}>
      <Card sx={styles.card}>
        <CardActionArea
          sx={styles.cardActionArea}
          onClick={() => {
            navigate("/easyReport/selecionarPagina");
          }}
        >
          <img src={easyReportLogo} style={styles.img} />
        </CardActionArea>
      </Card>
      <Box sx={styles.content}>
        <Outlet />
      </Box>
    </Box>
  );
}
