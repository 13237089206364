import { Add, Delete } from "@mui/icons-material";
import {
  Box,
  Stack,
  Typography,
  Button,
  TextField,
  DialogActions,
  Divider,
  useTheme,
  Snackbar,
  Alert,
  IconButton,
} from "@mui/material";

import { useEffect, useMemo, useState } from "react";
import MapaModal from "../mapaModal";
import api from "../../../../services/api";
import Enlace from "./enlace";

const useStyles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  dialogContent: {
    flexGrow: 1,
  },
  contentBox: { mt: 1, display: "flex", justifyContent: "space-between" },
  enlaceBox: {
    display: "flex",
    flexDirection: "column",
    gap: 1,
    width: "100%",
    overflow: "auto",
    paddingRight: 1,
    height: "43.5vh",
  },
  formBox: { mt: 2, display: "flex" },
  interfaceBox: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    width: "100%",
  },

  boxMapa: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "auto",
    flexGrow: 1,
    minWidth: "50%",
    minHeight: "43.5vh",
  },
});

export default function CreateScreen(props) {
  const { setCreateScreen, addMapa } = props;
  const [snackbar, setSnackbar] = useState(null);
  const [loadingConfirmarButton, setLoadingConfirmarButton] = useState(false);
  const theme = useTheme();
  const styles = useStyles(theme);

  const [titulo, setTitulo] = useState("");

  const initialEnlace = {
    hostEntrada: "",
    hostSaida: "",
    interface: "",
    coordenadaEntradaX: null,
    coordenadaEntradaY: null,
    coordenadaSaidaX: null,
    coordenadaSaidaY: null,
    capacidade: null,
  };

  const [enlaces, setEnlaces] = useState([initialEnlace]);
  const [hosts, setHosts] = useState([]);

  const isAnyFieldEmpty =
    titulo &&
    enlaces.every(
      (enlace) =>
        enlace.hostEntrada &&
        enlace.hostSaida &&
        enlace.interface &&
        enlace.capacidade &&
        enlace.coordenadaEntradaX &&
        enlace.coordenadaEntradaY &&
        enlace.coordenadaSaidaX &&
        enlace.coordenadaSaidaY
      // Adicione outras verificações aqui conforme necessário
    )
      ? true
      : false;

  useEffect(() => {
    handleApiGetHosts();
  }, []);

  async function handleApiGetHosts() {
    try {
      const response = await api.get("/easyReport/HostName/find", {});

      if (response.data.status === "Error") {
        console.error(response.data);
        setSnackbar({
          children: "Error: Não foi possível buscar dados",
          severity: "error",
        });
      } else {
        setHosts(response?.data);
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });

      console.error(error);
    }
  }
  async function createMapa() {
    try {
      setLoadingConfirmarButton(true);
      const response = await api.post("/mapas/create", {
        titulo,
        enlaces,
      });

      if (response.data.status === "Error") {
        console.error(response.data);
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        setHosts(response.data);
        addMapa(response.data);
        clearModal();
        setCreateScreen(false);
      }
      setLoadingConfirmarButton(false);
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
      setLoadingConfirmarButton(false);
      console.error(error);
    }
  }

  const memorizedEnlaces = useMemo(() => enlaces, [enlaces]);

  function clearModal() {
    setTitulo("");
    setEnlaces([
      {
        hostEntrada: "",
        hostSaida: "",
        interface: "",
        coordenadaEntradaX: null,
        coordenadaEntradaY: null,
        coordenadaSaidaX: null,
        coordenadaSaidaY: null,
        capacidade: null,
      },
    ]);
  }

  const handleEnlaceChange = (index, event) => {
    const { name, value } = event.target;

    const newEnlaces = [...enlaces];
    newEnlaces[index][name] = value;
    setEnlaces(newEnlaces);
  };

  const addEnalce = () => {
    if (enlaces.length) {
      setEnlaces([
        ...enlaces,
        {
          hostEntrada: "",
          hostSaida: "",
          interface: "",
          coordenadaEntradaX: null,
          coordenadaEntradaY: null,
          coordenadaSaidaX: null,
          coordenadaSaidaY: null,
          capacidade: null,
        },
      ]);
    }
  };

  const removeEnlace = (index) => {
    const newEnlaces = [...enlaces];
    newEnlaces.splice(index, 1);
    setEnlaces(newEnlaces);
  };

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 2,
          }}
        >
          <Box style={styles.boxMapa}>
            <MapaModal enlaces={memorizedEnlaces} />
          </Box>
          <Box sx={styles.dialogContent} display={"inline-block"}>
            <Box>
              <Typography color={"primary"} variant="h6">
                Criar novo mapa
              </Typography>
              <Box sx={styles.contentBox}>
                <TextField
                  label={"Título"}
                  size="small"
                  autoComplete="off"
                  value={titulo}
                  onChange={(event) => {
                    setTitulo(event.target.value);
                  }}
                />
              </Box>
              <Box sx={styles.formBox}>
                <Box sx={styles.interfaceBox}>
                  <Box>
                    <Button
                      onClick={addEnalce}
                      endIcon={<Add sx={{ mt: -0.3 }} />}
                    >
                      <Typography
                        variant="subtitle1"
                        color={"primary.main"}
                        fontWeight={500}
                        textTransform={"initial"}
                      >
                        Novo elance
                      </Typography>
                    </Button>
                  </Box>
                  <Divider variant="middle" />

                  <Box sx={styles.enlaceBox}>
                    {Array.isArray(enlaces) &&
                      enlaces.map((enlace, index) => {
                        return (
                          <Stack
                            direction={"row"}
                            alignItems={"start"}
                            gap={2}
                            key={index}
                          >
                            <Enlace
                              key={index}
                              index={index}
                              enlaces={enlaces}
                              hosts={hosts}
                              handleEnlaceChange={handleEnlaceChange}
                              setSnackbar={setSnackbar}
                            />
                            {enlaces.length > 1 && (
                              <IconButton
                                onClick={() => removeEnlace(index)}
                                sx={{ mt: 3 }}
                              >
                                <Delete color="secondary" />
                              </IconButton>
                            )}
                          </Stack>
                        );
                      })}
                  </Box>
                </Box>
              </Box>
              <DialogActions>
                <Button
                  color="secondary"
                  onClick={() => {
                    setCreateScreen(false);
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  color="primary"
                  onClick={createMapa}
                  disabled={loadingConfirmarButton || !isAnyFieldEmpty}
                >
                  Confirmar
                </Button>
              </DialogActions>
            </Box>
          </Box>
        </Box>
      </Box>

      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Box>
  );
}
