import { Fragment, useRef } from "react";
import {
  MapContainer,
  Marker,
  Polyline,
  TileLayer,
  Tooltip,
} from "react-leaflet";

const styles = {
  mapContainer: {
    width: "100%",
    height: "100%",
    borderRadius: 3,
    leafletContainer: {},
    position: "relative",
  },
};

export default function MapaModal(props) {
  const { enlaces } = props;
  const key = enlaces.map((e) => e.id).join("-");

  const mapRef = useRef();

  return (
    <>
      <MapContainer
        center={[-10, -48]}
        zoom={4}
        style={styles.mapContainer}
        key={key}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          //url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
          attribution="&copy; OpenStreetMap contributors"
        />
        {Array.isArray(enlaces) &&
          enlaces.map((enlace, index) => {
            return (
              <Fragment key={index}>
                {enlace.coordenadaEntradaX && enlace.coordenadaEntradaY && (
                  <Marker
                    key={index}
                    position={[
                      parseFloat(enlace.coordenadaEntradaY),
                      parseFloat(enlace.coordenadaEntradaX),
                    ]}
                    color="blue"
                  >
                    <Tooltip
                      permanent
                      direction="top"
                      offset={[-15, 0]}
                      opacity={1}
                    >
                      {enlace?.hostEntrada.nome}
                    </Tooltip>
                  </Marker>
                )}

                {enlace.coordenadaSaidaX && enlace.coordenadaSaidaY && (
                  <Marker
                    position={[
                      parseFloat(enlace.coordenadaSaidaY),
                      parseFloat(enlace.coordenadaSaidaX),
                    ]}
                    color="blue"
                  >
                    <Tooltip
                      permanent
                      direction="top"
                      offset={[-15, 0]}
                      opacity={1}
                    >
                      {enlace?.hostSaida.nome}
                    </Tooltip>
                  </Marker>
                )}
              </Fragment>
            );
          })}

        {Array.isArray(enlaces) &&
          enlaces.map((enlace, index) => {
            if (
              enlace.coordenadaEntradaY &&
              enlace.coordenadaEntradaX &&
              enlace.coordenadaSaidaX &&
              enlace.coordenadaSaidaY
            ) {
              return (
                <Polyline
                  key={index}
                  color="green"
                  positions={[
                    [enlace.coordenadaEntradaY, enlace.coordenadaEntradaX],
                    [enlace.coordenadaSaidaY, enlace.coordenadaSaidaX],
                  ]}
                ></Polyline>
              );
            }
          })}
      </MapContainer>
    </>
  );
}
