import {
  Box,
  Card,
  Paper,
  Stack,
  Divider,
  Typography,
  IconButton,
} from "@mui/material";
import { MapContainer, Polyline, TileLayer, Tooltip } from "react-leaflet";
import { CellTower } from "@mui/icons-material";

import api from "../../../../../services/api";
import { useEffect, useRef, useState } from "react";

import L, { map } from "leaflet";
import "../../../../../componentes/leaflet-migrations";
import "../../../../../componentes/leaflet-fullscreen/Leaflet.fullscreen";
import Legend from "../../../../../componentes/legend";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import serverIcon from "../../../../../assets/router-removebg-preview.png";

dayjs.extend(utc);
dayjs.extend(timezone);

const styles = {
  paperContainer: {
    padding: "16px ",
    display: "flex",
    alignItems: "center",
    position: "relative",
    width: "100%",
    borderRadius: 3,
    mt: 5.5,
  },
  body: {
    width: "100%",
  },

  mapInfoStack: {
    padding: "12px 16px",
    width: "250px",
  },
  mapInfoDataStack: {
    padding: "12px 16px",
    width: "120px",
  },
  mapInfoTitle: {
    fontSize: "0.75rem",
    lineHeight: 1.25,
    fontWeight: 600,
    letterSpacing: "0.033em",
    color: "primary.main",
  },
  mapInfoText: {
    fontSize: "0.875rem",
    lineHeight: 1.5,
    fontWeight: 400,
    textTransform: "capitalize",
  },
};

const selectCorVel = (percent) => {
  if (percent === 0) {
    return ["rgb(128, 128, 128)", 0]; // Cor cinza para percent = 0
  }
  return percent <= 25
    ? ["rgb(5, 160, 31)", 1]
    : percent <= 50
    ? ["rgb(236, 233, 43)", 2]
    : percent <= 75
    ? ["rgb(236, 120, 43)", 3]
    : ["rgb(223, 28, 28)", 4];
};

export default function LeafletMapa(props) {
  const { mapa } = props;
  const [trafficData, setTrafficData] = useState([]);
  const [migrationLayer, setmigrationLayer] = useState(null);
  const [firstRender, setFirstRender] = useState(true);
  const [dataMap, setDataMap] = useState([]);
  const mapRef = useRef(null);
  const hosts = [];
  const interfaces = [];

  function converterBitsPorSegundo(bits) {
    if (bits) {
      if (bits >= Math.pow(1000, 5)) {
        const hexa = bits.toString(16);
        return hexa.toUpperCase() + " Hex";
      } else if (bits >= Math.pow(1000, 5)) {
        return (bits / Math.pow(1000, 5)).toFixed(2) + " Pbps";
      } else if (bits >= Math.pow(1000, 4)) {
        return (bits / Math.pow(1000, 4)).toFixed(2) + " Tbps";
      } else if (bits >= Math.pow(1000, 3)) {
        return (bits / Math.pow(1000, 3)).toFixed(2) + " Gbps";
      } else if (bits >= Math.pow(1000, 2)) {
        return (bits / Math.pow(1000, 2)).toFixed(2) + " Mbps";
      } else if (bits >= 1000) {
        return (bits / 1000).toFixed(2) + " Kbps";
      } else {
        return bits.toFixed(2) + " bps";
      }
    }
  }

  if (mapa && mapa.enlaces) {
    mapa.enlaces.map((el) => {
      hosts.push(el.hostEntrada.nome);
      interfaces.push(el.interface.nome);
    });
  }

  let Icon = L.icon({
    iconUrl: serverIcon,
    iconSize: [54, 28], // size of the icon
    iconAnchor: [25, 18], // point of the icon which will correspond to marker's location
    popupAnchor: [0, 0],
  });

  function anamition() {
    if (migrationLayer !== null) {
      migrationLayer.destroy();

      const map = mapRef.current;
      let replica = [100, 3, 1.5, 1, 0.7, 0.5, 0.35, 0.25, 0.17, 0.1];
      const replicas = (x) => {
        let a = 7.754953134958531;
        let b = -0.4968354938765751;
        return a * Math.exp(b * x);
      };
      var data = [];
      let enlaces = [];

      let processedEnlacesNames = new Set();
      mapa.enlaces.map((enlace, index) => {
        let cont = 0;
        let posi = true;
        let trafficInput = trafficData[index]?.input_traffic_mean_bps
          ? trafficData[index]?.input_traffic_mean_bps
          : 0;

        let trafficOutput = trafficData[index]?.output_traffic_mean_bps
          ? trafficData[index]?.output_traffic_mean_bps
          : 0;
        let trafficTotal = parseFloat(trafficInput) + parseFloat(trafficOutput);

        let trafficDataPercent =
          (parseFloat(trafficTotal) / (enlace.capacidade * 1e9)) * 100;

        data.map((enlaceData) => {
          if (
            enlaceData.from[0] == parseFloat(enlace.coordenadaEntradaX) &&
            enlaceData.from[1] == parseFloat(enlace.coordenadaEntradaY) &&
            enlaceData.to[0] == parseFloat(enlace.coordenadaSaidaX) &&
            enlaceData.to[1] == parseFloat(enlace.coordenadaSaidaY)
          ) {
            //verifica se para a coordenada em o lado é false, ele enetende que ambos os lados estão preenchidos e incrementa o angulo e reseta o lado
            posi = true;
            if (enlaceData.side == false || cont == 0) cont++;
            else posi = false;
          }
        });

        data = [
          ...data,
          {
            id: index,
            from: [enlace.coordenadaEntradaX, enlace.coordenadaEntradaY],
            to: [enlace.coordenadaSaidaX, enlace.coordenadaSaidaY],
            labels: [null, null],
            color: selectCorVel(trafficDataPercent)[0],
            velocity: selectCorVel(trafficDataPercent)[1],
            selectAngle: cont == 0 ? 100 : replicas(cont),
            side: posi,
          },
        ];

        if (dataMap.length < 1) {
          const hostDeEntrada = JSON.stringify({
            coordenadaY: enlace?.coordenadaEntradaY,
            coordenadaX: enlace?.coordenadaEntradaX,
            host: enlace?.hostEntrada.nome,
          });
          const hostDeSaida = JSON.stringify({
            coordenadaY: enlace?.coordenadaSaidaY,
            coordenadaX: enlace?.coordenadaSaidaX,
            host: enlace?.hostSaida.nome,
          });

          if (!processedEnlacesNames.has(hostDeEntrada)) {
            processedEnlacesNames.add(hostDeEntrada);

            L.marker([enlace.coordenadaEntradaY, enlace.coordenadaEntradaX], {
              icon: Icon,
              title: enlace?.hostEntrada.nome,
            }).addTo(map);

            L.tooltip([enlace.coordenadaEntradaY, enlace.coordenadaEntradaX], {
              offset: [2.5, -20],
              direction: "top",
              className: "custom-tooltip",
              permanent: true,
              content: enlace?.hostEntrada.nome,
            }).addTo(map);
          }
          if (!processedEnlacesNames.has(hostDeSaida)) {
            processedEnlacesNames.add(hostDeSaida);

            L.marker([enlace.coordenadaSaidaY, enlace.coordenadaSaidaX], {
              icon: Icon,
              title: enlace?.hostSaida.nome,
            }).addTo(map);

            L.tooltip([enlace.coordenadaSaidaY, enlace.coordenadaSaidaX], {
              offset: [2.5, -20],
              direction: "top",
              permanent: true,
              className: "custom-tooltip", // Adicione uma classe CSS personalizada
              content: enlace?.hostSaida.nome,
            }).addTo(map);
          }
        }
      });

      migrationLayer.setData(data);
      setDataMap(data);
    }
  }

  useEffect(() => {
    if (!mapRef.current) return; // Verifica se o mapa está definido
    const map = mapRef.current;
    if (!map) return; // Garante que map não é null
    anamition();
  }, [trafficData, firstRender]);

  useEffect(() => {
    if (!mapRef.current) return; // Verifica se o mapa está definido
    const map = mapRef.current;
    if (!map) return; // Garante que map não é null

    if (firstRender && map) {
      setFirstRender(false);
    }

    let newMigrationLayer = new L.migrationLayer({
      map: map,
      data: [],
    });
    if (!migrationLayer && map) {
      setmigrationLayer(newMigrationLayer);
      newMigrationLayer.addTo(map);
    }

    anamition();
    return () => {
      newMigrationLayer.destroy();
      mapRef.current = null;
    };
  }, [mapRef.current]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleExecultScriptCliente();
    }, 60000); // 60000 milissegundos = 1 minuto

    return () => {
      clearInterval(intervalId); // Limpa o intervalo ao desmontar o componente
    };
  }, []);
  useEffect(() => {
    handleExecultScriptCliente();
  }, []);
  async function handleExecultScriptCliente() {
    try {
      const response = await api.post("/mapas/scriptExecult", {
        acessoProdutoId: mapa.acessoProdutoId,
        host: hosts.toString(),
        interface: interfaces.toString(),
        nomeDoRelatorio: "teste",
        tipoRelatorio: "mapa",
      });
      setTrafficData(response.data);
    } catch (error) {
      console.error(error);
      setTrafficData([]);
    }
  }

  const coordenadas = [];

  if (Array.isArray(mapa.enlaces)) {
    mapa.enlaces.map((enlace, index) => {
      coordenadas.push([
        parseFloat(enlace.coordenadaEntradaY),
        parseFloat(enlace.coordenadaEntradaX),
      ]);
      coordenadas.push([
        parseFloat(enlace.coordenadaSaidaY),
        parseFloat(enlace.coordenadaSaidaX),
      ]);
    });
  }

  return (
    <Stack
      direction={"row"}
      gap={2}
      justifyContent={"space-between"}
      sx={styles.body}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          height: "100%",
        }}
      >
        <MapContainer
          key={`${mapa.id}-qualquerCoisa`}
          fullscreenControl={true}
          ref={mapRef}
          id="map"
          center={[-10, -48]}
          zoom={4}
          /* zoom={
            Array.isArray(coordenadas) && coordenadas.length >= 2
              ? calculateMaxDistance(coordenadas)
              : 7
          }*/
          style={{
            width: "100%",
            height: "400px",
            borderRadius: 3,
            leafletContainer: {},
            position: "relative",
          }}
          /* 
          center={
            Array.isArray(coordenadas) && coordenadas.length >= 2
              ? calculateCenterOfAverageDistance([coordenadas])
              : [0, 0]
          }*/
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            //url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
            attribution="&copy; OpenStreetMap contributors"
          />
          {mapa &&
            mapRef.current &&
            Array.isArray(dataMap) &&
            dataMap.map((enlace, index) => {
              if (true) {
                const map = mapRef.current;
                var fromPixel = map.latLngToContainerPoint(
                  new L.LatLng(
                    parseFloat(enlace.from[1]),
                    parseFloat(enlace.from[0])
                  )
                );
                var toPixel = map.latLngToContainerPoint(
                  new L.LatLng(
                    parseFloat(enlace.to[1]),
                    parseFloat(enlace.to[0])
                  )
                );

                var startX = parseFloat(fromPixel.x);
                var startY = parseFloat(fromPixel.y);
                var endX = parseFloat(toPixel.x);
                var endY = parseFloat(toPixel.y);

                // Existem vários círculos entre dois pontos, dois círculos podem ser determinados por dois pontos e o raio, e um dos círculos pode ser selecionado conforme necessário
                var Lo = Math.sqrt(
                  Math.pow(startX - endX, 2) + Math.pow(startY - endY, 2)
                );
                var m = (startX + endX) / 2; // 横轴中点
                var n = (startY + endY) / 2; // 纵轴中点
                var factor = enlace.selectAngle;
                var radius = Math.sqrt(
                  Math.pow(Lo / 2, 2) + Math.pow(Lo * factor, 2)
                );

                if (enlace.side) {
                  var centerX = (endY - startY) * factor + m;
                  var centerY = (startX - endX) * factor + n;
                  var endAngle = Math.atan2(startY - centerY, startX - centerX);
                  var startAngle = Math.atan2(endY - centerY, endX - centerX);
                } else {
                  var centerX = (startY - endY) * factor + m;
                  var centerY = (endX - startX) * factor + n;
                  var startAngle = Math.atan2(
                    startY - centerY,
                    startX - centerX
                  );
                  var endAngle = Math.atan2(endY - centerY, endX - centerX);
                }
                if (startAngle * endAngle < 0) {
                  if (startAngle < 0) {
                    startAngle += Math.PI * 2;
                    endAngle += Math.PI * 2;
                  } else {
                    endAngle += Math.PI * 2;
                  }
                }

                let rota = [];
                for (let i = startAngle; i <= endAngle; i = i + 0.0001) {
                  let x = centerX + Math.cos(i) * radius;
                  let y = centerY + Math.sin(i) * radius;
                  var point = L.point(x, y);
                  var fromLatLng = map.containerPointToLatLng(point);
                  rota.push([fromLatLng.lat, fromLatLng.lng]);
                }

                return (
                  <Polyline key={index} color="transparent" positions={rota}>
                    <Tooltip sticky={true}>
                      {mapa.enlaces[index]?.hostEntrada.nome} {"→"}{" "}
                      {mapa.enlaces[index]?.hostSaida.nome}
                      <br />
                      Input:{" "}
                      {converterBitsPorSegundo(
                        trafficData[index]?.input_traffic_mean_bps
                      ) || 0}
                      <br />
                      Output:{" "}
                      {converterBitsPorSegundo(
                        trafficData[index]?.output_traffic_mean_bps
                      ) || 0}
                    </Tooltip>
                  </Polyline>
                );
              }
            })}

          <Legend />
        </MapContainer>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          overflow: "auto",
          height: "400px",
        }}
      >
        {Array.isArray(mapa.enlaces) &&
          mapa.enlaces.map((enlace, index) => {
            return (
              <Box key={index}>
                <Stack direction={"row"} alignItems={"center"}>
                  <CellTower
                    fontSize="large"
                    color={
                      trafficData[index]?.input_traffic_mean_bps &&
                      trafficData[index]?.output_traffic_mean_bps &&
                      parseFloat(trafficData[index]?.input_traffic_mean_bps) !=
                        0 &&
                      parseFloat(trafficData[index]?.output_traffic_mean_bps) !=
                        0
                        ? "primary"
                        : "secondary"
                    }
                  />
                  <Stack sx={styles.mapInfoStack}>
                    <Typography sx={styles.mapInfoTitle}>
                      Host de entrada:
                    </Typography>
                    <Typography sx={styles.mapInfoText}>
                      {enlace?.hostEntrada?.nome}
                    </Typography>
                  </Stack>
                  <Stack sx={styles.mapInfoDataStack}>
                    <Typography sx={styles.mapInfoTitle}>Input:</Typography>
                    <Typography sx={styles.mapInfoText}>
                      {converterBitsPorSegundo(
                        trafficData[index]?.input_traffic_mean_bps
                      ) || 0}
                    </Typography>
                  </Stack>
                  <Stack sx={styles.mapInfoDataStack}>
                    <Typography sx={styles.mapInfoTitle}>Output:</Typography>
                    <Typography sx={styles.mapInfoText}>
                      {converterBitsPorSegundo(
                        trafficData[index]?.output_traffic_mean_bps
                      ) || 0}
                    </Typography>
                  </Stack>

                  <Stack sx={styles.mapInfoStack}>
                    <Typography sx={styles.mapInfoTitle}>
                      Host de saida:
                    </Typography>
                    <Typography sx={styles.mapInfoText}>
                      {enlace?.hostSaida?.nome}
                    </Typography>
                  </Stack>
                </Stack>
                <Divider />
              </Box>
            );
          })}
      </Box>
    </Stack>
  );
}
