import {
  Add,
  Close,
  ContentCopy,
  EditOutlined,
  Link,
  LockOpenOutlined,
  LockOutlined,
  RefreshOutlined,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  FormControl,
  Grow,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../../../services/api";
import AlertModal from "../../../alertModal";

export default function ModalShareMap({
  id = "",
  tipo = 0,
  openModalShare = false,
  setOpenModalShare = () => {},
  mapName = "",
  mapas = [],
  setSnackbar = () => {},
  mapToken = {},
  updateMapTokenInMaps = () => {},
  index = 0,
}) {
  const [mapsInternal, setMapsInternal] = useState([]);
  const [loading, setLoading] = useState(null);
  const [statusLink, setStatusLink] = useState(1);
  const [link, setLink] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [prevMapsInternal, setPrevMapsInternal] = useState([]);
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [tokenId, setTokenId] = useState("");
  const theme = useTheme();
  const styles = {
    stackStatus: {
      width: "100%",
      alignItems: "center",
      justifyContent: "space-between",
      height: "auto",
      pt: "4px",
      //backgroundColor: "rgba(0,0,0,0.08)",
      backgroundColor: theme.palette.background.cardStatus,
      borderRadius: "10px",
      paddingY: "5px",
    },
    boxStatus: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      p: 0.5,
    },
    stackIconStatus: {
      p: 1,
      ml: 1,
      //backgroundColor: "rgba(0,0,0,0.08)",
      backgroundColor: theme.palette.color.bgIcon,
      borderRadius: "50%",
    },
    chipMaps: {
      width: "100%",
      alignItems: "center",
      justifyContent: "space-between",
      height: "auto",
      borderRadius: "10px",
      paddingY: "5px",
    },
    boxChipMaps: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      flexWrap: "wrap",
      listStyle: "none",
      p: 0.5,
      m: 0,
    },
  };
  const handleClose = () => {
    // if (checkChanges) {
    //   setOpenAlertModal(true);
    //   return;
    // }
    setOpenModalShare(false);
    setEditMode(false);
    setStatusLink(mapToken?.status);
    if (editMode) {
      setMapsInternal(prevMapsInternal);
    }
  };
  useEffect(() => {
    if (mapToken?.token) {
      setTokenId(mapToken.id);
      const url = `${window.location.origin}/easyMaps/public/${
        tipo === 0 ? "geo" : "top"
      }/${id}/${mapToken.token}`;
      setLink(url);
      setStatusLink(mapToken.status);
      setMapsInternal(
        mapToken.MapsInternal.map(({ mapsId }) => {
          return mapas.find((map) => map.mapRefId === mapsId);
        }) ?? []
      );
    }
  }, [mapToken]);

  async function handleApiCreateMapToken() {
    try {
      setLoading(true);
      const response = await api.post("/easyMaps/map/token/create", {
        mapsId: id,
        mapsInternalIds:
          mapsInternal.map(({ mapRefId }) => {
            return { mapsId: mapRefId };
          }) ?? [],
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível criar o link do mapa",
          severity: "error",
        });
      } else {
        if (response.data) {
          updateMapTokenInMaps(response.data, index);
          const url = `${window.location.origin}/easyMaps/public/${
            tipo === 0 ? "geo" : "top"
          }/${id}/${response.data.token}`;
          setLink(url);
          setTokenId(response.data?.id);
          setEditMode(false);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error:${
          error?.message || "Error: Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  }
  async function handleApiUpdateMapToken() {
    try {
      setLoading(true);

      const response = await api.put("/easyMaps/map/token/update", {
        id: tokenId,
        status: statusLink,
        mapsInternalIds:
          mapsInternal.map(({ mapRefId }) => {
            return { mapsId: mapRefId, mapTokenId: tokenId };
          }) ?? [],
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível criar o link do mapa",
          severity: "error",
        });
      } else {
        if (response.data) {
          updateMapTokenInMaps(response.data, index);
          const url = `${window.location.origin}/easyMaps/public/${
            tipo === 0 ? "geo" : "top"
          }/${id}/${response.data.token}`;
          setLink(url);
          setEditMode(false);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error:${
          error?.message || "Error: Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
      setMapsInternal(prevMapsInternal);
    } finally {
      setLoading(false);
    }
  }
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setSnackbar({
      children: "Campo copiado!",
      severity: "info",
    });
  };
  return (
    <>
      <Dialog open={openModalShare} onClose={handleClose}>
        <DialogContent>
          <Stack
            width="450px"
            gap={1}
            direction={"row"}
            justifyContent={"space-between"}
          >
            <DialogTitle>{`Compartilhar "${mapName}"`} </DialogTitle>
            {!editMode && link && (
              <IconButton
                onClick={() => {
                  setPrevMapsInternal(mapsInternal);
                  setEditMode(true);
                }}
              >
                <EditOutlined />
              </IconButton>
            )}
          </Stack>
          <Stack width="450px" gap={1}>
            <DialogContentText>
              {"Compartilhar mapas e POPs internos"}
            </DialogContentText>
            {editMode ? (
              <Grow in={editMode}>
                <Stack direction={"row"} overflow={"visible"} width={"100%"}>
                  <Autocomplete
                    multiple
                    disableCloseOnSelect
                    getOptionLabel={(option) => option?.titulo}
                    isOptionEqualToValue={(option, value) =>
                      option.mapRefId === value.mapRefId
                    }
                    disabled={mapas?.length == 0 || !editMode}
                    value={mapsInternal}
                    onChange={(event, newValue) => {
                      setMapsInternal(newValue);
                      if (!newValue.length > 0 && !link) {
                        setEditMode(false);
                      }
                    }}
                    id="combo-box-demo"
                    size="small"
                    color="primary"
                    options={mapas || []}
                    renderOption={(props, option) => (
                      <li {...props} key={option.mapRefId}>
                        {option.titulo}
                      </li>
                    )}
                    renderInput={(params) => (
                      <>
                        <TextField
                          {...params}
                          color="primary"
                          sx={{ width: "100%", minWidth: "450px" }}
                          label={`Mapas Internos de "${mapName}"`}
                          helperText={
                            !mapas.length > 0 &&
                            "Esse mapa não possui mapas ou POPs internos"
                          }
                        />
                      </>
                    )}
                  />
                </Stack>
              </Grow>
            ) : mapsInternal.length > 0 ? (
              <Stack direction="row" sx={styles.chipMaps}>
                <Grow in={mapsInternal.length > 0 && !editMode}>
                  <Box sx={styles.boxChipMaps} gap={"5px"} component="ul">
                    {mapsInternal.map((maps, index) => (
                      <li margin={0.5} key={index}>
                        <Chip
                          disabled={!statusLink}
                          color={statusLink ? "primary" : "default"}
                          label={maps.titulo}
                        />
                      </li>
                    ))}
                  </Box>
                </Grow>
              </Stack>
            ) : (
              <Tooltip
                placement="bottom-start"
                title={
                  !mapas.length > 0 &&
                  "Esse mapa não possui mapas ou POPs internos"
                }
              >
                {" "}
                <Button
                  variant="outlined"
                  startIcon={<Add />}
                  disabled={!mapas.length > 0}
                  onClick={() => setEditMode(true)}
                >
                  Adicionar
                </Button>
              </Tooltip>
            )}
            {link && (
              <>
                <DialogContentText>Status</DialogContentText>
                {editMode ? (
                  <Grow in={!!editMode}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="select-stats-label">Status</InputLabel>
                      <Select
                        labelId="select-stats-label"
                        id="select-stats"
                        value={statusLink}
                        label="Status"
                        onChange={(e) => {
                          setStatusLink(e.target.value);
                        }}
                      >
                        <MenuItem value={1}>Ativo</MenuItem>
                        <MenuItem value={0}>Inativo</MenuItem>
                      </Select>
                    </FormControl>
                  </Grow>
                ) : (
                  <Stack direction="row" sx={styles.stackStatus}>
                    <Grow in={link && !editMode}>
                      <Box sx={styles.boxStatus} gap={2}>
                        {statusLink ? (
                          <>
                            <Stack sx={styles.stackIconStatus}>
                              <LockOpenOutlined color="action" />
                            </Stack>
                            <Stack direction={"column"}>
                              <Typography variant="body1">
                                Link ativo
                              </Typography>
                              <DialogContentText>
                                O link existe e pode ser usado por qualquer um
                              </DialogContentText>
                            </Stack>
                          </>
                        ) : (
                          <>
                            {" "}
                            <Stack sx={styles.stackIconStatus}>
                              <LockOutlined />
                            </Stack>
                            <Stack direction={"column"}>
                              <Typography variant="body1">
                                Link inativo
                              </Typography>
                              <DialogContentText>
                                O link existe mas está desativado
                              </DialogContentText>
                            </Stack>
                          </>
                        )}
                      </Box>
                    </Grow>
                  </Stack>
                )}
                {!!link && !editMode && (
                  <Stack sx={{ mt: "10px", gap: "10px" }}>
                    <DialogContentText>
                      {"Link de compartilhamento:"}
                    </DialogContentText>{" "}
                    <Grow in={!!link}>
                      <Stack>
                        <TextField
                          disabled={!statusLink}
                          size="small"
                          value={link}
                          slotProps={{
                            input: {
                              endAdornment: (
                                <InputAdornment position="end" size="small">
                                  <Tooltip title="Copiar link" placement="top">
                                    <IconButton
                                      disabled={!statusLink}
                                      edge="end"
                                      onClick={() => {
                                        //window.open(link, "_blank");
                                        //handleApiUpdateMapToken();
                                        handleCopy(link);
                                      }}
                                    >
                                      <ContentCopy />
                                    </IconButton>{" "}
                                  </Tooltip>
                                </InputAdornment>
                              ),
                            },
                          }}
                        />
                      </Stack>
                    </Grow>
                  </Stack>
                )}
              </>
            )}
            <DialogActions>
              <Stack direction="row" gap={2} mt="12px">
                {!link ? (
                  <>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Cancelar
                    </Button>
                    <LoadingButton
                      loading={loading}
                      variant="contained"
                      onClick={() => {
                        //setLoading(true);
                        handleApiCreateMapToken();
                      }}
                    >
                      Gerar Link
                    </LoadingButton>
                  </>
                ) : (
                  <>
                    {editMode ? (
                      <>
                        {" "}
                        <Button
                          variant="outlined"
                          onClick={() => {
                            setEditMode(false);
                            setStatusLink(mapToken?.status);
                            setMapsInternal(prevMapsInternal);
                          }}
                        >
                          Cancelar
                        </Button>{" "}
                        <LoadingButton
                          loading={loading}
                          variant="contained"
                          onClick={() => {
                            handleApiUpdateMapToken();
                            //handleClose();
                          }}
                        >
                          Salvar
                        </LoadingButton>
                      </>
                    ) : (
                      <Button
                        variant="contained"
                        onClick={() => {
                          handleClose();
                        }}
                      >
                        Concluir
                      </Button>
                    )}
                  </>
                )}
              </Stack>
            </DialogActions>
          </Stack>
        </DialogContent>
      </Dialog>
      <AlertModal
        openModal={openAlertModal}
        setOpenModal={setOpenAlertModal}
        //confirmar={disableButton}
        //handleButton={handleApiDelete}
        severity={"error"}
        singleButton={false}
      />
    </>
  );
}
