import L from "leaflet";
import { useTheme } from "@mui/material";
import { useMap } from "react-leaflet";
import { useEffect, useRef } from "react";

const PointIcon = ({
  point,
  index,
  updateEdge = () => {},
  updateEdgeEnd = () => {},
  removeEdge = () => {},
}) => {
  const map = useMap();
  const theme = useTheme();
  const markerRef = useRef(null);

  useEffect(() => {
    // Criar o ícone do marcador
    const icon = L.divIcon({
      html: `
        <div style="
          background-color: ${theme.palette.color.zabbixSeverty.selectedTranslucid};
          width: 15px;
          height: 15px;
          border-radius: 50%;
        "/>`,
    });

    // Adicionar o marcador ao mapa
    const marker = L.marker([point.lat, point.long], {
      draggable: true,
      autoPan: true,
      icon: icon,
    }).addTo(map);

    markerRef.current = marker;

    // Evento de arrastar
    const onDrag = (e) => {
      const latLng = e.target.getLatLng();
      if (latLng) {
        updateEdge(index, {
          newPosition: {
            lat: latLng.lat,
            lng: latLng.lng,
          },
          index: index,
        });
      }
    };
    const onDragEnd = (e) => {
      const latLng = e.target.getLatLng();
      if (latLng) {
        updateEdgeEnd(index, {
          y: latLng.lat,
          x: latLng.lng,
        });
      }
    };
    const onContext = (e) => {
      removeEdge(index);
    };

    // Adicionar o evento de arrastar ao marcador
    marker.on("drag", onDrag);
    marker.on("dragend", onDragEnd);
    marker.on("contextmenu", onContext);

    // Limpar o marcador quando o componente for desmontado
    return () => {
      marker.off("drag", onDrag); // Remover o evento de arrastar
      marker.off("dragend", onDragEnd); // Remover o evento de arrastar
      if (map.hasLayer(marker)) {
        map.removeLayer(marker); // Remover o marcador do mapa
      }
    };
  }, [map]);

  return null;
};

export default PointIcon;
