import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../../../../services/api";

export default function CreateModalCDN(params) {
  const {
    setOpenCreateModal,
    openCreateModal,
    setSnackbar,
    setLoadingTable,
    styles,
    handleNewCdn,
    validarIp,
  } = params;
  const [disableCreateButton, setDisableCreateButton] = useState(false);
  const [asNum, setAsNum] = useState("");
  const [asName, setAsName] = useState("");
  const [tipo, setTipo] = useState("");
  const [bloco, setBloco] = useState("");

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && disableCreateButton === false) {
      handleApiPostCdn();
    }
  };
  const handleHelperText = (ip) => {
    if (ip) {
      return validarIp(ip) === 1
        ? "O endereço IPv4 é válido!"
        : validarIp(ip) === 2
        ? "O endereço IPv6 é válido!"
        : "Formato de IP inválido";
    }
    return null;
  };
  function clearModal() {
    setAsNum("");
    setAsName("");
    setTipo("");
    setBloco("");
  }
  useEffect(() => {
    if (!asName || !asNum || !tipo || validarIp(bloco) === 0) {
      setDisableCreateButton(true);
    } else {
      setDisableCreateButton(false);
    }
  }, [asName, asNum, bloco, tipo]);

  async function handleApiPostCdn() {
    try {
      setLoadingTable(true);
      setDisableCreateButton(true);
      const response = await api.post("/relatoriosFlow/cdn/create", {
        as_num: asNum,
        as_name: asName,
        tipo,
        bloco,
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados Salvos com sucesso",
          severity: "success",
        });
        if (response.data) {
          handleNewCdn(response.data);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoadingTable(false);
      setDisableCreateButton(false);
      setOpenCreateModal(false);
      clearModal();
    }
  }
  return (
    <>
      <Modal
        open={openCreateModal}
        onClose={() => {
          setOpenCreateModal(false);
          clearModal();
        }}
        sx={{ alignItems: "center", justifyContent: "center", display: "flex" }}
      >
        <Paper sx={styles.paperModal} elevation={3}>
          <DialogTitle color="primary">Novo CDN </DialogTitle>{" "}
          <DialogContent sx={styles.dialogContent}>
            <Stack direction="row" spacing={"20px"} sx={{ mt: 1 }}>
              <TextField
                sx={{ width: "100%" }}
                id="textfield-porta"
                label="AS Nome"
                value={asName}
                onChange={(e) => setAsName(e.target.value)}
              />
            </Stack>
            <Stack direction="row" spacing={"20px"}>
              <TextField
                sx={styles.TextField}
                id="textfield-asnnum"
                label="ASN"
                type="number"
                value={asNum}
                onChange={(e) => setAsNum(e.target.value)}
              />
              <TextField
                sx={styles.TextField}
                id="textfield-tipo"
                label="Tipo"
                value={tipo}
                onChange={(e) => setTipo(e.target.value)}
              />
            </Stack>
            <Stack direction="row" spacing={"20px"}>
              <TextField
                sx={{ width: "100%" }}
                id="textfield-bloco"
                label="Bloco"
                onKeyDown={handleKeyDown}
                value={bloco}
                error={bloco ? !validarIp(bloco) : false}
                helperText={handleHelperText(bloco)}
                onChange={(e) => {
                  setBloco(e.target.value);
                }}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              onClick={() => {
                setOpenCreateModal(false);
                clearModal();
              }}
            >
              Cancelar
            </Button>
            <Button
              disabled={disableCreateButton}
              onClick={() => {
                handleApiPostCdn();
              }}
            >
              Criar
            </Button>
          </DialogActions>
        </Paper>
      </Modal>
    </>
  );
}
