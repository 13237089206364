import { Box, Paper, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
};

export default function EasyFlow() {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  return (
    <Box sx={styles.container}>
      <Paper
        sx={{
          borderRadius: "15px",
          minWidth: "260px",
          maxWidth: "870px",
        }}
        elevation={3}
      >
        <Tabs
          variant="scrollable"
          scrollButtons={true}
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <Tab
            label={"Relatórios Flow"}
            iconPosition="start"
            value={0}
            sx={styles.tab}
            onClick={() => {
              navigate("/easyFlow/relatoriosFlow");
            }}
          />
        </Tabs>
      </Paper>

      <Box sx={{ width: "100%" }}>
        <Outlet />
      </Box>
    </Box>
  );
}
