import {
  Box,
  Card,
  Paper,
  Stack,
  Divider,
  Typography,
  IconButton,
  createSvgIcon,
} from "@mui/material";

import { Edit, PublicOutlined } from "@mui/icons-material";

import { useState } from "react";

import "../../../../componentes/leaflet-migrations";
import "../../../../componentes/leaflet-fullscreen/Leaflet.fullscreen";
import { ReactComponent as IconEasyMaps } from "../../../../assets/icons/IconEasyMaps.svg";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import EditScreen from "../editScreen/";

import LeafletMapa from "./leaflatMapa";

dayjs.extend(utc);
dayjs.extend(timezone);

const styles = {
  paperContainer: {
    padding: "16px ",
    display: "flex",
    alignItems: "center",
    position: "relative",
    width: "100%",
    borderRadius: 3,
    mt: 5.5,
  },
  body: {
    width: "100%",
  },

  mapInfoStack: {
    padding: "12px 16px",
    width: "250px",
  },
  mapInfoDataStack: {
    padding: "12px 16px",
    width: "120px",
  },
  mapInfoTitle: {
    fontSize: "0.75rem",
    lineHeight: 1.25,
    fontWeight: 600,
    letterSpacing: "0.033em",
    color: "primary.main",
  },
  mapInfoText: {
    fontSize: "0.875rem",
    lineHeight: 1.5,
    fontWeight: 400,
    textTransform: "capitalize",
  },
};

export default function MyMap(props) {
  const { mapa, updateMapa, deleteMapa } = props;
  const [openModalUpdate, setOpenModalUpdate] = useState(false);
  const EasyMapsIcon = createSvgIcon(<IconEasyMaps />);

  return (
    <Box
      sx={{
        width: "calc((100% - 60px))",
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Card
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "16rem",
          height: "4rem",
          opacity: 1,
          bgcolor: "secondary.main",
          borderRadius: 3,
          position: "absolute",
          ml: 4,
          padding: 0,
        }}
        elevation={1}
      >
        <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={1}
        >
          <EasyMapsIcon
            fontSize="small"
            sx={{ color: "#E8E6E3", marginBottom: 1.5 }}
          />

          <Typography
            sx={{
              fontSize: "1rem",
              lineHeight: 1.625,
              fontWeight: 700,
              letterSpacing: "0.0075em",
              opacity: 1,
              color: "white",
              marginBottom: 1,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: "9rem",
            }}
          >
            {mapa.titulo ? mapa.titulo : "Mapa de backbone"}
          </Typography>
          <IconButton
            onClick={() => {
              setOpenModalUpdate(true);
            }}
          >
            <Edit fontSize="small" sx={{ color: "white", marginBottom: 1.5 }} />
          </IconButton>
        </Stack>
      </Card>
      <Paper sx={styles.paperContainer} elevation={3}>
        {!openModalUpdate && <LeafletMapa mapa={mapa} key={mapa.id} />}
        {openModalUpdate && (
          <EditScreen
            setOpenModalUpdate={setOpenModalUpdate}
            mapa={mapa}
            updateMapa={updateMapa}
            deleteMapa={deleteMapa}
            key={`${mapa.id}-EditScreen`}
          />
        )}
      </Paper>
    </Box>
  );
}
