import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { io } from "socket.io-client";

import Layout from "./pages/layout";
import LayoutEasyMaps from "./pages/layoutEasymaps";
import LayoutEasyMapsPublic from "./pages/layoutEasymapsPublic";
import Construcao from "./pages/construcao";
import Login from "./pages/login";
import Perfil from "./pages/perfil";
import HomeIn from "./pages/homeIn";
import IotShopping from "./pages/iotShopping";
import IotProduto from "./pages/iotProduto";
import IotAdministrcao from "./pages/iotAdministracao";
//import Acessos from "./pages/Acessos";
import Suporte from "./pages/suporte";
import Chat from "./pages/suporte/chat";
import RelatoriosFlow from "./pages/easyFlow/relatoriosFlow";
import EasyFlow from "./pages/easyFlow";
import Administracao from "./pages/administracao";
import L from "leaflet";
//import CriaUsuario from "./pages/criaUsuario";
//import Empresas from "./pages/empresas";
import Acesso from "./pages/acesso";
import Cadastro from "./pages/cadastro";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CssBaseline } from "@mui/material";
import EasyMaps from "./pages/easyMaps";
import EasyMapsOld from "./pages/easymapsOld";
import EasyReport from "./pages/easyReport";
import EasyAdd from "./pages/easyAdd";
//import HostsEasyAdd from "./pages/easyAdd/paginaInicial";
import foto from "./assets/fullscreen.png";

import SolicitarRedefinirSenha from "./pages/solicitarRedefinirSenha";
import RedefinirSenha from "./pages/redefinirSenha";
import AgendarRelatorio from "./pages/easyReport/agendarRelatorio";
import SelectionPage from "./pages/easyReport/selectionPage";
import NovoRelatorio from "./pages/easyReport/agendarRelatorio/novoRelatorio";
import HistoricoRelatorio from "./pages/easyReport/agendarRelatorio/historicoRelatorio";
import GerarRelatorio from "./pages/easyReport/gerarRelatorio";
import { myTheme } from "./themes";
import MapaGeolocalizado from "./pages/easyMaps/geolocalizado";
import MapaTopologia from "./pages/easyMaps/topologia";
import { socket, socketCliente } from "./services/socket";
import MapaGeolocalizadoPublic from "./pages/easyMapsPublic/geolocalizado";
import MapaTopologiaPublic from "./pages/easyMapsPublic/topologia";
import NotFoundMaps from "./pages/easyMaps/notFound";

function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [checkLogin, setCheckLogin] = useState(false);
  const [colorMode, setColorMode] = useState(prefersDarkMode);
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [isConnectedCliente, setIsConnectedCliente] = useState(
    socketCliente.connected
  );
  const [easyMaps, setEasyMaps] = useState(false);

  useEffect(() => {
    setColorMode(prefersDarkMode);
  }, [prefersDarkMode]);
  useEffect(() => {
    let colorModeStorage = JSON.parse(localStorage.getItem("3"));
    if (colorModeStorage) {
      setColorMode(colorModeStorage.colorMode);
    }
  }, []);

  useEffect(() => {
    function onConnectCliente() {
      setIsConnectedCliente(true);
      socketCliente.emit(
        "first_connection",
        `Frontend cliente estabeleceu conexão, id conection: ${socketCliente.id}`
      );
    }
    function onConnect() {
      setIsConnected(true);
      socket.emit(
        "first_connection",
        `Frontend cliente estabeleceu conexão, id conection: ${socket.id}`
      );
    }
    function onDisconnect() {
      setIsConnected(false);
    }
    function onDisconnectCliente() {
      setIsConnectedCliente(false);
    }

    function connectErrorEvent(error) {
      console.log("Socket error - erro de conexão:", error);
    }
    function reconnectAttemptEvent(attempt) {
      console.log(
        `Socket warn - o servido esta tentando ser reconectar. N de tentativas: ${attempt}`
      );
    }
    function reconnectErrorEvent(error) {
      console.log("Socket error - erro de reeconexão:", error);
    }
    function reconnectEvent(attempt) {
      console.log("Socket success: servidor reeconectado com sucesso");
    }
    if (!checkLogin) {
      if (isConnected) socket.disconnect();
      if (isConnectedCliente) socketCliente.disconnect();
    }

    if (checkLogin) {
      socketCliente.connect();
      socket.connect();

      socketCliente.on("connect", onConnectCliente);
      socketCliente.on("disconnect", onDisconnectCliente);
      socketCliente.on("connect_error", connectErrorEvent);
      socketCliente.io.on("reconnect_attempt", reconnectAttemptEvent);
      socketCliente.io.on("reconnect_error", reconnectErrorEvent);
      socketCliente.io.on("reconnect", reconnectEvent);

      socket.on("connect", onConnect);
      socket.on("disconnect", onDisconnect);
      socket.on("connect_error", connectErrorEvent);
      socket.io.on("reconnect_attempt", reconnectAttemptEvent);
      socket.io.on("reconnect_error", reconnectErrorEvent);
      socket.io.on("reconnect", reconnectEvent);
      return () => {
        socketCliente.off("connect", onConnectCliente);
        socketCliente.off("disconnect", onDisconnect);
        socketCliente.off("connect_error", connectErrorEvent);
        socketCliente.io.off("reconnect_attempt", reconnectAttemptEvent);
        socketCliente.io.off("reconnect_error", reconnectErrorEvent);
        socketCliente.io.off("reconnect", reconnectEvent);

        socket.off("connect", onConnect);
        socket.off("disconnect", onDisconnect);
        socket.off("connect_error", connectErrorEvent);
        socket.io.off("reconnect_attempt", reconnectAttemptEvent);
        socket.io.off("reconnect_error", reconnectErrorEvent);
        socket.io.off("reconnect", reconnectEvent);
      };
    }
  }, [checkLogin]);

  useEffect(() => {
    const fullscreenStyle = `
    .custom-tooltip{
      background-color: rgba(255,255,255,0.7);
      border: none;
    }
    .leaflet-interactive{
      outline:none
    }

    .leaflet-touch .leaflet-control-fullscreen a {
      background-position: 2px 2px;
    }
    .leaflet-fullscreen-on .leaflet-control-fullscreen a {
      background-position:0 -26px;
    }
    .leaflet-touch.leaflet-fullscreen-on .leaflet-control-fullscreen a {
      background-position: 2px -24px;
    }
  
    /* Do not combine these two rules; IE will break. */
    .leaflet-container:-webkit-full-screen {
      width:100%!important;
      height:100%!important;
    }
    .leaflet-container.leaflet-fullscreen-on {
      width:100%!important;
      height:100%!important;
    }
  
    .leaflet-pseudo-fullscreen {
      position:fixed!important;
      width:100%!important;
      height:100%!important;
      top:0!important;
      left:0!important;
      z-index:99999;
    }
  
    @media
      (-webkit-min-device-pixel-ratio:2),
      (min-resolution:192dpi) {
        .leaflet-control-fullscreen a {
          background-image:url(fullscreen@2x.png);
        }
    }`;
    // Cria a folha de estilo embutida para aplicar o filtro ao Leaflet
    const style = colorMode
      ? `
      .leaflet-layer,
      .leaflet-control-zoom-in,
      .leaflet-control-zoom-out,
      .leaflet-control-attribution {
        filter: invert(100%) hue-rotate(180deg) brightness(60%) contrast(95%) sepia(20%) grayscale(30%);
        //filter: invert(100%) hue-rotate(180deg) brightness(100%) contrast(85%) ;
      }
      .leaflet-control-fullscreen a {
        filter: invert(100%);
        background:${"#f2f2f2"} url(${foto}) no-repeat 0 0;
       
        background-size:26px 52px;
      }
      .leaflet-div-icon {
        border: none!important;
        background: transparent!important;
   
      }
      .leaflet-tooltip {
        background-color:#3d3d3d;
        border: 0px;
        box-shadow: none;
    }
  
    .leaflet-tooltip-top:before {
      
      margin-top: -12px;
      border-top-color:#3d3d3d;
    
      }
    ` + fullscreenStyle
      : ` 
        .leaflet-layer,
      .leaflet-control-attribution {
        //filter: invert(100%) hue-rotate(180deg) brightness(60%) contrast(95%) sepia(20%) grayscale(30%);
        filter: brightness(100%) contrast(85%)   grayscale(30%);
      }
    .leaflet-tooltip {
      
      border: 0px;
      box-shadow: none;
  }
    .leaflet-control-fullscreen a {
      background:${"#fff"} url(${foto}) no-repeat 0 0;
      background-size:26px 52px;
    }
    .leaflet-div-icon {
      border: none!important;
      background: transparent!important;
    }
 
    ::-webkit-scrollbar {
      width: 10px;
    }
    ::-webkit-scrollbar-track {
      background: #cecece;
    }
    ::-webkit-scrollbar-thumb {
      background: #575e62;
    }
    ::-webkit-scrollbar-thumb:hover {
      background:${theme.palette.secondary.main}
    }
    ` + fullscreenStyle;

    const styleElement = L.DomUtil.create("style", "leaflet-custom-style");

    styleElement.type = "text/css";
    if (styleElement.styleSheet) {
      styleElement.styleSheet.cssText = style;
    } else {
      styleElement.appendChild(document.createTextNode(style));
    }

    document.getElementsByTagName("head")[0].appendChild(styleElement);

    // Remove a folha de estilo embutida quando o componente é desmontado
    return () => {
      document.getElementsByTagName("head")[0].removeChild(styleElement);
    };
  }, [colorMode]);

  useEffect(() => {
    // Define o estilo a ser aplicado com base no modo de cor
    const style = colorMode
      ? `::-webkit-scrollbar-track {
        background: #1E1E1E; 
      }
      ::-webkit-scrollbar-thumb {
        background: #555;
      }
      ::-webkit-scrollbar {
        width: 10px; 
        height:10px
      }
        ::-webkit-scrollbar-thumb:hover {
          background: #474747;
        }
     `
      : `::-webkit-scrollbar-track {
        background: #e2e2e2;
      }
      ::-webkit-scrollbar-thumb {
        background: #b3b3b3; 
        }

     ::-webkit-scrollbar-thumb:hover {
           background: #8f8e8e; 
       
      }
      ::-webkit-scrollbar {
        width: 10px;
        height:10px
       
      }`;

    // Cria o elemento <style> e adiciona o CSS
    const styleElement = document.createElement("style");
    styleElement.type = "text/css";

    // Verifica compatibilidade para inserir o CSS
    if (styleElement.styleSheet) {
      styleElement.styleSheet.cssText = style;
    } else {
      styleElement.appendChild(document.createTextNode(style));
    }
    // Adiciona o elemento <style> ao <head> do documento
    document.head.appendChild(styleElement);
    // Remove o estilo ao desmontar o componente
    return () => {
      document.head.removeChild(styleElement);
    };
  }, [colorMode]);

  const theme = React.useMemo(() => myTheme(colorMode), [colorMode]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route exact path="/login" element={<Login />} />
            <Route
              exact
              path="/resetPassword/:token/:id"
              element={<RedefinirSenha />}
            />

            <Route
              exact
              path="/resetPassword"
              element={<SolicitarRedefinirSenha />}
            />

            <Route path="*" element={<Navigate replace to="/login" />} />
            <Route path="/cadastro" element={<Cadastro />} />

            {/* layout EasyMaps */}
            <Route
              element={
                <LayoutEasyMaps
                  socket={socket}
                  socketCliente={socketCliente}
                  colorMode={colorMode}
                  setColorMode={setColorMode}
                  setCheckLogin={(data) => {
                    setCheckLogin(data);
                  }}
                />
              }
            >
              <Route path="/easyMaps" element={<EasyMaps />} />
              <Route path="/easyMaps/notFound" element={<NotFoundMaps />} />
              <Route
                path="/easyMaps/geo/*"
                element={<Navigate replace to="/easyMaps" />}
              />
              <Route
                path="/easyMaps/geo/:id"
                element={<MapaGeolocalizado socketCliente={socketCliente} />}
              />
              <Route
                path="/easyMaps/top/:id"
                element={<MapaTopologia socketCliente={socketCliente} />}
              />
            </Route>
            <Route
              element={
                <LayoutEasyMapsPublic
                  socket={socket}
                  socketCliente={socketCliente}
                  colorMode={colorMode}
                  setColorMode={setColorMode}
                  setCheckLogin={(data) => {
                    setCheckLogin(data);
                  }}
                />
              }
            >
              {/* <Route
                path="/easyMaps/geo/*"
                element={<Navigate replace to="/easyMaps" />}
              /> */}
              <Route path="/easyMaps/notFound" element={<NotFoundMaps />} />
              <Route
                path="/easyMaps/public/geo/:id/:token"
                element={
                  <MapaGeolocalizadoPublic socketCliente={socketCliente} />
                }
              />
              <Route
                path="/easyMaps/public/top/:id/:token"
                element={<MapaTopologiaPublic socketCliente={socketCliente} />}
              />
            </Route>
            <Route
              element={
                <Layout
                  socket={socket}
                  socketCliente={socketCliente}
                  colorMode={colorMode}
                  setColorMode={setColorMode}
                  setCheckLogin={(data) => {
                    setCheckLogin(data);
                  }}
                />
              }
            >
              <Route path="/acesso" element={<Acesso />} />
              <Route path="/dev" element={<Construcao />} />
              <Route path="/easyWatch" element={<Construcao />} />
              <Route path="/easyMapsOld/*" element={<EasyMapsOld />} />
              <Route path="/iot">
                <Route
                  path="/iot"
                  element={<Navigate replace to="/iot/shopping" />}
                />
                <Route path="/iot/shopping" element={<Construcao />} />
                <Route path="/iot/administracao" element={<Construcao />} />
                <Route path="/iot/produto/:id" element={<Construcao />} />
              </Route>
              <Route path="/vendar" element={<Construcao />} />
              <Route path="/financeiro" element={<Construcao />} />
              <Route path="/homeIn" element={<HomeIn />} />
              <Route path="/administracao" element={<Administracao />} />
              <Route path="/perfil" element={<Perfil />} />
              <Route
                path="/easyAdd"
                element={<EasyAdd socket={socketCliente} />}
              />
              {/* <Route path="/easyAdd/hosts" element={<EasyAdd />} /> */}
              <Route path="/easyReport" element={<EasyReport />}>
                <Route
                  path="/easyReport/*"
                  element={
                    <Navigate replace to="/easyReport/selecionarPagina" />
                  }
                />
                <Route
                  path="/easyReport/selecionarPagina"
                  element={<SelectionPage />}
                />
                <Route
                  path="/easyReport/agendar"
                  element={<AgendarRelatorio />}
                />
                <Route path="/easyReport/gerar" element={<GerarRelatorio />} />
                <Route
                  path="/easyReport/agendar/novoRelatorio"
                  element={<NovoRelatorio />}
                />
                <Route
                  path="/easyReport/agendar/historico/:relatorioId"
                  element={<HistoricoRelatorio />}
                />
              </Route>
              <Route path="/easySGP" element={<Construcao />} />

              <Route path="/suporte" element={<Suporte socket={socket} />}>
                <Route path="/suporte/:chatId" element={<Chat />} />
              </Route>
              <Route path="/documentacao" element={<Construcao />} />
              <Route path="/easyFlow" element={<EasyFlow />}>
                <Route
                  path="/easyFlow/relatoriosFlow"
                  element={<RelatoriosFlow />}
                />
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
