import {
  Autocomplete,
  Divider,
  InputAdornment,
  TextField,
  useTheme,
} from "@mui/material";
import { GridCellModes, useGridApiContext } from "@mui/x-data-grid";
import { useEffect } from "react";

export default function EditCellAutoCompleteInterface({
  id,
  field,
  value,
  lista,
  disabled = false,
  pre = true,
  selected,
  setHostsEdit = () => {},
}) {
  const theme = useTheme();
  const apiRef = useGridApiContext();

  const options = lista.map((option) => option[field]);

  const onChange = (event, newInputValue) => {
    if (pre) {
      apiRef.current.setEditCellValue({
        id,
        field,
        value: newInputValue,
        debounceMs: 1,
      });
    }
    onSelected(newInputValue);
  };
  const onClose = (event, newInputValue) => {
    apiRef.current.stopCellEditMode({ id, field });
  };

  const onSelected = (newValue) => {
    if (newValue == "AGENTE") {
      if (!pre) {
        apiRef.current.setEditCellValue({
          id,
          field,
          value: newValue,
        });
        apiRef.current.setEditCellValue({
          id,
          field: "tipoSnmp",
          value: "-",
        });
        apiRef.current.setEditCellValue({
          id,
          field: "portaSnmp",
          value: "10050",
        });
      }
      setHostsEdit((data) => {
        let row = data.find((a) => a.id == id);
        row["interface"] = "AGENTE";
        row["tipoSnmp"] = "-";
        row["portaSnmp"] = "10050";
        return [...data];
      });
    } else {
      if (!pre) {
        if (!pre) {
          apiRef.current.setEditCellValue({
            id,
            field,
            value: newValue,
          });

          apiRef.current.setEditCellValue({
            id,
            field: "tipoSnmp",
            value: "SNMPv2",
          });
          apiRef.current.setEditCellValue({
            id,
            field: "portaSnmp",
            value: "161",
          });
        }
      }
      setHostsEdit((data) => {
        let row = data.find((a) => a.id == id);
        row["interface"] = "SNMP";
        row["tipoSnmp"] = "SNMPv2";
        row["portaSnmp"] = "161";
        return [...data];
      });
    }
  };
  useEffect(() => {
    if (value == "") {
      onSelected("SNMP");
    }
  }, [value]);

  return (
    <>
      <Autocomplete
        value={value || ""}
        disableClearable
        freeSolo
        blurOnSelect={pre}
        openOnFocus={pre}
        autoSelect
        onClose={onClose}
        onChange={onChange}
        onInputChange={onChange}
        disabled={disabled}
        sx={{
          p: "0px 10px",
          width: "100%",
          fontSize: 13,
          height: "100%",
          border: selected ? "1px solid" + theme.palette.primary.main : null,
        }}
        options={options}
        getOptionLabel={(option) => option || ""}
        InputProps={{
          disableUnderline: true,
        }}
        renderInput={(params) => (
          <TextField
            autoFocus
            key={"text" + field + id}
            {...params}
            variant="standard"
            size="small"
            slotProps={{
              input: {
                ...params.InputProps,
                disableUnderline: true,
                style: { fontSize: "13px", padding: "5px 0px" },
              },
            }}
          />
        )}
      />
    </>
  );
}
