import {
  Box,
  Paper,
  Tabs,
  Tab,
  Stack,
  TextField,
  InputAdornment,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
  Snackbar,
  Alert,
  Skeleton,
  Typography,
  Autocomplete,
  Checkbox,
  Grid,
} from "@mui/material";

import { useEffect, useState } from "react";
import {
  Add,
  Search,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank,
} from "@mui/icons-material";
import GroupMaps from "../../componentes/easyMaps/groupMaps";
import api from "../../services/api";
import CardMapsHome from "../../componentes/easyMaps/cardMapsHome";
import NewMap from "../../componentes/easyMaps/newMap";
const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    mt: 5,
    mb: 5,
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "80%",
    gap: 1,
    mt: 5,
  },
  headerBox: {
    display: "flex",
    flexDirection: "column",
    gap: 1,
    width: "100%",
  },
  headerTypography: {
    fontWeight: 600,
    color: "primary.main",
  },
  addIcon: { mt: -0.2 },
  headerPaper: {
    padding: "16px",
    display: "flex",
    gap: "16px",
    flexDirection: "column",
    position: "relative",
    width: "100%",
  },

  box: { display: "flex", width: "100%" },

  button: { width: "148px", minWidth: "148px" },
  buttonDialog: {
    color: theme?.palette?.color?.buttonConfirm || "primary.main",
  },
  textfield: { width: "360px" },
  cardsBox: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    flexWrap: "wrap",
    gap: 2,
  },
});

export default function EasyMaps(props) {
  const [tabValue, setTabValue] = useState(0);
  const [mapsGroups, setMapsGroups] = useState([]);
  const [mapsId, setMapsId] = useState([]);
  const [openNovoGrupoDialog, setOpenNovoGrupoDialog] = useState(false);
  const [titulo, setTitulo] = useState("");
  const [maps, setMaps] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [loadingButtom, setLoadingButtom] = useState(false);
  const [snackbar, setSnackbar] = useState(null);
  const [openNovoMapaDialog, setOpenNovoMapaDialog] = useState(false);
  const [openNovoPopDialog, setOpenNovoPopDialog] = useState(false);
  const [textFilter, setTextFilter] = useState("");
  const handleCloseSnackbar = () => setSnackbar(null);
  const theme = useTheme();
  const styles = useStyles(theme);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    handleApi();
    //update width
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    setTextFilter("");
  }, [tabValue]);

  async function handleApi() {
    try {
      setLoadingPage(true);
      await Promise.all([handleApiGetGroupMaps(), handleApiGetMaps()]);
    } catch (error) {
    } finally {
      setLoadingPage(false);
    }
  }

  async function handleApiGetMaps() {
    try {
      const response = await api.get("/easyMaps/map/findMany");
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível carregar os mapas",
          severity: "error",
        });
      } else {
        if (response.data) {
          setMaps(response.data);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error:${
          error?.message || "Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    }
  }

  async function handleApiGetGroupMaps() {
    try {
      const response = await api.get("/easyMaps/groupMaps/findMany");
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível carregar os Grupo de Mapas",
          severity: "error",
        });
      } else {
        if (response.data) {
          setMapsGroups(response.data);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error:${
          error?.message || "Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    }
  }

  async function handleApiNewGroupMap() {
    try {
      setLoadingButtom(true);
      const response = await api.post("/easyMaps/groupMaps/create", {
        titulo,
        mapsId: mapsId.map((map) => {
          return { mapsId: map.id };
        }),
        logsLoginClienteId: JSON.parse(localStorage.getItem("logs"))?.id || "",
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível criar mapa",
          severity: "error",
        });
      } else {
        if (response.data) {
          let newMaps = [...mapsGroups];
          newMaps.push(response.data);
          let mapsArray = maps.filter((map) =>
            mapsId.some((idObj) => idObj.id === map.id)
          );
          newMaps[newMaps.length - 1].maps = [...mapsArray];
          setMapsGroups(newMaps);
          setOpenNovoGrupoDialog(false);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error:${
          error?.message || "Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    } finally {
      setLoadingButtom(false);
    }
  }

  function addNewMapInGroup(newMap, index) {
    let newMaps = [...mapsGroups];
    if (!newMaps[index].maps) {
      newMaps[index].maps = [];
    }
    newMaps[index].maps.push(newMap);
    setMapsGroups(newMaps);
  }

  function removeManyMapInGroup(indexGroup, idMapArray) {
    let newMaps = [...mapsGroups];

    newMaps[indexGroup].maps = newMaps[indexGroup].maps.filter(
      (map) => !idMapArray.some((idObj) => idObj.id === map.id)
    );
    setMapsGroups(newMaps);
  }

  function updateTitleGroup(newTitle, indexGroup) {
    const newMaps = [...mapsGroups];
    newMaps[indexGroup].titulo = newTitle;
    setMapsGroups(newMaps);
  }
  function removeGroup(indexGroup) {
    const newMaps = [...mapsGroups];
    newMaps.splice(indexGroup, 1);
    setMapsGroups(newMaps);
  }

  function addMapInMaps(map) {
    const newMaps = [...maps];
    newMaps.push(map);
    setMaps(newMaps);
  }

  function updateTitleInMaps(newTitle, indexMap) {
    const newMaps = [...maps];
    newMaps[indexMap].titulo = newTitle;
    setMaps(newMaps);
  }
  function deleteMapsInMaps(indexMap) {
    const newMaps = [...maps];
    newMaps.splice(indexMap, 1);
    setMaps(newMaps);
  }
  function duplicateMapsInMaps(newMap, id) {
    let map = JSON.parse(JSON.stringify(maps.find((map) => map.id == id)));
    let mapDuplicated = { ...map, ...newMap };
    setMaps((maps) => [...maps, mapDuplicated]);
  }
  function updateMapTokenInMaps(mapToken, indexMap) {
    let newMaps = [...maps];
    newMaps[indexMap]["mapToken"] = mapToken;
    setMaps(newMaps);
  }
  function addMapsInGroup(mapArrayId, indexGroup) {
    const newMaps = [...mapsGroups];
    const mapsArray = maps.filter((map) =>
      mapArrayId.some((idObj) => idObj.id === map.id)
    );
    newMaps[indexGroup].maps = [...newMaps[indexGroup].maps, ...mapsArray];
    setMapsGroups(newMaps);
  }

  const groupMapsFilter =
    mapsGroups.filter((group) => {
      return group.titulo.toLowerCase().includes(textFilter.toLowerCase());
    }) || [];

  const mapasFilter =
    maps.filter((map) => {
      return map.titulo.toLowerCase().includes(textFilter.toLowerCase());
    }) || [];

  // console.log(Math.floor((width * 0.8) / 360));

  return (
    <Box sx={styles.container}>
      <Box sx={styles.body}>
        <Paper sx={styles.headerPaper}>
          <Tabs
            scrollButtons={true}
            value={tabValue}
            onChange={(event, newValue) => {
              setTabValue(newValue);
            }}
          >
            <Tab
              label={"Grupo de Mapas"}
              iconPosition="start"
              value={0}
              sx={styles.tab}
            />
            <Tab
              label={"Mapas"}
              iconPosition="start"
              value={1}
              sx={styles.tab}
            />
            <Tab
              label={"Pop's"}
              iconPosition="start"
              value={2}
              sx={styles.tab}
            />
          </Tabs>
          <Box sx={styles.headerBox}>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              flexWrap={"wrap"}
              gap={1}
            >
              <TextField
                placeholder={"Título do mapa"}
                autoComplete="off"
                size="small"
                value={textFilter}
                onChange={(event) => {
                  setTextFilter(event.target.value);
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: theme.palette.background.paperLinkedMaps,
                    boxShadow: "none",
                    width: "300px",
                    "&:hover": {
                      backgroundColor: theme.palette.background.paperLinkedMaps,
                    },
                  },
                }}
                slotProps={{
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <Search />
                      </InputAdornment>
                    ),
                  },
                }}
              />
              <Button
                color="primary"
                variant="contained"
                sx={styles.button}
                endIcon={<Add sx={styles.addIcon} />}
                onClick={() => {
                  tabValue === 0
                    ? setOpenNovoGrupoDialog(true)
                    : tabValue === 1
                    ? setOpenNovoMapaDialog(true)
                    : setOpenNovoPopDialog(true);
                }}
              >
                {tabValue === 0
                  ? "Novo Grupo"
                  : tabValue === 1
                  ? "Novo Mapa"
                  : "Novo POP"}
              </Button>
            </Stack>
          </Box>
        </Paper>
        {loadingPage ? (
          Array.from({ length: 8 }).map((_, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              sx={{ width: "100%", height: "58px", borderRadius: "6px" }}
            />
          ))
        ) : (
          <>
            {tabValue === 0 && (
              <>
                {Array.isArray(groupMapsFilter) &&
                  groupMapsFilter.length > 0 &&
                  groupMapsFilter.map((mapsGroup, index) => (
                    <GroupMaps
                      groupsId={mapsGroup.id}
                      title={mapsGroup.titulo}
                      mapsGroup={mapsGroup.maps}
                      key={index}
                      index={index}
                      setSnackbar={setSnackbar}
                      addNewMapInGroup={addNewMapInGroup}
                      removeManyMapInGroup={removeManyMapInGroup}
                      updateTitleGroup={updateTitleGroup}
                      removeGroup={removeGroup}
                      maps={maps}
                      addMapInMaps={addMapInMaps}
                      addMapsInGroup={addMapsInGroup}
                    />
                  ))}
              </>
            )}
            {tabValue === 1 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                <Grid
                  container
                  rowSpacing={1}
                  direction="row"
                  justifyContent="flex-start"
                  columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                  columns={parseInt(Math.floor((width * 0.8) / 360))}
                >
                  {Array.isArray(mapasFilter) &&
                    mapasFilter.length > 0 &&
                    mapasFilter.map(
                      (map, index) =>
                        map.tipo != 2 && (
                          <Grid item key={index} xs={1} flex={1}>
                            <CardMapsHome
                              key={index}
                              index={index}
                              id={map.id}
                              mapName={map.titulo}
                              tipo={map.tipo}
                              inGroup={false}
                              alertas={map.alertas}
                              hostsNames={[map.hosts]}
                              hostsCounts={map.hosts?.length || 0}
                              popsCounts={map.pops?.length || 0}
                              mapasCounts={map.mapas?.length || 0}
                              deleteMapsInMaps={deleteMapsInMaps}
                              duplicateMapsInMaps={duplicateMapsInMaps}
                              updateTitleInMaps={updateTitleInMaps}
                              updateMapTokenInMaps={updateMapTokenInMaps}
                              setSnackbar={setSnackbar}
                              mapas={map?.mapas}
                              pops={map?.pops}
                              mapToken={map?.mapToken}
                            />
                          </Grid>
                        )
                    )}
                </Grid>
              </Box>
            )}
            {tabValue === 2 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                <Grid
                  container
                  rowSpacing={1}
                  direction="row"
                  justifyContent="flex-start"
                  columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                  //columns={() => Math.floor((width * 0.8) / 360)}
                  columns={parseInt(Math.floor((width * 0.8) / 360))}
                  //columns={4}
                >
                  {Array.isArray(mapasFilter) &&
                    mapasFilter.length > 0 &&
                    mapasFilter.map(
                      (map, index) =>
                        map.tipo == 2 && (
                          <Grid item key={index} xs={1} flex={1}>
                            <CardMapsHome
                              key={index}
                              index={index}
                              id={map.id}
                              mapName={map.titulo}
                              tipo={map.tipo}
                              inGroup={false}
                              alertas={map.alertas}
                              hostsNames={[map.hosts]}
                              hostsCounts={map.hosts?.length || 0}
                              popsCounts={map.pops?.length || 0}
                              mapasCounts={map.mapas?.length || 0}
                              duplicateMapsInMaps={duplicateMapsInMaps}
                              deleteMapsInMaps={deleteMapsInMaps}
                              updateTitleInMaps={updateTitleInMaps}
                              updateMapTokenInMaps={updateMapTokenInMaps}
                              setSnackbar={setSnackbar}
                              mapas={map?.mapas}
                              pops={map?.pops}
                              mapToken={map?.mapToken}
                            />
                          </Grid>
                        )
                    )}
                </Grid>
              </Box>
            )}
          </>
        )}
      </Box>
      <Dialog
        open={openNovoGrupoDialog}
        onClose={() => {
          setOpenNovoGrupoDialog(false);
        }}
      >
        <DialogTitle>Criar novo grupo</DialogTitle>
        <DialogContent>
          <Typography>Título</Typography>
          <TextField
            variant={"outlined"}
            placeholder="Título do grupo"
            sx={styles.textfield}
            autoComplete="off"
            onChange={(event) => {
              setTitulo(event.target.value);
            }}
          />
          <Typography>Adicionar mapas existentes</Typography>
          <Autocomplete
            multiple
            id="checkboxes-maps"
            options={maps}
            disableCloseOnSelect
            onChange={(event, newValue) => {
              setMapsId(newValue);
            }}
            getOptionLabel={(option) => (option?.titulo ? option?.titulo : "")}
            renderOption={(props, option, { selected }) => (
              <Box component="li" {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option?.titulo}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant={"outlined"}
                placeholder="Seleciona os mapas"
                sx={styles.textfield}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenNovoGrupoDialog(false);
            }}
          >
            CANCELAR
          </Button>
          <Button
            sx={styles.buttonDialog}
            onClick={() => {
              handleApiNewGroupMap();
            }}
            disabled={loadingButtom || titulo === ""}
          >
            SALVAR
          </Button>
        </DialogActions>
      </Dialog>
      <NewMap
        key={"newMapa-Main"}
        openNovoMapaDialog={openNovoMapaDialog}
        setOpenNovoMapaDialog={setOpenNovoMapaDialog}
        setSnackbar={setSnackbar}
        maps={maps}
        initialtab={1}
        addMapInMaps={addMapInMaps}
      />
      <NewMap
        key={"newMapa-pop-Main"}
        openNovoMapaDialog={openNovoPopDialog}
        setOpenNovoMapaDialog={setOpenNovoPopDialog}
        setSnackbar={setSnackbar}
        maps={maps}
        initialtab={1}
        addMapInMaps={addMapInMaps}
        tipoDefault={2}
      />
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </Box>
  );
}
