import construcao from "../../assets/construcao.png";

export default function Construcao() {
  return (
    <div
      style={{
        width: "100%",
        height: "calc(80vh - 64px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <img
        src={construcao}
        style={{ height: "25vh", width: "auto" }}
        alt="img-construcao"
      />
      <div style={{ fontWeight: "600", fontSize: "18px", marginTop: "10px" }}>
        Página em construção
      </div>
      <div>volte em breve</div>
    </div>
  );
}
